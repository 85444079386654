import { Component, Input } from '@angular/core';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';

@Component({
  selector: 'app-image-carousel-template',
  templateUrl: './image-carousel-template.component.html',
  styleUrls: ['./image-carousel-template.component.scss']
})
export class ImageCarouselTemplateComponent {
  constructor(private modalDialogService: ModalDialogService) { }
  @Input() imageUrls: string[] = [];
  @Input() selectedIndex: number = 0;
  autoPlay: boolean = false;

  closeModal() {
    this.modalDialogService.closeModal();
  }
}
