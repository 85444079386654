export enum Permission {
  Can_View_Dashboard = 'view_dashboard',
  Can_Edit_Dashboard = 'edit_dashboard',
  // Can_View_Assets = 'view_assets',
  Can_Edit_Assets = 'edit_assets',
  Can_Create_Asset = 'create_asset',
  Can_Get_Assets = 'get_asset',
  Can_List_Assets = 'list_assets',
  Can_View_SystemInfo = 'view_systeminfo',
  Can_View_User_Management = 'view_user_management',
  Can_Assign_Gateway = 'link_assetschild',
  Can_Unlink_Asset_Associate = 'unlink_assetsassociate',
  Can_Link_Asset_Associate = 'link_assetsassociate',
  Can_Get_User = 'get_user',
  Can_Get_All_Users = 'list_users',
  Can_Create_User = 'create_user',
  Can_Delete_User = 'delete_user',
  Can_Update_User = 'update_user',
  Can_Get_Role_Assignments = 'get_userroleandscopeassignments',
  Can_Get_All_Users_Role_Assignments = 'list_userroleandscopeassignments',
  Can_Create_Role_Assignments = 'create_roleandscopeassignment',
  Can_Create_Role = 'create_role',
  Can_Delete_Role_Assignments = 'delete_roleandscopeassignment',
  Can_Get_Tenant = 'get_tenant',
  Can_Get_All_Tenant = 'list_tenants',
  Can_Create_Tenant = 'create_tenant',
  Can_Delete_Tenant = 'delete_tenant',
  Can_Update_Tenant = 'update_tenant',
  Can_Update_Asset = 'update_asset',
  Can_View_Admin_Dashboard = 'view_admindashboard',
  Can_View_Customer_Dashboard = 'view_customerdashboard',
  Can_Get_RawData = 'get_rawdata',
  Can_View_Telementry_Data = 'view_telemetrytrend',
  Can_Get_UnifiedMessage = 'get_unifiedmessage',

  Can_List_NotificationRules = 'list_notificationrules',
  Can_Update_Notification = 'update_notification_rule',
  Can_Create_Notification = 'create_notification_rule'
}
