export class SensorParameter {
  sensorId!: string;
  advertisementInterval!: number;
  advertisementDuration!: number;
  accelerationRange!: string;
  accelerationRangeSource!: string[];
  x_Axis!: string;
  y_Axis!: string;
  z_Axis!: string;
  axisSources!: string[];
  reportedAdvertisementInterval?:string;
  reportedAdvertisementDuration?:string;
  reportedAccelerationRange?:string;
  reported_X_Axis?:string;
  reported_Y_Axis?:string;
  reported_Z_Axis?:string;
}
