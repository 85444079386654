
<div class="filter-container1">
    <span *ngFor="let item of filterMenuListItems">
      <ejs-dropdownlist *ngIf="item.isChecked" class="dropdown" [placeholder]="item.filterName"  [dataSource]='item.data' [showClearButton]="true" [allowFiltering]="true" [fields]='fields'
      (change)="onInputChange($event, item.filterName)" value={{item.value}}>
     </ejs-dropdownlist>
    </span>
   <app-custom-menu class="add-filter-btn" (kebabMenuClickEvent)="openKebabMenuFlyout($event)" [menuItems]="filterMenuListItems" id="filter-menu"></app-custom-menu>
   <div #flyoutContainer class="grid-flyout-container">
       <ejs-listbox [dataSource]='flyoutMenuItems' [fields]='flyOutItemFields' cssClass="kebab-menu-flyout"
         *ngIf="showFlyOutItems" >
         <ng-template #itemTemplate="" let-data="" >
           <div class="kebab-menu-flyout-item">
             <input type="checkbox" (change)="filterMenuchange(data, $event)" [checked]="data.isChecked" class="filter-checkbox"/> 
             <div [title]="data.filterName" class="filter-menu-text"> {{data.filterName}}</div>
           </div>
         </ng-template>
       </ejs-listbox>
   </div>
  </div>

