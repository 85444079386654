<div class="notification-heading-container">
  <h2>Notifications</h2>
  <app-custom-button [buttonData]="dismissAllButtonData" (buttonClick)="dismissAllBtnClick($event)" />
</div>
<div class="notifications-list-container">
  <div *ngFor="let item of getCommandSignalNotifications()" class="notification-item">
    <app-command-signal [commandEventData]="item?.data"
      (notificationClicked)="notificationClicked()"></app-command-signal>
    <hr>
  </div>
  <div *ngFor="let item of getAlertSignalNotifications()" class="notification-item">
    <app-alert-signal [alertEventData]="item?.data" (notificationClicked)="notificationClicked()"></app-alert-signal>
    <hr>
  </div>
  <div *ngIf="getAlertSignalNotifications().length > 0" class="view-details-btn-container">
    <app-custom-button [buttonData]="viewAllAlertSignalNotificationsButtonData"
      (buttonClick)="viewAllNotificationsBtnClick(viewAllAlertSignalNotificationsButtonData)" />
  </div>
  <hr *ngIf="getAlertSignalNotifications().length > 0">
</div>