<div class="command-signal-template-container" *ngIf="commandEventData">
  <span class="event-icon" [ngClass]="getStatusIconClass()"></span>
  <div class="command-signal-template-texts">
    <p class="text-sm command-heading" (click)="commandNotificationClicked(commandEventData)"
      (keypress)="commandNotificationClicked(commandEventData)" tabindex="-1"
      (keypress)="commandNotificationClicked(commandEventData)" tabindex="-1">
      {{title}}
    </p>
    <small class="text-xsm">{{message}}</small>
    <small class="text-xsm timestamp">{{timeAgoValue(commandEventData.lastUpdatedTime)}}</small>
  </div>
</div>

<div *ngIf="commandEventData && commandEventData.response" class="view-messages-container">
  <div class="view-messages-heading-container" (click)="toggleMessages()" (keypress)="toggleMessages()" tabindex="-1">
    <span class="e-icons" [ngClass]="{'e-chevron-up': !isCollapsed, 'e-chevron-down': isCollapsed}"></span>
    <small class="text-xsm view-messages-heading">View Messages</small>
  </div>
  <ul *ngIf="!isCollapsed" class="view-messages">
    <li class="text-xsm" *ngFor="let item of getDetailedMessages(commandEventData)">{{item.message}}</li>
  </ul>
</div>