import { Component, Input } from '@angular/core';
import { SetupStatus } from '../../app-constants/shared.enum';

@Component({
  selector: 'app-setup-status',
  templateUrl: './setup-status.component.html',
  styleUrls: ['./setup-status.component.scss']
})
export class SetupStatusComponent {
  @Input() setupStatus?: SetupStatus;
}
