import { Injectable } from '@angular/core';
import { ApiResponse } from '../../models/api-response';
import { environment } from 'src/environments/environment';
import { SENSOR_API_ENDPOINTS } from '../../app-constants/api-constants';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from '../login-services/logging.service';
import { catchError, throwError, lastValueFrom, Observable, map, tap } from 'rxjs';
import { SensorSchedule } from '../../models/sensor-scheduler';
import { ErrorHandlerService } from '../error-handler/error-handler.service';

@Injectable()
export class SensorSchedulerService {
  constructor(
    private http: HttpClient,
    private appInsightService: LoggingService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getSensorMeasurementScheduler(sensorId: string, tenantId: string): Observable<SensorSchedule> {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.SAVE_SENSOR_MEASUREMENT_SCHEDULER(tenantId, sensorId);
    return this.http.get<ApiResponse<SensorSchedule>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: ApiResponse<SensorSchedule>) => {
          const errorMessage = error.error?.map(pair => `${pair.key}: ${pair.value}`).join(', ');
          this.appInsightService.logException(errorMessage);
          throw error;
        })
      );
  }

  public saveSensorMeasurementScheduler(tenantId: string, sensorId: string, sensorSchedule: SensorSchedule): Promise<ApiResponse<SensorSchedule>> {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.SAVE_SENSOR_MEASUREMENT_SCHEDULER(tenantId, sensorId);
    const saveScheduleRequest: SensorSchedule = {
      sensorId: sensorSchedule.sensorId,
      wakeUpTime: sensorSchedule.wakeUpTime,
      sleepTime: sensorSchedule.sleepTime,
      measurementIntervalInMinutes: sensorSchedule.measurementIntervalInMinutes,
      offsetTime: sensorSchedule.offsetTime,
      schedule: sensorSchedule.schedule
    };
    const sensorMeasurementSchedule = this.http.put<ApiResponse<SensorSchedule>>(url, saveScheduleRequest)
      .pipe(
        catchError((error: ApiResponse<SensorSchedule>) => {
          const errorMessage = error.error?.map(pair => `${pair.key}: ${pair.value}`).join(', ');
          this.appInsightService.logException(errorMessage);
          throw error;
        })
      );
    return lastValueFrom(sensorMeasurementSchedule);
  }

  public getRestoreDefaultSensorSchedule(tenantId: string): Observable<SensorSchedule> {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_DEFAULT_SCHEDULE(tenantId);
    return this.http.get<ApiResponse<SensorSchedule>>(url)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }
}
