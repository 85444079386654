import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TooltipComponent } from '@syncfusion/ej2-angular-popups';
import { SessionService } from '../../services/session-management-services/session.service';
import { BreadcrumbInfo } from '../../models/breadcrumb-info';
import { BreadcrumbService } from '../../services/breadcrumb-service/breadcrumb.service';
import { AssetType } from '../../app-constants/asset-constants';

@Component({
  selector: 'app-asset-hierarchy-tool-tip',
  templateUrl: './asset-hierarchy-tool-tip.component.html',
  styleUrls: ['./asset-hierarchy-tool-tip.component.scss']
})
export class AssetHierarchyToolTipComponent {
  breadcrumbs:  BreadcrumbInfo[] = [];
  @Input() label!: string;
  @Input() assetId!: string;
  @Input() assetHierarchyData!:any;
  @Input() preloadedData: boolean = false;
  position: string = 'TopLeft';
  loading: boolean = true;
  @ViewChild('tooltipObj') tooltipObj!: TooltipComponent;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private sessionService: SessionService,
  ) {}

  onBeforeOpen(e: any): void {
    this.loading = true;
    if (!this.preloadedData)
    {
      this.position = 'TopCenter';
      this.breadcrumbService.getBreadcrumb(this.sessionService.tenantId, this.assetId, true).subscribe((breadCrumbInfo: BreadcrumbInfo[]) => {
        this.breadcrumbs = breadCrumbInfo.filter(breadcrumb => breadcrumb.objectType !== AssetType.ENTERPRISE && breadcrumb.objectType !== AssetType.EQUIPMENT);
        this.loading = false;
      });
    }
    else {
      this.breadcrumbs = this.assetHierarchyData;
      this.loading = false;
    }
  }
}
