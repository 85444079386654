import { AppRouteConstants } from './shared/app-constants/app-route-constants';
import { appRouteGuard } from './core/auth/app.route.guard';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './core/home/home.component';
import { NgModule } from '@angular/core';
import { NotificationPreferencesComponent } from './shared/components/notification-preferences/notification-preferences.component';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { RouteSet } from './shared/app-constants/role-constants';
import { SignOutComponent } from './login/components/sign-out/sign-out/sign-out.component';

const oktaConfigurations = new OktaAuth({ ...environment.oktaAuthConfiguration as OktaAuthOptions });

const routes: Routes = [
  {
    path: '',
    redirectTo: `${AppRouteConstants.ROUTE_TO_CUSTOMER}`,
    pathMatch: 'full',
    data: { role: RouteSet.Customer }
  },
  {
    path: `${AppRouteConstants.ROUTE_TO_CUSTOMER}`,
    loadChildren: () => import('./customer/customer.module').then((module) => module.CustomerModule),
    canActivate: [appRouteGuard],
    data: { role: RouteSet.Customer }
  },
  {
    path: `${AppRouteConstants.NOTIFICATION_PREFERENCES}`,
    component: NotificationPreferencesComponent,
    canActivate: [appRouteGuard],
    data: { role: RouteSet.Customer }
  },
  {
    path: `${AppRouteConstants.ROUTE_TO_ADMIN}`,
    loadChildren: () => import('./admin/admin.module').then((module) => module.AdminModule),
    canActivate: [appRouteGuard],
    data: { role: RouteSet.Admin }
  },
  {
    path: `${AppRouteConstants.APP_LOGIN_ROUTE}`, loadChildren: () => import('./login/login.module').then((module) => module.LoginModule)
  },
  {
    path: `${AppRouteConstants.APP_LOGOUT_ROUTE}`, component: SignOutComponent
  },
  {
    path: `${AppRouteConstants.ROUTE_TO_HOME}`, component: HomeComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    OktaAuthModule,
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth: oktaConfigurations }
    },
  ]
})
export class AppRoutingModule {
}
