import { TimeSpan } from './time-span';

declare global {
  interface Date {
    readonly prototype: Date;
    addDays(days: number, useThis?: boolean): Date;
    isToday(): boolean;
    clone(): Date;
    isAnotherMonth(date: Date): boolean;
    isWeekend(): boolean;
    isSameDate(date: Date): boolean;
    getStringDate(): string;
    findDifference(date: Date): TimeSpan;
    timeAgo(date: Date): string;
    toDate():  Date | undefined
    toTimeSpan():  TimeSpan | undefined
  }
  interface String {
    readonly prototype: String;
    toDate():  Date | undefined
    toTimeSpan():  TimeSpan | undefined
    timeAgo(date: Date): String | undefined
    toLocaleDateString(locales?: Intl.LocalesArgument, options?: Intl.DateTimeFormatOptions): String | undefined
  }
}

Date.prototype.addDays = function(days: number): Date {
  if (!days) return this;
  const date = this;
  date.setDate(date.getDate() + days);

  return date;
};

Date.prototype.isToday = function(): boolean {
  const today = new Date();
  return this.isSameDate(today);
};

Date.prototype.clone = function(): Date {
  return new Date(+this);
};

Date.prototype.isAnotherMonth = function(date: Date): boolean {
  return date && this.getMonth() !== date.getMonth();
};

Date.prototype.isWeekend = function(): boolean {
  return this.getDay() === 0 || this.getDay() === 6;
};

Date.prototype.isSameDate = function(date: Date): boolean {
  return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};

Date.prototype.getStringDate = function(): string {
    //Month names
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const today = new Date();
  if (this.getMonth() === today.getMonth() && this.getDay() === today.getDay()) {
    return 'Today';
  } else if (this.getMonth() === today.getMonth() && this.getDay() === today.getDay() + 1) {
    return 'Tomorrow';
  } else if (this.getMonth() === today.getMonth() && this.getDay() === today.getDay() - 1) {
    return 'Yesterday';
  } else {
    return monthNames[this.getMonth()] + ' ' + this.getDay() + ', ' + this.getFullYear();
  }
};

Date.prototype.findDifference =  function(date: Date): TimeSpan
{
  const date1 = this;
  const date2 = date;

  const msBetweenDates = date2.getTime() - date1.getTime();

  return new TimeSpan(msBetweenDates);
};

Date.prototype.timeAgo =  function(date: Date): string
{
  const date1 = this;
  const date2 = date;

  const msBetweenDates = date2.getTime() - date1.getTime();
  const time = new TimeSpan(msBetweenDates);
  const days = time.days;
  const hours = time.hours;
  const minutes = time.minutes;
  const seconds = time.seconds;
  const char = 's';
  if (days >= 365) {
    const years = Math.floor(days / 365);
    return `${years} year${appendCharIfGreater(years, char)} ago`;
  }
  if (days >= 30) {
    const months = Math.floor(days / 30);
    return `${months} month${appendCharIfGreater(months, char)} ago`;
  }
  if (days >= 7) {
    const weeks = Math.floor(days / 7);
    return `${weeks} week${appendCharIfGreater(weeks, char)} ago`;
  }
  if (days > 0) {
    return `${days} day${appendCharIfGreater(days, char)} ago`;
  }
  if (hours > 0) {
    return `${hours} hour${appendCharIfGreater(hours, char)} ago`;
  }
  if (minutes > 0) {
    return `${minutes} minute${appendCharIfGreater(minutes, char)} ago`;
  }
  if (seconds > 0) {
    return `${seconds} second${appendCharIfGreater(seconds, char, 10)} ago`;
  }
  return '';
};

function appendCharIfGreater(value:number, char:string, num:number = 1) {
  return value > num ? char : '';
}

Date.prototype.toDate = function(): Date | undefined
{
  try
  {
    if (this !== undefined)
      return this;
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

Date.prototype.toTimeSpan = function(): TimeSpan | undefined
{
  try
  {
    const date = this?.toDate();
    if (date !== undefined)
      return new TimeSpan(date.getMilliseconds());
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

String.prototype.toDate = function(): Date | undefined
{
  try
  {
    if (this !== undefined)
      return new Date(this.toString());
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

String.prototype.toTimeSpan = function(): TimeSpan | undefined
{
  try
  {
    const date = this?.toDate();
    if (date !== undefined)
      return new TimeSpan(date.getMilliseconds());
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

String.prototype.timeAgo = function(date: Date): String | undefined
{
  try
  {
    const d = this?.toDate();
    if (d !== undefined)
      return d.timeAgo(date);
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

String.prototype.toLocaleDateString = function(locales?: Intl.LocalesArgument, options?: Intl.DateTimeFormatOptions): String | undefined
{
  try
  {
    const date = this?.toDate();
    if (date !== undefined)
      return date.toLocaleDateString(locales, options);
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export { };
