import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-battery-health-status',
  templateUrl: './battery-health-status.component.html',
  styleUrls: ['./battery-health-status.component.scss']
})
export class BatteryHealthStatusComponent {
  @Input() lastValue?: string;

  get batteryHealthLabel() {
    // Parse the last value to a number
    if (!this.lastValue)
      return 'Replace';
    const numericValue = parseFloat(this.lastValue);

    // Check conditions and return the appropriate label
    if (!isNaN(numericValue)) {
      if (numericValue >= 2.5) {
        return 'Okay';
      }
      else {
        return 'Replace';
      }
    } else {
      return ''; // Handle case where lastValue is not a valid number
    }
  }
}
