import { AdminInfoRoutes } from 'src/app/admin/constants/route.constants';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { LoginState } from 'src/app/shared/models/login-state';
import { OktaGuardService } from './okta-guard.service';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { SharedRoute } from '../../shared/app-constants/app-route-constants';
import { RoleType, RouteSet } from '../../shared/app-constants/role-constants';

export const appRouteGuard: CanActivateFn = async(route, state) => {
  const authService = inject(OktaGuardService);
  const router = inject(Router);
  const sessionService = inject(SessionService);
  const isAuthenticated = await authService.isAuthenticated();
  if (!isAuthenticated) {
    return redirectToLogin(isAuthenticated, state, router, sessionService);
  }
  else {
    const isAuthorised = await authService.canActivate(route, state);
    if (isAuthorised) {
      const isAdminPage = SharedRoute.includes(state.url) && RouteSet.Admin.includes(sessionService.roleName) || state.url.startsWith(`/${AdminInfoRoutes.BASE_ADMIN_ROUTE}`);
      publishLoginState(isAuthorised, isAdminPage, sessionService, state);
      return true;
    }
    else {
      return router.navigate(['**']);
    }
  }
};

const redirectToLogin = (isAuthenticated: boolean, state: RouterStateSnapshot, router: Router, sessionService: SessionService): boolean => {
  const unauthorizedLoginState: LoginState = {
    loginStatus: isAuthenticated,
    roleName: sessionService.roleName,
    isAdminPage: false,
    isAdminRole: false
  };
  sessionService.loginState$.next(unauthorizedLoginState);
  sessionService.requestedUrl = window.location.pathname !== '/' ? state.url : router.url;
  router.navigate(['/login']);
  return isAuthenticated;
};
const publishLoginState = (isAuthorized: boolean, isAdminPage: boolean, sessionService: SessionService, state: RouterStateSnapshot): boolean => {
  const adminLoginState: LoginState = {
    loginStatus: isAuthorized,
    roleName: sessionService.roleName,
    isAdminPage: isAdminPage,
    isAdminRole: sessionService.isAdminRole
  };
  sessionService.loginState$.next(adminLoginState);
  sessionService.requestedUrl = state.url;
  return isAuthorized;
};
