import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import 'src/app/shared/extension-classes/date.extension';
import { AlertSignalEventTypes } from 'src/app/shared/app-constants/shared.enum';
import { SignalRAlertSignalEvent, AlarmOrWarningEventData, AssetHealthStateChangeEventData, DeviceOnlineOrOfflineEventData } from 'src/app/shared/models/signalr-events';
import { AssetsInfoHelperService } from 'src/app/customer/assets-info/services/assets-info-helper.service';
import { AssetType } from 'src/app/shared/app-constants/asset-constants';
import { NotificationService } from 'src/app/shared/services/notification-services/notification.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb-service/breadcrumb.service';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { BreadcrumbInfo } from 'src/app/shared/models/asset';

@Component({
  selector: 'app-alert-signal',
  templateUrl: './alert-signal.component.html',
  styleUrls: ['./alert-signal.component.scss']
})
export class AlertSignalComponent implements OnInit {
  @Input() alertEventData?: SignalRAlertSignalEvent<AlarmOrWarningEventData | AssetHealthStateChangeEventData | DeviceOnlineOrOfflineEventData>;
  @Output() notificationClicked: EventEmitter<void> = new EventEmitter<void>();
  alertSignalEventTypes = AlertSignalEventTypes;
  title: string = '';
  message: string = '';
  assetHierarchyData!: BreadcrumbInfo[];
  preloadedData: boolean = true;

  constructor(
    private assetsInfoHelperService: AssetsInfoHelperService,
    private breadcrumbService: BreadcrumbService,
    private notificationService: NotificationService,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    if (!this.alertEventData) return;
    this.loadAssetHierarchyData(this.alertEventData?.equipmentId);
    this.title = this.getTitleForAlertSignalNotification(this.alertEventData);
    this.message = this.getMessageForAlertSignalNotification(this.alertEventData);
  }

  timeAgoValue(timeStamp: number | undefined) {
    return this.notificationService.timeAgoValue(timeStamp);
  }

  loadAssetHierarchyData(assetId:string)
  {
    if (!assetId) return;
    this.breadcrumbService.getBreadcrumb(this.sessionService.tenantId, assetId, true).subscribe((breadCrumbInfo: BreadcrumbInfo[]) => {
      this.assetHierarchyData = breadCrumbInfo.filter(breadcrumb => breadcrumb.objectType !== AssetType.ENTERPRISE && breadcrumb.objectType !== AssetType.EQUIPMENT);
    });
  }

  alertNotificationClicked(alertEventData: SignalRAlertSignalEvent<AlarmOrWarningEventData | AssetHealthStateChangeEventData | DeviceOnlineOrOfflineEventData>) {
    if (this.isAlertNotificationClickable(alertEventData)) {
      this.assetsInfoHelperService.assetNavigation(AssetType.EQUIPMENT, alertEventData?.equipmentId, alertEventData?.equipmentName, `/${alertEventData?.scope?.split('/')[1]}`);
      this.notificationClicked.emit();
    }
  }

  isAlertNotificationClickable(alertEventData: SignalRAlertSignalEvent<AlarmOrWarningEventData | AssetHealthStateChangeEventData | DeviceOnlineOrOfflineEventData>): boolean {
    return alertEventData.equipmentId !== null && alertEventData.equipmentId !== undefined && alertEventData.equipmentId !== '' && (alertEventData.eventType === AlertSignalEventTypes.Alarm || alertEventData.eventType === AlertSignalEventTypes.Warning);
  }

  getAlertIcon(): string {
    return this.alertEventData ? this.notificationService.getIconForAlertNotification(this.alertEventData) : '';
  }

  getTitleForAlertSignalNotification(alertEventData: SignalRAlertSignalEvent<AlarmOrWarningEventData | AssetHealthStateChangeEventData | DeviceOnlineOrOfflineEventData>): string {
    return this.notificationService.getTitleForAlertSignalNotification(alertEventData);
  }

  getMessageForAlertSignalNotification(alertEventData: SignalRAlertSignalEvent<AlarmOrWarningEventData | AssetHealthStateChangeEventData | DeviceOnlineOrOfflineEventData>): string {
    return this.notificationService.getMessageForAlertSignalNotification(alertEventData);
  }
}
