import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SensorTelemetryGraphS7100ClientMockService } from './sensor-telemetry-graph-s7100.client-mock.service';
import { SensorTelemetryGraphS7100ClientService } from './sensor-telemetry-graph-s7100.client.service';
import { SensorTelemetryGraphS7100InterfaceClientService } from './sensor-telemetry-graph-s7100-interface.client.service';
import { TokenInfo } from '../../models/token-info';

@Injectable({
  providedIn: 'root'
})
export class SensorTelemetryGraphS7100Service implements SensorTelemetryGraphS7100InterfaceClientService {
  sensorTelemetryGraphS7100InterfaceClientService: SensorTelemetryGraphS7100InterfaceClientService;
  constructor(
    public httpClient: HttpClient,
    private sensorTelemetryGraphS7100ClientService: SensorTelemetryGraphS7100ClientService,
    private sensorTelemetryGraphS7100ClientMockService: SensorTelemetryGraphS7100ClientMockService
  ) {
    if (!environment.isMock) {
      this.sensorTelemetryGraphS7100InterfaceClientService = this.sensorTelemetryGraphS7100ClientMockService;
    }
    else {
      this.sensorTelemetryGraphS7100InterfaceClientService = this.sensorTelemetryGraphS7100ClientService;
    }
  }

  getTokenForReport(_workspaceId: any, _reportId: any): Promise<TokenInfo> {
    return (this.sensorTelemetryGraphS7100InterfaceClientService.getTokenForReport(_workspaceId, _reportId));
  }

  getTokenForReportByEmailId(_workspaceId: any, _reportId: any, _dataSetId: any, _userId: any): Promise<TokenInfo> {
    return (this.sensorTelemetryGraphS7100InterfaceClientService.getTokenForReportByEmailId(_workspaceId, _reportId, _dataSetId, _userId));
  }
}
