import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonLabels, ButtonNames, ButtonTypes, CustomEventSource, CustomEventType, GridUIType, ModalTemplateTypes } from 'src/app/shared/app-constants/shared.enum';
import { ButtonData, OpenModalDialogData } from 'src/app/shared/models/shared.models';
import { groupDetailsTableDefinition } from 'src/app/shared/models/table-definition';
import { TreeGridColumnData } from 'src/app/shared/models/tree-grid';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { CampaignManagementService } from '../../services/campaign-management.service';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { AdminInfoRoutes, CampaignManagementRoutes } from 'src/app/admin/constants/route.constants';
import { AssetApiIncludeConstants } from 'src/app/shared/app-constants/asset-constants';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { filterData } from 'src/app/admin/artifact-management/models/artifact';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {
  cancelButtonData: ButtonData = { label: ButtonLabels.CANCEL, name: ButtonNames.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER };
  createButtonData: ButtonData = { label: ButtonLabels.CREATE, name: ButtonNames.CREATE, type: ButtonTypes.PRIMARY_BASIC };
  code:string = 'XW6ORRWMR28jq174vBpWwdBHFMOMib5gYFZnCc0RbioBAzFubz52iA==';
  filterMenuListItems:any[] = [{ fieldName: 'serialNumber', filterName: 'Serial Number', isChecked: true, value: '', data: [] }, { fieldName: 'enterpriseName', filterName: 'Enterprise Name', isChecked: false, value: ''  }];
  formGroup!: FormGroup;
  getGatewayListServiceUrl: string = '';
  groupData: Array<any> = [];
  groupId: string = '';
  headerName: string = 'Create Group';
  headerText: any = [{ text: 'Gateways' }, { text: 'Sensor' },];
  selectedDevices = [];

  protected readonly groupDetailsTableDefinition: Array<TreeGridColumnData> = groupDetailsTableDefinition;
  protected readonly GridUIType = GridUIType.CHECK_BOX_FLAT_GRID;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private modalDialogService: ModalDialogService,
    private campaignManagementService: CampaignManagementService,
    private sessionSerive: SessionService,
  ) { }

  ngOnInit(): void {
    this.initiatePageLoad();
  }

  initiatePageLoad() {
    this.createFormGroup();
    this.getGatewayListServiceUrl = this.campaignManagementService.getGatewayListServiceUrl(this.sessionSerive.tenantId, this.code);
    this.campaignManagementService.getAllAssets(this.sessionSerive.tenantId, this.code).subscribe((response) => {
      const serialNumber = response.map((x: any) => {
        return {
          name: x.serialNumber,
        };
      });
      this.filterMenuListItems = this.filterMenuListItems.map((x: any) => {
        if (x.fieldName === 'serialNumber') {
          return { ...x, data: serialNumber };
        } else {
          return x;
        }
      });
    });
  }

  createFormGroup() {
    this.formGroup = this.formBuilder.group({
      groupName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
    });
  }

  updateFormGroup(group: any) {
    this.formGroup.patchValue({
      groupName: group.groupName,
    });
    this.selectedDevices = group.data;
  }

  gridDataEvent(event: CustomEvent) {
    if (event.eventSource === CustomEventSource.TREEGRID && event.eventType === CustomEventType.ROW_SELECTION_CHANGED) {
      this.selectedDevices = event.eventData.map((item:any) => ({ serialNo: item.id }));
    }
  }

  createNewGroup() {
    const groupName = this.formGroup?.get('groupName')?.value;
    const savegroupRequest: any = {
      groupName: groupName,
      createdBy: this.sessionSerive?.userDetails?.fullName,
      gateways: this.selectedDevices
    };
    this.campaignManagementService.createGroup(this.sessionSerive.tenantId, savegroupRequest, this.code).subscribe((response) => {
      const data = new OpenModalDialogData();
      data.templateName = ModalTemplateTypes.SUCCESS_TEMPLATE;
      data.customData.message = 'Group Creation Initiated';
      this.modalDialogService.openModal(data);
      this.onCancel();
    });
  }

  onCancel() {
    const url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${CampaignManagementRoutes.CAMPAIGN_MANAGEMENT_BASE_ROUTE}/${CampaignManagementRoutes.CAMPAIGN_MANAGEMENT_GROUP_SUMMARY}`;
    this.router.navigate([url]);
  }

  buttonClick() {
    this.createNewGroup();
  }

  onFilterValuechange(e: CustomEvent) {
    const array = this.filterMenuListItems;
    const data = array.map(x => x.filterName === e.eventData.name ? { ...x, value:  e.eventData.items } : { ...x });
    this.filterMenuListItems = [...data];
    let url = this.getGatewayListServiceUrl.split('&')[0];
    data.forEach((x:filterData) => {
      if (x.value) {
        url += `&${x.fieldName}=${x.value}`;
      }
    });
    this.getGatewayListServiceUrl = url;
    this.filterMenuListItems = [...data];
  }

  onfilterSelect(e: CustomEvent) {
    const changedData = this.filterMenuListItems.map(x => x.filterName === e.eventData.data.filterName ? { ...x, isChecked: !x.isChecked } : { ...x });
    this.filterMenuListItems = [...changedData];
  }
}
