<!-- SIGNAL 4 OR ABOVE -->
<svg xmlns="http://www.w3.org/2000/svg" *ngIf="strength === 'HIGH'" [ngClass]="{ 'xsm-icon': size === 'size_xsm',
  'sm-icon': size === 'size_sm',
  'lg-icon': size === 'size_lg',
  'xl-icon': size === 'size_xl',
  'xxl-icon': size === 'size_xxl',
  'xl2-icon': size === 'size_2xl',
  'xl4-icon': size === 'size_4xl',
  'xl5-icon': size === 'size_5xl' }" viewBox="0 0 31 27" fill="none">
  <path d="M4.77246 23.7272L4.77246 20.3181" stroke="#02306B" stroke-width="4.54545" stroke-linecap="round" />
  <path d="M11.5908 23.7272L11.5908 14.6363" stroke="#02306B" stroke-width="4.54545" stroke-linecap="round" />
  <path d="M18.4092 23.7272L18.4092 8.95451" stroke="#02306B" stroke-width="4.54545" stroke-linecap="round" />
  <path d="M25.2275 23.7272L25.2275 3.27269" stroke="#02306B" stroke-width="4.54545" stroke-linecap="round" />
</svg>

<!-- SIGNAL 3 -->
<svg xmlns="http://www.w3.org/2000/svg" *ngIf="strength === 'GOOD'" [ngClass]="{ 'xsm-icon': size === 'size_xsm',
    'sm-icon': size === 'size_sm',
    'lg-icon': size === 'size_lg',
    'xl-icon': size === 'size_xl',
    'xxl-icon': size === 'size_xxl',
    'xl2-icon': size === 'size_2xl',
    'xl4-icon': size === 'size_4xl',
    'xl5-icon': size === 'size_5xl' }" viewBox="0 0 30 27" fill="none">
  <path d="M4.77246 23.7272L4.77246 20.3181" stroke="#02306B" stroke-width="4.54545" stroke-linecap="round" />
  <path d="M11.5908 23.7272L11.5908 14.6363" stroke="#02306B" stroke-width="4.54545" stroke-linecap="round" />
  <path d="M18.4092 23.7272L18.4092 8.95451" stroke="#02306B" stroke-width="4.54545" stroke-linecap="round" />
  <path d="M25.2275 23.7272L25.2275 3.27269" stroke="#D6DFEB" stroke-width="4.54545" stroke-linecap="round" />
</svg>

<!-- SIGNAL 2 -->
<svg xmlns="http://www.w3.org/2000/svg" *ngIf="strength === 'LOW'" [ngClass]="{ 'xsm-icon': size === 'size_xsm',
    'sm-icon': size === 'size_sm',
    'lg-icon': size === 'size_lg',
    'xl-icon': size === 'size_xl',
    'xxl-icon': size === 'size_xxl',
    'xl2-icon': size === 'size_2xl',
    'xl4-icon': size === 'size_4xl',
    'xl5-icon': size === 'size_5xl' }" viewBox="0 0 31 27" fill="none">
  <path d="M4.77246 23.7272L4.77246 20.3181" stroke="#02306B" stroke-width="4.54545" stroke-linecap="round" />
  <path d="M11.5908 23.7272L11.5908 14.6363" stroke="#02306B" stroke-width="4.54545" stroke-linecap="round" />
  <path d="M18.4092 23.7272L18.4092 8.95451" stroke="#D6DFEB" stroke-width="4.54545" stroke-linecap="round" />
  <path d="M25.2275 23.7272L25.2275 3.27269" stroke="#D6DFEB" stroke-width="4.54545" stroke-linecap="round" />
</svg>

<!-- SIGNAL 1 -->
<svg xmlns="http://www.w3.org/2000/svg" *ngIf="strength === 'VERYLOW'" [ngClass]="{ 'xsm-icon': size === 'size_xsm',
    'sm-icon': size === 'size_sm',
    'lg-icon': size === 'size_lg',
    'xl-icon': size === 'size_xl',
    'xxl-icon': size === 'size_xxl',
    'xl2-icon': size === 'size_2xl',
    'xl4-icon': size === 'size_4xl',
    'xl5-icon': size === 'size_5xl' }" viewBox="0 0 30 27" fill="none">
  <path d="M4.77246 23.7272L4.77246 20.3181" stroke="#02306B" stroke-width="4.54545" stroke-linecap="round" />
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M22.9551 11.3173C23.1104 11.4343 23.2442 11.5806 23.347 11.7513L27.5005 18.625V3.27267C27.5005 2.01747 26.483 0.999939 25.2278 0.999939C23.9726 0.999939 22.9551 2.01747 22.9551 3.27267V11.3173ZM12.1018 25.9423C11.9377 25.98 11.7667 25.9999 11.5911 25.9999C10.3359 25.9999 9.31836 24.9824 9.31836 23.7272V14.6363C9.31836 13.3811 10.3359 12.3636 11.5911 12.3636C12.8463 12.3636 13.8638 13.3811 13.8638 14.6363L13.8638 22.9844L12.2134 25.7156C12.1694 25.7886 12.1322 25.8645 12.1018 25.9423ZM20.6822 11.7026V8.95448C20.6822 7.69929 19.6646 6.68176 18.4094 6.68176C17.1543 6.68176 16.1367 7.69929 16.1367 8.95448L16.1367 19.2229L20.6515 11.7513C20.6615 11.7349 20.6717 11.7186 20.6822 11.7026Z"
    fill="#D6DFEB" />
  <path
    d="M21.9998 13C22.4436 13 22.853 13.2344 23.078 13.6187L29.828 25.1187C30.0561 25.5062 30.0561 25.9844 29.8342 26.3719C29.6124 26.7594 29.1967 27 28.7499 27H15.2498C14.8029 27 14.3873 26.7594 14.1654 26.3719C13.9436 25.9844 13.9467 25.5031 14.1717 25.1187L20.9217 13.6187C21.1467 13.2344 21.5561 13 21.9998 13Z"
    fill="#CC3300" />
  <path
    d="M22.3137 17.5502C22.3137 17.2515 22.0724 17.0101 21.7737 17.0101C21.475 17.0101 21.2336 17.2515 21.2336 17.5502V21.8705C21.2336 22.1692 21.475 22.4105 21.7737 22.4105C22.0724 22.4105 22.3137 22.1692 22.3137 21.8705V17.5502ZM21.7737 24.5706C21.9527 24.5706 22.1244 24.4995 22.251 24.3729C22.3776 24.2463 22.4487 24.0746 22.4487 23.8956C22.4487 23.7166 22.3776 23.5449 22.251 23.4183C22.1244 23.2917 21.9527 23.2205 21.7737 23.2205C21.5946 23.2205 21.4229 23.2917 21.2963 23.4183C21.1698 23.5449 21.0986 23.7166 21.0986 23.8956C21.0986 24.0746 21.1698 24.2463 21.2963 24.3729C21.4229 24.4995 21.5946 24.5706 21.7737 24.5706Z"
    fill="white" />
</svg>

<!-- SIGNAL 0-->
<svg xmlns="http://www.w3.org/2000/svg" *ngIf="strength === 'REPLACE'" [ngClass]="{ 'xsm-icon': size === 'size_xsm',
    'sm-icon': size === 'size_sm',
    'lg-icon': size === 'size_lg',
    'xl-icon': size === 'size_xl',
    'xxl-icon': size === 'size_xxl',
    'xl2-icon': size === 'size_2xl',
    'xl4-icon': size === 'size_4xl',
    'xl5-icon': size === 'size_5xl' }" viewBox="0 0 31 27" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M22.9551 11.3173C23.1104 11.4343 23.2442 11.5806 23.347 11.7513L27.5005 18.625V3.27267C27.5005 2.01747 26.483 0.999939 25.2278 0.999939C23.9726 0.999939 22.9551 2.01747 22.9551 3.27267V11.3173ZM20.6822 11.7026V8.95448C20.6822 7.69929 19.6646 6.68176 18.4094 6.68176C17.1543 6.68176 16.1367 7.69929 16.1367 8.95448L16.1367 19.2229L20.6515 11.7513C20.6615 11.7349 20.6717 11.7186 20.6822 11.7026ZM13.8638 22.9844V14.6363C13.8638 13.3811 12.8463 12.3636 11.5911 12.3636C10.3359 12.3636 9.31836 13.3811 9.31836 14.6363L9.31836 23.7272C9.31836 24.9824 10.3359 25.9999 11.5911 25.9999C11.7667 25.9999 11.9377 25.98 12.1018 25.9423C12.1322 25.8645 12.1694 25.7886 12.2134 25.7156L13.8638 22.9844ZM4.77273 25.9999C3.51753 25.9999 2.5 24.9824 2.5 23.7272V20.3181C2.5 19.0629 3.51753 18.0454 4.77273 18.0454C6.02792 18.0454 7.04545 19.0629 7.04545 20.3181V23.7272C7.04545 24.9824 6.02792 25.9999 4.77273 25.9999Z"
    fill="#D6DFEB" />
  <path
    d="M21.9998 13C22.4436 13 22.853 13.2344 23.078 13.6187L29.828 25.1187C30.0561 25.5062 30.0561 25.9844 29.8342 26.3719C29.6124 26.7594 29.1967 27 28.7499 27H15.2498C14.8029 27 14.3873 26.7594 14.1654 26.3719C13.9436 25.9844 13.9467 25.5031 14.1717 25.1187L20.9217 13.6187C21.1467 13.2344 21.5561 13 21.9998 13Z"
    fill="#CC3300" />
  <path
    d="M22.3137 17.5502C22.3137 17.2515 22.0724 17.0101 21.7737 17.0101C21.475 17.0101 21.2336 17.2515 21.2336 17.5502V21.8705C21.2336 22.1692 21.475 22.4105 21.7737 22.4105C22.0724 22.4105 22.3137 22.1692 22.3137 21.8705V17.5502ZM21.7737 24.5706C21.9527 24.5706 22.1244 24.4995 22.251 24.3729C22.3776 24.2463 22.4487 24.0746 22.4487 23.8956C22.4487 23.7166 22.3776 23.5449 22.251 23.4183C22.1244 23.2917 21.9527 23.2205 21.7737 23.2205C21.5946 23.2205 21.4229 23.2917 21.2963 23.4183C21.1698 23.5449 21.0986 23.7166 21.0986 23.8956C21.0986 24.0746 21.1698 24.2463 21.2963 24.3729C21.4229 24.4995 21.5946 24.5706 21.7737 24.5706Z"
    fill="white" />
</svg>