import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SensorTelemetryGraphS7100InterfaceClientService } from './sensor-telemetry-graph-s7100-interface.client.service';
import { TokenGenerationService } from 'src/app/shared/services/token-generation-services/tokenGeneration.service';
import { TokenInfo } from '../../models/token-info';

@Injectable({
  providedIn: 'root'
})
export class SensorTelemetryGraphS7100ClientService implements SensorTelemetryGraphS7100InterfaceClientService {
  constructor(
    public httpClient: HttpClient,
    public tokenGenerationService: TokenGenerationService
  ) { }

  getTokenForReport(_workspaceId: any, _reportId: any): Promise<TokenInfo> {
    return this.tokenGenerationService.fn_getTokenForReport(_workspaceId, _reportId);
  }

  getTokenForReportByEmailId(_workspaceId: any, _reportId: any, _dataSetId: any, _userId: any): Promise<TokenInfo> {
    return this.tokenGenerationService.fn_getTokenForReportByEmailId(_workspaceId, _reportId, _dataSetId, _userId);
  }
}
