import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-asset-status-indicator',
  templateUrl: './asset-status-indicator.component.html',
  styleUrls: ['./asset-status-indicator.component.scss']
})
export class AssetStatusIndicatorComponent {
  @Input() showEmptyPill: boolean = true;
  alertContent?:AlertInfo;
  itemsCount!:number;
  noContent!:boolean;
  healthyContent?:AlertInfo;
  offlineContent?:AlertInfo;
  warningContent?:AlertInfo;

  private _data!: AlertInfo[];
  @Input() public get data(): AlertInfo[] {
    return this._data;
  }

  public set data(value: AlertInfo[]) {
    this._data = value;
    this.updateContent();
  }

  private noContentFn = () => this.alertContent === undefined &&
        this.warningContent === undefined &&
        this.healthyContent === undefined &&
        this.offlineContent === undefined;

  private updateContent() {
    this.alertContent = this.data?.find(x => x.alertType === AlertInfoType.Alert);
    this.warningContent = this.data?.find(x => x.alertType === AlertInfoType.Warning);
    this.healthyContent = this.data?.find(x => x.alertType === AlertInfoType.Good);
    this.offlineContent = this.data?.find(x => x.alertType === AlertInfoType.Offline);
    this.noContent = this.noContentFn();
    this.itemsCount = this.data?.length ?? 0;
  }
}

export interface AlertInfo{
  alertType: string;
  count?: number
}

export enum AlertInfoType{
  Alert = 'Alert',
  Warning = 'Warning',
  Good = 'Good',
  Offline = 'Offline',
}
