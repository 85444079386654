import { Injectable } from '@angular/core';
import {  showSpinner, hideSpinner } from '@syncfusion/ej2-angular-popups';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  constructor() { }

  hideSpinner = () => {
    const containerElement = document.getElementById('container');
    if (containerElement) {
      hideSpinner(containerElement);
    }
  };

  showSpinner = () => {
    const containerElement = document.getElementById('container');
    if (containerElement) {
      showSpinner(containerElement);
    }
  };
}
