
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' }) // As off now i will keep this for quik fix later i will move this and move the dailogs and related services to shared
export class ArtifactManagementServiceService {
  fileData: File = {} as File;
  currentArtifactType: string = '';
  addTagArtifactId:string = '';
  addTagArtifactType:string = '';
  constructor() {}

  saveFileData(file: File) {
    this.fileData = file;
  }

  getFileData() {
    return this.fileData;
  }

  deleteFileData() {
    this.fileData = {} as File;
  }

  setArtifactType(artifactType: string) {
    this.currentArtifactType = artifactType;
  }

  getArtifactType() {
    return this.currentArtifactType;
  }

  saveAddTagArtifactId(artifactId: string) {
    this.addTagArtifactId = artifactId;
  }

  saveAddTagArtifactType(artifactType: string) {
    this.addTagArtifactType = artifactType;
  }

  getAddTagArtifactId() {
    return this.addTagArtifactId;
  }

  getAddTagArtifactType() {
    return this.addTagArtifactType;
  }
}
