import { Component, Input } from '@angular/core';
import { StatusIndicator } from 'src/app/shared/models/shared.models';

@Component({
  selector: 'app-asset-single-status',
  templateUrl: './asset-single-status.component.html',
  styleUrls: ['./asset-single-status.component.scss']
})
export class AssetSingleStatusComponent {
  @Input() statusIndicator?: StatusIndicator;
}
