import { Injectable, NgZone } from '@angular/core';
import { fromEvent, interval, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastModel } from '@syncfusion/ej2-angular-notifications';
import { ToastService } from 'src/app/shared/services/toast-services/toast.service';
import { LoginState } from 'src/app/shared/models/login-state';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { AppRouteConstants } from 'src/app/shared/app-constants/app-route-constants';
import { SessionExpiryConstants } from 'src/app/shared/app-constants/shared.constants';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {
  private idleTime = 0; // seconds
  private readonly idleLimit = SessionExpiryConstants.SESSION_EXPIRY_MINS;
  private readonly warningTime = SessionExpiryConstants.SESSION_EXPIRY_WARNING_MINS;
  private readonly checkInterval = SessionExpiryConstants.SESSION_EXPIRY_CHECK_TIMER_MINS * 60 * 1000; // Milliseconds

  private warningShown = false;
  private subscription!: Subscription;
  private subscriptions: Subscription[] = [];
  private userEvents = [
    'mousedown',
    'click',
    'keydown',
  ];

  constructor(
    private toastService: ToastService,
    private router: Router,
    private sessionService: SessionService,
    private ngZone: NgZone
  ) {
  }

  trackIdleSession() {
    this.resetTimer(); // Ensure idle time is reset when starting
    this.clearSessionTracking();
    this.setSessionTracking();
  }

  private setSessionTracking() {
    this.subscription = interval(this.checkInterval).subscribe(() => {
      this.idleTime += this.checkInterval / 60000;
      if ((this.idleTime >= (this.idleLimit - this.warningTime)) && !this.warningShown) {
        this.showWarning();
        this.warningShown = true;
      }
      if (this.idleTime >= this.idleLimit) {
        this.handleSessionExpiration();
      }
    });

    this.ngZone.runOutsideAngular(() => {
      this.userEvents.forEach(event => {
        const actsubscription = fromEvent(window, event).subscribe((e: Event) => {
          this.resetTimer();
        });
        this.subscriptions.push(actsubscription);
      });
    });
  }

  private showWarning() {
    const toastModel: ToastModel = { title: SessionExpiryConstants.SESSION_EXPIRY_TITLE, content: SessionExpiryConstants.SESSION_EXPIRY_WARNING_MESSAGE, position: { X: 'Center' }, showCloseButton: true, icon: 'warning-icon', width: '25rem', timeOut: 10000 };
    this.toastService.showToast(toastModel);
  }

  private handleSessionExpiration() {
    this.clearSessionTracking();
    const logoutState: LoginState = {
      loginStatus: false,
      roleName: '',
      isAdminPage: false,
      isAdminRole: false
    };
    this.sessionService.loginState$.next(logoutState);
    const url = `/${AppRouteConstants.APP_LOGOUT_ROUTE}`;
    this.router.navigate([url], { replaceUrl: true });
  }

  public clearSessionTracking() {
    this.idleTime = 0; // Reset idle time

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private resetTimer() {
    this.idleTime = 0;
    if (this.warningShown) {
      this.warningShown = false;
    }
  }
}
