import { ApiResponse } from 'src/app/shared/models/api-response';
import { AssetType } from 'src/app/shared/app-constants/asset-constants';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler/error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap } from 'rxjs';
import { COMMAND_API_ENDPOINTS, SENSOR_API_ENDPOINTS } from 'src/app/shared/app-constants/api-constants';
import { Sensor, SensorTelemetryData } from '../../models/sensor';
import { SensorList } from '../../../../shared/models/asset-list-model/sensor-list';
import { CommandResponse } from 'src/app/shared/models/shared.models';

@Injectable()
export class SensorService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getFullySetupSensorsServiceUrl(tenantId: string): string {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_FULLY_SETUP_SENSOR_LIST(tenantId);
    return url;
  }

  public getReadyToAssignSensorsServiceUrl(tenantId: string): string {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_READY_TO_ASSIGN_SENSOR_LIST(tenantId);
    return url;
  }

  public getReadyToCommissionSensorsServiceUrl(tenantId: string): string {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_READY_TO_COMMISSION_SENSOR_LIST(tenantId);
    return url;
  }

  public getSensorListServiceUrl(tenantId: string, requiredFields: string): string {
    return environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_ASSET_SENSOR_LIST(tenantId, requiredFields);
  }

  public getSensorList(tenantId: string): Observable<SensorList[]> {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_LIST(tenantId);
    return this.http.get<ApiResponse<SensorList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(catchError((error: any) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      }));
  }

  public getSensorDetails(serialNumber: string, tenantId: string, preventSpinner?: boolean): Observable<Sensor> {
    let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    if (preventSpinner)
      headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Prevent-Spinner': '' }) };
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_DETAILS(tenantId, serialNumber, AssetType.SENSOR);
    return this.http.get<ApiResponse<Sensor>>(url, headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public saveSensor(tenantId: string, sensor: Sensor): Observable<string> {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.SAVE_SENSOR_DETAILS(tenantId, sensor.sensorId);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const requestOptions = { headers: headers };
    return this.http.put<ApiResponse<string>>(url, JSON.stringify(sensor), requestOptions).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public getSensorLiveTelemetryData(macAddress: string, tenantId: string, preventSpinner?: boolean): Observable<SensorTelemetryData> {
    let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    if (preventSpinner)
      headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Prevent-Spinner': '' }) };
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_LIVE_TELEMETRY_DATA(tenantId, macAddress);
    return this.http.get<SensorTelemetryData>(url, headers).pipe(
      catchError((httpError: any) => {
        this.errorHandlerService.showErrorPopUp(httpError);
        throw httpError;
      })
    );
  }

  requestDataMeasurement(tenantId: string, commandName: string, sensorSerialNumber: string, gatewaySerialNumber: string): Observable<CommandResponse> {
    const url = environment.dashboardApiBaseUrl + COMMAND_API_ENDPOINTS.INVOKE(tenantId);
    const body = { commandName: commandName, sensorSerialNumber: sensorSerialNumber, gatewaySerialNumber: gatewaySerialNumber, requiredAction: 'invoke' };
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<ApiResponse<CommandResponse>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }
}
