<ejs-dialog [visible]="visible" id='dialog' #ejDialog [target]='targetElement' [position]="modalDialogData.position"
  [target]='targetElement' isModal='true' [height]="modalDialogData.dimension?.height"
  [width]="modalDialogData.dimension?.width" (close)="closed()">

  <ng-template *ngIf="modalDialogData.templateRef then modalDialogData.templateRef" />

  <app-confirm-template [message]="modalDialogData.customData['message']" [id]="modalDialogData.id"
    *ngIf="modalDialogData.templateName==='ConfirmTemplateComponent'"></app-confirm-template>

  <app-error-template [message]="modalDialogData.customData['message']" [id]="modalDialogData.id"
    *ngIf="modalDialogData.templateName==='ErrorTemplateComponent'"></app-error-template>

  <app-success-template [message]="modalDialogData.customData['message']" [id]="modalDialogData.id"
    *ngIf="modalDialogData.templateName==='SuccessTemplateComponent'"></app-success-template>

  <app-image-editor-template [id]="modalDialogData.id" [imageEditorItem]="modalDialogData.customData.imageEditorItem"
    [assetId]="modalDialogData.customData.assetId"
    [assetPhotosCarouselItem]="modalDialogData.customData.assetPhotosCarouselItem"
    *ngIf="modalDialogData.templateName==='ImageEditorTemplateComponent'"></app-image-editor-template>

  <app-image-carousel-template [id]="modalDialogData.id" [imageUrls]="modalDialogData.customData['imageUrls']"
    [selectedIndex]="modalDialogData.customData['selectedIndex']"
    *ngIf="modalDialogData.templateName==='ImageCarouselTemplateComponent'"></app-image-carousel-template>

  <app-role-and-scope-assignment [id]="modalDialogData.id" [selectedUsers]="modalDialogData.customData['selectedUsers']"
  [roleIdToSelect] = "modalDialogData.customData['roleIdToSelect']" [scopeIdToSelect]="modalDialogData.customData['scopeIdToSelect']" *ngIf="modalDialogData.templateName==='RoleAndScopeAssignmentComponent'">
  </app-role-and-scope-assignment>
  <app-upload-artifact [id]="modalDialogData.id" *ngIf="modalDialogData.templateName==='UploadArtifactComponent'"></app-upload-artifact>
  <app-initiate-campaign [id]="modalDialogData.id" *ngIf="modalDialogData.templateName==='InitiateCampaignComponent'"></app-initiate-campaign>
  <app-create-group [id]="modalDialogData.id" *ngIf="modalDialogData.templateName==='CreateGroupComponent'"></app-create-group>
  <app-add-tag [id]="modalDialogData.id" *ngIf="modalDialogData.templateName==='AddTagsComponent'"></app-add-tag>
  <app-sensor-thresholds [id]="modalDialogData.id" *ngIf="modalDialogData.templateName==='SensorThresholdsComponent'"
    [equipmentName]="modalDialogData.customData['equipmentName']" [tenantId]="modalDialogData.customData['tenantId']"
    [sensorType]="modalDialogData.customData['sensorType']"
    [sensorSerialNumber]="modalDialogData.customData['sensorSerialNumber']"
    [sensorId]="modalDialogData.customData['sensorId']"></app-sensor-thresholds>

  <app-users-preferences [id]="modalDialogData.id" *ngIf="modalDialogData.templateName==='UsersPreferencesComponent'">
  </app-users-preferences>

  <app-sensor-parameter *ngIf="modalDialogData.templateName==='SensorParameterComponent'"
    [tenantId]="modalDialogData.customData['tenantId']"
    [sensorId]="modalDialogData.customData['sensorId']"
    [sensorState]="modalDialogData.customData['sensorState']"></app-sensor-parameter>

    <app-sensor-scheduler *ngIf="modalDialogData.templateName==='SensorSchedulerComponent'"
    [tenantId]="modalDialogData.customData['tenantId']"
    [sensorId]="modalDialogData.customData['sensorId']"
    [sensorState]="modalDialogData.customData['sensorState']"
    [associatedGatewayId]="modalDialogData.customData['associatedGatewayId']">
  </app-sensor-scheduler>

  <app-senser-telemetry-graph-s7100 [id]="modalDialogData.id"
    [sensorSerialNumber]="modalDialogData.customData['sensorSerialNumber']"
    [macAddress]="modalDialogData.customData['macAddress']"
    *ngIf="modalDialogData.templateName === 'SensorTelemetryGraphS7100Component'"></app-senser-telemetry-graph-s7100>

  <app-grid-template [id]="modalDialogData.id" [columnData]="modalDialogData.customData.gridTemplateData.columnData"
    [rowData]="modalDialogData.customData.gridTemplateData.rowData"
    [heading]="modalDialogData.customData.gridTemplateData.heading"
    [allowMultiselect]="modalDialogData.customData.gridTemplateData.allowMultiselect"
    *ngIf="modalDialogData.templateName==='GridTemplateComponent'"></app-grid-template>

</ejs-dialog>
