import { DatePipe } from '@angular/common';
import { TreeGridColumnCustomTemplateData, TreeGridColumnData } from './tree-grid';
import { ButtonLabels } from '../app-constants/shared.enum';

export const gatewayTableDefinition: TreeGridColumnData[] = [
  { field: 'gatewayId', headerText: 'Id', visible: false, isPrimaryKey: true },
  { field: 'onlineStatus', headerText: 'Online Status', customTemplate: TreeGridColumnCustomTemplateData.SignalStatus, headerWidth: '9rem' },
  { field: 'gatewayName', headerText: 'Gateway Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'locationName', headerText: 'Location', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'gatewaySerialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column', headerWidth: '14rem' },
  { field: 'connectedSensorsInfo', headerText: 'Connected Sensors', customTemplate: TreeGridColumnCustomTemplateData.ConnectedSensorsTemplateComponent, textAlign: 'Center', headerWidth: '12rem' },
  { field: 'cellularSignalStrength', headerText: 'Cellular', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength, headerWidth: '8rem' },
];

export const sensorTableDefinition: TreeGridColumnData[] = [
  { field: 'sensorId', headerText: 'Id', visible: false, isPrimaryKey: true },
  { field: 'bluetoothRssi', headerText: 'RSSI', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength, headerWidth: '8rem' },
  { field: 'reportedConnectionStatus', headerText: 'Connection', customTemplate: TreeGridColumnCustomTemplateData.ConnectionStatusTemplateComponent, headerWidth: '10rem' },
  { field: 'sensorName', headerText: 'Sensor Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'equipmentName', headerText: 'Equipment Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'sensorSerialNumber', headerText: 'Serial Number', headerWidth: '14rem' },
  { field: 'lastCommunication', headerText: 'Last Communicated', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent, headerWidth: '15rem' }
];

export const adminGatewayGridColumn: TreeGridColumnData[] = [
  { field: 'serialNumber', headerText: 'Serial Number', cssClass: 'tree-grid-first-column' },
  { field: 'label', headerText: 'Gateway Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'lifecycleStatus', headerText: 'State' },
  { field: 'kebabMenu', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Restart Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/restart.svg' } ] },
];

export const adminFullySetupGatewayGridColumn: TreeGridColumnData[] = [
  { field: '', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusBarComponent, headerWidth: '12rem' },
  { field: 'name', headerText: 'Gateway Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent,  cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'location', headerText: 'Site', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'serialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'connectedSensors', headerText: 'Connected Sensors' },
  { field: 'cellularSignalStrength', headerText: 'Cellular', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength },
  { field: 'onlineStatus', headerText: 'Online', customTemplate: TreeGridColumnCustomTemplateData.SignalStatus },
  { field: '', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Restart Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/restart.svg' }, { label: 'Unassign Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }] },
];
export const adminFullySetupCampaignGridColumn: TreeGridColumnData[] = [
  { field: 'campaignName', headerText: 'Campaign name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'enterpriseName', headerText: 'Enterprise Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'associatedGroups', headerText: 'Associated groups', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'e-admin-module-count-row', textAlign: 'center' },
  { field: 'associatedGateways', headerText: 'Gateway count', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'e-admin-module-count-row', textAlign: 'center' },
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.CampaignStatusIndicatorTemplateComponent, cssClass: 'e-admin-module-count-row', textAlign: 'center' },
  { field: 'menu', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Pause', icon: 'assets/icons-and-logos/pause-circle.svg' }, { label: 'Abort', icon: 'assets/icons-and-logos/gateways-and-sensors/restart.svg' }, { label: 'Rollback', icon: 'assets/icons-and-logos/gateways-and-sensors/restart.svg' }] },
];

export const groupSummaryTableDefinition: TreeGridColumnData[] = [
  { field: 'groupName', headerText: 'Group name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'enterpriseName', headerText: 'Enterprise Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent,   },
  // { field: 'tags', headerText: 'Tags', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'e-admin-module-count-row', textAlign: 'center'  },
  { field: 'gateways', headerText: 'Gateway count', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'e-admin-module-count-row', textAlign: 'center' },
  { field: 'sensors', headerText: 'Sensor count', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'e-admin-module-count-row', textAlign: 'center' },
  { field: 'campaigns', headerText: 'Campaigns', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-campaign-count-column', textAlign: 'center' },
  { field: 'status', headerText: 'Campaign Status', customTemplate: TreeGridColumnCustomTemplateData.CampaignStatusTemplateComponent },
  { field: 'menu', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Delete', icon: 'assets/icons-and-logos/artifact-management/delete-icon.svg' }, { label: 'Edit', icon: 'assets/icons-and-logos/artifact-management/edit-icon.svg' }] },
];

export const campaignOperationsTableDefinition: TreeGridColumnData[] = [
  { field: 'entity', headerText: 'Entity', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'type', headerText: 'Type', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, headerWidth: '5rem' },
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.CampaignStatusIndicatorTemplateComponent, cssClass: 'admin-module-tree-grid-campaign-count-column', textAlign: 'center'  },
  { field: 'description', headerText: 'Description', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  // { field: 'time', headerText: 'Time', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, },
  { field: 'createdOn', headerText: 'Time stamp', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'createdBy', headerText: 'Event initiated by', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  // { field: '', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Restart Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/restart.svg' }, { label: 'Unassign Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }] },
];

export const groupDetailsTableDefinition: TreeGridColumnData[] = [
  { field: 'serialNumber', headerText: 'Serial Number' },
  { field: 'enterprise', headerText: 'Enterprise Name' },
  { field: 'label', headerText: 'Gateway Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'lifecycleStatus', headerText: 'State' },
];

export const updategroupDetailsTableDefinition: TreeGridColumnData[] = [
  { field: 'serialNumber', headerText: 'Serial Number' },
  { field: 'enterprise', headerText: 'Enterprise Name' },
  { field: 'label', headerText: 'Gateway Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'lifecycleStatus', headerText: 'State' },
];

export const groupAssetsTableDefinition: TreeGridColumnData[] = [
  { field: 'serialNo', headerText: 'Serial Number' },
  { field: 'enterprise', headerText: 'Enterprise Name' },
  { field: 'label', headerText: 'Gateway Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'site', headerText: 'Site Info' },
  { field: 'status', headerText: 'State' },
];

export const campaignDetailsTableDefinition: TreeGridColumnData[] = [
  { field: 'serialNo', headerText: 'Serial Number', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, headerWidth: '12rem', },
  { field: 'groupName', headerText: 'Group Name' },
  { field: 'startedDate', headerText: 'Started Date' },
  { field: 'finishedDate', headerText: 'Finished Date' },
  { field: 'logs', headerText: 'Description', customTemplate: TreeGridColumnCustomTemplateData.CampaignStatusTemplateComponent },
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.CampaignStatusTemplateComponent }
];

export const campaignsInGroupTableDefinition: TreeGridColumnData[] = [
  { field: 'campaignName', headerText: 'Campaign', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'campaignCreatedOn', headerText: 'Date Created' },
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.CampaignStatusTemplateComponent }
];

export const adminReadyToAssignGatewayGridColumn: TreeGridColumnData[] = [
  { field: '', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusBarComponent, headerWidth: '12rem' },
  { field: 'name', headerText: 'Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'location', headerText: 'Location', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, },
  { field: 'serialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'connectedSensors', headerText: 'Connected Sensors' },
  { field: 'cellularSignalStrength', headerText: 'Cellular', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength },
  { field: 'onlineStatus', headerText: 'Online', customTemplate: TreeGridColumnCustomTemplateData.SignalStatus },
  { field: '', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Restart Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/restart.svg' }, { label: 'Unassign Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }] },
];

export const adminReadyToProvisionGatewayGridColumn: TreeGridColumnData[] = [
  { field: '', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusBarComponent, headerWidth: '12rem' },
  { field: 'name', headerText: 'Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'location', headerText: 'Location', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, },
  { field: 'serialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'connectedSensors', headerText: 'Connected Sensors' },
  { field: 'cellularSignalStrength', headerText: 'Cellular', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength },
  { field: 'onlineStatus', headerText: 'Online', customTemplate: TreeGridColumnCustomTemplateData.SignalStatus },
  { field: '', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Restart Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/restart.svg' }, { label: 'Unassign Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }] },
];

export const adminSensorsGridColumn: TreeGridColumnData[] = [
  { field: 'serialNumber', headerText: 'Serial Number', cssClass: 'tree-grid-first-column' },
  { field: 'reportedConnectionStatus', headerText: 'Connected Status', customTemplate: TreeGridColumnCustomTemplateData.ConnectionStatusTemplateComponent },
  { field: 'objectSubType', headerText: 'Type' },
  { field: 'reportedFirmwareRevision', headerText: 'Firmware Version' },
  { field: 'lifecycleStatus', headerText: 'Status' }
];

export const adminFullySetupSensorsGridColumn: TreeGridColumnData[] = [
  { field: 'alertCount', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '12rem' },
  { field: 'sensorName', headerText: 'Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'equipment', headerText: 'Equipment', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'location', headerText: 'Site', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'criticality', headerText: 'Criticality' },
  { field: 'thresholdConfiguration', headerText: 'ThresholdConfiguration' },
  { field: 'dataMeasurement', headerText: 'Data Measurement' },
  { field: 'batteryStatus', headerText: 'Battery Status' },
  { field: 'sensorSerialNumber', headerText: 'Serial Number' },
  { field: 'reportedConnectionStatus', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.ConnectionStatusTemplateComponent },
  { field: '', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Disassociate Sensor', icon: 'assets/icons-and-logos/gateways-and-sensors/disassociate.svg' }, { label: 'Unassign Sensor', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }] },
];

export const adminReadyToBeAssignedSensorsGridColumn: TreeGridColumnData[] = [
  { field: 'alertCount', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '12rem' },
  { field: 'sensorName', headerText: 'Sensor Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'equipment', headerText: 'Equipment Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'location', headerText: 'Site', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'sensorSerialNumber', headerText: 'Serial Number' },
  { field: 'reportedConnectionStatus', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.ConnectionStatusTemplateComponent },
  { field: '', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Disassociate Sensor', icon: 'assets/icons-and-logos/gateways-and-sensors/disassociate.svg' }, { label: 'Unassign Sensor', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }] },
];

export const adminReadyToBeCommissionedSensorsGridColumn: TreeGridColumnData[] = [
  { field: 'alertCount', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '12rem' },
  { field: 'sensorName', headerText: 'Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'sensorSerialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'equipment', headerText: 'Equipment', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, },
  { field: 'firmwareVersion', headerText: 'Firmware' },
  { field: 'setupStatus', headerText: 'Status' },
  { field: 'bluetoothRssi', headerText: 'RSSI', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength },
  { field: '', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Disassociate Sensor', icon: 'assets/icons-and-logos/gateways-and-sensors/disassociate.svg' }, { label: 'Unassign Sensor', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }] },
];

export const sensorParametersDataGridColumnDefinition: TreeGridColumnData[] = [
  { field: 'type', headerText: 'Type', headerWidth: 'auto' },
  { field: 'axis', headerText: 'Axis', headerWidth: 'auto' },
  { field: 'lastValue', headerText: 'Last Value', headerWidth: '15%' },
  { field: 'unit', headerText: 'Units', headerWidth: '10%' },
  { field: 'timestampUtc', headerText: 'Timestamp (UTC)', headerWidth: '20%', pipes: [{ pipe: new DatePipe('en-US', null, { dateFormat: 'YYYY-MM-dd TH:mm', timezone: '' }) }] },
  { field: 'timestamp', headerText: 'When', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent, headerWidth: '10rem' }
];

export const adminGatewayDetailUnAssignedSensorsDataGridColumn: TreeGridColumnData[] =
  [
    { field: 'assetStatusInfo', headerText: 'Health Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8.3rem', textAlign: 'Center' },
    { field: 'sensorName', headerText: 'Sensor Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
    { field: 'sensorSerialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column', headerWidth: '12rem' },
    { field: 'firmwareVersion', headerText: 'Firmware Ver.', headerWidth: '5rem' },
    { field: 'setupStatus', headerText: 'Setup Status', customTemplate: TreeGridColumnCustomTemplateData.SetupStatusComponent, headerWidth: '11rem' },
    { field: 'reportedConnectionStatus', headerText: 'Connection', customTemplate: TreeGridColumnCustomTemplateData.ConnectionStatusTemplateComponent, headerWidth: '10rem' },
    { field: 'bluetoothRssi', headerText: 'RSSI', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength, headerWidth: '7rem' },
    { field: 'lastCommunication', headerText: 'When', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent, headerWidth: '8rem' },
    { field: '_', headerText: '', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: ButtonLabels.DISASSOCIATE_SENSOR, icon: 'assets/icons-and-logos/gateways-and-sensors/disassociate.svg' }, { label: ButtonLabels.FORCE_DISASSOCIATE_SENSOR, icon: 'assets/icons-and-logos/gateways-and-sensors/force-disassociate.svg' }], headerWidth: '50px' }
  ];

export const adminGatewayDetailAssignedSensorsDataGridColumn: TreeGridColumnData[] =
  [
    { field: 'assetStatusInfo', headerText: 'Health Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8.3rem', textAlign: 'Center' },
    { field: 'sensorName', headerText: 'Sensor Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
    { field: 'sensorSerialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column', headerWidth: '12rem' },
    { field: 'firmwareVersion', headerText: 'Firmware Ver.', headerWidth: '5rem' },
    { field: 'setupStatus', headerText: 'Setup Status', customTemplate: TreeGridColumnCustomTemplateData.SetupStatusComponent, headerWidth: '11rem' },
    { field: 'reportedConnectionStatus', headerText: 'Connection', customTemplate: TreeGridColumnCustomTemplateData.ConnectionStatusTemplateComponent, headerWidth: '10rem' },
    { field: 'bluetoothRssi', headerText: 'RSSI', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength, headerWidth: '7rem' },
    { field: 'lastCommunication', headerText: 'When', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent, headerWidth: '8rem' },
    { field: 'assignedEquipmentName', headerText: 'Equipment Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column tree-grid-equipment-link' },
    { field: '_', headerText: '', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: ButtonLabels.DISASSOCIATE_SENSOR, icon: 'assets/icons-and-logos/gateways-and-sensors/disassociate.svg' }, { label: ButtonLabels.UNASSIGN_SENSOR, icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }, { label: ButtonLabels.FORCE_DISASSOCIATE_SENSOR, icon: 'assets/icons-and-logos/gateways-and-sensors/force-disassociate.svg' }], headerWidth: '50px' }
  ];

export const adminGatewayDetailGatewayParametersDataGridColumn: TreeGridColumnData[] = [
  { field: 'parameterLabel', headerText: 'Parameter', headerWidth: '18rem' },
  { field: 'parameterValue', headerText: 'Value', headerWidth: '20rem' },
];

export const sensorParameterTableDefinition: TreeGridColumnData[] = [
  { field: 'type', headerText: 'Type' },
  { field: 'axis', headerText: 'Axis' },
  { field: 'lastValue', headerText: 'Last Value' },
  { field: 'unit', headerText: 'Units' },
  { field: 'timestampUtc', headerText: 'Timestamp (UTC)', headerWidth: '12rem', pipes: [{ pipe: new DatePipe('en-US', null, { dateFormat: 'YYYY-MM-dd TH:mm', timezone: '' }) }] },
  { field: 'timestamp', headerText: 'When', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent, headerWidth: '10rem' }
];

export const adminTenantGridColumn: TreeGridColumnData[] = [
  { field: 'salesForceAccountId', headerText: 'Salesforce Account Id' },
  { field: 'name', headerText: 'Name', cssClass: 'tree-grid-first-column' },
  { field: 'industryType', headerText: 'Industry Type' }
];

export const adminSiteGridColumnHeaders: TreeGridColumnData[] = [
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8.3rem', textAlign: 'Center' },
  { field: 'label', headerText: 'Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'lastCommunication', headerText: 'Last Communicated', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent },
  { field: 'connectedSensorsInfo', headerText: 'Connected Sensors', customTemplate: TreeGridColumnCustomTemplateData.ConnectedSensorsTemplateComponent, textAlign: 'Center', headerWidth: '12rem' },
];

export const adminEnterpriseDetailUserListGridColumnHeaders: TreeGridColumnData[] = [
  { field: 'fullName', headerText: 'Full Name', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'role', headerText: 'Role' },
  { field: 'site', headerText: 'Site' },
  { field: 'jobTitle', headerText: 'Job Title' },
  { field: 'emailAddress', headerText: 'Email Address' },
  { field: 'phoneNumber', headerText: 'Phone Number' },
];

export const enterprisesTableDefinition: TreeGridColumnData[] = [
  { field: 'enterpriseName', headerText: 'Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'industryType', headerText: 'Industry Type' },
  { field: 'tenant', headerText: 'Tenant' },
];

export const adminPerceptivAccountGridColumnDefinition: TreeGridColumnData[] = [
  { field: 'perceptivAccountName', headerText: 'Perceptiv Account Name', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'billingAddress', headerText: 'Billing Address' },
  { field: 'shippingAddress', headerText: 'Shipping Address' },
  { field: 'industryType', headerText: 'Industry Type' },
  { field: 'primaryContact', headerText: 'Primary Account' },
  { field: 'secondaryContact', headerText: 'Secondary Contact' },
];

export const admin_EnterpriseDetailsUserGridColumnHeaders: TreeGridColumnData[] = [
  { field: 'fullName', headerText: 'Full Name', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'role', headerText: 'Role' },
  { field: 'site', headerText: 'Site' },
  { field: 'jobTitle', headerText: 'Job Title' },
  { field: 'emailAddress', headerText: 'Email Address' },
  { field: 'phoneNumber', headerText: 'Phone Number' },
];

export const unitDetailsPageEquipmentsGridColumnData: TreeGridColumnData[] = [
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8.3rem', textAlign: 'Center' },
  { field: 'label', headerText: 'Equipment Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'objectSubType', headerText: 'Type' },
  { field: 'criticality', headerText: 'Criticality' },
  { field: 'lastCommunication', headerText: 'Last Communicated', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent },
  { field: 'connectedSensorsInfo', headerText: 'Connected Sensors', customTemplate: TreeGridColumnCustomTemplateData.ConnectedSensorsTemplateComponent, textAlign: 'Center', headerWidth: '12rem' },
];

export const unitDetailsPageSensorsGridColumnData: TreeGridColumnData[] = [
  { field: 'assetStatusInfo', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8.3rem', textAlign: 'Center' },
  { field: 'sensorName', headerText: 'Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'equipmentName', headerText: 'Equipment', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'areaName', headerText: 'Area', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'profileThresholdType', headerText: 'Threshold Configuration' },
  { field: 'batteryVoltage', headerText: 'Battery', customTemplate: TreeGridColumnCustomTemplateData.BatteryHealthStatusComponent, textAlign: 'Center', headerWidth: '8rem' },
  { field: 'sensorSerialNumber', headerText: 'Serial Number', headerWidth: '180' },
  { field: 'hiddenKebabMenu', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Unassign Sensor', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }] }
];

export const siteDetailsPageUsersGridColumnData: TreeGridColumnData[] = [
  { field: 'fullName', headerText: 'Full Name', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'role', headerText: 'Role' },
  { field: 'site', headerText: 'Site', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, },
  { field: 'jobTitle', headerText: 'Job Title' },
  { field: 'emailAddress', headerText: 'Email Address' },
  { field: 'phoneNumber', headerText: 'Phone Number' },
];

export const siteDetailsPageAreasGridColumnData: TreeGridColumnData[] = [
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8.3rem', textAlign: 'Center' },
  { field: 'label', headerText: 'Area Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'lastCommunication', headerText: 'Last Communicated', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent },
  { field: 'connectedSensorsInfo', headerText: 'Connected Sensors', customTemplate: TreeGridColumnCustomTemplateData.ConnectedSensorsTemplateComponent, textAlign: 'Center', headerWidth: '12rem' },
];

export const customerSiteDetailsPageGatewaysGridColumnData: TreeGridColumnData[] = [
  { field: 'gatewayId', headerText: 'Id', visible: false, isPrimaryKey: true },
  { field: 'onlineStatus', headerText: 'Online Status', customTemplate: TreeGridColumnCustomTemplateData.SignalStatus,  headerWidth: '9rem' },
  { field: 'gatewayName', headerText: 'Gateway Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'locationName', headerText: 'Location', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'gatewaySerialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column',  headerWidth: '14rem' },
  { field: 'connectedSensorsInfo', headerText: 'Connected Sensors', customTemplate: TreeGridColumnCustomTemplateData.ConnectedSensorsTemplateComponent, textAlign: 'Center', headerWidth: '12rem' },
  { field: 'cellularSignalStrength', headerText: 'Cellular', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength, headerWidth: '8rem' }
];

export const siteDetailsPageGatewaysGridColumnData: TreeGridColumnData[] = [
  { field: 'onlineStatus', headerText: 'Online Status', customTemplate: TreeGridColumnCustomTemplateData.SignalStatus,  headerWidth: '9rem' },
  { field: 'gatewayName', headerText: 'Gateway Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'locationName', headerText: 'Location', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'gatewaySerialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column',  headerWidth: '14rem' },
  { field: 'connectedSensorsInfo', headerText: 'Connected Sensors', customTemplate: TreeGridColumnCustomTemplateData.ConnectedSensorsTemplateComponent, textAlign: 'Center', headerWidth: '12rem' },
  { field: 'cellularSignalStrength', headerText: 'Cellular', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength, headerWidth: '8rem' },
  { field: 'hiddenKebabMenu', headerText: '', headerWidth: '50',  customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Unassign Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }, { label: 'Restart Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/restart.svg' }] }
];

export const customerUnitDetailsPageSensorsGridColumnData: TreeGridColumnData[] = [
  { field: 'sensorId', headerText: 'Id', visible: false, isPrimaryKey: true },
  { field: 'assetStatusInfo', headerText: 'Health Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8.3rem', textAlign: 'Center' },
  { field: 'sensorName', headerText: 'Sensor Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'equipmentName', headerText: 'Equipment Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'areaName', headerText: 'Area', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'batteryVoltage', headerText: 'Battery', customTemplate: TreeGridColumnCustomTemplateData.BatteryHealthStatusComponent, textAlign: 'Center', headerWidth: '8rem' },
  { field: 'sensorSerialNumber', headerText: 'Serial Number', headerWidth: '14rem' },
];

export const areaDetailsPageUnitsGridColumnData: TreeGridColumnData[] = [
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8.3rem', textAlign: 'Center' },
  { field: 'label', headerText: 'Unit Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'objectSubType', headerText: 'Type' },
  { field: 'lastCommunication', headerText: 'Last Communicated', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent },
  { field: 'connectedSensorsInfo', headerText: 'Connected Sensors', customTemplate: TreeGridColumnCustomTemplateData.ConnectedSensorsTemplateComponent, textAlign: 'Center', headerWidth: '12rem' },
];

export const areaDetailsPageGatewaysGridColumnData: TreeGridColumnData[] = [
  { field: 'onlineStatus', headerText: 'Online Status', customTemplate: TreeGridColumnCustomTemplateData.SignalStatus,  headerWidth: '9rem' },
  { field: 'gatewayName', headerText: 'Gateway Name', cssClass: 'admin-module-tree-grid-first-column', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'locationName', headerText: 'Location', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'gatewaySerialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column',  headerWidth: '14rem' },
  { field: 'connectedSensorsInfo', headerText: 'Connected Sensors', customTemplate: TreeGridColumnCustomTemplateData.ConnectedSensorsTemplateComponent, textAlign: 'Center', headerWidth: '12rem' },
  { field: 'cellularSignalStrength', headerText: 'Cellular', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength, headerWidth: '8rem' },
  { field: 'hiddenKebabMenu', headerText: '', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Unassign Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }, { label: 'Restart Gateway', icon: 'assets/icons-and-logos/gateways-and-sensors/restart.svg' }], headerWidth: '2rem' }
];

export const customerAreaDetailsPageGatewaysGridColumnData: TreeGridColumnData[] = [
  { field: 'gatewayId', headerText: 'Id', visible: false, isPrimaryKey: true },
  { field: 'onlineStatus', headerText: 'Online Status', customTemplate: TreeGridColumnCustomTemplateData.SignalStatus,  headerWidth: '9rem' },
  { field: 'gatewayName', headerText: 'Gateway Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'locationName', headerText: 'Location', cssClass: 'admin-module-tree-grid-first-column', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'gatewaySerialNumber', headerText: 'Serial Number', cssClass: 'admin-module-tree-grid-first-column',  headerWidth: '14rem' },
  { field: 'connectedSensorsInfo', headerText: 'Connected Sensors', customTemplate: TreeGridColumnCustomTemplateData.ConnectedSensorsTemplateComponent, textAlign: 'Center', headerWidth: '12rem' },
  { field: 'cellularSignalStrength', headerText: 'Cellular', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength, headerWidth: '8rem' }
];

export const equipmentDetailsPageSensorsGridColumnData: TreeGridColumnData[] = [
  { field: 'assetStatusInfo', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8.3rem', textAlign: 'Center' },
  { field: 'sensorName', headerText: 'Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'equipmentName', headerText: 'Equipment', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'areaName', headerText: 'Area', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'profileThresholdType', headerText: 'Threshold Configuration' },
  { field: 'batteryVoltage', headerText: 'Battery', customTemplate: TreeGridColumnCustomTemplateData.BatteryHealthStatusComponent, textAlign: 'Center', headerWidth: '8rem' },
  { field: 'sensorSerialNumber', headerText: 'Serial Number', headerWidth: '180' },
  { field: 'hiddenKebabMenu', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Unassign Sensor', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }] }
];

export const customerUserListGridColumnData: TreeGridColumnData[] = [
  { field: 'fullName', headerText: 'Full Name', cssClass: 'admin-module-tree-grid-first-column', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'role', headerText: 'Role', headerWidth: '200', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'site', headerText: 'Location', headerWidth: '100' },
  { field: 'jobTitle', headerText: 'Job Title', headerWidth: '200', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'emailAddress', headerText: 'Email Address', headerWidth: '375', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'phoneNumber', headerText: 'Phone Number' },
];

export const roleListGridColumnData: TreeGridColumnData[] = [
  { field: 'roleName', headerText: 'Role Name', headerWidth: '150', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, },
  { field: 'description', headerText: 'Description', headerWidth: '200', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, },
  { field: 'roleType', headerText: 'Type', headerWidth: '100', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, },
  { field: 'location', headerText: 'Location',  headerWidth: '100', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, },
  { field: '', headerText: '', headerWidth: '100', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Delete', icon: 'assets/icons-and-logos/gateways-and-sensors/unassign.svg' }] }
];

export const PermissionsGridColumnData: TreeGridColumnData[] = [
  { field: 'name', headerText: 'Name', showCheckbox: true, },
  { field: 'description', headerText: 'Description' }
];

export const customerSensorListTableDefinition: TreeGridColumnData[] = [
  { field: 'bluetoothRssi', headerText: 'RSSI', customTemplate: TreeGridColumnCustomTemplateData.CellularSignalStrength, headerWidth: '8rem' },
  { field: 'reportedConnectionStatus', headerText: 'Connection', customTemplate: TreeGridColumnCustomTemplateData.ConnectionStatusTemplateComponent, headerWidth: '10rem' },
  { field: 'sensorName', headerText: 'Sensor Name', cssClass: 'admin-module-tree-grid-first-column', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, },
  { field: 'assignedEquipmentName', headerText: 'Equipment Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'sensorSerialNumber', headerText: 'Serial Number', headerWidth: '14rem' },
  { field: 'lastCommunication', headerText: 'Last Communicated', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent, headerWidth: '15rem' }
];

export const RoleGridColumnData: TreeGridColumnData[] = [
  { field: 'roleName', headerText: 'Name', headerWidth: '150' },
  { field: 'description', headerText: 'Description', headerWidth: '400' },
  { field: 'roleType', headerText: 'Type', headerWidth: '150' },
  { field: 'location', headerText: 'Location', headerWidth: '200' },
  { field: 'locationType', headerText: 'Location Type', headerWidth: '200' }
];

export const assignedNotificationGridColumnHeaders: TreeGridColumnData[] = [
  { field: 'status', headerText: 'Status', headerWidth: '80' },
  { field: 'ruleName', headerText: 'Name', headerWidth: '300', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'equipments', headerText: 'Equipment', headerWidth: '100' },
  { field: 'devices', headerText: 'Devices', headerWidth: '100' },
  { field: 'frequency', headerText: 'Frequency', headerWidth: '100' },
];

export const equipmentHealthCardGridColumnData: TreeGridColumnData[] = [
  { field: 'hiddenCol', headerText: '', headerWidth: '1rem' },
  { field: 'label', headerText: 'Name', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8rem', textAlign: 'Center' },
  { field: 'lastCommunication', headerText: 'Last Communicated', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent, textAlign: 'Center' },
  { field: 'objectSubType', headerText: 'Type', textAlign: 'Center' }
];

export const alertsCardGridColumnData: TreeGridColumnData[] = [
  { field: 'hiddenCol', headerText: '', headerWidth: '2rem' },
  { field: 'week', headerText: 'Week' },
  { field: 'system', headerText: 'System' },
  { field: 'warning', headerText: 'Warning' },
  { field: 'alarm', headerText: 'Alarm' }
];

export const topAlertedEquipmentCardGridColumnData: TreeGridColumnData[] = [
  { field: 'hiddenCol', headerText: '', headerWidth: '1rem' },
  { field: 'label', headerText: 'Name', cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8rem', textAlign: 'Center' },
  { field: 'lastCommunication', headerText: 'Last Communicated', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent, textAlign: 'Center' },
  { field: 'objectSubType', headerText: 'Type', textAlign: 'Center' }
];
export const assignedNotificationGridColumnCustomerUserHeaders: TreeGridColumnData[] = [
  { field: 'status', headerText: 'Status', headerWidth: '80' },
  { field: 'ruleName', headerText: 'Name', headerWidth: '300' },
  { field: 'equipments', headerText: 'Equipment', headerWidth: '100' },
  { field: 'devices', headerText: 'Devices', headerWidth: '100' },
  { field: 'frequency', headerText: 'Frequency', headerWidth: '100' },
];

export const sensorEventsGridColumnData: TreeGridColumnData[] = [
  { field: 'hiddenCol1', headerText: '', headerWidth: '0.2rem' },
  { field: 'statusAlertInfo', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8rem', textAlign: 'Center' },
  { field: 'hiddenCol2', headerText: '', headerWidth: '0.2rem' },
  { field: 'parameterName', headerText: 'Parameter Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'units', headerText: 'Units', headerWidth: '5rem' },
  { field: 'lastValue', headerText: 'Last Value' },
  { field: 'conditionalValue', headerText: 'Threshold Value' },
  { field: 'sensorSerialNumber', headerText: 'Sensor Name', cssClass: 'admin-module-tree-grid-first-column', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'timeStampUtc', headerText: 'Timestamp (UTC)', headerWidth: '14rem', pipes: [{ pipe: new DatePipe('en-US', null, { dateFormat: 'YYYY-MM-dd TH:mm', timezone: '' }) }] },
  { field: 'timeStamp', headerText: 'When', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent, headerWidth: '10rem' }
];

export const equipmentParamsColumnData: TreeGridColumnData[] = [
  { field: 'label', headerText: 'Name', cssClass: 'admin-module-tree-grid-first-column', hideValueOnRowExpand: false, customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8rem', textAlign: 'Center' },
  { field: 'alarmAlerts', headerText: 'Alarm Alerts', textAlign: 'Center', headerWidth: '12rem', },
  { field: 'warningAlerts', headerText: 'Warning Alerts', textAlign: 'Center', headerWidth: '12rem', },
  { field: 'type', headerText: 'Type', textAlign: 'Center' }
];

export const admin_RolesGridColumn: TreeGridColumnData[] = [
  { field: 'name', headerText: 'Role Name', cssClass: 'admin-module-tree-grid-first-column', headerWidth: '60' },
  { field: 'type', headerText: 'Role Type', headerWidth: '60' },
  { field: 'description', headerText: 'Role Description', headerWidth: '200' },
];

export const eventsGridColDef: TreeGridColumnData[] = [
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8rem' },
  { field: 'eventId', headerText: 'Event ID', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'equipmentName', headerText: 'Equipment Name', customTemplate: TreeGridColumnCustomTemplateData.AssetHierarchyToolTipComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'sensorSerialNumber', headerText: 'Sensor Serial Number', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'timeStampUtc', headerText: 'Timestamp (UTC)' },
  { field: 'timeStampUtc', headerText: 'When', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent }
];

export const eventParamsGridColDef: TreeGridColumnData[] = [
  { field: 'status', headerText: 'Status', customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, headerWidth: '8rem' },
  { field: 'parameterName', headerText: 'Parameter Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'unit', headerText: 'Unit', headerWidth: '4rem' },
  { field: 'lastValue', headerText: 'Last Value', headerWidth: '7rem' },
  { field: 'thresholdValue', headerText: 'Threshold', headerWidth: '7rem' },
  { field: 'sensorSerialNumber', headerText: 'Sensor Serial Number', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'admin-module-tree-grid-first-column' },
  { field: 'timeStampUTC', headerText: 'Timestamp (UTC)', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'timeStampUTC', headerText: 'When', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent }
];

export const artiFactForOSGridTableColumnData: TreeGridColumnData[] = [
  { field: 'name', headerText: 'Artifact Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'version', headerText: 'Version' },
  { field: 'configVersion', headerText: 'Config Version' },
  { field: 'createdOn', headerText: 'Created On' },
  { field: 'tags', headerText: 'Tags' },
  { field: 'storageStatus', headerText: 'Storage Status' },
  { field: 'otaServerUploadStatus', headerText: 'Ota Server Upload Status' },
  { field: 'hiddenKebabMenu', headerText: '', headerWidth: '50', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Add Tag', icon: 'assets/icons-and-logos/artifact-management/add-icon.svg' }, { label: 'Edit', icon: 'assets/icons-and-logos/artifact-management/edit-icon.svg' }, { label: 'Delete', icon: 'assets/icons-and-logos/artifact-management/delete-icon.svg' }, { label: 'Revoke', icon: 'assets/icons-and-logos/artifact-management/revoke.svg' }] },
];

export const artiFactForFirmwareGridColumnData: TreeGridColumnData[] = [
  { field: 'name', headerText: 'Artifact Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent },
  { field: 'version', headerText: 'Version' },
  { field: 'configVersion', headerText: 'Config Version' },
  { field: 'subType', headerText: 'Firmware Type' },
  { field: 'createdOn', headerText: 'Created On' },
  { field: 'tags', headerText: 'Tags' },
  { field: 'storageStatus', headerText: 'Perceptive Status' },
  { field: 'otaServerUploadStatus', headerText: 'OTA Server Upload Status' },
  { field: 'hiddenKebabMenu', headerText: '', headerWidth: '2rem', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Add Tag', icon: 'assets/icons-and-logos/artifact-management/add-icon.svg' }, { label: 'Edit', icon: 'assets/icons-and-logos/artifact-management/edit-icon.svg' }, { label: 'Delete', icon: 'assets/icons-and-logos/artifact-management/delete-icon.svg' }, { label: 'Revoke', icon: 'assets/icons-and-logos/artifact-management/revoke.svg' }] }
];

export const artiFactForSoftwareModuleGridColumnData: any[] = [
  { field: 'name', headerText: 'Artifact Name', customTemplate: 'MiddleEllipsisTemplateComponent' },
  { field: 'version', headerText: 'Version' },
  { field: 'configVersion', headerText: 'Config Version' },
  { field: 'createdOn', headerText: 'Created On' },
  { field: 'tags', headerText: 'Tags' },
  { field: 'hiddenKebabMenu ', headerText: '', headerWidth: '2rem', customTemplate: TreeGridColumnCustomTemplateData.KebabMenuComponent, kebabMenuListItems: [{ label: 'Add Tag', icon: 'assets/icons-and-logos/artifact-management/add-icon.svg' }, { label: 'Edit', icon: 'assets/icons-and-logos/artifact-management/edit-icon.svg' }, { label: 'Delete', icon: 'assets/icons-and-logos/artifact-management/delete-icon.svg' }, { label: 'Revoke', icon: 'assets/icons-and-logos/artifact-management/revoke.svg' }] }
];

