import 'src/app/shared/extension-classes/date.extension';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UtilitiesService } from '../../services/utlities-services/utilities.service';

@Component({
  selector: 'app-last-communication-template',
  templateUrl: './last-communication-template.component.html',
  styleUrls: ['./last-communication-template.component.scss']
})
export class LastCommunicationTemplateComponent implements OnInit, OnChanges {
  @Input() data!: Date | string | undefined;
  lastCommunication: string = '';
  get lastCommunicationLive():string {
    this.updateLastCommunication();
    return this.lastCommunication;
  }

  constructor(private utilityServices: UtilitiesService)
  {
  }

  ngOnInit(): void {
    this.updateLastCommunication();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.updateLastCommunication();
    }
  }

  updateLastCommunication(): void {
    if (typeof this.data === 'string' && !this.utilityServices.isNullOrEmptyOrWhitespace(this.data)) {
      const date = new Date(this.data);
      this.lastCommunication = date.timeAgo(new Date());
    } else if (this.data instanceof Date) {
      this.lastCommunication = this.data.timeAgo(new Date());
    }
  }
}
