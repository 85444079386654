<div class="grid-template-container">
  <div class="grid-template-header">
    <h2>{{heading}}</h2>
    <svg class="modal-close-icon" (click)="this.closeModal()" (keypress)="closeModal()" xmlns="http://www.w3.org/2000/svg" width="20" height="21"
      viewBox="0 0 20 21" fill="none">
      <path
        d="M16.2727 6.11055C16.7933 5.58996 16.7933 4.74453 16.2727 4.22394C15.7521 3.70335 14.9067 3.70335 14.3861 4.22394L10.0007 8.61355L5.61104 4.2281C5.09045 3.70751 4.24502 3.70751 3.72443 4.2281C3.20384 4.74869 3.20384 5.59413 3.72443 6.11472L8.11403 10.5002L3.72859 14.8898C3.208 15.4104 3.208 16.2558 3.72859 16.7764C4.24918 17.297 5.09462 17.297 5.61521 16.7764L10.0007 12.3868L14.3903 16.7722C14.9108 17.2928 15.7563 17.2928 16.2769 16.7722C16.7975 16.2516 16.7975 15.4062 16.2769 14.8856L11.8873 10.5002L16.2727 6.11055Z"
        fill="#2E3238" />
    </svg>
  </div>
  <app-tree-grid #modalGrid [data]="rowData" [columns]="columnData" [allowSorting]="allowSorting"
    [toolbarOption]="toolbarOption" [gridHeight]="gridHeight" [gridUIType]="gridUIType"
    [allowMultiselect]="allowMultiselect"></app-tree-grid>
  <div class="modal-btns">
    <app-custom-button (buttonClick)="onCancelButtonClick()" [buttonData]="cancelButtonData" />
    <app-custom-button (buttonClick)="onConfirmButtonClick()" [buttonData]="confirmButtonData" />
  </div>
</div>