import { AdminInfoRoutes, CustomerInfoRoutes, EnterpriseInfoRoutes } from 'src/app/admin/constants/route.constants';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardInfoRoutes } from 'src/app/customer/dashboard-info/constants/route.constants';
import { OktaGuardService } from '../auth/okta-guard.service';
import { Router } from '@angular/router';
import { RouteSet } from 'src/app/shared/app-constants/role-constants';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { SpinnerService } from 'src/app/shared/services/spinner-service/spinner.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    private oktaGuardService: OktaGuardService,
    private router: Router,
    private sessionService: SessionService,
    private spinner: SpinnerService,
  ) { }

  ngOnInit(): void {
    this.spinner.showSpinner();
    this.redirectBasedOnRole();
  }

  private async redirectBasedOnRole(): Promise<void> {
    await this.oktaGuardService.setSession();
    const roleName = this.sessionService.roleName;
    if (this.sessionService.requestedUrl && this.sessionService.requestedUrl !== '/') {
      this.router.navigate([this.sessionService.requestedUrl]);
    }
    if (RouteSet.Admin.includes(roleName)) {
      this.sessionService.requestedUrl = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${EnterpriseInfoRoutes.ENTERPRISE_BASE_ROUTE}/${EnterpriseInfoRoutes.ENTERPRISE_LIST}`;
      this.router.navigate([`/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${EnterpriseInfoRoutes.ENTERPRISE_BASE_ROUTE}/${EnterpriseInfoRoutes.ENTERPRISE_LIST}`]);
    } else if (RouteSet.Customer.includes(roleName)) {
      this.sessionService.requestedUrl = `/${CustomerInfoRoutes.BASE_CUSTOMER_ROUTE}/${DashboardInfoRoutes.BASE_DASHBOARD_ROUTE}/${DashboardInfoRoutes.DASHBOARD_HOME}`;
      this.router.navigate(
        [`/${CustomerInfoRoutes.BASE_CUSTOMER_ROUTE}/${DashboardInfoRoutes.BASE_DASHBOARD_ROUTE}/${DashboardInfoRoutes.DASHBOARD_HOME}`],
        { queryParams: { s: this.sessionService.userScope } }
      );
    } else {
      this.router.navigate(['/']); // Default route if no specific role is found
    }
  }

  ngOnDestroy(): void {
    this.spinner.hideSpinner();
  }
}
