import { AllRolesAssignedToUser } from 'src/app/customer/user-management/models/all-roles-assigned-to-user';
import { ApiResponse } from 'src/app/shared/models/api-response';
import { catchError, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggingService } from 'src/app/shared/services/login-services/logging.service';
import { Role } from 'src/app/customer/user-management/models/role';
import { USER_API_ENDPOINTS } from '../../shared/app-constants/api-constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private appInsightService: LoggingService
  ) { }

  public getRoles(): Promise<Role[]> {
    const roles$ = this.http.get<ApiResponse<Role[]>>(environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.ROLES)
      .pipe(map((response) => response.data)).pipe(
        catchError((error: HttpErrorResponse) => {
          this.appInsightService.logException(error);
          throw error;
        })
      );
    return firstValueFrom(roles$);
  }

  public getAllRoleAssignmentsOfAUser(tenantId: string, userId: string): Promise<AllRolesAssignedToUser[]> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.ROLEASSIGNMENTS(tenantId, userId);
    const rolesAssignedToCurrentUser = this.http.get<ApiResponse<AllRolesAssignedToUser[]>>(url)
      .pipe(map((response) => response.data)).pipe(
        catchError((error: HttpErrorResponse) => {
          this.appInsightService.logException(error);
          throw error;
        })
      );
    return lastValueFrom(rolesAssignedToCurrentUser);
  }
}
