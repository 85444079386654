import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-campaign-status-indicator',
  templateUrl: './campaign-status-indicator.component.html',
  styleUrls: ['./campaign-status-indicator.component.scss']
})
export class CampaignStatusIndicatorComponent {
  @Input() campaignStatus?:string;

  // showValue(type: string) {
  //   this.selectedType = type;
  // }

  // resetStatus() {
  //   this.selectedType = '';
  // }
}
