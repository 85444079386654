import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMAGE_API_ENDPOINTS_DASHBOARD } from '../../app-constants/api-constants';
import { SessionService } from '../session-management-services/session.service';
import { ApiResponse } from '../../models/api-response';
import { Observable, Subscriber, catchError, map, tap } from 'rxjs';
import { ImageSasUrlResponse } from '../../models/shared.models';
import { distinctUntilChanged } from 'rxjs/operators';
import { AnonymousCredential, BlockBlobClient } from '@azure/storage-blob';

@Injectable({
  providedIn: 'root'
})
export class AzureBlobClientHelperService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    private sessionService: SessionService,
  ) { }

  getBlobURI(fileName: string, assetId: string) {
    const headers = { headers: new HttpHeaders({ 'Prevent-Spinner': '' }) };
    const request = { assetId: assetId, fileName: fileName };
    const url = environment.dashboardApiBaseUrl + IMAGE_API_ENDPOINTS_DASHBOARD.GET_IMAGE_SAS_URI(this.sessionService.tenantId);
    return this.http.post<ApiResponse<ImageSasUrlResponse>>(url, request, headers)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  //Called on every change of upload bytes
  getProgressPercent(fileSize: number, uploadedBytes: number): number {
    if (fileSize === uploadedBytes) return 100;
    const percentage = (uploadedBytes / fileSize) * 100;
    return Math.round(percentage);
  }

  //Uploads the file using blob uri
  uploadFile(file: Blob, blobUri: string): Observable<number> {
    const blockBlobClient = new BlockBlobClient(
      blobUri,
      new AnonymousCredential()
    );
    return new Observable<number>(observer => {
      blockBlobClient
        .uploadBrowserData(file, {
          onProgress: this.onProgress(observer),
          blobHTTPHeaders: {
            blobContentType: file.type
          }
        })
        .then(
          this.onUploadComplete(observer, file),
          this.onUploadError(observer)
        );
    }).pipe(distinctUntilChanged());
  }

  //Called on progress of file upload
  onProgress(observer: Subscriber<number>) {
    return (progress: any) => observer.next(progress.loadedBytes as number);
  }

  //Called when upload gets completed
  onUploadComplete(observer: Subscriber<number>, file: Blob) {
    return () => {
      observer.next(file.size);
      observer.complete();
    };
  }

  //Called if uploading results into error
  onUploadError(observer: Subscriber<number>) {
    return (error: any) => {
      this.errorHandlerService.showErrorPopUp(error);
      observer.error(error);
    };
  }
}
