import { Injectable } from '@angular/core';
import { ModalDialogService } from '../modal-dialog-service/modal-dialog.service';
import { ModalTemplateTypes } from '../../app-constants/shared.enum';
import { OpenModalDialogData } from '../../models/shared.models';
import { ApiResponseError } from '../../models/api-response';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  constructor(
    private modalDialogService: ModalDialogService,
  ) { }

  showErrorPopUp(httpError: any): Error {
    const data: OpenModalDialogData = new OpenModalDialogData();
    if (httpError.error?.error?.length === 1) {
      data.templateName = ModalTemplateTypes.ERROR_TEMPLATE;
      data.customData.message = httpError.error.error[0].value;
      this.modalDialogService.openModal(data);
    }
    else if (httpError.error?.error?.length > 1) {
        //  TODO: Anshumaan: Marker #1 to keep an eye out for such scenario and bring it to Akash's attention so that a real world scenario can be talked about.
      data.templateName = ModalTemplateTypes.ERROR_TEMPLATE;
      data.customData.message = httpError.error.error.map((obj:any) => obj.key + ' : ' + obj.value).join('\n');
      this.modalDialogService.openModal(data);
    }
    else if (httpError.error?.message) {
      //  TODO: Anshumaan: Marker #1 to keep an eye out for such scenario and bring it to Akash's attention so that a real world scenario can be talked about.
      data.templateName = ModalTemplateTypes.ERROR_TEMPLATE;
      data.customData.message = httpError.error.message;
      this.modalDialogService.openModal(data);
    }
    else {
      data.templateName = ModalTemplateTypes.ERROR_TEMPLATE;
      data.customData.message = 'Unexpected error occured. Please contact admin for further details.';
      this.modalDialogService.openModal(data);
    }
    return httpError;
  }

  handleCustomError(error: ApiResponseError[]) {
    //TODO: parse proper error once error contract gets defined to show specific error message, else just pass error to showErrorPopUp
    if (!error) return;
    if (error.length > 0) {
      const data: OpenModalDialogData = new OpenModalDialogData();
      data.templateName = ModalTemplateTypes.ERROR_TEMPLATE;
      const msg = error.map(item => item.value).join(',');
      data.customData.message = msg ? msg : 'Unexpected error occured. Contact admin.';
      this.modalDialogService.openModal(data);
      return;
    }
    this.showErrorPopUp(error);
  }
}

// TODO: The full extent of this stateless service is going to be determined when we have the full view of figma shared in prototype.
// TODO: Anshumaan MArker #3 Promse Rejection handling.
