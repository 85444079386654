import { ButtonData } from '../../models/shared.models';
import { ButtonStateService } from '../../services/button-state-service/button-state.service';
import { ButtonTemplatePosition, ButtonTypes, CustomEventSource, CustomEventType } from '../../app-constants/shared.enum';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { CustomEvent } from '../../models/custom-event';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent implements OnInit {
  @Input() buttonData: ButtonData = { label: '', type: ButtonTypes.PRIMARY_BASIC, name: '' };
  @Input() template!: TemplateRef<any>;
  @Input() templatePosition: ButtonTemplatePosition|any = ButtonTemplatePosition.NONE;
  @Output() buttonClick = new EventEmitter<CustomEvent>();
  isDisabled$!: Observable<boolean>;

  constructor(private buttonStateService: ButtonStateService) { }

  ngOnInit(): void {
    if (this.buttonData.disable) {
      this.buttonStateService.setButtonDisableState(this.buttonData.name, true);
    }
    this.isDisabled$ = this.buttonStateService.getButtonDisableState(this.buttonData.name);
  }

  // Event handler for button click
  btnClick(): void {
    const event: CustomEvent = { eventType: CustomEventType.CLICK, eventData: this.buttonData.name, eventSource: CustomEventSource.BUTTON };
    this.buttonClick.emit(event);
  }
}
