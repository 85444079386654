export class RoleName {
  public static readonly PERCEPTIV_PLATFORM_ADMIN = 'Perceptiv Platform Admin';
  public static readonly PERCEPTIV_PLATFORM_SUPPORT_USER = 'Perceptiv Platform Support User';
  public static readonly PERCEPTIV_PLATFORM_USER = 'Perceptiv Platform User';
  public static readonly PERCEPTIV_ADMIN = 'Perceptiv Admin';
  public static readonly PERCEPTIV_USER = 'Perceptiv User';
  public static readonly CUSTOMER_ADMIN = 'Customer Admin';
  public static readonly CUSTOMER_USER = 'Customer User';
}

export class RoleType {
  public static readonly TENANT_ROLE:string = 'TenantRole';
  public static readonly GLOBAL_ROLE:string = 'GlobalRole';
}

export const RouteSet = {
  Admin: [
    RoleName.PERCEPTIV_PLATFORM_ADMIN,
    RoleName.PERCEPTIV_PLATFORM_SUPPORT_USER,
    RoleName.PERCEPTIV_PLATFORM_USER,
    RoleName.PERCEPTIV_ADMIN
  ],
  Customer: [
    RoleName.PERCEPTIV_PLATFORM_ADMIN,
    RoleName.PERCEPTIV_PLATFORM_SUPPORT_USER,
    RoleName.PERCEPTIV_PLATFORM_USER,
    RoleName.PERCEPTIV_ADMIN,
    RoleName.CUSTOMER_ADMIN,
    RoleName.CUSTOMER_USER
  ]
};

export class RoleConstants {
  public static readonly CREATE:string = 'Create';
  public static readonly EDIT:string = 'Edit';
}
