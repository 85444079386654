<svg *ngIf="status?.toLowerCase() === 'online'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 26" [ngClass]="{ 'xsm-icon': size === 'size_xsm',
    'sm-icon': size === 'size_sm',
    'lg-icon': size === 'size_lg',
    'xl-icon': size === 'size_xl',
    'xxl-icon': size === 'size_xxl',
    'xl2-icon': size === 'size_2xl',
    'xl4-icon': size === 'size_4xl',
    'xl5-icon': size === 'size_5xl' }" fill="none">
    <path
        d="M17.2158 8.3125C16.5078 3.95215 14.8379 0.890625 12.8945 0.890625C10.9512 0.890625 9.28125 3.95215 8.57324 8.3125H17.2158ZM8.20703 13C8.20703 14.084 8.26562 15.124 8.36816 16.125H17.416C17.5186 15.124 17.5771 14.084 17.5771 13C17.5771 11.916 17.5186 10.876 17.416 9.875H8.36816C8.26562 10.876 8.20703 11.916 8.20703 13ZM24.0615 8.3125C22.665 4.99707 19.8379 2.43359 16.3467 1.39844C17.5381 3.04883 18.3584 5.53418 18.7881 8.3125H24.0615ZM9.4375 1.39844C5.95117 2.43359 3.11914 4.99707 1.72754 8.3125H7.00098C7.42578 5.53418 8.24609 3.04883 9.4375 1.39844ZM24.584 9.875H18.9834C19.0859 10.9004 19.1445 11.9502 19.1445 13C19.1445 14.0498 19.0859 15.0996 18.9834 16.125H24.5791C24.8477 15.124 24.999 14.084 24.999 13C24.999 11.916 24.8477 10.876 24.584 9.875ZM6.64453 13C6.64453 11.9502 6.70312 10.9004 6.80566 9.875H1.20508C0.941406 10.876 0.785156 11.916 0.785156 13C0.785156 14.084 0.941406 15.124 1.20508 16.125H6.80078C6.70312 15.0996 6.64453 14.0498 6.64453 13ZM8.57324 17.6875C9.28125 22.0479 10.9512 25.1094 12.8945 25.1094C14.8379 25.1094 16.5078 22.0479 17.2158 17.6875H8.57324ZM16.3516 24.6016C19.8379 23.5664 22.6699 21.0029 24.0664 17.6875H18.793C18.3633 20.4658 17.543 22.9512 16.3516 24.6016ZM1.72754 17.6875C3.12402 21.0029 5.95117 23.5664 9.44238 24.6016C8.25098 22.9512 7.43066 20.4658 7.00098 17.6875H1.72754Z"
        fill="#2E3238" />
</svg>
<svg *ngIf="status?.toLowerCase() === 'offline'" viewBox="0 0 27 26" xmlns="http://www.w3.org/2000/svg" [ngClass]="{ 'xsm-icon': size === 'size_xsm',
    'sm-icon': size === 'size_sm',
    'lg-icon': size === 'size_lg',
    'xl-icon': size === 'size_xl',
    'xxl-icon': size === 'size_xxl',
    'xl2-icon': size === 'size_2xl',
    'xl4-icon': size === 'size_4xl',
    'xl5-icon': size === 'size_5xl' }" fill="none">
    <g clip-path="url(#clip0_5508_18105)">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12.3232 0C14.2666 0 15.9365 3.06152 16.6445 7.42188H8.00195C8.70996 3.06152 10.3799 0 12.3232 0ZM7.79688 15.2344C7.69434 14.2334 7.63574 13.1934 7.63574 12.1094C7.63574 11.0254 7.69434 9.98535 7.79688 8.98438H16.8447C16.8666 9.19797 16.8865 9.41334 16.9042 9.63054C16.8547 9.69272 16.8093 9.75887 16.7684 9.82873L13.5955 15.2344H7.79688ZM12.6784 16.7969H8.00195C8.34366 18.9013 8.90941 20.7032 9.62183 22.0044L12.6784 16.7969ZM8.73463 23.5159C7.61687 21.868 6.84277 19.4678 6.42969 16.7969H1.15625C2.52427 20.0447 5.26522 22.571 8.65842 23.6457L8.73463 23.5159ZM22.8293 15.2344H24.0078C24.2764 14.2334 24.4277 13.1934 24.4277 12.1094C24.4277 11.0254 24.2764 9.98535 24.0127 8.98438H18.4121C18.413 8.99366 18.414 9.00294 18.4149 9.01223C18.9293 9.07469 19.3896 9.37299 19.6564 9.82873L22.8293 15.2344ZM15.7754 0.507812C19.2666 1.54297 22.0938 4.10645 23.4902 7.42188H18.2168C17.7871 4.64355 16.9668 2.1582 15.7754 0.507812ZM1.15625 7.42188C2.54785 4.10645 5.37988 1.54297 8.86621 0.507812C7.6748 2.1582 6.85449 4.64355 6.42969 7.42188H1.15625ZM6.23438 8.98438C6.13184 10.0098 6.07324 11.0596 6.07324 12.1094C6.07324 13.1592 6.13184 14.209 6.22949 15.2344H0.633789C0.370117 14.2334 0.213867 13.1934 0.213867 12.1094C0.213867 11.0254 0.370117 9.98535 0.633789 8.98438H6.23438Z"
            fill="#2E3238" />
        <path
            d="M18.2127 12C18.6565 12 19.0658 12.2344 19.2908 12.6187L26.0408 24.1187C26.269 24.5062 26.269 24.9844 26.0471 25.3719C25.8252 25.7594 25.4096 26 24.9627 26H11.4627C11.0158 26 10.6002 25.7594 10.3783 25.3719C10.1565 24.9844 10.1596 24.5031 10.3846 24.1187L17.1346 12.6187C17.3596 12.2344 17.769 12 18.2127 12Z"
            fill="#CC3300" />
        <path
            d="M18.5275 16.55C18.5275 16.2513 18.2862 16.01 17.9875 16.01C17.6888 16.01 17.4475 16.2513 17.4475 16.55V20.8703C17.4475 21.169 17.6888 21.4104 17.9875 21.4104C18.2862 21.4104 18.5275 21.169 18.5275 20.8703V16.55ZM17.9875 23.5705C18.1665 23.5705 18.3382 23.4994 18.4648 23.3728C18.5914 23.2462 18.6625 23.0745 18.6625 22.8955C18.6625 22.7164 18.5914 22.5447 18.4648 22.4181C18.3382 22.2915 18.1665 22.2204 17.9875 22.2204C17.8085 22.2204 17.6368 22.2915 17.5102 22.4181C17.3836 22.5447 17.3125 22.7164 17.3125 22.8955C17.3125 23.0745 17.3836 23.2462 17.5102 23.3728C17.6368 23.4994 17.8085 23.5705 17.9875 23.5705Z"
            fill="white" />
    </g>
    <defs>
        <clipPath id="clip0_5508_18105">
            <rect width="26" height="26" fill="white" transform="translate(0)" />
        </clipPath>
    </defs>
</svg>