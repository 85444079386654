<div class="confirm-container">
  <svg class="modal-close-icon" (click)="this.closeModal()" (keypress)="closeModal()" xmlns="http://www.w3.org/2000/svg" width="20" height="21"
    viewBox="0 0 20 21" fill="none">
    <path
      d="M16.2727 6.11055C16.7933 5.58996 16.7933 4.74453 16.2727 4.22394C15.7521 3.70335 14.9067 3.70335 14.3861 4.22394L10.0007 8.61355L5.61104 4.2281C5.09045 3.70751 4.24502 3.70751 3.72443 4.2281C3.20384 4.74869 3.20384 5.59413 3.72443 6.11472L8.11403 10.5002L3.72859 14.8898C3.208 15.4104 3.208 16.2558 3.72859 16.7764C4.24918 17.297 5.09462 17.297 5.61521 16.7764L10.0007 12.3868L14.3903 16.7722C14.9108 17.2928 15.7563 17.2928 16.2769 16.7722C16.7975 16.2516 16.7975 15.4062 16.2769 14.8856L11.8873 10.5002L16.2727 6.11055Z"
      fill="#2E3238" />
  </svg>
  <svg class="modal-main-icon" xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57"
    fill="none">
    <g clip-path="url(#clip0_1699_56686)">
      <path
        d="M28.5 56.5C35.9261 56.5 43.048 53.55 48.299 48.299C53.55 43.048 56.5 35.9261 56.5 28.5C56.5 21.0739 53.55 13.952 48.299 8.70101C43.048 3.44999 35.9261 0.5 28.5 0.5C21.0739 0.5 13.952 3.44999 8.70101 8.70101C3.44999 13.952 0.5 21.0739 0.5 28.5C0.5 35.9261 3.44999 43.048 8.70101 48.299C13.952 53.55 21.0739 56.5 28.5 56.5ZM28.5 14.5C29.9547 14.5 31.125 15.6703 31.125 17.125V29.375C31.125 30.8297 29.9547 32 28.5 32C27.0453 32 25.875 30.8297 25.875 29.375V17.125C25.875 15.6703 27.0453 14.5 28.5 14.5ZM25 39C25 38.0717 25.3687 37.1815 26.0251 36.5251C26.6815 35.8688 27.5717 35.5 28.5 35.5C29.4283 35.5 30.3185 35.8688 30.9749 36.5251C31.6313 37.1815 32 38.0717 32 39C32 39.9283 31.6313 40.8185 30.9749 41.4749C30.3185 42.1312 29.4283 42.5 28.5 42.5C27.5717 42.5 26.6815 42.1312 26.0251 41.4749C25.3687 40.8185 25 39.9283 25 39Z"
        fill="#2E3238" />
    </g>
    <defs>
      <clipPath id="clip0_1699_56686">
        <rect width="56" height="56" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
  <div class="modal-message">{{message}}</div>
  <hr>
  <div class="modal-btns">
    <app-custom-button (buttonClick)="onCancelButtonClick()" [buttonData]="cancelButtonData" />
    <app-custom-button (buttonClick)="onConfirmButtonClick()" [buttonData]="confirmButtonData" />
  </div>
</div>