import { DayOfWeek } from '../../../shared/app-constants/shared.enum';

export interface NotificationViewModel {
  countOfAlertEvents: number;
  countOfDevicesEvents: number;
  countOfHealthEvents: number;
  dailyFrequencyTime?: Date;
  dayOfWeek?: DayOfWeek;
  isActive: boolean;
  isDeleted: boolean;
  lastOnlineStamp?: string;
  monthlyFrequencyTime?: Date;
  notificationFrequency: NotificationFrequency;
  notificationId?: string;
  notificationMethods?: NotificationMethodType[];
  notifyAllEquipment?: NotifyOnAllAssets;
  notifyAllSensorsAndGateways?: NotifyOnAllDevices;
  notifyAllUser?: boolean;
  notifyOnAlertEvent: boolean;
  notifyOnCertificateExpiry: boolean;
  notifyOnDeviceOffline: boolean;
  notifyOnDeviceOnline: boolean;
  notifyOnHealthStateChange: boolean;
  roleNames?: string[];
  ruleName: string;
  scopeIdOfHierarchy: string;
  specificAssets?: AssetSelection[];
  specificSensorsAndGateways?: DeviceSelection[];
  templateId?: string;
  userIds?: string[];
  weeklyFrequencyTime?: Date;
  windowPeriod: number;
}

export interface AssetSelection {
  assetId: string;
  isSelected: boolean;
}

export interface DeviceSelection {
  deviceSerialNumber: string;
  isSelected: boolean;
}

export interface NotifyOnAllAssets {
  parentScopeId: string;
  isSelected: boolean;
}

export interface NotifyOnAllDevices {
  parentScopeId: string;
  isSelected: boolean;
}

export enum NotificationFrequency {
  None = 'None',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Instantly = 'Instantly'
}

export enum NotificationMethodType {
  Email = 'Email',
  Sms = 'Sms'
}
