export class TokenInfo {
  token?: string;
  tokenId?: string;
  expiration?: string;
}

export class PowerBIReportParams {
  reportId?: string;
  reportEmbedURL?: string;
  reportAccessToken?: string;
  filterSchemaURL?: string;
  filterDataSource?: string;
  filterDataColumn?: string;
  filterDataValue?: string;
  filterDayCount?: number;
  startDate?: string;
  endDate?: string;
}
