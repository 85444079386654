import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { NgModule } from '@angular/core';
import { HeaderInterceptor } from './shared/interceptors/header.interceptor';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LoggingService } from './shared/services/login-services/logging.service';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaGuardService } from './core/auth/okta-guard.service';
import { SessionService } from './shared/services/session-management-services/session.service';
import { SharedModule } from './shared/shared.module';
import { SpinnerService } from './shared/services/spinner-service/spinner.service';
import { TokenGenerationService } from './shared/services/token-generation-services/tokenGeneration.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UserService } from 'src/app/customer/user-management/services/user-services/user.service';
import { GatewayService } from './admin/gateways-info/services/gateway-services/gateway.service';
import { DatePipe } from '@angular/common';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    OktaAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    CoreModule,
    SharedModule,
    AppRoutingModule,
  ],
  providers: [
    LoggingService,
    SpinnerService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    TokenGenerationService,
    SessionService,
    OktaGuardService
    // ContextMenuService //TODO: Syncfusion context menu service is added here as it was making all tests fail when added in shared module providers
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
