import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OpenModalDialogData } from '../../models/shared.models';
import { CustomEvent } from '../../models/custom-event';

@Injectable({
  providedIn: 'root'
})
export class ModalDialogService {
  dataToEmitOnClose = {};
  private openModalDialogData = new Subject<OpenModalDialogData>();
  private modalDialogEventData = new Subject<CustomEvent>();
  private closeModalData = new Subject();

  // Method to be called in order to open the modal by providing it OpenModalDialogData
  openModal(data: OpenModalDialogData) {
    this.dataToEmitOnClose = data.customData;
    this.openModalDialogData.next(data);
  }

  // Subscrbe this method to take action when consumer calls openModal() method
  openModalObservable(): Observable<OpenModalDialogData> {
    return this.openModalDialogData.asObservable();
  }

  // Method to be called in order to close the modal
  closeModal() {
    this.closeModalData.next(this.dataToEmitOnClose);
  }

  // Subscrbe this method to take action when consumer calls closeModal() method
  closeModalObservable() {
    return this.closeModalData.asObservable();
  }

  // Method to be called in order to emit any data from modal by converting into CustomEvent event
  emitModalDialogEvent(data: CustomEvent) {
    this.modalDialogEventData.next(data);
  }

  // Subscrbe this method to take action when consumer emits any data from emitModalDialogEvent
  modalDialogEventObservable() {
    return this.modalDialogEventData.asObservable();
  }
}
