import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { AppRouteConstants } from 'src/app/shared/app-constants/app-route-constants';
import { SpinnerService } from 'src/app/shared/services/spinner-service/spinner.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {
  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private spinner: SpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.logout();
  }

  async logout() {
    this.spinner.showSpinner();
    await this.oktaAuth.signOut().catch(error => {
      const url = `/${AppRouteConstants.APP_LOGIN_ROUTE}`;
      this.router.navigate([url], { replaceUrl: true });
    });
  }
}
