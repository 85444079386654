import { AdminSideNavComponent } from './admin-side-nav/admin-side-nav.component';
import { AlertSidebarComponent } from './alert-sidebar/alert-sidebar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProfileSidebarComponent } from './profile-sidebar/profile-sidebar.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SideNavComponent } from './side-nav/side-nav.component';
import { AlertSignalComponent } from './alert-sidebar/templates/alert-signal/alert-signal.component';
import { CommandSignalComponent } from './alert-sidebar/templates/command-signal/command-signal/command-signal.component';

@NgModule({
  declarations: [
    AdminSideNavComponent,
    AlertSidebarComponent,
    BreadcrumbsComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    PageNotFoundComponent,
    ProfileSidebarComponent,
    SideNavComponent,
    AlertSignalComponent,
    CommandSignalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    AdminSideNavComponent,
    AlertSidebarComponent,
    BreadcrumbsComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    PageNotFoundComponent,
    ProfileSidebarComponent,
    SideNavComponent
  ]
})
export class CoreModule { }
