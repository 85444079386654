<ejs-tooltip #tooltipObj (beforeOpen)="onBeforeOpen($event)" [content]="flyoutContent"
  [position]="position">
  <span class="a-link">{{ label }}</span>
</ejs-tooltip>

<!-- Flyout content template -->
<ng-template #flyoutContent>
  <div class="tooltip-container">
    <div *ngIf="loading" class="loading-message">
      Loading....
    </div>
    <div class="breadcrumb-container" *ngIf="!loading">
      <ul class="breadcrumb">
        <li *ngFor="let breadcrumb of breadcrumbs; let last = last">
          <div class="breadcrumb-item">
            <div class="breadcrumb-content">
              <span class="breadcrumb-name">{{ breadcrumb.name }}</span>
              <div class="breadcrumb-subtext">{{ breadcrumb.objectType | capitalize }}</div>
            </div>
            <div *ngIf="!last" class=" e-icons e-chevron-right e-medium breadcrumb-separator"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>