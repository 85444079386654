import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/shared/models/login-state';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb-service/breadcrumb.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { BreadcrumbInfo } from '../../shared/models/breadcrumb-info';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetType } from 'src/app/shared/app-constants/asset-constants';
import { SignalRHubService } from 'src/app/shared/services/signalr-services/signalr-hub.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  isAdminLogin = false;
  isLoggedIn = false;
  @Input() adminPage = true;
  scope!: string;
  private subscriptions = new Subscription();

  breadcrumbs: BreadcrumbInfo[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private signalRHubService: SignalRHubService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.sessionService.loginState$.subscribe((values: LoginState) => {
      this.isLoggedIn = values.loginStatus;
      this.isAdminLogin = values.isAdminRole;
    }));
    this.activatedRoute.queryParams.subscribe(params => {
      this.scope = params['s'];
    });
    if (this.breadcrumbService.breadcrumbsDataChanged$) {
      this.subscriptions.add(this.breadcrumbService.breadcrumbsDataChanged$.subscribe(() => {
        this.getBreadcrumbValues();
      }));
    }

    this.subscriptions.add(this.signalRHubService.signalRAssetChangeSignalEvent$.subscribe((data) => {
      if (this.breadcrumbs && data?.data?.assetId && data.data.properties?.label) {
        const crumb = this.breadcrumbs.find(b => b.id === data.data.assetId);
        if (crumb) {
          crumb.label = data.data.properties.label;
          crumb.name = data.data.properties.label;
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getBreadcrumbValues(): void {
    const breadcrumbs = this.breadcrumbService.getBreadcrumbValues();
    const transformedBreadcrumbs = this.breadcrumbService.transformBreadcrumbs(breadcrumbs, this.adminPage, this.scope);
    if (this.scope) {
      const partsCount = this.scope.split('/').filter(Boolean).length;
      this.breadcrumbs = (partsCount > 1)
        ? transformedBreadcrumbs.filter(x => x.objectType !== AssetType.ENTERPRISE)
        : transformedBreadcrumbs;
    } else {
      this.breadcrumbs = transformedBreadcrumbs;
    }
  }

  navigateTo(event: Event, url: string): void {
    event.preventDefault();
    if (url) {
      const [path, queryString] = url.split('?');
      const queryParams = this.getQueryParams(queryString);
      this.router.navigate([path], { queryParams });
    }
  }

  private getQueryParams(queryString: string): { [key: string]: string } {
    return queryString ? queryString.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = decodeURIComponent(value);
      return params;
    }, {} as { [key: string]: string }) : {};
  }
}
