import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap } from 'rxjs';
import { ARTIFACT_MANAGEMENT_API_ENDPOINTS, CAMPAIGN_MANAGEMENT_API_ENDPOINTS, GATEWAY_API_ENDPOINTS } from 'src/app/shared/app-constants/api-constants';
import { ApiResponse } from 'src/app/shared/models/api-response';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler/error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' }) // As off now i will keep this for quik fix later i will move this and move the dailogs and related services to shared
export class CampaignManagementService {
  constructor(
    private errorHandlerService: ErrorHandlerService,
    private http: HttpClient
  )  { }

  public getGatewayListServiceUrl(tenantId: string, code:string): string {
    return environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_ASSETS(tenantId, code);
  }

  public getCampaignsUrl(tenantId: string, code:string): string {
    return environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.CREATE_CAMPAIGN(tenantId, code);
  }

  public getGroupsUrl(tenantId: string, code:string, groupName:string): string {
    return environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_GROUPS(tenantId, code);
  }

  public getArtifactsUrl(tenantId: string, code:string, type:string): string {
    return environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.GET_ALL_ARTIFACTS(tenantId, code, false, type);
  }

  public getOperationsUrl(tenantId: string, campaginId: string, code:string): string {
    return environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_OPERATIONS(tenantId, campaginId, code);
  }

  public getCampaignByIdUrl(tenantId: string, campaignId: string, code:string): string {
    return environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_CAMPAIGN_BY_ID(tenantId, campaignId, code);
  }

  public getGroupByIdUrl(tenantId: string, groupId: string, code:string): string {
    return environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_GROUP_BY_ID(tenantId, groupId, code);
  }

  public getCampaignByGroupIdUrl(tenantId: string, groupId: string, code:string): string {
    return environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_CAMPAIGN_BY_GROUP_ID(tenantId, groupId, code);
  }

  public createGroup(tenantId: string, groupData: any, code: string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.CREATE_GROUP(tenantId, code);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = groupData;
    return this.http.post<ApiResponse<any>>(url, JSON.stringify(body), headers).pipe(
      tap(response => this.errorHandlerService.handleCustomError(response.error)),
      map(response => response.data),
      catchError(error => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public createCampaign(tenantId: string, code:string, campaignData: any): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.CREATE_CAMPAIGN(tenantId, code);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = campaignData;
    return this.http.post<ApiResponse<any>>(url, JSON.stringify(body), headers).pipe(
      tap(response => this.errorHandlerService.handleCustomError(response.error)),
      map(response => response.data),
      catchError(error => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public campaignActions(tenantId: string, campaginId:string, code:string, campaignData: any): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.CAMPAIGN_ACTIONS(tenantId, campaginId, code);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = campaignData;
    return this.http.post<ApiResponse<any>>(url, JSON.stringify(body), headers).pipe(
      tap(response => this.errorHandlerService.handleCustomError(response.error)),
      map(response => response.data),
      catchError(error => {
        console.log(error);
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAllAssets(tenantId: string, code: string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_ASSETS(tenantId, code);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAllCampaignsByGroupId(tenantId: string, campaginId: string, code:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_CAMPAIGN_BY_GROUP_ID(tenantId, campaginId, code);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAllCampaigns(tenantId: string, code:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.CREATE_CAMPAIGN(tenantId, code);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAllGroups(tenantId: string, code:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_GROUPS(tenantId, code);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAllOperations(tenantId: string, campaginId: string, code:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_OPERATIONS(tenantId, campaginId, code);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getGroupDetails(tenantId: string, code:string, groupId: string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_GROUP_BY_ID(tenantId, code, groupId);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getCampaignDetails(tenantId: string, campaignId:string, code: string): Observable<any> {
    const url = environment.dashboardApiBaseUrl +  CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_CAMPAIGN_BY_ID(tenantId, campaignId, code);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getGroupDetail(tenantId: string, code:string, groupId: string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_GROUP_BY_ID(tenantId, groupId, code);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getGroup(tenantId: string, code: string, groupName: string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.GET_GROUPS(tenantId, code, groupName);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public updateGroup(tenantId: string, groupData: any, groupId: string, code:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.UPDATE_GROUP(tenantId, groupId, code);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = groupData;
    return this.http.put<ApiResponse<any>>(url, JSON.stringify(body), headers).pipe(
      tap(response => this.errorHandlerService.handleCustomError(response.error)),
      map(response => response.data),
      catchError(error => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public deleteGroup(tenantId: string, groupId: string, code: string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + CAMPAIGN_MANAGEMENT_API_ENDPOINTS.DELETE_GROUP(tenantId, groupId, code);
    return this.http.delete<ApiResponse<any>>(url).pipe(
      tap(response => this.errorHandlerService.handleCustomError(response.error)),
      map(response => response.data),
      catchError(error => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getArtifacts(tenantId: string, code: string, type:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.GET_ALL_ARTIFACTS(tenantId, code, false, type);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAFirmwareArtifacts(firmwareUrl:string): Observable<any> {
    const url = firmwareUrl;
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }
}
