import { Embed, IReportEmbedConfiguration, models, service } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { PowerBIReportParams } from '../models/token-info';

export const POWERBI_REPORTCONFIG: IReportEmbedConfiguration = {
  type: 'report',
  embedUrl: undefined,
  tokenType: models.TokenType.Embed,
  accessToken: undefined,
  viewMode: models.ViewMode.View,
  settings: {
    visualSettings: {
      visualHeaders: [{
        settings: {
          visible: false
        }
      }]
    },
    customLayout: {
      displayOption: models.DisplayOption.FitToPage
    },
    layoutType: models.LayoutType.Custom,
    navContentPaneEnabled: false,
    filterPaneEnabled: false,
    panes: {
      filters: {
        expanded: false,
        visible: false,
      },
    },
  },
};

export const POWERBI_REPORT_TEST_S7100 = {
  FILTER_SCHEMA_URL_TEST_S7100: 'https://powerbi.com/product/schema#basic',
  WORKSPACE_ID_TEST_S7100: '30fcf1b3-725a-4fca-9c7b-2eab95ab1599',
  REPORT_ID_TEST_S7100: 'fcd5a255-6928-4516-9829-4af6cc61a2f0',
  REPORT_DATASET_ID_TEST_S7100: '7120cb91-a1fa-48fa-9d7c-23ae319d808b',
  REPORT_TOKEN_TEST_S7100: '',
  REPORT_TABLE_TEST_S7100: 'txn_unified_messages_s7100_hot',
  REPORT_COLUMN_SENSORID_TEST_S7100: 'sensorId',
  REPORT_MAC_ADDRESS_TEST_S7100: '',
};

export const POWERBI_REPORT_S7100 = {
  FILTER_SCHEMA_URL_S7100: 'https://powerbi.com/product/schema#basic',
  WORKSPACE_ID_S7100: '30fcf1b3-725a-4fca-9c7b-2eab95ab1599',
  REPORT_ID_S7100: '44fe841e-4fb5-474e-8325-fe7a23b888f0',
  REPORT_DATASET_ID_S7100: '70ef8480-aa85-45f2-8f4c-c0abffae4786',
  REPORT_TOKEN_S7100: '',
  REPORT_TABLE_S7100: 'txn_unified_messages_s7100_hot',
  REPORT_COLUMN_SENSORID_S7100: 'sensorId',
  REPORT_MAC_ADDRESS_S7100: '',
};

export const POWERBI_REPORT_PB18 = {
  FILTER_SCHEMA_URL_PB18: 'https://powerbi.com/product/schema#basic',
  WORKSPACE_ID_PB18: '30fcf1b3-725a-4fca-9c7b-2eab95ab1599',
  REPORT_ID_PB18: 'aa31d31a-336a-4cb9-a189-a14fb216f345',
  REPORT_DATASET_ID_PB18: 'bdb05c65-c524-402a-bf84-a76ba65f021b',
  REPORT_TOKEN_PB18: '',
  REPORT_TABLE_PB18: 'txn_unified_messages_s7100_hot',
  REPORT_COLUMN_SENSORID_PB18: 'sensorId',
  REPORT_MAC_ADDRESS_PB18: '',
};

export const POWERBI_REPORT = {
  REPORT_EMBED_URL: (ReportId: string, WorkspaceId: string) => `https://app.powerbi.com/reportEmbed?reportId=${ReportId}&groupId=${WorkspaceId}`,
  PAGED_REPORT_EMBED_URL: (ReportId: string, WorkspaceId: string, ReportSection: string) => `https://app.powerbi.com/reportEmbed?reportId=${ReportId}&groupId=${WorkspaceId}&pageName=${ReportSection}`,
  FAILED_ERROR: (status: string, statusText: string) => `Failed to fetch config for report. Status: ${String(status)} ${String(statusText)}`
};

/**
 * Map of event handlers to be applied to the embedded report
 * Update event handlers for the report by redefining the map using this.eventHandlersMap
 * Set event handler to null if event needs to be removed
 * More events can be provided from here
 * https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/handle-events#report-events
 */
export const HANDLERS = {
  EVENT_HANDLERS_MAP(reportObj: PowerBIReportEmbedComponent) {
    const map = new Map([
      ['loaded', () => {
        const reportEvent = reportObj?.getReport();
        reportEvent?.setComponentTitle('Embedded report');
      }],
      ['rendered', () => console.log('Report has rendered')],
      ['error',
        (event?: service.ICustomEvent<any>) => {
          if (event) {
            console.error(event.detail);
          }
        }],
      ['visualClicked', () => console.log('Visual clicked')],
      ['pageChanged', (event) => console.log(event)],
    ]) as Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null>;
    return map;
  }
};

export function hideOverlayForCombinedPowerBIReport() {
  const ele = document.getElementById('combined-power-bi-report-container-overlay');
  if (!ele) return;
  ele.style.display = 'none';
}

export const CONFIG = {
  BASIC_CONFIG(defaultReportConfig: IReportEmbedConfiguration, additionalReportConfigParams: PowerBIReportParams) {
    const _reportConfig: IReportEmbedConfiguration = {
      ...defaultReportConfig,
      id: additionalReportConfigParams.reportId,
      embedUrl: additionalReportConfigParams.reportEmbedURL,
      accessToken: additionalReportConfigParams.reportAccessToken,
      filters: [{
        $schema: String(additionalReportConfigParams.filterSchemaURL),
        target: {
          table: String(additionalReportConfigParams.filterDataSource),
          column: additionalReportConfigParams.filterDataColumn,
        },
        operator: 'In',
        values: [String(additionalReportConfigParams.filterDataValue)],
        filterType: models.FilterType.Basic,
        requireSingleSelection: false
      }]
    };
    return _reportConfig;
  },
  ADD_ADDITIONAL_CONFIG(defaultReportConfig: IReportEmbedConfiguration, additionalReportConfigParams: PowerBIReportParams) {
    const _reportConfig: IReportEmbedConfiguration = {
      ...defaultReportConfig,
      id: additionalReportConfigParams.reportId,
      embedUrl: additionalReportConfigParams.reportEmbedURL,
      accessToken: additionalReportConfigParams.reportAccessToken,
      filters: [{
        $schema: String(additionalReportConfigParams.filterSchemaURL),
        target: {
          table: String(additionalReportConfigParams.filterDataSource),
          column: additionalReportConfigParams.filterDataColumn,
        },
        operator: 'In',
        values: [String(additionalReportConfigParams.filterDataValue)],
        filterType: models.FilterType.Basic,
        requireSingleSelection: false
      }]
    };
    return _reportConfig;
  },
  ADD_ADDITIONAL_CUSTOM_DATE_CONFIG(defaultReportConfig: IReportEmbedConfiguration, additionalReportConfigParams: PowerBIReportParams) {
    const _reportConfig: IReportEmbedConfiguration = {
      ...defaultReportConfig,
      id: additionalReportConfigParams.reportId,
      embedUrl: additionalReportConfigParams.reportEmbedURL,
      accessToken: additionalReportConfigParams.reportAccessToken,
      filters: [{
        $schema: String(additionalReportConfigParams.filterSchemaURL),
        target: {
          table: String(additionalReportConfigParams.filterDataSource),
          column: String(additionalReportConfigParams.filterDataColumn),
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: 'And',
        conditions: [
          {
            operator: 'GreaterThanOrEqual',
            value: additionalReportConfigParams.startDate
          },
          {
            operator: 'LessThanOrEqual',
            value: additionalReportConfigParams.endDate
          }
        ]
      }]
    };
    return _reportConfig;
  }
};

export const FILTER = {
  ADD_BASIC_FILTER(additionalReportFilterParams: PowerBIReportParams) {
    const _basicFilter = {
      $schema: String(additionalReportFilterParams.filterSchemaURL),
      target: {
        table: String(additionalReportFilterParams.filterDataSource),
        column: String(additionalReportFilterParams.filterDataColumn),
      },
      operator: 'In',
      values: [String(additionalReportFilterParams.filterDataValue)],
      filterType: 1 // models.FilterType.BasicFilter
    };
    return _basicFilter;
  },
  ADD_ADVANCED_IS_FILTER(additionalReportFilterParams: PowerBIReportParams) {
    const _advancedFilter = {
      $schema: String(additionalReportFilterParams.filterSchemaURL),
      target: {
        table: String(additionalReportFilterParams.filterDataSource),
        column: String(additionalReportFilterParams.filterDataColumn),
      },
      filterType: models.FilterType.Advanced,
      logicalOperator: 'And',
      conditions: [
        {
          operator: 'Is',
          value: additionalReportFilterParams.filterDataValue
        }
      ]
    };
    return _advancedFilter;
  },
  ADD_ADVANCED_LESSTHAN_FILTER(additionalReportFilterParams: PowerBIReportParams) {
    const _advancedFilter = {
      $schema: String(additionalReportFilterParams.filterSchemaURL),
      target: {
        table: String(additionalReportFilterParams.filterDataSource),
        column: String(additionalReportFilterParams.filterDataColumn),
      },
      filterType: models.FilterType.Advanced,
      logicalOperator: 'And',
      conditions: [
        {
          operator: 'LessThan',
          value: additionalReportFilterParams.filterDataValue
        }
      ]
    };
    return _advancedFilter;
  },
  ADD_RELATIVE_DATE_FILTER(additionalReportFilterParams: PowerBIReportParams) {
    return [{
      $schema: String(additionalReportFilterParams.filterSchemaURL),
      target: {
        table: String(additionalReportFilterParams.filterDataSource),
        column: String(additionalReportFilterParams.filterDataColumn),
      },
      filterType: models.FilterType.RelativeDate,
      operator: models.RelativeDateOperators.InLast,
      timeUnitsCount: Number(additionalReportFilterParams.filterDayCount),
      timeUnitType: models.RelativeDateFilterTimeUnit.Days,
      includeToday: true
    }];
  },
  ADD_CUSTOM_DATE_FILTER(additionalReportFilterParams: PowerBIReportParams) {
    return [{
      $schema: String(additionalReportFilterParams.filterSchemaURL),
      target: {
        table: String(additionalReportFilterParams.filterDataSource),
        column: String(additionalReportFilterParams.filterDataColumn),
      },
      filterType: models.FilterType.Advanced,
      logicalOperator: 'And',
      conditions: [
        {
          operator: 'GreaterThanOrEqual',
          value: additionalReportFilterParams.startDate
        },
        {
          operator: 'LessThanOrEqual',
          value: additionalReportFilterParams.endDate
        }
      ]
    }];
  }
};

export class DayFilter {
  public static readonly DAY_30_FILTER: string = '30 Day';
  public static readonly DAY_60_FILTER: string = '60 Day';
  public static readonly DAY_90_FILTER: string = '90 Day';
  public static readonly DAY_CUSTOM_FILTER: string = 'Custom Date';
}

export class DayCount {
  public static readonly DAY_30_FILTER_COUNT: number = 30;
  public static readonly DAY_60_FILTER_COUNT: number = 60;
  public static readonly DAY_90_FILTER_COUNT: number = 90;
}

export class Graph {
  public static readonly HOT_SENSOR_GRAPH: string = 'Hot';
  public static readonly COLD_SENSOR_GRAPH: string = 'Cold';
}
