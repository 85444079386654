<div class="profile-sidebar">
  <div class="avatar">
    <img src="../../../assets/sidebar/user-profile.svg" alt="Avatar">
  </div>
  <div class="row-container">
    <ejs-listbox (change)="selectionChange($event)" class="profile-sidebar-listbox-wrapper"
      [dataSource]='data'></ejs-listbox>
    <app-custom-button class="profile-logout" (buttonClick)="logout()" [buttonData]="logoutButtonData" />
  </div>
</div>
