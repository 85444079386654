import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ASSETS_API_ENDPOINTS, ASSETS_TREE_API_ENDPOINTS } from '../../app-constants/api-constants';
import { ApiResponse } from '../../models/api-response';
import { AssetsTree } from '../../models/asset';
import { TreeGridColumnCustomTemplateData, TreeGridColumnData } from '../../models/tree-grid';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { AssetType } from '../../app-constants/asset-constants';
import { CapitalizePipe } from '../../custom-pipes/capitalize.pipe';

@Injectable({
  providedIn: 'root'
})
export class TreeGridHelperService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  createTreeGridHeaders() {
    const treeGridColumns: Array<TreeGridColumnData> = [];
    const col0: TreeGridColumnData = { field: 'id', headerText: 'Id', visible: false, isPrimaryKey: true };
    const col1: TreeGridColumnData = { field: 'label', headerText: 'Name', customTemplate: TreeGridColumnCustomTemplateData.MiddleEllipsisTemplateComponent, cssClass: 'tree-grid-first-column' };
    const customStatusTemplateProps = { showEmptyPill: false };
    const col2: TreeGridColumnData = { field: 'status', headerText: 'Status', hideValueOnRowExpand: true, customTemplate: TreeGridColumnCustomTemplateData.AssetStatusIndicator, customTemplateProps: customStatusTemplateProps, headerWidth: '8rem', textAlign: 'Center' };
    const col3: TreeGridColumnData = { field: 'lastCommunication', headerText: 'Last Communicated', customTemplate: TreeGridColumnCustomTemplateData.LastCommunicationTemplateComponent, textAlign: 'Center'  };
    const col4: TreeGridColumnData = { field: 'objectType', headerText: 'Type', textAlign: 'Center', headerWidth: '12rem', pipes: [{ pipe: new CapitalizePipe() }] };
    treeGridColumns.push(col1, col0, col2, col3, col4);
    return treeGridColumns;
  }

  createTreeGridHeadersForRoleAssignment() {
    const treeGridColumns: Array<TreeGridColumnData> = [];
    const col1: TreeGridColumnData = { field: 'name', headerText: 'Name', showCheckbox: true };
    const col2: TreeGridColumnData = { field: 'type', headerText: 'Type', headerWidth: '10rem' };
    treeGridColumns.push(col1, col2);
    return treeGridColumns;
  }

  createTreeGridHeadersForAssetSelectionForNotificationRules() {
    const treeGridColumns: Array<TreeGridColumnData> = [];
    const col1: TreeGridColumnData = { field: 'name', headerText: 'Name', showCheckbox: true };
    const col2: TreeGridColumnData = { field: 'type', headerText: 'Type', headerWidth: '10rem' };
    const col3: TreeGridColumnData = { field: 'criticality', headerText: 'Criticality', headerWidth: '10rem' };
    treeGridColumns.push(col1, col2, col3);
    return treeGridColumns;
  }

  getAssetsTreeData(tenantId: string, assetId: string, objectTypes?:string): Observable<AssetsTree[]> {
    const url = environment.dashboardApiBaseUrl + ASSETS_TREE_API_ENDPOINTS.GET_ASSETS_TREE(tenantId, assetId, objectTypes);
    return this.http.get<ApiResponse<AssetsTree[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  getAllAssetsTreeData(tenantId: string, objectTypes?:string): Observable<AssetsTree[]> {
    const url = environment.dashboardApiBaseUrl + ASSETS_TREE_API_ENDPOINTS.GET_ALL_ASSETS_TREE(tenantId, objectTypes);
    return this.http.get<ApiResponse<AssetsTree[]>>(url).pipe(map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  getEnterpriseTree(tenantId: string): Observable<AssetsTree[]> {
    const url = environment.dashboardApiBaseUrl + ASSETS_TREE_API_ENDPOINTS.GET_ENTERPRISE_TREE(tenantId);
    return this.http.get<ApiResponse<AssetsTree[]>>(url).pipe(map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  getChildMappingForAssetTreeData() {
    return 'subData';
  }

  getAssetsGridServiceUrl(tenantId: string, assetId: string): string {
    const includeObjectType = `${AssetType.ENTERPRISE},${AssetType.SITE},${AssetType.AREA},${AssetType.UNITS},${AssetType.EQUIPMENT}`;
    return environment.dashboardApiBaseUrl + ASSETS_API_ENDPOINTS.GET_ASSETS(tenantId, assetId, includeObjectType);
  }
}
