import { Component, Input } from '@angular/core';
import { StatusIndicator } from 'src/app/shared/models/shared.models';

@Component({
  selector: 'app-asset-status-bar',
  templateUrl: './asset-status-bar.component.html',
  styleUrls: ['./asset-status-bar.component.scss']
})
export class AssetStatusBarComponent {
  @Input() statusIndicators?: StatusIndicator[];
  selectedType?: string;

  showValue(type: string) {
    this.selectedType = type;
  }

  resetStatus() {
    this.selectedType = '';
  }
}
