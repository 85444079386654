import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CheckBoxChangeEventArgs, RowInfo, RowSelectingEventArgs } from '@syncfusion/ej2-angular-grids';
import { PageSettingsModel, TreeGridComponent as EJ2TreeGridComponent, TreeGridComponent as RowExpandedEventArgs } from '@syncfusion/ej2-angular-treegrid';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns';
import { PageEventArgs } from '@syncfusion/ej2-grids';
import { MenuEventArgs } from '@syncfusion/ej2-navigations';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { PerceptivWebApiAdaptor, PerceptivWebApiData, TreeGridColumnData } from 'src/app/shared/models/tree-grid';
import { CustomEventSource, CustomEventType, GridUIType } from '../../app-constants/shared.enum';

@Component({
  selector: 'app-tree-grid',
  templateUrl: './tree-grid.component.html',
  styleUrls: ['./tree-grid.component.scss']
})
export class TreeGridComponent implements OnInit {
  /* Refer following documentation sample for further details: https://ej2.syncfusion.com/angular/documentation/treegrid/data-binding/remote-data?cs-save-lang=1&cs-lang=ts */
  // Note: Since we dont have pagination for tree grid and data will always be for normal grid, 'hasChildMapping' & 'parentIdMapping' should not be passed.
  // A default value has been set as it cannot be null. With these default values it will render flat grid as values wont be able to construct nesting.
  @Input() allowMultiselect: boolean = true;
  @Input() tab: boolean = false;
  @Input() allowPaging: boolean = false;
  @Input() allowResizing: boolean = false;
  @Input() allowSorting: boolean = false;
  @Input() autoCheckHierarchy: boolean = false;
  @Input() childMapping: string = '';
  @Input() columns: Array<TreeGridColumnData> = []; // Columns passed by consumer
  @Input() contextMenuItems: Object[] = [];
  @Input() data!: Object[] | DataManager; // The data passed to it from consumer
  @Input() enableCollapseAll: boolean = true;
  @Input() gridHeight?: string;
  @Input() gridUIType: GridUIType | string = GridUIType.BORDERED_TREE_GRID; // Property to toggle between tree UIs
  @Input() hasChildMapping: string = 'Parent'; //Set to name of field/column from the data received that determines if the node has further child nodes. Dont pass this property when using this control if flat grid is needed
  @Input() idMapping: string = ''; //Set to name of field/column from the data received that determines linkage between parent child relations
  @Input() loadingIndicator = { indicatorType: 'Spinner' };
  @Input() localRecordsCount!: number | undefined; // The data passed to it from consumer
  @Input() pageSettings: PageSettingsModel = { pageSize: 10, pageSizes: true };
  @Input() parentIdMapping: string = 'ParentItem'; //Set to name of field/column from the data received that determines its parent node. Dont pass this property when using this control if flat grid is needed
  @Input() selectionSettings: any = { mode: 'Both' };
  @Input() toolbarOption?: string[];
  @Input() treeColumnIndex: number = 0;
  @Input() query: Query = new Query();
  @Input() clipMode: string = 'EllipsisWithTooltip';
  @Output() contextMenuClicked = new EventEmitter<{ contextMenuId: string, rowData: any }>();
  @Output() loadMore = new EventEmitter<any>(); // Event passed to the consumer
  @Output() resultProcessed:EventEmitter<PerceptivWebApiData> = new EventEmitter<PerceptivWebApiData>();
  @Output() treeGridEvent = new EventEmitter<CustomEvent>(); // Event passed to the consumer
  @ViewChild('flyoutContainer') flyoutContainer!: ElementRef;
  @ViewChild('treegrid') public treeGrid!: EJ2TreeGridComponent;
  selectionSettingsModel = { mode: 'Both' };
  public headerCustomAttributes: any;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    this.closeKebabFlyout(event.srcElement.id as string);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.closeKebabFlyout();
  }

  @Input()
  public get serviceUrl(): string {
    return this._serviceUrl;
  }

  public set serviceUrl(value: string) {
    if (this._serviceUrl !== value) {
      this._serviceUrl = value;
      this.data = new DataManager({
        url: this._serviceUrl,
        adaptor: new PerceptivWebApiAdaptor(this.resultProcessed),
        crossDomain: true,
      });
    }
  }

  ngOnInit() {
    this.setHeaderCustomAttributes();
  }

  private setHeaderCustomAttributes(): void {
    if (this.gridUIType !== 'bordered-tree-grid' && this.gridUIType !== 'checkbox-tree-grid') {
      this.headerCustomAttributes = { class: 'black-column-headers' };
    } else {
      this.headerCustomAttributes = {};
    }
  }

  flyoutMenuItems: string[] = []; //Items to be shown in flyout
  rowDataForFlyoutMenu: any; //The row model will be passed here to detect the row
  showFlyOutItems: boolean = false; //Used to show hide the items inside flyout
  flyOutItemFields: FieldSettingsModel = { text: 'label' };
  private _serviceUrl!: string; // The data source passed to it from consumer

  rowExpanded(e: any) {
    const event: CustomEvent = { eventType: CustomEventType.ROW_EXPANDED, eventData: e, eventSource: CustomEventSource.TREEGRID };
    this.treeGridEvent.emit(event);
  }

  rowSelectedOnGridClick(e: any) {
    const event: CustomEvent = { eventType: CustomEventType.CLICK, eventData: e, eventSource: CustomEventSource.TREEGRID };
    this.treeGridEvent.emit(event);

    this.rowSelectionChanged();
  }

  rowDeselectedOnGridClick(e: any) {
    const event: CustomEvent = { eventType: CustomEventType.ROW_DESELECT, eventData: e, eventSource: CustomEventSource.TREEGRID };
    this.treeGridEvent.emit(event);

    this.rowSelectionChanged();
  }

  cellSelectedOnGridClick(e: any) {
    const event: CustomEvent = { eventType: CustomEventType.CELL_CLICK, eventData: e, eventSource: CustomEventSource.TREEGRID };
    this.treeGridEvent.emit(event);
  }

  // Event handler for row collapse
  collapsed(e: any) {
    if (!e?.data) return;
    this.collapseChildren(e.data);
  }

  // Event handler for row expand
  expanded(e: any) {
    if (!e?.data) return;
    e.data.isRowExpanded = true;
  }

  expanding(e: RowExpandedEventArgs) {
    const event: CustomEvent = { eventType: CustomEventType.EXPANDING, eventData: e, eventSource: CustomEventSource.TREEGRID };
    this.treeGridEvent.emit(event);
  }

  collapseChildren(data: any) {
    data.isRowExpanded = false;
    if (!data?.childRecords) return;
    data.childRecords.forEach((child: any) => {
      this.collapseChildren(child);
    });
  }

  showCellValue(column: TreeGridColumnData, data: any) {
    if (!column.hideValueOnRowExpand) return true;
    const records = this.treeGrid?.getCurrentViewRecords() as any[];
    const matchedRecordItem = records?.find(x => x.id === data.id);
    return !matchedRecordItem?.isRowExpanded;
  }

  contextMenuClick(args?: MenuEventArgs): void {
    if (args && args.item && args.item.id) {
      const contextMenuId = args.item.id;
      const rowData = this.treeGrid.getSelectedRecords()[0];
      this.contextMenuClicked.emit({ contextMenuId, rowData });
    }
  }

  // Open the kebab menu flyout
  openKebabMenuFlyout(e: CustomEvent) {
    if (e.eventSource === CustomEventSource.KEBAB_MENU && e.eventType === CustomEventType.CLICK) {
      this.showFlyOutItems = false;
      this.flyoutMenuItems = e.eventData.items;
      this.rowDataForFlyoutMenu = e.eventData.rowData;
      const ele = this.flyoutContainer.nativeElement;
      if (!ele) return;
      ele.style.display = 'block';
      const tab:any = document.getElementById('tab_default')?.getBoundingClientRect();
      ele.style.top = ele.style.top = this.tab ? e.eventData.clickEvent.pageY - tab?.y - 32 - window.scrollY + 'px' : e.eventData.clickEvent.pageY + 'px';
      const defaultFlyoutWidth = ele.clientWidth ? ele.clientWidth + 20 : 140;
      ele.style.left = this.tab ? e.eventData.clickEvent.pageX - tab?.x  - defaultFlyoutWidth - window.scrollX + 'px' : e.eventData.clickEvent.pageX - defaultFlyoutWidth + 'px';
      setTimeout(() => {
        this.showFlyOutItems = true;
      });
    }
  }

  // Flyout menu item selected
  flyOutItemSelected(e: any) {
    const event: CustomEvent = { eventType: CustomEventType.KEBAB_ITEM_SELECTED, eventData: { selectedItem: e.items[0], rowData: this.rowDataForFlyoutMenu }, eventSource: CustomEventSource.TREEGRID };
    this.treeGridEvent.emit(event);
  }

  closeKebabFlyout(originator?: string) {
    const ele = this.flyoutContainer.nativeElement;
    if (ele && originator !== 'kebab-svg') {
      ele.style.display = 'none';
    }
  }

  // Event fired just before row selected. Used to take some action before selection happen
  rowSelecting(args: RowSelectingEventArgs): void {
    if (!this.allowMultiselect && args.isHeaderCheckboxClicked) {
      args.cancel  = true;
    }
    if (!this.allowMultiselect) {
      this.treeGrid.clearSelection();
    }
  }

  onActionComplete(args: PageEventArgs) {
    if (args.requestType === 'paging' && this.localRecordsCount && this.pageSettings.pageSize) {
      const total = this.localRecordsCount;
      const pageTotal = Number(args.currentPage) * this.pageSettings.pageSize;
      if (total <= pageTotal) {
        this.loadMore.next(args);
      }
    }
  }

  rowSelectionChanged(): void {
    const event: CustomEvent = { eventType: CustomEventType.ROW_SELECTION_CHANGED, eventData: this.treeGrid.getSelectedRecords(), eventSource: CustomEventSource.TREEGRID };
    this.treeGridEvent.emit(event);
  }

  checkBox(e: CheckBoxChangeEventArgs) {
    if (e.checked === true) {
      const event: CustomEvent = { eventType: CustomEventType.ROW_CHECKED, eventData: e, eventSource: CustomEventSource.TREEGRID };
      this.treeGridEvent.emit(event);
    }
    else if (e.checked === false) {
      const event: CustomEvent = { eventType: CustomEventType.ROW_UNCHECKED, eventData: e, eventSource: CustomEventSource.TREEGRID };
      this.treeGridEvent.emit(event);
    }
  }

  refresh(): void {
    this.treeGrid.refresh();
  }

  silentRefresh(): void {
    const previousLoadingIndicatorConfig = this.loadingIndicator  ?? { indicatorType: 'Spinner' };
      // Before refresh, set loadingIndicator to 'Shimmer'
    this.loadingIndicator = { indicatorType: 'Shimmer' };
    setTimeout(() => {
      // Fetch data
      this.treeGrid.refresh();
      // After refresh, reset loadingIndicator to 'Spinner'
      this.loadingIndicator = previousLoadingIndicatorConfig;
    }, 100);
  }

  getRowRecordsInfo(): RowInfo[] {
    const gridRecords = this.treeGrid.getDataRows()
      .map(row => this.treeGrid.getRowInfo(row));
    return gridRecords;
  }

  tryUpdateRowData(data: any[], key:(item:any) => string | number, match?: (target: any, source: any) => boolean) {
    if (data && data.length > 0) {
      const updatedRows = this.treeGrid.getDataRows()
        .map(row => this.treeGrid.getRowInfo(row))
        .map(target => {
          let matched;
          if (match) {
            matched = data.find(src => match(target, src));
          }
          else {
            matched = data.find(src => key(target?.rowData) === key(src));
          }
          if (matched) {
            Object.assign(target.rowData as any, matched);
            return target;
          }
          return undefined;
        });
      for (const item of updatedRows) {
        if (item) {
          this.treeGrid.setRowData(key(item.rowData), item.rowData);
        }
      }
    }
  }
}
