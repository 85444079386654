import { AssetBatteryStatus, AssetPhotosCarouselItemType, AssetStatus, ButtonSizes, ButtonTypes, ModalTemplateTypes } from '../app-constants/shared.enum';
import { ModalDimensions, ModalPositions } from '../app-constants/modal-dialog-constants';
import { PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { PipeTransform, TemplateRef } from '@angular/core';
import { TreeGridColumnData } from './tree-grid';

export interface StatusIndicator {
  type: AssetStatus;
  value: number
}

export interface BatterStatus {
  type: AssetBatteryStatus
}

export interface DescriptionDetails {
  description: string;
  date: Date;
  gateway: string;
}

export interface ButtonData {
  label: string;
  type: ButtonTypes;
  name: string;
  disable?: boolean;
  size?: ButtonSizes;
  width?: string;
}

export class OpenModalDialogData {
  position?: PositionDataModel = ModalPositions.TOP_CENTER;
  dimension?: { height: string; width: string } = ModalDimensions.MD;
  templateName!: ModalTemplateTypes; //Pass component class name when shared component wants to be loaded
  templateRef!: TemplateRef<any>; //Pass template reference if direct template wants to be loaded
  customData: any = {};
  id: string = '';
}

export interface ModalGridTemplateData {
  heading: string;
  rowData: Object[];
  columnData: TreeGridColumnData[];
  allowMultiselect: boolean;
}

export enum StepStatusCircleState{
  FULL = 'Full',
  HALF = 'Half',
  EMPTY = 'Empty',
  NOTASSIGNED  = 'Notassigned',
}

export interface LifecycleState{
  value:string;
  label?:string;
  flyout?:boolean;
  enabled?:boolean;
}

export interface AssetPhotosCarouselItem {
  id: string;
  name: string;
  url: string;
  thumbLocation: string;
  type: AssetPhotosCarouselItemType,
  isUploaded: boolean;
}

export interface ImageEditorItem {
  url?: string;
  file?: File;
  assetPhotosCarouselItem?: AssetPhotosCarouselItem;
}

export interface ImageSasUrlResponse {
  id: string;
  assetId: string;
  fileName: string;
  sysFileName: string;
  tenantId: string;
  sasUrl: string;
  thumbLocation: string;
  location: string;
  isUploaded: boolean;
}

export interface PipeTransformData{
  pipe:PipeTransform;
  args?:any[];
}

export interface CommandResponse {
  commandId: string;
  commandName: string;
  data: any;
  message: string;
  status: string;
  statusCode: number;
  traceId: string;
}

