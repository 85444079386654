import { AxialData, AxialDataAcceleration, SensorParameterData } from '../../models/sensor-thresholds';
import { ButtonData, OpenModalDialogData } from 'src/app/shared/models/shared.models';
import { ButtonLabels, ButtonNames, ButtonTypes, ModalTemplateTypes } from 'src/app/shared/app-constants/shared.enum';
import { ButtonStateService } from 'src/app/shared/services/button-state-service/button-state.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { THRESHOLD_PROFILE_KEYS } from 'src/app/shared/app-constants/sensor-constants';
import { ThresholdConfigurationService } from 'src/app/shared/services/threshold-configuration/threshold-configuration.service';

@Component({
  selector: 'app-sensor-thresholds',
  templateUrl: './sensor-thresholds.component.html',
  styleUrls: ['./sensor-thresholds.component.scss']
})
export class SensorThresholdsComponent implements OnInit {
  @Input() equipmentName: string = '';
  @Input() sensorId: string = '';
  @Input() sensorSerialNumber: string = '';
  @Input() sensorType: string = '';
  @Input() tenantId: string = '';
  axialData: AxialData[] = [];
  cancelButtonData: ButtonData = { label: ButtonLabels.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.CANCEL };
  exportButtonData: ButtonData = { label: ButtonLabels.EXPORT, type: ButtonTypes.SECONDARY_BASIC, name: ButtonNames.EXPORT, width: '6rem', disable: true };
  importButtonData: ButtonData = { label: ButtonLabels.IMPORT, type: ButtonTypes.SECONDARY_BASIC, name: ButtonNames.IMPORT, width: '6rem', disable: true };
  isRuleEnabled: boolean = false;
  profileFields: Object = { text: 'Name', value: 'Id' };
  profilePlaceholder: string = 'Select profile';
  profiles: { [key: string]: Object }[] = [];
  profileThresholdType?: string = '';
  restoreDefaultsButtonData: ButtonData = { label: ButtonLabels.RESTORE_DEFAULTS, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.RESTORE_DEFAULTS };
  saveButtonData: ButtonData = { label: ButtonLabels.SAVE, type: ButtonTypes.PRIMARY_BASIC, name: ButtonNames.SAVE_FOR_THRESHOLD, width: '10rem', disable: true };
  selectedProfile: string | null = '';

  constructor(
    private buttonStateService: ButtonStateService,
    private modalDialogService: ModalDialogService,
    private thresholdConfigurationService: ThresholdConfigurationService,
  ) { }

  ngOnInit(): void {
    this.profiles = [{ Id: THRESHOLD_PROFILE_KEYS.ISO_KEY(this.sensorType.toLowerCase()), Name: THRESHOLD_PROFILE_KEYS.ISO_LABEL }, { Id: THRESHOLD_PROFILE_KEYS.STATISTICAL_KEY(this.sensorType.toLowerCase()), Name: THRESHOLD_PROFILE_KEYS.STATISTICAL_LABEL }, { Id: THRESHOLD_PROFILE_KEYS.CUSTOM_KEY(this.sensorType.toLowerCase()), Name: THRESHOLD_PROFILE_KEYS.CUSTOM_LABEL }];
    this.getThresholdConfig();
  }

  getThresholdConfig() {
    this.thresholdConfigurationService.getThresholdConfig(this.tenantId, this.sensorType, this.sensorSerialNumber).subscribe(response => {
      this.axialData = response.axialDataList;
      this.isRuleEnabled = response.isEnabled;
      this.profileThresholdType = response.profileThresholdType;
    });
  }

  profileChange() {
    this.setSaveButtonDisableState(false);
    this.thresholdConfigurationService.getThresholdConfigForSelectedProfile(this.tenantId, this.sensorType, String(this.selectedProfile)).subscribe(response => {
      this.axialData = response.axialDataList;
      this.isRuleEnabled = response.isEnabled;
      this.profileThresholdType = response.profileThresholdType;
    });
  }

  warningChange(acceleration: AxialDataAcceleration) {
    this.setSaveButtonDisableState(false);
    this.selectedProfile = THRESHOLD_PROFILE_KEYS.CUSTOM_KEY(this.sensorType.toLowerCase());
    this.setAllAccelerationToSelectedProfile(THRESHOLD_PROFILE_KEYS.CUSTOM_LABEL);
    acceleration.warning = acceleration.warning ? acceleration.warning.toString() : '';
  }

  alarmChange(acceleration: AxialDataAcceleration) {
    this.setSaveButtonDisableState(false);
    this.selectedProfile = THRESHOLD_PROFILE_KEYS.CUSTOM_KEY(this.sensorType.toLowerCase());
    this.setAllAccelerationToSelectedProfile(THRESHOLD_PROFILE_KEYS.CUSTOM_LABEL);
    acceleration.alarm = acceleration.alarm ? acceleration.alarm.toString() : '';
  }

  enabledChange() {
    this.setSaveButtonDisableState(false);
  }

  setAllAccelerationToSelectedProfile(selectedProfileLabel: string) {
    this.axialData.forEach(axis => {
      axis.accelerations.forEach(accelartion => {
        accelartion.option = selectedProfileLabel;
      });
    });
  }

  onCancelButtonClick() {
    this.closeModal();
  }

  closeModal() {
    this.modalDialogService.closeModal();
  }

  onRestoreDefaultButtonClick() {
    this.selectedProfile = null;
    this.thresholdConfigurationService.getThresholdConfigForSelectedProfile(this.tenantId, this.sensorType, THRESHOLD_PROFILE_KEYS.DEFAULT_KEY(this.sensorType.toLowerCase())).subscribe(response => {
      this.axialData = response.axialDataList;
      this.isRuleEnabled = response.isEnabled;
      this.profileThresholdType = response.profileThresholdType;
      this.setSaveButtonDisableState(false);
    });
  }

  onSaveButtonClick() {
    const sensorParameterData: SensorParameterData = {
      axialDataList: this.axialData,
      isEnabled: this.isRuleEnabled,
      profileThresholdType: this.profileThresholdType,
      sensorId: this.sensorId
    };
    this.thresholdConfigurationService.saveThresholdConfig(this.tenantId, this.sensorType, this.sensorSerialNumber, sensorParameterData).subscribe(response => {
      if (!response) return;
      this.showSuccessModal();
    });
  }

  showSuccessModal() {
    const data = new OpenModalDialogData();
    data.templateName = ModalTemplateTypes.SUCCESS_TEMPLATE;
    data.customData.message = 'Configuration saved successfully!';
    this.modalDialogService.openModal(data);
  }

  setSaveButtonDisableState(disable: boolean) {
    this.buttonStateService.setButtonDisableState(this.saveButtonData.name, disable);
  }
}
