import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, firstValueFrom, lastValueFrom, map, tap } from 'rxjs';
import { UserDetails } from 'src/app/admin/user-management/models/user-details';
import { USER_API_ENDPOINTS } from 'src/app/shared/app-constants/api-constants';
import { ApiResponse } from 'src/app/shared/models/api-response';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler/error-handler.service';
import { environment } from 'src/environments/environment';
import { AllRolesAssignedToUser } from '../../models/all-roles-assigned-to-user';
import { OktaUser } from '../../models/okta-user';
import { Permission } from '../../models/permission';
import { Role } from '../../models/role';
import { User } from '../../models/user';
import { UserRoleAssignment } from '../../models/user-role-assignment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public createRole(role: Role): Observable<Object> {
    return this.http.post(environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.ROLE, role);
  }

  public getPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>(environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.PERMISSIONS);
  }

  public getUserRoleAssignment(tenantId: string, scope: string): Promise<UserRoleAssignment[]> {
    const params = { tenantId: tenantId };
    const url = environment.dashboardApiBaseUrl + this.makeRequestUrl(USER_API_ENDPOINTS.USERROLEASSIGNMENTS, params);
    const scopeHeader = { 'scope': scope };
    const userRoles = this.http.get<UserRoleAssignment[]>(url, { headers: new HttpHeaders(scopeHeader) });
    return firstValueFrom(userRoles);
  }

  public createUser(tenantId: string, scope: string, user: User): Observable<Object> {
    const params = { tenantId: tenantId };
    const scopeHeader = { 'scope': scope };
    const url = environment.dashboardApiBaseUrl + this.makeRequestUrl(USER_API_ENDPOINTS.CREATEUSER, params);
    return this.http.post(url, user, { headers: new HttpHeaders(scopeHeader) });
  }

  public updateUser(tenantId: string, saveEnterpriseRequest: UserDetails): Observable<UserDetails> {
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = saveEnterpriseRequest;
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.UPDATE_USER_DETAILS(tenantId);
    return this.http.put<ApiResponse<UserDetails>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public deleteUser(id: string, tenantId: string, scope: string) {
    const params = { tenantId: tenantId, id: id };
    const scopeHeader = { 'scope': scope };
    const url = environment.dashboardApiBaseUrl + this.makeRequestUrl(USER_API_ENDPOINTS.DELETEUSER, params);
    return this.http.delete(url, { headers: new HttpHeaders(scopeHeader) });
  }

  public getUserById(tenantId: string, id: string): Observable<User> {
    const params = { tenantId: tenantId, id: id };
    const url = environment.dashboardApiBaseUrl + this.makeRequestUrl(USER_API_ENDPOINTS.GETUSERByID, params);
    return this.http.get<User>(url);
  }

  public getAllUsers(scope: string, tenantId: string, pageSize: string, offset: string): Observable<User[]> {
    const params = { tenantId: tenantId, pageSize: pageSize, offset: offset };
    const scopeHeader = { 'scope': scope };
    const url = environment.dashboardApiBaseUrl + this.makeRequestUrl(USER_API_ENDPOINTS.GETUSERS, params);
    return this.http.get<User[]>(url, { headers: new HttpHeaders(scopeHeader) });
  }

  public getUsersByScope(scope: string, tenantId: string): Observable<User[]> {
    const params = { tenantId: tenantId, scope: scope };
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = environment.dashboardApiBaseUrl + this.makeRequestUrl(USER_API_ENDPOINTS.GET_USERS_BY_SCOPE, params);
    return this.http.get<ApiResponse<User[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public createUserRoleAssignment(scope: string, tenantId: string, userId: string, roleAssignment: AllRolesAssignedToUser): Observable<Object> {
    const params = { tenantId: tenantId, userId: userId };
    const scopeHeader = { 'scope': scope };
    const url = environment.dashboardApiBaseUrl + this.makeRequestUrl(USER_API_ENDPOINTS.CREATEROLEASSIGNMENT, params);
    return this.http.post(url, roleAssignment, { headers: new HttpHeaders(scopeHeader) });
  }

  public deleteUserRoleAssignment(scope: string, tenantId: string, userId: string, roleId: string): Observable<Object> {
    const params = { tenantId: tenantId, userId: userId, roleId: roleId };
    const scopeHeader = { 'scope': scope };
    const url = environment.dashboardApiBaseUrl + this.makeRequestUrl(USER_API_ENDPOINTS.DELETEROLEASSIGNMENT, params);
    return this.http.delete(url, { headers: new HttpHeaders(scopeHeader) });
  }

  public createOktaUser(user: OktaUser): Observable<Object> {
    const url = environment.oktaBaseUrl + '/users?activate=true&sendEmail=false';
    const authHeader = { 'SSWS': environment.oktaAPIKey };
    return this.http.post(url, user, { headers: new HttpHeaders(authHeader) });
  }

  public getUserByOktaId(tenantId: string, oktaId: string, scope: string): Promise<UserDetails> {
    const user$ = this.usersApiCall(tenantId, oktaId, scope);
    return lastValueFrom(user$);
  }

  public getUserByToken() {
    const user$ = this.usersByTokenApiCall();
    return lastValueFrom(user$);
  }

  public getUserDetailsByOktaId(tenantId: string, oktaId: string, scope: string): Observable<UserDetails> {
    return this.usersApiCall(tenantId, oktaId, scope);
  }

  usersApiCall = (tenantId: string, oktaId: string, scope: string) => {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.GET_USER_DETAILS_BY_OKTAID(tenantId, oktaId);
    const scopeHeader = { 'scope': scope };
    return this.http.get<ApiResponse<UserDetails>>(url, { headers: new HttpHeaders(scopeHeader) }).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  };

  usersByTokenApiCall = () => {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.GETUSERBYTOKEN;
    return this.http.get<ApiResponse<UserDetails>>(url)
      .pipe(map((response) => response.data)).pipe(
      // Using catchError to handle errors
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  };

  makeRequestUrl(urlPattern: string, params: { [key: string]: string }): string {
    let url = urlPattern;
    for (const key in params) {
      if (key in params) {
        url = url.replace(`{${key}}`, params[key]);
      }
    }
    return url;
  }
}
