import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-asset-show-graph',
  templateUrl: './app-asset-show-graph.component.html',
  styleUrls: ['./app-asset-show-graph.component.scss']
})
export class AppAssetShowGraphComponent {
  @Input() data:string = '';
  imgPath = 'assets/icons-and-logos/chart-line.svg';
}
