import { Injectable } from '@angular/core';
import { of, lastValueFrom } from 'rxjs';
import { SensorTelemetryGraphS7100InterfaceClientService } from './sensor-telemetry-graph-s7100-interface.client.service';
import { TokenInfo } from '../../models/token-info';
import { mockTokenInfo } from '../../mock-data/mock-token-info';

@Injectable({
  providedIn: 'root'
})
export class SensorTelemetryGraphS7100ClientMockService implements SensorTelemetryGraphS7100InterfaceClientService {
  private readonly token = mockTokenInfo;

  getTokenForReport(): Promise<TokenInfo> {
    return lastValueFrom(of(this.token));
  }

  getTokenForReportByEmailId(_workspaceId: any, _reportId: any, _dataSetId: any, _userId: any): Promise<TokenInfo> {
    return lastValueFrom(of(this.token));
  }
}
