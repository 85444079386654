import { environment } from 'src/environments/environment';
import { finalize, from, lastValueFrom, Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { SpinnerService } from 'src/app/shared/services/spinner-service/spinner.service';
import { XmlHttpRequestInterceptor } from './xhr.interceptor';
import { PREVENT_SPINNER } from '../app-constants/shared.constants';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  totalRequests = 0;
  private xhrInterceptor:XmlHttpRequestInterceptor;

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private spinnerSerivce: SpinnerService,
  ) {
    this.xhrInterceptor = new XmlHttpRequestInterceptor(this.oktaAuth);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    if (!request.headers.has(PREVENT_SPINNER)) this.spinnerSerivce.showSpinner();
    return from(this.handle(request, next)).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.spinnerSerivce.hideSpinner();
        }
      })
    );
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    const isLoggedIn = await this.oktaAuth.isAuthenticated();
    const accessToken: string = this.oktaAuth.getAccessToken() || '';
    // const isUaApiBaseUrl = request.url.startsWith(environment.uaApiBaseUrl);
    // if (isLoggedIn && isUaApiBaseUrl) {
    //   request = request.clone({
    //     headers: request.headers.set(
    //       'Authorization',
    //       `Bearer ${accessToken}`
    //     ).set('Content-Type', ['application/json', '*'])
    //   });
    // }
    return await lastValueFrom(next.handle(request));
  }
}
