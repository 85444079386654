import { Component, Input } from '@angular/core';
import { BatterStatus } from 'src/app/shared/models/shared.models';

@Component({
  selector: 'app-asset-battery-status',
  templateUrl: './asset-battery-status.component.html',
  styleUrls: ['./asset-battery-status.component.scss']
})
export class AssetBatteryStatusComponent {
  @Input() batteryStatus?: BatterStatus;
}
