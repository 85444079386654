import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';
import { FieldSettingsModel } from '@syncfusion/ej2-angular-navigations';
import { CustomEventSource, CustomEventType } from 'src/app/shared/app-constants/shared.enum';
import { CustomEvent } from 'src/app/shared/models/custom-event';

@Component({
  selector: 'app-filter-component',
  templateUrl: './filter-component.component.html',
  styleUrls: ['./filter-component.component.scss']
})
export class FilterComponent {
  @ViewChild('autocomplete') autocomplete?: AutoCompleteComponent;
  @ViewChild('flyoutContainer') flyoutContainer!: ElementRef;
  @ViewChild('flyoutContainer') flyoutContainer1!: ElementRef;
  @Input() tab:boolean = false;
  @Input() filterMenuListItems:any[] = [];
  @Output() kebabMenuClickEvent = new EventEmitter<CustomEvent>();
  @Output() filterEvent = new EventEmitter<CustomEvent>();
  @Output() selectFilterEvent = new EventEmitter<CustomEvent>();
  fields: any = { text: 'name', value: 'name' };
  flyoutMenuItems: string[] = []; //Items to be shown in flyout
  rowDataForFlyoutMenu: any; //The row model will be passed here to detect the row
  showFlyOutItems: boolean = false; //Used to show hide the items inside flyout
  showFlyOutItems1: boolean = false;
  flyOutItemFields: FieldSettingsModel = { text: 'label' };

  artifactData: Object[] = [];

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    console.log('event', event);
    this.closeKebabFlyout(event.srcElement.id as string);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.closeKebabFlyout();
  }

  onInputChange(e:any, filterName:string) {
    const event: CustomEvent = { eventType: CustomEventType.CLICK, eventSource: CustomEventSource.KEBAB_MENU, eventData: { clickEvent: e, items: e.value, name: filterName } };
    this.filterEvent.emit(event);
  }

  closeKebabFlyout(originator?: string) {
    const ele = this.flyoutContainer.nativeElement;
    if (ele && originator !== 'filter-menu') {
      ele.style.display = 'none';
    }
  }

  openKebabMenuFlyout(e: CustomEvent) {
    if (e.eventSource === CustomEventSource.KEBAB_MENU && e.eventType === CustomEventType.CLICK) {
      this.showFlyOutItems = false;
      this.flyoutMenuItems = e.eventData.items;
      this.rowDataForFlyoutMenu = e.eventData.rowData;
      const ele = this.flyoutContainer.nativeElement;
      if (!ele) return;
      ele.style.display = 'block';
      const tab:any = document.getElementById('tab_default1')?.getBoundingClientRect();
      ele.style.top = this.tab ? e.eventData.clickEvent.pageY - tab?.y  - window.scrollY + 'px' : e.eventData.clickEvent.pageY + 20 + 'px';
      const defaultFlyoutWidth = ele.clientWidth ? ele.clientWidth + 20 : 140;
      ele.style.left = this.tab ? e.eventData.clickEvent.pageX - tab?.x + 120 - defaultFlyoutWidth - window.scrollX + 'px' : e.eventData.clickEvent.pageX + 120 - defaultFlyoutWidth + 'px';
      setTimeout(() => {
        this.showFlyOutItems = true;
      });
    }
  }

  filterMenuchange(data: any, e: any) {
    const event: CustomEvent = { eventType: CustomEventType.CLICK, eventSource: CustomEventSource.KEBAB_MENU, eventData: { clickEvent: e, data: data } };
    this.selectFilterEvent.emit(event);
  }
}

