import { AppRouteConstants } from 'src/app/shared/app-constants/app-route-constants';
import { AssetType } from 'src/app/shared/app-constants/asset-constants';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { CustomEventSource, CustomEventType } from 'src/app/shared/app-constants/shared.enum';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SystemInfoRoutes } from 'src/app/admin/constants/route.constants';

@Injectable({
  providedIn: 'root'
})
export class AssetsInfoHelperService {
  constructor(
    private router: Router
  ) {

  }

  assetNavigation(type: string, id: string, name: string, scope: string) {
    if (!type) return;
    type = type.toLowerCase();
    if (type === AssetType.SITE) {
      this.router.navigate(['/customer/assets-info/site/site-info'], { queryParams: { id: id, name: name, s: scope } });
    }
    else if (type === AssetType.AREA) {
      this.router.navigate(['/customer/assets-info/area'], { queryParams: { id: id, name: name, s: scope } });
    }
    else if (type === AssetType.UNITS) {
      this.router.navigate(['/customer/assets-info/unit'], { queryParams: { id: id, name: name, s: scope } });
    }
    else if (type === AssetType.EQUIPMENT) {
      this.router.navigate(['/customer/assets-info/equipment/equipment'], { queryParams: { id: id, name: name, s: scope } });
    }
  }

  onGatewaysGridEventReceived(e: CustomEvent, scope: string) {
    if (e.eventSource === CustomEventSource.TREEGRID && e.eventType === CustomEventType.CELL_CLICK && (e.eventData.cellIndex.cellIndex === 2 || e.eventData.cellIndex.cellIndex === 4)) {
      this.router.navigate(
        [`/${AppRouteConstants.ROUTE_TO_CUSTOMER}/${SystemInfoRoutes.SYSTEM_INFO_BASE_ROUTE}/${SystemInfoRoutes.ROUTE_TO_GATEWAY}`],
        {
          queryParams: {
            id: e.eventData.data.gatewayId,
            s: scope
          }
        }
      );
    }
    if (e.eventSource === CustomEventSource.TREEGRID && e.eventType === CustomEventType.CELL_CLICK && (e.eventData.cellIndex.cellIndex === 3)) {
      const routePath = e.eventData.data.locationType === AssetType.SITE
        ? AppRouteConstants.APP_CUSTOMER_SITE_ROUTE
        : AppRouteConstants.ROUTE_TO_AREA_INFO;
      this.router.navigate(
        [`/${AppRouteConstants.ROUTE_TO_CUSTOMER}/${AppRouteConstants.ROUTE_TO_ASSETS_INFO}/${routePath}`],
        {
          queryParams: {
            id: e.eventData.data.locationId,
            name: e.eventData.data.locationName,
            s: scope
          }
        }
      );
    }
  }

  onSensorsGridEventReceived(e: CustomEvent, scope: string) {
    if (e.eventSource === CustomEventSource.TREEGRID && e.eventType === CustomEventType.CELL_CLICK && e.eventData.cellIndex.cellIndex === 2) {
      this.router.navigate([`/${AppRouteConstants.ROUTE_TO_CUSTOMER}/${SystemInfoRoutes.SYSTEM_INFO_BASE_ROUTE}/${SystemInfoRoutes.ROUTE_TO_SENSOR}`], { queryParams: { id: e.eventData.data.sensorId, serialNumber: e.eventData.data.sensorSerialNumber, s: scope } });
    }
    if (e.eventSource === CustomEventSource.TREEGRID && e.eventType === CustomEventType.CELL_CLICK && e.eventData.cellIndex.cellIndex === 3) {
      this.router.navigate(
        [`/${AppRouteConstants.ROUTE_TO_CUSTOMER}/${AppRouteConstants.APP_CUSTOMER_EQUIPMENT_ROUTE}/${AssetType.EQUIPMENT}`],
        {
          queryParams: {
            id: e.eventData.data.equipmentId,
            name: e.eventData.data.equipmentName,
            s: scope
          }
        }
      );
    }
    if (e.eventSource === CustomEventSource.TREEGRID && e.eventType === CustomEventType.CELL_CLICK && e.eventData.cellIndex.cellIndex === 4) {
      this.router.navigate(
        [`/${AppRouteConstants.ROUTE_TO_CUSTOMER}/${AppRouteConstants.APP_CUSTOMER_AREA_ROUTE}/${AssetType.AREA}`],
        {
          queryParams: {
            id: e.eventData.data.areaId,
            name: e.eventData.data.areaName,
            s: scope
          }
        }
      );
    }
  }
}
