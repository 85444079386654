<div class="threshold-container">
  <h2 class="threshold-heading pure-black-color">Sensor Alarm Thresholds</h2>
  <div class="threshold-subheadings">
    <h3 class="pure-black-color">Assigned Equipment: {{equipmentName}}</h3>
    <div class="threshhold-profile-selection">
      <h3 class="pure-black-color">Select Profile:</h3>
      <ejs-dropdownlist [(ngModel)]="selectedProfile" [fields]='profileFields' [placeholder]='profilePlaceholder'
        [dataSource]='profiles' (ngModelChange)="profileChange()"></ejs-dropdownlist>
    </div>
  </div>
  <hr>
  <div class="threshold-table-container">
    <div class="threshold-table-6-columns">
      <div><span class="checkbox-title">Rule Enabled</span> <ejs-switch [(ngModel)]="isRuleEnabled"
          (ngModelChange)="enabledChange()"></ejs-switch></div>
      <span></span>
      <p class="text-sm pure-black-color">Warning</p>
      <p class="text-sm pure-black-color">Alarm</p>
      <p class="text-sm pure-black-color">Unit</p>
      <p class="text-sm pure-black-color">Enabled</p>
    </div>

    <div *ngFor="let axis of axialData;" class="threshold-table-body">
      <div class="threshold-table-6-columns">
        <h3 class="pure-black-color bold">{{axis.label}}</h3>
      </div>
      <div *ngFor="let acceleration of axis.accelerations">
        <div class="threshold-table-6-columns">
          <p class="text-sm">{{acceleration.label}}</p>
          <input [(ngModel)]="acceleration.option" disabled required />
          <input type="number" [(ngModel)]="acceleration.warning" required [disabled]="!acceleration.enabled"
            (change)="warningChange(acceleration)">
          <input type="number" [(ngModel)]="acceleration.alarm" required [disabled]="!acceleration.enabled"
            (change)="alarmChange(acceleration)">
          <p class="text-sm bold">{{acceleration.unit}}</p>
          <ejs-switch [(ngModel)]="acceleration.enabled" (ngModelChange)="enabledChange()"></ejs-switch>
        </div>
      </div>
    </div>

  </div>
  <hr>
  <div class="modal-btns">
    <div class="modal-btns-set">
      <app-custom-button [buttonData]="restoreDefaultsButtonData" (buttonClick)="onRestoreDefaultButtonClick()" />
    </div>
    <div class="modal-btns-set">
      <app-custom-button (buttonClick)="onCancelButtonClick()" [buttonData]="cancelButtonData" />
      <app-custom-button [buttonData]="saveButtonData" (buttonClick)="onSaveButtonClick()" />
    </div>
  </div>
</div>
