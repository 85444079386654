import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMAGE_API_ENDPOINTS_DASHBOARD } from '../../app-constants/api-constants';
import { SessionService } from '../session-management-services/session.service';
import { ApiResponse } from '../../models/api-response';
import { catchError, map, tap } from 'rxjs';
import { ImageSasUrlResponse, OpenModalDialogData } from '../../models/shared.models';
import { AssetPhotosCarouselItemType, ModalTemplateTypes } from '../../app-constants/shared.enum';
import { ModalDialogService } from '../modal-dialog-service/modal-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class ImageCarouselHelperService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private errorHandlerService: ErrorHandlerService,
    private modalDialogService: ModalDialogService,
  ) { }

  getAssetPhotos(assetId: string, thumbnail: boolean = true) {
    const headers = { headers: new HttpHeaders({ 'Prevent-Spinner': '' }) };
    const url = environment.dashboardApiBaseUrl + IMAGE_API_ENDPOINTS_DASHBOARD.GET_IMAGES_FOR_AN_ASSET(this.sessionService.tenantId, assetId, thumbnail);
    return this.http.get<ApiResponse<ImageSasUrlResponse[]>>(url, headers)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  deleteAssetPhoto(imageId: string, preventSpinner: boolean = false) {
    let headers = { headers: new HttpHeaders() };
    if (preventSpinner) headers = { headers: new HttpHeaders({ 'Prevent-Spinner': '' }) };
    const url = environment.dashboardApiBaseUrl + IMAGE_API_ENDPOINTS_DASHBOARD.DELETE_IMAGE(this.sessionService.tenantId, imageId);
    return this.http.delete<ApiResponse<any>>(url, headers)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  getTransformCarouselItems = (res: ImageSasUrlResponse[]) => {
    return res?.map((obj: ImageSasUrlResponse) => {
      const { id: id, fileName: name, location: url, thumbLocation: thumbLocation, isUploaded: isUploaded } = obj;
      return { id: id, name: name, url: url, thumbLocation: thumbLocation, type: AssetPhotosCarouselItemType.PHOTO, isUploaded: isUploaded };
    });
  };

  showSuccessModal(message: string) {
    const data = new OpenModalDialogData();
    data.templateName = ModalTemplateTypes.SUCCESS_TEMPLATE;
    data.customData.message = message;
    this.modalDialogService.openModal(data);
  }
}
