import { Injectable } from '@angular/core';
import { ToastModel } from '@syncfusion/ej2-angular-notifications';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastSubject = new Subject<ToastModel>();
  toastEvent$ = this.toastSubject.asObservable();

  maxToastCount = 3;

  constructor() { }

  showToast(toastModel : ToastModel) {
    this.toastSubject.next(toastModel);
  }
}
