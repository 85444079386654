import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomEvent } from '../../models/custom-event';
import { ButtonSizes, ButtonTypes, CustomEventSource, CustomEventType } from '../../app-constants/shared.enum';
import { ButtonData } from '../../models/shared.models';

@Component({
  selector: 'app-custom-menu',
  templateUrl: './custom-menu.component.html',
  styleUrls: ['./custom-menu.component.scss']
})
export class CustomMenuComponent {
  @Input() menuItems?: any[];
  @Input() rowData: any;
  @Output() kebabMenuClickEvent = new EventEmitter<CustomEvent>();
  menuButtonData:ButtonData = { label: '+', name: '+', type: ButtonTypes.PRIMARY_BASIC, size: ButtonSizes.SM };
  custonMenuClick(e: MouseEvent | KeyboardEvent) {
    const event: CustomEvent = { eventType: CustomEventType.CLICK, eventSource: CustomEventSource.KEBAB_MENU, eventData: { clickEvent: e, items: this.menuItems, rowData: this.rowData } };
    this.kebabMenuClickEvent.emit(event);
  }
}
