import { ButtonData } from '../../models/shared.models';
import { ButtonLabels, ButtonNames, ButtonTypes, CustomEventSource, CustomEventType } from '../../app-constants/shared.enum';
import { ButtonStateService } from '../../services/button-state-service/button-state.service';
import { Component, Input, OnInit } from '@angular/core';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { mockTimeZone } from '../../mock-data/mock-user-preferences';
import { mockUnitPreferences } from '../../mock-data/mock-unit-preference';
import { ModalDialogService } from '../../services/modal-dialog-service/modal-dialog.service';
import { SessionService } from '../../services/session-management-services/session.service';
import { TimeZone } from 'src/app/admin/gateways-info/models/gateway';
import { UnitPreferences } from '../../models/unit-preferences';
import { UserDetails, UserPreferences } from 'src/app/admin/user-management/models/user-details';
import { UserPreferencesConstants } from '../../app-constants/asset-constants';
import { UserService } from 'src/app/customer/user-management/services/user-services/user.service';

@Component({
  selector: 'app-users-preferences',
  templateUrl: './users-preferences.component.html',
  styleUrls: ['./users-preferences.component.scss']
})
export class UsersPreferencesComponent implements OnInit {
  @Input() id!: string;
  cancelButtonData: ButtonData = { label: ButtonLabels.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.CANCEL };
  formGroup!: FormGroup;
  saveButtonData: ButtonData = { label: ButtonLabels.SAVE, type: ButtonTypes.PRIMARY_BASIC, name: ButtonNames.SAVE_MODAL, width: '10rem' };
  selectTimeZoneOptions: TimeZone[] = mockTimeZone;
  selectTimeZoneOptionsFieldMapping: Object = { text: 'displayName', value: 'id' };
  selectUnitPreferencesOptions: UnitPreferences[] = mockUnitPreferences;
  selectUnitPreferencesOptionsFieldMapping: Object = { text: 'name', value: 'id' };
  userDetails!: UserDetails;

  constructor(
    private buttonStateService: ButtonStateService,
    private formBuilder: FormBuilder,
    private modalDialogService: ModalDialogService,
    private sessionService: SessionService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.createFormGroup();
    let scope = '/';
    if (this.sessionService.allRolesAssignedToUser) scope = this.sessionService.allRolesAssignedToUser[0].scope;
    this.userService.getUserDetailsByOktaId(this.sessionService.tenantId, this.sessionService.userId, scope).subscribe((response) => {
      if (response) {
        this.userDetails = response;
        const unitOfMeasure: string = this.userDetails.unitOfMeasure ? this.userDetails.unitOfMeasure : UserPreferencesConstants.DEFAULT_UNITOFMEASURE;
        this.setDefaultUnitPreferences(unitOfMeasure);
      }
    });
    this.formGroup?.statusChanges.subscribe(() => {
      this.updateButtonsState();
    });
  }

  createFormGroup() {
    this.formGroup = this.formBuilder.group({
      selectedTimeZone: new FormControl({ value: UserPreferencesConstants.DEFAUL_TTIMEZONE, disabled: true }),
      selectedUnitPreferences: [null, [Validators.required]]
    });
  }

  updateButtonsState() {
    const enableButtons = this.formGroup?.valid && this.formGroup?.dirty;
    this.buttonStateService.setButtonDisableState(this.saveButtonData.name, !enableButtons);
  }

  setDefaultUnitPreferences(id: string) {
    this.formGroup?.get('selectedUnitPreferences')?.patchValue(id);
  }

  save() {
    const selectedTimeZone = this.selectTimeZoneOptions?.find(x => x.id === this.formGroup?.get('selectedTimeZone')?.value);
    const selectedUnitPreference = this.selectUnitPreferencesOptions?.find(x => x.id === this.formGroup?.get('selectedUnitPreferences')?.value);
    const data: UserPreferences = {
      timeZone: selectedTimeZone?.id,
      unitOfMeasure: selectedUnitPreference?.id
    };
    const customData: CustomEvent = { eventSource: CustomEventSource.MODAL, eventType: CustomEventType.SAVE_USER_PREFERENCES, eventData: { id: this.id, data: data } };
    this.modalDialogService.emitModalDialogEvent(customData);
  }

  cancel() {
    this.modalDialogService.closeModal();
  }
}
