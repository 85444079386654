import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ButtonData } from 'src/app/shared/models/shared.models';
import { ButtonLabels, ButtonTypes, ButtonNames } from 'src/app/shared/app-constants/shared.enum';
import { ButtonStateService } from 'src/app/shared/services/button-state-service/button-state.service';
import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { DeviceState } from 'src/app/shared/app-constants/asset-constants';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { SensorParameter } from '../../models/sensor-parameter';
import { SensorParameterService } from 'src/app/shared/services/sensor-parameter-services/sensor-parameter.service';

@Component({
  selector: 'app-sensor-parameter',
  templateUrl: './sensor-parameter.component.html',
  styleUrls: ['./sensor-parameter.component.scss']
})
export class SensorParameterComponent implements OnInit, OnChanges {
  @Input() sensorId: string = '';
  @Input() sensorState: string = '';
  @Input() tenantId: string = '';
  advertisementDurationSource = [{ text: '10 sec', value: '10' }, { text: '20 sec', value: '20' }, { text: '30 sec', value: '30' }];
  advertisementIntervalSource = [{ text: '10 min', value: '600' }, { text: '30 min', value: '1800' }, { text: '1 hour', value: '3600' }];
  cancelButtonData:ButtonData = { label: ButtonLabels.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.CANCEL };
  fields: Object = { text: 'text', value: 'value' };
  moreButtonData: ButtonData = { label: '', type: ButtonTypes.SECONDARY_BASIC, name: '', disable: true };
  restoreDefaultsButtonData: ButtonData = { label: ButtonLabels.RESTORE_DEFAULTS, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.RESTORE_DEFAULTS };
  saveButtonData:ButtonData = { label: ButtonLabels.SAVE, type: ButtonTypes.PRIMARY_BASIC, name: ButtonNames.SAVE_MODAL, width: '10rem' };
  sensorParameterForm!: FormGroup;
  sensorsParameter: SensorParameter = new SensorParameter();
  reportedAdvertisementInterval = 0;

  public get SensorLifeCycle(): typeof DeviceState {
    return DeviceState;
  }

  constructor(
    private buttonStateService: ButtonStateService,
    private modalDialogService: ModalDialogService,
    public sensorParameterService: SensorParameterService,
  ) { }

  ngOnInit() {
    this.createSensorParameter();
    this.getSensorParameters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const sensorStateChange:SimpleChange = changes['sensorState'];
    if (sensorStateChange) {
      //Call button state service
      this.updateButtonStates();
    }
  }

  private updateButtonStates() {
    this.buttonStateService.setButtonDisableState(this.saveButtonData.name, !(this.sensorParameterForm?.dirty && this.sensorParameterForm?.valid));
  }

  getSensorParameters() {
    this.sensorParameterService.getSensorParameters(this.sensorId, this.tenantId).subscribe(response => {
      this.sensorsParameter = response;
      this.reportedAdvertisementInterval = Number(this.sensorsParameter.reportedAdvertisementInterval) / 60;
      this.createSensorParameter(this.sensorsParameter);
    });
  }

  createSensorParameter(sensorParameter?: SensorParameter) {
    this.sensorParameterForm = new FormGroup({
      advertisementInterval: new FormControl({ value: sensorParameter?.advertisementInterval.toString(), disabled: false }, [Validators.required]),
      advertisementDuration: new FormControl({ value: sensorParameter?.advertisementDuration.toString(), disabled: false }, [Validators.required]),
      accelerationRange: new FormControl({ value: sensorParameter?.accelerationRange.toString(), disabled: false }, [Validators.required]),
      x_Axis: new FormControl({ value: sensorParameter?.x_Axis, disabled: false }, [Validators.required, this.validateAxisValues()]),
      y_Axis: new FormControl({ value: sensorParameter?.y_Axis, disabled: false }, [Validators.required, this.validateAxisValues()]),
      z_Axis: new FormControl({ value: sensorParameter?.z_Axis, disabled: false }, [Validators.required, this.validateAxisValues()]),
    });
    this.sensorParameterForm.statusChanges.subscribe(status => {
      this.updateButtonStates();
    });
    this.updateButtonStates();
    this.subscribeToAxisValueChange();
  }

  // Custom validator for axis fields
  validateAxisValues(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.sensorParameterForm) return null;
      const currentControlName = Object.keys(this.sensorParameterForm.controls).find(key => this.sensorParameterForm.get(key) === control);
      if (!currentControlName) return null;
      const allAxisControls = ['x_Axis', 'y_Axis'];
      const toBeValidatedAxisControls = allAxisControls.filter(x => x !== currentControlName);
      const errors: ValidationErrors = {};
      toBeValidatedAxisControls.forEach(item => {
        const value = this.sensorParameterForm.get(item)?.value;
        if (value === control.value) {
          errors[currentControlName] = 'Axis value should be unique';
        }
      });
      return errors;
    };
  }

  // This method subscribe to changes in all 3 axis fields
  // For change in any of one, it silently trigger validation for rest of two
  subscribeToAxisValueChange() {
    this.sensorParameterForm.get('x_Axis')?.valueChanges.subscribe((newValue) => {
      this.sensorParameterForm.get('y_Axis')?.updateValueAndValidity({ emitEvent: false });
      this.axisValueChange();
    });
    this.sensorParameterForm.get('y_Axis')?.valueChanges.subscribe((newValue) => {
      this.sensorParameterForm.get('x_Axis')?.updateValueAndValidity({ emitEvent: false });
      this.axisValueChange();
    });
  }

  axisValueChange() {
    if (this.sensorParameterForm.get('x_Axis')?.value !== this.sensorParameterForm.get('y_Axis')?.value) {
      const allAxisValues = ['A', 'H', 'V'];
      const xAxisValue = this.sensorParameterForm.get('x_Axis')?.value;
      const yAxisValue = this.sensorParameterForm.get('y_Axis')?.value;
      const zAxisValue = allAxisValues.find(x => x !== yAxisValue && x !== xAxisValue);
      this.sensorParameterForm.patchValue({ z_Axis: zAxisValue });
    }
  }

  save(sensorParameter: SensorParameter) {
    if (!this.sensorParameterForm.valid || !this.sensorParameterForm.dirty) {
      return;
    }
    sensorParameter.accelerationRangeSource = this.sensorsParameter.accelerationRangeSource;
    sensorParameter.axisSources = this.sensorsParameter.axisSources;
    sensorParameter.sensorId = this.sensorsParameter.sensorId;
    this.sensorParameterService.saveSensorParameter(this.tenantId, this.sensorsParameter.sensorId, sensorParameter);
    this.modalDialogService.closeModal();
  }

  cancel() {
    this.modalDialogService.closeModal();
  }

  // Returns a css class for error by accepting form control name
  getCssClass(formControlName: string): string {
    if (!this.sensorParameterForm) return '';
    if (!this.sensorParameterForm.get(formControlName)) return '';
    if (!this.sensorParameterForm.get(formControlName)?.valid) return 'e-error';
    return '';
  }

  onRestoreDefaultButtonClick() {
    this.sensorParameterService.getRestoreDefaultSensorParameters(this.tenantId).subscribe(response => {
      this.sensorsParameter = response;
      this.reportedAdvertisementInterval = Number(this.sensorsParameter.reportedAdvertisementInterval) / 60;
      this.sensorsParameter.sensorId = this.sensorId;
      this.createSensorParameter(this.sensorsParameter);
      this.sensorParameterForm?.markAsDirty();
      this.updateButtonStates();
    });
  }
}
