<form [formGroup]="formGroup" class="form-container" *ngIf="formGroup">
<div class="main-container">
    <h3 class="header-text">Add Tags</h3>
    <ejs-multiselect class="dropdown" placeholder="Select tags"formControlName= 'artifactTag' [dataSource]="availabletags"></ejs-multiselect>
    <div class="modal-footer">
        <app-custom-button [buttonData]="cancelButtonData" (buttonClick)="onCancel()"/>
        <app-custom-button [buttonData]="addButtonData" (buttonClick)="onAddTag()"/>
    </div>
</div>
</form>
