import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @Input() scope!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    if (!this.scope) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.scope = params['s'] ?? this.sessionService.defaultEnterpriseId;
      });
    }
  }
}
