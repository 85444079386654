import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DropDownTreeComponent } from '@syncfusion/ej2-angular-dropdowns';
import { createSpinner } from '@syncfusion/ej2-angular-popups';
import { Subscription } from 'rxjs';
import { RouteSet } from './shared/app-constants/role-constants';
import { EnterpriseList } from './shared/models/asset-list-model/enterprise-list';
import { LoginState } from './shared/models/login-state';
import { AssetListService } from './shared/services/asset-list-services/asset-list.service';
import { BreadcrumbService } from './shared/services/breadcrumb-service/breadcrumb.service';
import { SessionService } from './shared/services/session-management-services/session.service';
import { SpinnerService } from './shared/services/spinner-service/spinner.service';
import { AssetApiIncludeConstants } from './shared/app-constants/asset-constants';
import { TreeGridHelperService } from './shared/services/tree-grid-helper-services/tree-grid-helper.service';
import { AssetsTree } from './shared/models/asset';
import { SignalRHubService } from './shared/services/signalr-services/signalr-hub.service';
import { NotificationService } from './shared/services/notification-services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.enterpriseDropdown?.hidePopup(); //This is done so as to forcefully close the opened enterprise list dropdown in breadcrumb
  }

  @ViewChild('enterpriseDropdown', { static: false }) enterpriseDropdown!: DropDownTreeComponent;
  enterpriseList: EnterpriseList[] = [];
  enterpriseId!: string;
  assetlist: AssetsTree[] = [];
  remoteFieldForEnterpriseDropdown!: Object;
  isLoginPage = true;
  isAdminPage = false;
  isAdminLogin = false;
  isAuthenticatedUser = false;
  title = 'Perceptiv_IoT-Dashboard';
  scope!: string;
  isDropDownTreeDisabled = true;
  private assetListFetched: boolean = false;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private sessionService: SessionService,
    private spinnerService: SpinnerService,
    private breadcrumbService: BreadcrumbService,
    private treeGridHelperService: TreeGridHelperService,
    private assetListService: AssetListService,
    private signalRHubService: SignalRHubService,
    private notificationService: NotificationService,
  ) {
    translate.addLangs(['en', 'fr']); // declare the supported languages [Optional]
    translate.setDefaultLang('en'); // fail safe - default language, if key not found in current laguage.json file
  }

  ngOnInit() {
    this.translate.use('en');
    this.subscriptions.add(this.sessionService.loginState$.subscribe((loginState: LoginState) => {
      this.isAuthenticatedUser = loginState.loginStatus;
      this.isAdminLogin = RouteSet.Admin.includes(this.sessionService.roleName);
      if (!loginState.loginStatus) {
        this.isLoginPage = true;
      }
      else if (loginState.loginStatus && this.isAdminLogin) {
        this.isAdminPage = loginState.isAdminPage;
        this.isLoginPage = false;
        if (!this.assetListFetched) {
          this.getAssetList();
          this.assetListFetched = true;
        }
      }
      else if (loginState.loginStatus && !this.isAdminLogin) {
        this.isAdminPage = false;
        this.isLoginPage = false;
      }
    }));
    this.subscriptions.add(this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.breadcrumbService.setBreadcrumbInfo([]);
        this.spinnerService.showSpinner();
      }
      if (event instanceof NavigationEnd) {
        this.spinnerService.hideSpinner();
      }
    }));
  }

  ngAfterViewInit() {
    createSpinner({
      target: document.getElementById('container') as any
    });
    this.scope = this.sessionService.userScope;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getAssetList() {
    this.treeGridHelperService.getEnterpriseTree(this.sessionService.tenantId).subscribe((response) => {
      this.assetlist = response;
      this.setupDropDownFields();
      this.isDropDownTreeDisabled = false;
    });
  }

  setupDropDownFields() {
    this.remoteFieldForEnterpriseDropdown = {
      dataSource: this.assetlist,
      value: 'id',
      text: 'name',
      child: 'subData'
    };
  }

  onChangeOfAsset(e: any) {
    const selectedNode = e.itemData;
    const id = selectedNode.id as string;
    if (selectedNode.parentID != null) {
      const parentId = selectedNode.parentID as string;
      this.scope = `/${parentId}/${id}`;
    }
    else {
      this.scope = `/${id}`;
    }
    this.sessionService.userScope = this.scope;
  }
}
