import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenInfo } from '../../models/token-info';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

/**
 * Service to make HTTP calls
 */
export class TokenGenerationService {
  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * @returns embed configuration
   */
  private getTokenForReport(endpoint: string): Observable<TokenInfo> {
    return this.httpClient.get<TokenInfo>(endpoint);
  }

  async fn_getTokenForReport(_workspaceId: any, _reportId: any): Promise<TokenInfo> {
    const fullTokenURL = environment.dashboardApiBaseUrl + '/PowerBIEmbedded/GetTokenForReport?workspaceid=' + _workspaceId + '&reportid=' + _reportId;
    return await new Promise<TokenInfo>((resolve, reject) => {
      const tokenSubscription = this.getTokenForReport(fullTokenURL).subscribe({
        next: (token: TokenInfo) => {
          resolve(token);
        },
        error: (err) => {
          reject(err);
        },
        complete: () => {
          tokenSubscription.unsubscribe();
        }
      });
    });
  }

  async fn_getTokenForReportByEmailId(_workspaceId: any, _reportId: any, _dataSetId: any, _userId: any): Promise<TokenInfo> {
    const fullTokenURL = environment.dashboardApiBaseUrl + '/PowerBIEmbedded/GetTokenForReportByEmailId?workspaceid=' + _workspaceId + '&reportid=' + _reportId + '&dataSetId=' + _dataSetId + '&userId=' + _userId;
    return await new Promise<TokenInfo>((resolve, reject) => {
      const tokenSubscription = this.getTokenForReport(fullTokenURL).subscribe({
        next: (token: TokenInfo) => {
          resolve(token);
        },
        error: (err) => {
          reject(err);
        },
        complete: () => {
          tokenSubscription.unsubscribe();
        }
      });
    });
  }
}
