import { Injectable } from '@angular/core';
import { Permission } from 'src/app/customer/user-management/models/permission';

@Injectable({
  providedIn: 'root'
})
export class PermissionCheckerService {
  hasPermission(userPermissions: Permission[], permissionRequiredForRoute: string): boolean {
    return userPermissions.some(p => p.name.includes(permissionRequiredForRoute));
  }

  hasRequiredRole(userRole: string, rolesRequiredForRoute: string[]): boolean {
    return rolesRequiredForRoute.some(r => r === userRole);
  }
}
