import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions, LogLevel } from '@microsoft/signalr';
import { delay, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SignalREvents, SignalRMethods } from '../../app-constants/events-constants';
import { SignalREventTypes } from '../../app-constants/shared.enum';
import { SignalRAssetChangeSignalEvent, SignalRCommandSignalEvent, SignaREvent } from '../../models/signalr-events';

@Injectable({
  providedIn: 'root'
})
export class SignalRHubService {
  private hubConnection!: HubConnection; // Refernce to HubConnection being in use
  private hubConnectionStates = HubConnectionState; //Enum of all possible hub states
  private signalREvents = new Subject<SignaREvent<any>>();
  private signalRAssetChangeSignalEvent = new Subject<SignaREvent<SignalRAssetChangeSignalEvent>>();
  private signalRCommandSignalEvent = new Subject<SignaREvent<SignalRCommandSignalEvent>>();

  private clientId:string = '';

  public signalREvents$ = this.signalREvents.asObservable();
  public signalRAssetChangeSignalEvent$ = this.signalRAssetChangeSignalEvent.asObservable();
  public signalRCommandSignalEvent$ = this.signalRCommandSignalEvent.asObservable();

  public accessTokenFactory: (() => Promise<string>) | null = null;

  constructor() {
    this.clientId = this.generateGUID();
  }

  generateGUID(): string {
    return crypto.randomUUID();
  }

  // Returns current hub connection state
  get currentHubConnectionState(): HubConnectionState {
    return this.hubConnection?.state;
  }

  /**
   * Set up hub default configuration. Gets called automatically when this service is initialized
   * @param {string} token The authorization token
   * @param {string} tenantId The tenant id
   * @param {string} emailId The email id of user
   * @returns HubConnection reference object
   */
  setUpHub(tenantId: string, emailId: string): HubConnection {
    if (this.hubConnection) return this.hubConnection;
    const options: IHttpConnectionOptions =
    {
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: async() => {
        if (this.accessTokenFactory)
          return await this.accessTokenFactory();
        return '';
      }
    };
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.signalRHubBaseUrl}?tenantId=${tenantId}&email=${emailId}&clientId=${this.clientId}`, options)
      .withStatefulReconnect()
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();
    this.subscribeToEvents();
    return this.hubConnection;
  }

  /**
   * Starts the hub connection using default hub cnfiguration
   * @returns Observable of HubConnection reference object
   */
  startHub(): Observable<HubConnection> {
    return new Observable<HubConnection>(observer => {
      console.log('start Hub', this.hubConnection.state);
      if (this.currentHubConnectionState === this.hubConnectionStates.Disconnected) {
        this.hubConnection
          .start()
          .then(() => observer.next(this.hubConnection))
          .catch(err => {
            observer.error(err);
            console.error('SignalR hub', 'startHub', err);
          });
      }
      else {
        observer.next(this.hubConnection);
      }
    });
  }

  /**
   * Stops the hub if connected
   * @returns Observable of void
   */
  stopHub(): Observable<void> {
    return new Observable<void>(observer => {
      this.hubConnection
        .stop()
        .then(() => observer.next())
        .catch(err => {
          observer.error(err);
          console.error('SignalR hub', 'stopHub', err);
        });
    });
  }

  /**
   * Sets the scope to be used for receiving scope specific hub events
   * @param {string[]} scopes The scopes to be set in order to receive scope specific events
   * @param {string} tenantId The tenant id
   * @param {string} emailId The email id of user
   * @returns Observable of specific data type
   */
  setSubscribedScopes(scopes: string[], notificationType:SignalREventTypes, shhhh?: number): Observable<void> {
    return new Observable<void>(observer => {
      if (shhhh) {
        shhhh++;
        if (shhhh > 20)
          return;
      }
      else {
        shhhh = 0;
      }
      console.log('set scope', this.hubConnection.state, 'scopes', scopes, 'notification type', notificationType);
      if (this.hubConnection.state === HubConnectionState.Connected)
      {
        this.hubConnection.invoke(SignalRMethods.SET_SUBSCRIBED_SCOPES, scopes, notificationType)
          .then((_: any) => {
            observer.next();
          })
          .catch((err: any) => {
            observer.error(err);
            console.error('SignalR hub', 'setSubscribedScopes', err, 'Type', notificationType, 'Scopes', scopes);
          });
      } else if (this.hubConnection.state === HubConnectionState.Disconnected)
      {
        this.startHub()
          .pipe(delay(200))
          .pipe(() => this.setSubscribedScopes(scopes, notificationType, shhhh))
          .subscribe(observer);
      }
      else {
        setTimeout(() => this.setSubscribedScopes(scopes, notificationType, shhhh)
          .subscribe(observer), 200);
      }
    });
  }

  // Make subcriptions to all kinds of events
  subscribeToEvents() {
    this.subscribeSendNotificationEvent();
  }

  // Making subscription for SendNotification event
  subscribeSendNotificationEvent() {
    this.hubConnection.on(SignalREvents.SEND_NOTIFICATION, (data: SignaREvent<any>) => {
      this.signalREvents.next(data);
      console.log('SignalR Message received', data);
      if (data?.type === SignalREventTypes.AssetChangeSignal) {
        console.log('SignalR AssetChangeSignal received', data);
        this.signalRAssetChangeSignalEvent.next(data as SignaREvent<SignalRAssetChangeSignalEvent>);
      }
      if (data?.type === SignalREventTypes.CommandSignal) {
        this.signalRCommandSignalEvent.next(data as SignaREvent<SignalRCommandSignalEvent>);
      }
    });
  }
}
