import { debounceTime, filter, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PageScrollService {
  private lastScrollPosition?:[number, number];
  private readonly dataReloaded = new Subject<boolean>();
  private readonly defaultScrollPosition:[number, number] = [0, 0];

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
    this.subscribeRouterEvents();
    this.subscribeDataReloaded();
  }

  private subscribeDataReloaded() {
    this.dataReloaded
      .pipe(debounceTime(400))  //wait for emission silence before emitting the most recent source value, may drop values if they occur too frequently
      .subscribe((isFinal:boolean) => {
        this.restoreScrollPosition(isFinal);
      });
  }

  private subscribeRouterEvents() {
    this.router.events.pipe(
      filter(
        event => event instanceof Scroll  // filter other events, we are only interested in Scroll events here.
      )
    ).subscribe((event: any) => {
      this.lastScrollPosition = event?.position;
    });
  }

  /**
   * preserved the current scroll position in-Memory.
  */
  notifyPageUnloaded() {
    this.lastScrollPosition = this.getCurrentScrollPosition();  // keeping only scroll-Y since we are only maintaining vertical scroll.
  }

  /**
   * tries to restore the last scroll position, if any.
  */
  notifyDataLoaded(isFinal?:boolean) {
    this.dataReloaded.next(isFinal ?? true);
  }

  /**
   * tries to scroll the page to Top
   */
  scrollToTop() {
    this.viewportScroller.scrollToPosition(this.defaultScrollPosition);
  }

  /**
   * Tries to restore last scroll position provided from routes
   * @param isFinal : value true informs that no further attempt to restore scroll will be needed.
   */
  private restoreScrollPosition(isFinal:boolean) {
    if (this.lastScrollPosition) {
      this.viewportScroller.scrollToPosition(this.lastScrollPosition ?? this.defaultScrollPosition);
      if (isFinal)
        this.lastScrollPosition = undefined;
    }
  }

  private getCurrentScrollPosition(): [number, number] {
    return [0, window.scrollY];
  }
}
