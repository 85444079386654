<div class="sidenav-container">
  <div class="sidenav-item" routerLink="admin/enterprises-info" routerLinkActive="active">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="26" viewBox="0 0 32 26" fill="none">
      <g clip-path="url(#clip0_1552_57053)">
        <path
          d="M14.4 3.44893C14.4 4.28962 14.075 5.05072 13.54 5.61781L14.885 8.47814C15.495 8.30901 16.135 8.21947 16.8 8.21947C18.75 8.21947 20.52 8.99051 21.815 10.2441L25.695 7.4136C25.63 7.16488 25.6 6.90123 25.6 6.63261C25.6 4.87661 27.035 3.44893 28.8 3.44893C30.565 3.44893 32 4.87661 32 6.63261C32 8.3886 30.565 9.81628 28.8 9.81628C28.185 9.81628 27.605 9.64217 27.115 9.33873L23.235 12.1692C23.725 13.1343 24 14.2287 24 15.3827C24 16.303 23.825 17.1835 23.505 17.9943L26.87 20.004C27.405 19.6011 28.075 19.3623 28.795 19.3623C30.56 19.3623 31.995 20.79 31.995 22.546C31.995 24.302 30.56 25.7297 28.795 25.7297C27.03 25.7297 25.595 24.302 25.595 22.546C25.595 22.3769 25.61 22.2127 25.635 22.0535L22.265 20.0438C20.95 21.5809 18.99 22.551 16.8 22.551C13.23 22.551 10.27 19.9692 9.7 16.5816H6.165C5.69 17.7506 4.54 18.5714 3.2 18.5714C1.435 18.5714 0 17.1437 0 15.3877C0 13.6317 1.435 12.204 3.2 12.204C4.545 12.204 5.695 13.0248 6.165 14.1938H9.7C10.03 12.2488 11.145 10.5674 12.71 9.49294L11.365 6.62763C11.31 6.63261 11.255 6.63261 11.2 6.63261C9.435 6.63261 8 5.20493 8 3.44893C8 1.69294 9.435 0.265259 11.2 0.265259C12.965 0.265259 14.4 1.69294 14.4 3.44893ZM16.8 20.1632C17.4303 20.1632 18.0545 20.0397 18.6369 19.7997C19.2192 19.5597 19.7484 19.208 20.1941 18.7645C20.6398 18.3211 20.9934 17.7946 21.2346 17.2152C21.4758 16.6358 21.6 16.0148 21.6 15.3877C21.6 14.7606 21.4758 14.1396 21.2346 13.5602C20.9934 12.9808 20.6398 12.4544 20.1941 12.0109C19.7484 11.5675 19.2192 11.2157 18.6369 10.9757C18.0545 10.7357 17.4303 10.6122 16.8 10.6122C16.1697 10.6122 15.5455 10.7357 14.9631 10.9757C14.3808 11.2157 13.8516 11.5675 13.4059 12.0109C12.9602 12.4544 12.6066 12.9808 12.3654 13.5602C12.1242 14.1396 12 14.7606 12 15.3877C12 16.0148 12.1242 16.6358 12.3654 17.2152C12.6066 17.7946 12.9602 18.3211 13.4059 18.7645C13.8516 19.208 14.3808 19.5597 14.9631 19.7997C15.5455 20.0397 16.1697 20.1632 16.8 20.1632Z" />
      </g>
      <defs>
        <clipPath id="clip0_1552_57053">
          <rect width="32" height="25.4694" fill="white" transform="translate(0 0.265259)" />
        </clipPath>
      </defs>
    </svg>
    <p>Enterprises</p>
  </div>
  <div class="sidenav-item" routerLink="admin/gateways-info" routerLinkActive="active">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
      <path
        d="M18.3333 3.21869C21.3958 3.21869 24.1927 4.34865 26.3229 6.21982C26.8385 6.67595 27.6302 6.62412 28.0885 6.11097C28.5469 5.59783 28.4948 4.80996 27.9792 4.35383C25.4062 2.0991 22.026 0.730713 18.3333 0.730713C14.6406 0.730713 11.2604 2.0991 8.69271 4.35383C8.17708 4.80996 8.125 5.59264 8.58333 6.11097C9.04167 6.6293 9.82812 6.67595 10.349 6.21982C12.474 4.34865 15.2708 3.21869 18.3333 3.21869ZM19.5833 13.5853C19.5833 12.8959 19.026 12.3413 18.3333 12.3413C17.6406 12.3413 17.0833 12.8959 17.0833 13.5853V17.3173H3.33333C1.49479 17.3173 0 18.8049 0 20.6346V23.9519C0 25.7816 1.49479 27.2692 3.33333 27.2692H26.6667C28.5052 27.2692 30 25.7816 30 23.9519V20.6346C30 18.8049 28.5052 17.3173 26.6667 17.3173H19.5833V13.5853ZM5 20.6346C5.44203 20.6346 5.86595 20.8093 6.17851 21.1204C6.49107 21.4314 6.66667 21.8533 6.66667 22.2932C6.66667 22.7331 6.49107 23.155 6.17851 23.4661C5.86595 23.7771 5.44203 23.9519 5 23.9519C4.55797 23.9519 4.13405 23.7771 3.82149 23.4661C3.50893 23.155 3.33333 22.7331 3.33333 22.2932C3.33333 21.8533 3.50893 21.4314 3.82149 21.1204C4.13405 20.8093 4.55797 20.6346 5 20.6346ZM8.33333 22.2932C8.33333 21.8533 8.50893 21.4314 8.82149 21.1204C9.13405 20.8093 9.55797 20.6346 10 20.6346C10.442 20.6346 10.8659 20.8093 11.1785 21.1204C11.4911 21.4314 11.6667 21.8533 11.6667 22.2932C11.6667 22.7331 11.4911 23.155 11.1785 23.4661C10.8659 23.7771 10.442 23.9519 10 23.9519C9.55797 23.9519 9.13405 23.7771 8.82149 23.4661C8.50893 23.155 8.33333 22.7331 8.33333 22.2932ZM14.401 10.4079C15.474 9.54231 16.8438 9.02398 18.3333 9.02398C19.8229 9.02398 21.1927 9.54231 22.2656 10.4079C22.8021 10.8381 23.5885 10.7604 24.026 10.2265C24.4635 9.69263 24.3802 8.90995 23.8437 8.47455C22.3333 7.26166 20.4167 6.536 18.3333 6.536C16.25 6.536 14.3333 7.26166 12.8281 8.47455C12.2917 8.90477 12.2083 9.69263 12.6458 10.2265C13.0833 10.7604 13.8698 10.8433 14.4062 10.4079H14.401Z" />
    </svg>
    <p>Gateways</p>
  </div>
  <div class="sidenav-item" routerLink="admin/sensors-info" routerLinkActive="active">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="26" viewBox="0 0 32 26" fill="none">
      <g clip-path="url(#clip0_1552_56936)">
        <path
          d="M15.955 25.6156C16.695 25.6353 17.35 25.1573 17.545 24.4526L21.245 11.0882L22.13 12.8327C22.67 13.902 23.78 14.5772 24.99 14.5772H30.4C31.285 14.5772 32 13.8725 32 13.0002C32 12.128 31.285 11.4233 30.4 11.4233H24.99L22.23 5.98786C21.935 5.40144 21.3 5.06142 20.64 5.12548C19.98 5.18954 19.43 5.64783 19.255 6.2786L16.185 17.3713L12.765 1.63161C12.61 0.921991 11.99 0.409494 11.255 0.384853C10.52 0.360214 9.865 0.83329 9.665 1.52812L6.795 11.4233H1.6C0.715 11.4233 0 12.128 0 13.0002C0 13.8725 0.715 14.5772 1.6 14.5772H6.795C8.225 14.5772 9.48 13.6458 9.87 12.2906L10.98 8.46658L14.435 24.3689C14.59 25.0785 15.22 25.5959 15.955 25.6156Z" />
      </g>
      <defs>
        <clipPath id="clip0_1552_56936">
          <rect width="32" height="25.2308" fill="white" transform="matrix(1 0 0 -1 0 25.6155)" />
        </clipPath>
      </defs>
    </svg>
    <p>Sensors</p>
  </div>
  <div class="sidenav-item" routerLink="admin/user-management" routerLinkActive="active">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="26" viewBox="0 0 32 26" fill="none">
      <path
        d="M23.6426 20.2922C22.6727 17.9207 20.3418 16.25 17.625 16.25H14.375C11.6582 16.25 9.32734 17.9207 8.35742 20.2922C6.54961 18.393
        5.4375 15.8285 5.4375 13C5.4375 7.16523 10.1652 2.4375 16 2.4375C21.8348 2.4375 26.5625 7.16523 26.5625 13C26.5625 15.8285 25.4504 18.393 23.6426 20.2922ZM21.6062 21.9527C19.9812 22.9734 18.0617 23.5625 16 23.5625C13.9383 23.5625 12.0188 22.9734 10.3887 21.9527C10.7594 20.0891 12.4047 18.6875 14.375 18.6875H17.625C19.5953 18.6875 21.2406 20.0891 21.6113 21.9527H21.6062ZM16 26C19.4478 26 22.7544 24.6304 25.1924 22.1924C27.6304 19.7544 29 16.4478 29 13C29 9.55219 27.6304 6.24558 25.1924 3.80761C22.7544 1.36964 19.4478 0 16 0C12.5522 0 9.24558 1.36964 6.80761 3.80761C4.36964 6.24558 3 9.55219 3 13C3 16.4478 4.36964 19.7544 6.80761 22.1924C9.24558 24.6304 12.5522 26 16 26ZM16 12.1875C15.4613 12.1875 14.9446 11.9735 14.5637 11.5926C14.1828 11.2116 13.9688 10.695 13.9688 10.1562C13.9688 9.61753 14.1828 9.10087 14.5637 8.71994C14.9446 8.33901 15.4613 8.125 16 8.125C16.5387 8.125 17.0554 8.33901 17.4363 8.71994C17.8172 9.10087 18.0312 9.61753 18.0312 10.1562C18.0312 10.695 17.8172 11.2116 17.4363 11.5926C17.0554 11.9735 16.5387 12.1875 16 12.1875ZM11.5312 10.1562C11.5312 11.3414 12.0021 12.4781 12.8401 13.3161C13.6782 14.1542 14.8148 14.625 16 14.625C17.1852 14.625 18.3218 14.1542 19.1599 13.3161C19.9979 12.4781 20.4688 11.3414 20.4688 10.1562C20.4688 8.97106 19.9979 7.83442 19.1599 6.99637C18.3218 6.15831 17.1852 5.6875 16 5.6875C14.8148 5.6875 13.6782 6.15831 12.8401 6.99637C12.0021 7.83442 11.5312 8.97106 11.5312 10.1562Z"
        />
    </svg>
    <p>Users</p>
  </div>
  <div class="sidenav-item" [routerLink]="RouterLinkConstants.ROUTE_TO_NOTIFICATION_RULES" routerLinkActive="active">
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="24" viewBox="0 0 34 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.7143 0.799805H1V22.9998H32.7143V0.799805ZM2.58571 4.56987V2.38552H31.1286V4.63664L16.8586 13.2201L2.58571 4.56987ZM2.58571 6.68896V21.4141H31.1286V6.75147L16.855 15.337L2.58571 6.68896Z" fill="black"/>
      <path d="M1 0.799805V0.299805H0.5V0.799805H1ZM32.7143 0.799805H33.2143V0.299805H32.7143V0.799805ZM1 22.9998H0.5V23.4998H1V22.9998ZM32.7143 22.9998V23.4998H33.2143V22.9998H32.7143ZM2.58571 2.38552V1.88552H2.08571V2.38552H2.58571ZM2.58571 4.56987H2.08571V4.8515L2.32656 4.99747L2.58571 4.56987ZM31.1286 2.38552H31.6286V1.88552H31.1286V2.38552ZM31.1286 4.63664L31.3863 5.06511L31.6286 4.91937V4.63664H31.1286ZM16.8586 13.2201L16.5994 13.6477L16.8576 13.8042L17.1163 13.6486L16.8586 13.2201ZM2.58571 6.68896L2.84486 6.26137L2.08571 5.80127V6.68896H2.58571ZM2.58571 21.4141H2.08571V21.9141H2.58571V21.4141ZM31.1286 21.4141V21.9141H31.6286V21.4141H31.1286ZM31.1286 6.75147H31.6286V5.86723L30.8708 6.32301L31.1286 6.75147ZM16.855 15.337L16.5959 15.7646L16.8541 15.9211L17.1128 15.7655L16.855 15.337ZM1 1.2998H32.7143V0.299805H1V1.2998ZM1.5 22.9998V0.799805H0.5V22.9998H1.5ZM32.7143 22.4998H1V23.4998H32.7143V22.4998ZM32.2143 0.799805V22.9998H33.2143V0.799805H32.2143ZM2.08571 2.38552V4.56987H3.08571V2.38552H2.08571ZM31.1286 1.88552H2.58571V2.88552H31.1286V1.88552ZM31.6286 4.63664V2.38552H30.6286V4.63664H31.6286ZM17.1163 13.6486L31.3863 5.06511L30.8708 4.20818L16.6009 12.7916L17.1163 13.6486ZM2.32656 4.99747L16.5994 13.6477L17.1177 12.7925L2.84486 4.14227L2.32656 4.99747ZM2.08571 6.68896V21.4141H3.08571V6.68896H2.08571ZM2.58571 21.9141H31.1286V20.9141H2.58571V21.9141ZM31.6286 21.4141V6.75147H30.6286V21.4141H31.6286ZM30.8708 6.32301L16.5973 14.9086L17.1128 15.7655L31.3863 7.17993L30.8708 6.32301ZM17.1142 14.9094L2.84486 6.26137L2.32656 7.11656L16.5959 15.7646L17.1142 14.9094Z" fill="black"/>
    </svg>
    <p>Rules</p>
  </div>
  <div class="sidenav-item" routerLink="admin/artifact-management" routerLinkActive="active">
    <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0599 3.80282L16.0528 0L9.08101 3.80282L16.0528 7.57042L23.0599 3.80282ZM15.0317 8.94366L8.30636 5.21127V12.4296L15.0317 16.162V8.94366ZM16.7923 8.94366L23.8345 5.21127V12.4296L16.7923 16.162V8.94366ZM31.0528 17.5703L24.0458 13.7675L17.074 17.5703L24.0458 21.3379L31.0528 17.5703ZM23.6585 22.4999L16.7923 18.6971V26.1971L23.6585 29.9999V22.4999ZM24.7852 22.4999L31.6162 18.6619V26.1971L24.7852 29.9295V22.4999ZM7.63735 13.7675L14.6444 17.5703L7.63735 21.3379L0.665516 17.5703L7.63735 13.7675ZM0.383827 18.6971L7.25002 22.4999V29.9999L0.383827 26.1971V18.6971ZM15.2078 18.6619L8.37678 22.4999V29.9295L15.2078 26.1971V18.6619Z" fill="#2E3238"/>
      </svg>        
    <p>Artifact</p>
  </div>
    <div class="sidenav-item" routerLink="admin/campaign-management" routerLinkActive="active">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="28.000000pt" height="26.000000pt" viewBox="0 0 28.000000 26.000000"
      preserveAspectRatio="xMidYMid meet">
     
     <g transform="translate(0.000000,26.000000) scale(0.100000,-0.100000)"
     fill="#000000" stroke="none">
     <path d="M170 245 c-14 -8 -45 -14 -70 -15 -65 0 -93 -23 -87 -69 7 -54 42
     -144 59 -155 28 -17 48 0 48 44 0 45 12 50 61 25 33 -17 49 -13 49 13 0 10 9
     23 20 30 26 16 27 66 1 84 -10 7 -20 22 -22 33 -5 25 -27 29 -59 10z m40 -85 
     l0 -79 -42 19 c-39 17 -43 23 -46 59 -3 38 -1 41 40 60 24 10 44 20 46 20 1 1
     2 -35 2 -79z m-105 0 c0 -39 -3 -45 -22 -48 -22 -3 -63 28 -63 48 0 20 41 51
     63 48 19 -3 22 -9 22 -48z m149 13 c4 -9 0 -23 -9 -32 -13 -14 -15 -12 -15 17
     0 34 14 43 24 15z m-144 -113 c0 -18 -6 -33 -15 -37 -20 -7 -23 -3 -31 35 -6
     29 -4 32 20 32 22 0 26 -4 26 -30z"/>
     </g>
     </svg>              
    <p>Campaign</p>
  </div>
</div>
