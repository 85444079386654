<div class="image-container">
  <div *ngIf="isModalDisabled" class="editor-template-overlay">
    <p>Saving...</p>
  </div>
  <p *ngIf="!isModalDisabled && uploadProgressMessage" class="error-msg">{{uploadProgressMessage}}</p>
  <div class="image-editor">
    <ejs-imageeditor #imageEditor (created)="created()"></ejs-imageeditor>
  </div>
  <div class="modal-btns">
    <app-custom-button (buttonClick)="onCancelButtonClick()" [buttonData]="cancelButtonData" />
    <app-custom-button (buttonClick)="onConfirmButtonClick()" [buttonData]="confirmButtonData" />
  </div>
</div>