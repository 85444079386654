<div class="battery-health-status-container">
    <svg *ngIf="batteryHealthLabel === 'Okay'" width="19" height="18" viewBox="0 0 19 18"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.5" cy="9" r="9" fill="#2E3238" />
      <path
        d="M14.2438 5.46357C14.5286 5.74834 14.5286 6.2108 14.2438 6.49557L8.41167 12.3276C8.1269 12.6123 7.66443 12.6123 7.37965 12.3276L4.46358 9.41157C4.17881 9.1268 4.17881 8.66434 4.46358 8.37958C4.74835 8.09481 5.21082 8.09481 5.4956 8.37958L7.8968 10.7784L13.2141 5.46357C13.4989 5.17881 13.9613 5.17881 14.2461 5.46357H14.2438Z"
        fill="white" />
    </svg>
    <svg *ngIf="batteryHealthLabel === 'Replace'" width="19" height="18" viewBox="0 0 19 18"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.5" cy="9" r="9" fill="#CC3300" />
      <path
        d="M10.6938 3.864C10.6938 3.3861 10.3077 3 9.82988 3C9.35203 3 8.96598 3.3861 8.96598 3.864V10.776C8.96598 11.2539 9.35203 11.64 9.82988 11.64C10.3077 11.64 10.6938 11.2539 10.6938 10.776V3.864ZM9.82988 15.096C10.1163 15.096 10.391 14.9822 10.5935 14.7797C10.796 14.5771 10.9098 14.3024 10.9098 14.016C10.9098 13.7296 10.796 13.4549 10.5935 13.2523C10.391 13.0498 10.1163 12.936 9.82988 12.936C9.54348 12.936 9.26881 13.0498 9.06629 13.2523C8.86377 13.4549 8.75 13.7296 8.75 14.016C8.75 14.3024 8.86377 14.5771 9.06629 14.7797C9.26881 14.9822 9.54348 15.096 9.82988 15.096Z"
        fill="white" />
    </svg>
    <p>{{batteryHealthLabel}}</p>
  </div>