import { Component, Input } from '@angular/core';
import { ButtonLabels, ButtonNames, ButtonSizes, ButtonTypes } from 'src/app/shared/app-constants/shared.enum';
import { ButtonData } from 'src/app/shared/models/shared.models';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';

@Component({
  selector: 'app-success-template',
  templateUrl: './success-template.component.html',
  styleUrls: ['./success-template.component.scss']
})
export class SuccessTemplateComponent {
  @Input() id!: string; // A unique id to passed back to the consumer
  @Input() message!: string; // Message to be shown
  okayButtonData: ButtonData = { label: ButtonLabels.OKAY, type: ButtonTypes.PRIMARY_BASIC, name: ButtonNames.OKAY, width: '12.5rem', size: ButtonSizes.SM };

  constructor(private modalDialogService: ModalDialogService) { }

  // Method call on click of Cancel button
  onOkayButtonClick() {
    this.closeModal();
  }

  // Method to close modal
  closeModal() {
    this.modalDialogService.closeModal();
  }
}
