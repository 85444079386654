import { Component, Input } from '@angular/core';
import { ConnectedSensorsInfo } from '../../models/asset-list-model/gateway-list';

@Component({
  selector: 'app-connected-sensors-template',
  templateUrl: './connected-sensors-template.component.html',
  styleUrls: ['./connected-sensors-template.component.scss']
})
export class ConnectedSensorsTemplateComponent {
  @Input() connectedSensorsInfo!: ConnectedSensorsInfo;

  get showConnectedSensorsLabel() {
    if (!this.connectedSensorsInfo) return '';
    return `${this.connectedSensorsInfo.count}/${this.connectedSensorsInfo.total}`;
  }
}
