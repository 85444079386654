export const THRESHOLD_PROFILE_KEYS = {
  ISO_KEY: (sensorType: string) => `${sensorType}_iso`,
  STATISTICAL_KEY: (sensorType: string) => `${sensorType}_statistical`,
  CUSTOM_KEY: (sensorType: string) => `${sensorType}_custom`,
  DEFAULT_KEY: (sensorType: string) => `${sensorType}_default`,
  ISO_LABEL: 'ISO',
  STATISTICAL_LABEL: 'Statistical',
  CUSTOM_LABEL: 'Custom',
  DEFAULT_LABEL: 'Default'
};

export enum SensorState {
  REGISTERED = 'Registered',
  PROVISIONED = 'Provisioned',
  COMMISSIONED = 'Commissioned',
  DECOMMISSIONED = 'Decommissioned'
}
