export class Schedule {
  time!: string;
  calculated!: boolean;
  fullWaveform!: boolean;
  lowFrequency!: boolean;
}

export class SensorSchedule {
  sensorId!: string;
  wakeUpTime!: string;
  sleepTime!: string;
  measurementIntervalInMinutes!: number;
  offsetTime!: number;
  schedule!: Schedule[];

  reportedWakeUpTime?: string;
  reportedSleepTime?: string;
  reportedMeasurementIntervalInMinutes?: string;
}
