import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeGeneratorAllModule } from '@syncfusion/ej2-angular-barcode-generator';
import { ButtonAllModule, ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { AutoCompleteModule, CheckBoxSelectionService, DropDownListModule, DropDownTreeModule, ListBoxModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { ImageEditorModule } from '@syncfusion/ej2-angular-image-editor';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { AccordionModule, AppBarModule, BreadcrumbModule, CarouselModule, ContextMenuModule, MenuModule, SidebarModule, TabModule } from '@syncfusion/ej2-angular-navigations';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import { FilterService, PageService, ResizeService, SortService, ToolbarService, TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import { PowerBIEmbedModule } from 'node_modules/powerbi-client-angular';
import { NotificationPreferencesComponent } from 'src/app/shared/components/notification-preferences/notification-preferences.component';
import { SensorParameterComponent } from '../admin/sensors-info/components/sensor-parameter/sensor-parameter.component';
import { SensorSchedulerComponent } from '../admin/sensors-info/components/sensor-scheduler/sensor-scheduler.component';
import { SensorThresholdsComponent } from '../admin/sensors-info/components/sensor-thresholds/sensor-thresholds.component';
import { RoleAndScopeAssignmentComponent } from '../admin/user-management/components/role-and-scope-assignment/role-and-scope-assignment.component';
import { AppAssetShowGraphComponent } from './components/app-asset-show-graph/app-asset-show-graph.component';
import { ConfirmTemplateComponent } from './components/app-modal-dialog-templates/confirm-template/confirm-template.component';
import { ErrorTemplateComponent } from './components/app-modal-dialog-templates/error-template/error-template.component';
import { GridTemplateComponent } from './components/app-modal-dialog-templates/grid-template/grid-template.component';
import { ImageCarouselTemplateComponent } from './components/app-modal-dialog-templates/image-carousel-template/image-carousel-template.component';
import { ImageEditorTemplateComponent } from './components/app-modal-dialog-templates/image-editor-template/image-editor-template.component';
import { SuccessTemplateComponent } from './components/app-modal-dialog-templates/success-template/success-template.component';
import { AppModalDialogComponent } from './components/app-modal-dialog/app-modal-dialog.component';
import { AssetBatteryStatusComponent } from './components/asset-battery-status/asset-battery-status.component';
import { AssetGatewaysComponent } from './components/asset-gateways/asset-gateways.component';
import { AssetPhotosCarouselComponent } from './components/asset-photos-carousel/asset-photos-carousel.component';
import { AssetSensorsComponent } from './components/asset-sensors/asset-sensors.component';
import { AssetSingleStatusComponent } from './components/asset-single-status/asset-single-status.component';
import { AssetStatusBarComponent } from './components/asset-status-bar/asset-status-bar.component';
import { AssetStatusIndicatorComponent } from './components/asset-status-indicator/asset-status-indicator.component';
import { BatteryHealthStatusComponent } from './components/battery-health-status/battery-health-status.component';
import { CellularSignalStrengthComponent } from './components/cellular-signal-strength/cellular-signal-strength.component';
import { CollapsableHeaderComponent } from './components/collapsable-header/collapsable-header.component';
import { ConnectedSensorsTemplateComponent } from './components/connected-sensors-template/connected-sensors-template.component';
import { ConnectionStatusTemplateComponent } from './components/connection-status-template/connection-status-template.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { CustomShowHideButtonComponent } from './components/custom-show-hide-button/custom-show-hide-button.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { KebabMenuComponent } from './components/kebab-menu/kebab-menu.component';
import { LabelledCustomTableComponent } from './components/labelled-custom-table/labelled-custom-table.component';
import { LastCommunicationTemplateComponent } from './components/last-communication-template/last-communication-template.component';
import { LifecycleStateProgressBarComponent } from './components/lifecycle-state-progress-bar/lifecycle-state-progress-bar.component';
import { OnlineSignalStatusComponent } from './components/online-signal-status/online-signal-status.component';
import { SensorTelemetryGraphS7100Component } from './components/sensor-telemetry-graphs/sensor-telemetry-graph-s7100/sensor-telemetry-graph-s7100.component';
import { SetupStatusComponent } from './components/setup-status/setup-status.component';
import { SignalBarComponent } from './components/signal-bar/signal-bar.component';
import { SignalStatusComponent } from './components/signal-status/signal-status.component';
import { StepStatusCircleComponent } from './components/step-status-circle/step-status-circle.component';
import { TextButtonComponent } from './components/text-button/text-button.component';
import { TreeGridComponent } from './components/tree-grid/tree-grid.component';
import { UsersPreferencesComponent } from './components/users-preferences/users-preferences.component';
import { CapitalizePipe } from './custom-pipes/capitalize.pipe';
import { OrdinalDatePipe } from './custom-pipes/ordinal-date.pipe';
import { SensorParameterService } from './services/sensor-parameter-services/sensor-parameter.service';
import { SensorSchedulerService } from './services/sensor-scheduler-services/sensor-scheduler.service';
import { ThresholdConfigurationService } from './services/threshold-configuration/threshold-configuration.service';
import { MultiplePipesPipe } from './custom-pipes/multiple-pipes.pipe';
import { MiddleEllipsisTemplateComponent } from './components/middle-ellipsis-template/middle-ellipsis-template.component';
import { GatewayService } from '../admin/gateways-info/services/gateway-services/gateway.service';
import { SensorService } from '../admin/sensors-info/services/sensor-service/sensor.service';
import { UploadArtifactComponent } from '../admin/artifact-management/components/upload-artifact/upload-artifact.component';
import { InitiateCampaignComponent } from '../admin/campaign-management/components/initiate-campaign/initiate-campaign.component';
import { CustomMenuComponent } from './components/custom-menu/custom-menu.component';
import { CreateGroupComponent } from '../admin/campaign-management/components/create-group/create-group.component';
import { FilterComponent } from './components/filter-component/filter-component.component';
import { CampaignStatusComponent } from './components/campaign-status/campaign-status.component';
import { AddTagComponent } from '../admin/artifact-management/components/add-tag/add-tag.component';
import { CampaignStatusIndicatorComponent } from './components/campaign-status-indicator/campaign-status-indicator.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { AssetPageViewToggleTemplateComponent } from './components/asset-page-view-toggle/asset-page-view-toggle-template/asset-page-view-toggle-template.component';
import { AssetHierarchyToolTipComponent } from './components/asset-hierarchy-tool-tip/asset-hierarchy-tool-tip.component';

@NgModule({
  declarations: [
    AppAssetShowGraphComponent,
    AppModalDialogComponent,
    AssetBatteryStatusComponent,
    AssetGatewaysComponent,
    AssetPhotosCarouselComponent,
    AssetSensorsComponent,
    AssetSingleStatusComponent,
    AssetStatusBarComponent,
    AssetStatusIndicatorComponent,
    BatteryHealthStatusComponent,
    CellularSignalStrengthComponent,
    CreateGroupComponent,
    CollapsableHeaderComponent,
    ConfirmTemplateComponent,
    ConnectedSensorsTemplateComponent,
    ConnectionStatusTemplateComponent,
    CustomButtonComponent,
    CustomDropdownComponent,
    CustomInputComponent,
    CustomMenuComponent,
    CustomShowHideButtonComponent,
    ErrorTemplateComponent,
    GridTemplateComponent,
    ImageCarouselTemplateComponent,
    ImageEditorTemplateComponent,
    ImageUploaderComponent,
    ImageViewerComponent,
    InitiateCampaignComponent,
    KebabMenuComponent,
    LabelledCustomTableComponent,
    LastCommunicationTemplateComponent,
    LifecycleStateProgressBarComponent,
    MiddleEllipsisTemplateComponent,
    OnlineSignalStatusComponent,
    OrdinalDatePipe,
    RoleAndScopeAssignmentComponent,
    SensorParameterComponent,
    SensorSchedulerComponent,
    SensorTelemetryGraphS7100Component,
    SensorThresholdsComponent,
    SetupStatusComponent,
    SignalBarComponent,
    SignalStatusComponent,
    StepStatusCircleComponent,
    SuccessTemplateComponent,
    TextButtonComponent,
    TreeGridComponent,
    UploadArtifactComponent,
    UsersPreferencesComponent,
    CapitalizePipe,
    NotificationPreferencesComponent,
    MultiplePipesPipe,
    CustomMenuComponent,
    FilterComponent,
    CampaignStatusComponent,
    AddTagComponent,
    CampaignStatusIndicatorComponent,
    AssetPageViewToggleTemplateComponent,
    AssetHierarchyToolTipComponent,
  ],
  imports: [
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonAllModule,
    ButtonModule,
    CarouselModule,
    CheckBoxModule,
    CommonModule,
    ContextMenuModule,
    DatePickerModule,
    DialogModule,
    DropDownListModule,
    DropDownTreeModule,
    DatePickerModule,
    FormsModule,
    FormsModule,
    GridModule,
    ImageEditorModule,
    ListBoxModule,
    MenuModule,
    MultiSelectModule,
    PowerBIEmbedModule,
    ReactiveFormsModule,
    RadioButtonModule,
    SwitchModule,
    TabModule,
    TimePickerModule,
    ToastModule,
    TooltipModule,
    TranslateModule,
    TreeGridModule,
    DateRangePickerModule
  ],
  exports: [
    AccordionModule,
    AppBarModule,
    AppModalDialogComponent,
    AssetBatteryStatusComponent,
    AssetGatewaysComponent,
    AssetPhotosCarouselComponent,
    AssetHierarchyToolTipComponent,
    AssetSensorsComponent,
    AssetSingleStatusComponent,
    AssetStatusBarComponent,
    AssetStatusIndicatorComponent,
    AssetPageViewToggleTemplateComponent,
    AutoCompleteModule,
    BatteryHealthStatusComponent,
    BreadcrumbModule,
    ButtonAllModule,
    ButtonModule,
    CarouselModule,
    CellularSignalStrengthComponent,
    CheckBoxModule,
    CreateGroupComponent,
    CollapsableHeaderComponent,
    ConfirmTemplateComponent,
    ConnectedSensorsTemplateComponent,
    ConnectionStatusTemplateComponent,
    ContextMenuModule,
    CustomButtonComponent,
    CustomDropdownComponent,
    CustomInputComponent,
    CustomMenuComponent,
    CustomShowHideButtonComponent,
    DatePickerModule,
    DashboardLayoutModule,
    DialogModule,
    DropDownListModule,
    DropDownTreeModule,
    DatePickerModule,
    FormsModule,
    FilterComponent,
    GridModule,
    GridTemplateComponent,
    ImageEditorModule,
    ImageUploaderComponent,
    ImageViewerComponent,
    InitiateCampaignComponent,
    KebabMenuComponent,
    LabelledCustomTableComponent,
    LastCommunicationTemplateComponent,
    LifecycleStateProgressBarComponent,
    ListBoxModule,
    ListViewModule,
    MenuModule,
    MiddleEllipsisTemplateComponent,
    MultiSelectModule,
    OnlineSignalStatusComponent,
    OrdinalDatePipe,
    CapitalizePipe,
    QRCodeGeneratorAllModule,
    ReactiveFormsModule,
    RadioButtonModule,
    RoleAndScopeAssignmentComponent,
    SensorParameterComponent,
    SensorSchedulerComponent,
    SensorTelemetryGraphS7100Component,
    SensorThresholdsComponent,
    SidebarModule,
    SignalBarComponent,
    StepStatusCircleComponent,
    SwitchModule,
    TabModule,
    TextBoxModule,
    TextButtonComponent,
    TimePickerModule,
    ToastModule,
    TooltipModule,
    TranslateModule,
    TreeGridComponent,
    TreeGridModule,
    MultiplePipesPipe,
    UploadArtifactComponent,
    AddTagComponent,
    DateRangePickerModule
  ],
  providers: [
    CheckBoxSelectionService,
    FilterService,
    PageService,
    ResizeService,
    SensorParameterService,
    SensorSchedulerService,
    SortService,
    ThresholdConfigurationService,
    ToolbarService,
    GatewayService,
    SensorService,
    DatePipe
  ]
})

export class SharedModule { }
