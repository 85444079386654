import { AdminInfoRoutes, NotificationManagementRoutes } from '../../admin/constants/route.constants';

export class AppRouteConstants {
  public static readonly APP_LOGIN_ROUTE = 'login';
  public static readonly APP_LOGOUT_ROUTE = 'logout';
  public static readonly PAGE_NOT_FOUND = '**';
  public static readonly APP_BASE_HREF = '/';
  public static readonly APP_CUSTOMER_EQUIPMENT_ROUTE = '/assets-info/equipment';
  public static readonly APP_CUSTOMER_AREA_ROUTE = '/assets-info/area';
  public static readonly APP_CUSTOMER_SITE_ROUTE = 'site/site-info';
  public static readonly APP_CUSTOMER_GATEWAY_ROUTE = '/system-info/gateway';
  public static readonly APP_CUSTOMER_SENSOR_ROUTE = '/system-info/sensor';
  public static readonly APP_CUSTOMER_SENSOR_GRAPH_ROUTE = '/system-info/graph';
  public static readonly ROUTE_TO_CUSTOMER = 'customer';
  public static readonly ROUTE_TO_ADMIN = 'admin';
  public static readonly APP_CUSTOMER_UNIT_ROUTE = '/assets-info/unit';
  public static readonly ROUTE_TO_ASSETS_INFO = 'assets-info';
  public static readonly ROUTE_TO_AREA_INFO = 'area/area';
  public static readonly ROUTE_TO_UNIT_INFO = 'unit/unit';
  public static readonly ROUTE_TO_EQUIPMENT_INFO = 'equipment/equipment';
  public static readonly NOTIFICATION_PREFERENCES = 'notification-preferences';
  public static readonly ROUTE_TO_HOME = 'home';
  public static readonly ROUTE_TO_ASSETS_LIST = 'assets/assets-list';
}

export class RouterLinkConstants {
  public static readonly ROUTE_TO_NOTIFICATION_RULES = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${NotificationManagementRoutes.NOTIFICATION_BASE_ROUTE}/${NotificationManagementRoutes.NOTIFICATION_LIST_ROUTE}`;
}

export const SharedRoute = [
  `/${AppRouteConstants.NOTIFICATION_PREFERENCES}`,
];

