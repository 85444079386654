import { ApiResponse } from 'src/app/shared/models/api-response';
import { AssetType } from 'src/app/shared/app-constants/asset-constants';
import { catchError, map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler/error-handler.service';
import { GATEWAY_API_ENDPOINTS } from 'src/app/shared/app-constants/api-constants';
import { Gateway, GatewayStatus, GatewayUpdate, IndustrialProtocolConfigRequest, IndustrialProtocolConfigResponse } from '../../models/gateway';
import { GatewayList } from '../../../../shared/models/asset-list-model/gateway-list';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sensor } from 'src/app/shared/models/sensor';
import { SensorList } from '../../../../shared/models/asset-list-model/sensor-list';
import { CommandResponse } from 'src/app/shared/models/shared.models';

@Injectable()

export class GatewayService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  public getFullySetupGatewayServiceUrl(tenantId: string): string {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_FULLY_SETUP_GATEWAYS_LIST(tenantId);
    return url;
  }

  public getReadyToAssignGatewayServiceUrl(tenantId: string): string {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_READY_TO_ASSIGN_GATEWAYS_LIST(tenantId);
    return url;
  }

  public getReadyToProvisionGatewayServiceUrl(tenantId: string): string {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_READY_TO_PROVISION_GATEWAYS_LIST(tenantId);
    return url;
  }

  public getGatewayListByRootAssetIdServiceUrl(tenantId: string, siteId: string): string {
    return environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_LIST_BY_ROOT_ASSET_ID(tenantId, siteId);
  }

  public getGatewayListServiceUrl(tenantId: string, requiredFields: string): string {
    return environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_ASSET_GATEWAY_LIST(tenantId, requiredFields);
  }

  public getGatewayList(tenantId: string, scope?: string): Observable<GatewayList[]> {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_LIST(tenantId);
    return this.http.get<ApiResponse<GatewayList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public getGatewayListBySite(tenantId: string, siteId: string): Observable<GatewayList[]> {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_LIST_BY_ROOT_ASSET_ID(tenantId, siteId);
    return this.http.get<ApiResponse<GatewayList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public getGatewayDetailsById(gatewaySerialNumber: string, tenantId: string, preventSpinner?: boolean): Observable<Gateway> {
    let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    if (preventSpinner)
      headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Prevent-Spinner': '' }) };
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_DETAILS_ID(tenantId, gatewaySerialNumber);
    return this.http.get<ApiResponse<Gateway>>(url, headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public getGatewayDetails(gatewaySerialNumber: string, tenantId: string): Observable<Gateway> {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_DETAILS(tenantId, gatewaySerialNumber, AssetType.GATEWAY);
    return this.http.get<ApiResponse<Gateway>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public getGatewayStatus(gatewaySerialNumber: string, tenantId: string, preventSpinner?: boolean): Observable<GatewayStatus> {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_STATUS(tenantId, gatewaySerialNumber);
    let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    if (preventSpinner)
      headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Prevent-Spinner': '' }) };
    return this.http.get<ApiResponse<GatewayStatus>>(url, headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public loadAssignedSensor(gatewaySerialNumber: string, tenantId: string, preventSpinner?: boolean): Observable<Sensor> {
    let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    if (preventSpinner)
      headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Prevent-Spinner': '' }) };
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.LOAD_ASSIGNED_SENSOR(gatewaySerialNumber, tenantId);
    return this.http.get<ApiResponse<Sensor>>(url, headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public loadUnAssignedSensor(gatewayId: string, tenantId: string, preventSpinner?: boolean): Observable<SensorList[]> {
    let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    if (preventSpinner)
      headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Prevent-Spinner': '' }) };
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.LOAD_UNASSIGNED_SENSOR(tenantId, gatewayId);
    return this.http.get<ApiResponse<SensorList[]>>(url, headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public onSaveGatewayDetails(saveRequest: GatewayUpdate, gatewayDetails: Gateway, tenantId: string): Observable<Gateway>
  {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.SAVE_GATEWAY_DETAILS(tenantId, gatewayDetails.gatewayId);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = {
      gatewayName: saveRequest.gatewayName ? saveRequest.gatewayName : '',
      timeZone: saveRequest.timeZone,
      firmwareVersion: saveRequest.firmwareVersion,
      isIndustrialProtocolEnabled: saveRequest.isIndustrialProtocolEnabled,
      isLocalDataEnabled: saveRequest?.isLocalDataEnabled,
      selectedIndustrialProtocol: saveRequest?.selectedIndustrialProtocol,
    };
    return this.http.put<ApiResponse<Gateway>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public getGatewayIndustrialProtocolConfiguration(tenantId: string, gatewayId: string, preventSpinner?: boolean): Observable<IndustrialProtocolConfigResponse> {
    let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    if (preventSpinner)
      headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Prevent-Spinner': '' }) };
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_INDUSTRIAL_PROTOCOL_CONFIGURATIONS(tenantId, gatewayId);
    return this.http.get<ApiResponse<IndustrialProtocolConfigResponse>>(url, headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public saveGatewayIndustrialProtocolConfiguration(tenantId: string, gatewayId: string, request: IndustrialProtocolConfigRequest): Observable<string> {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.SAVE_GATEWAY_INDUSTRIAL_PROTOCOL_CONFIGURATIONS(tenantId, gatewayId);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = request;
    return this.http.post<ApiResponse<string>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: any) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public restartGateway(tenantId: string, serialNumber: string): Observable<CommandResponse> {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.RESTART_GATEWAY(tenantId, serialNumber);
    const body = { };
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<ApiResponse<CommandResponse>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }
}
