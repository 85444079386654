import { Pipe, PipeTransform } from '@angular/core';
import { PipeTransformData } from '../models/shared.models';

@Pipe({
  name: 'multiplePipes'
})
export class MultiplePipesPipe implements PipeTransform {
  transform(value: any, pipes: PipeTransformData[]|undefined): any {
    if (pipes) {
      let newValue = value;
      pipes.forEach(pipeData => {
        newValue = pipeData.pipe.transform(newValue, pipeData.args);
      });
      return newValue;
    }
    return value;
  }
}

