<button *ngIf="buttonData.type === 'e-primary-basic'
  || buttonData.type === 'e-primary-border'
  || buttonData.type === 'e-primary-no-border'
  || buttonData.type === 'e-primary-no-border-underline'
  || buttonData.type === 'e-danger-no-border'
  || buttonData.type === 'e-secondary-basic'
  || buttonData.type === 'e-primary-tertiary-basic'
  || buttonData.type === 'e-secondary-tertiary-basic'
  || buttonData.type === 'e-primary-danger-basic'
  || buttonData.type === 'e-secondary-danger-basic'
  || buttonData.type === 'e-brand-green-basic'
  || buttonData.type === 'e-neutral-no-border'"
  type="button"
  [style.width] = 'buttonData.width'
  [disabled]="isDisabled$ | async" [name]="buttonData.name" (click)="btnClick()" ejs-button
  [ngClass]="{'e-primary-basic': buttonData.type === 'e-primary-basic',
  'e-primary-border': buttonData.type === 'e-primary-border',
  'e-primary-no-border': buttonData.type === 'e-primary-no-border',
  'e-primary-no-border-underline': buttonData.type === 'e-primary-no-border-underline',
  'e-danger-no-border': buttonData.type === 'e-danger-no-border',
  'e-secondary-basic': buttonData.type === 'e-secondary-basic',
  'e-primary-tertiary-basic': buttonData.type === 'e-primary-tertiary-basic',
  'e-secondary-tertiary-basic': buttonData.type === 'e-secondary-tertiary-basic',
  'e-primary-danger-basic': buttonData.type === 'e-primary-danger-basic',
  'e-secondary-danger-basic': buttonData.type === 'e-secondary-danger-basic',
  'e-brand-green-basic': buttonData.type === 'e-brand-green-basic',
  'e-neutral-no-border': buttonData.type === 'e-neutral-no-border',
  'button-sm': buttonData.size === 'button-sm',
  'button-md': buttonData.size === 'button-md',
  'button-lg': buttonData.size === 'button-lg'}">
  <ng-template *ngIf="templatePosition === 'left' then template" />
  {{ buttonData.label }}
  <ng-template *ngIf="templatePosition === 'right' then template" />
</button>
