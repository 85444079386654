import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { OpenModalDialogData } from '../../models/shared.models';
import { ModalDialogService } from '../../services/modal-dialog-service/modal-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './app-modal-dialog.component.html',
  styleUrls: ['./app-modal-dialog.component.scss']
})
export class AppModalDialogComponent implements OnInit, OnDestroy {
  @ViewChild('ejDialog') ejDialog!: DialogComponent;
  modalDialogData: OpenModalDialogData = new OpenModalDialogData();
  visible: boolean = false; // Default visibility of modal
  public targetElement: HTMLElement = document.body;
  private subscriptions = new Subscription(); // Hold the subscriptions
  constructor(
    private modalDialogService: ModalDialogService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.modalDialogService.openModalObservable().subscribe((res: OpenModalDialogData) => {
        this.modalDialogData = res;
        this.changeDetector.detectChanges();
        this.openDialog();
      })
    );
    this.subscriptions.add(
      this.modalDialogService.closeModalObservable().subscribe(_ => {
        this.hideDialog();
      })
    );
  }

  closed() {
    this.resetDialogData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private openDialog(): void {
    this.ejDialog.show();
  }

  private hideDialog(): void {
    this.ejDialog.hide();
  }

  private resetDialogData() {
    this.modalDialogData = new OpenModalDialogData();
  }
}

