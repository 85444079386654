import { DeviceLifeCycle } from 'src/app/shared/app-constants/asset-constants';

export const ACCOUNT_API_ENDPOINTS = {
  GET_ACCOUNT_DETAIL: (tenantId: string, accountId: string) => `/tenants/${tenantId}/account/${accountId}/`,
  GET_PERCEPTIV_ACCOUNTS: (tenantId: string, queryString: string, pageSize: number, offset: number) => `/tenants/${tenantId}/account/${pageSize}/${offset}?searchText=${queryString}`,
};

export const AREA_API_ENDPOINTS = {
  GET_AREA_LIST: (tenantId: string, siteId: string) => `/tenants/${tenantId}/area/${siteId}/list`,
  GET_AREA_DETAILS: (tenantId: string, areaId: string) => `/tenants/${tenantId}/area/${areaId}`,
  SAVE_AREA_DETAILS: (tenantId: string) => `/tenants/${tenantId}/area`,
  PATCH_AREA_DETAILS: (tenantId: string, areaId: string) => `/tenants/${tenantId}/area/${areaId}`
};

export const ASSETS_TREE_API_ENDPOINTS = {
  GET_ASSETS_TREE: (tenantId: string, assetId: string, objectTypes?:string) => `/tenants/${tenantId}/asset/${assetId}/tree?includeObjectType=${objectTypes ?? ''}`,
  GET_ALL_ASSETS_TREE: (tenantId: string, objectTypes?:string) => `/tenants/${tenantId}/assets/tree?includeObjectType=${objectTypes ?? ''}`,
  GET_SEARCH_ASSETS: (tenantId: string, search: string) => `/tenants/${tenantId}/assets/search?search=${search}`,
  //GET_ENTERPRISE_TREE: (tenantId: string) => `/tenants/${tenantId}/assets/enterprise/tree`
  GET_ENTERPRISE_TREE: (tenantId: string) => `/tenants/${tenantId}/enterprise/site/hierarchy`
};

export const ENTERPRISE_API_ENDPOINTS = {
  GET_ENTERPRISE_LIST: (tenantId: string) => `/tenants/${tenantId}/enterprise`,
  GET_ENTERPRISE_LIST_TOBEDELETED: (tenantId: string, top:number, skip:number) => `/tenants/${tenantId}/enterprise?$top=${top}&$skip=${skip}`,
  GET_ENTERPRISE_DETAILS: (tenantId: string, enterpriseId: string) => `/tenants/${tenantId}/enterprise/${enterpriseId}`,
  SAVE_ENTERPRISE_DETAILS: (tenantId: string) => `/tenants/${tenantId}/enterprise`,
  PATCH_ENTERPRISE_DETAILS: (tenantId: string, enterpriseId: string) => `/tenants/${tenantId}/enterprise/${enterpriseId}`
};

export const EQUIPMENT_API_ENDPOINTS = {
  GET_EQUIPMENT_LIST: (tenantId: string, unitId: string) => `/tenants/${tenantId}/equipment/${unitId}/list`,
  GET_EQUIPMENT_DETAILS: (tenantId: string, equipmentId: string) => `/tenants/${tenantId}/equipment/${equipmentId}`,
  SAVE_EQUIPMENT_DETAILS: (tenantId: string) => `/tenants/${tenantId}/equipment`,
  PATCH_EQUIPMENT_DETAILS: (tenantId: string, equipmentId: string) => `/tenants/${tenantId}/equipment/${equipmentId}`,
  GET_BRANDS_LIST: (tenantId: string, brandName: string) => `/${tenantId}/product/brand/${brandName}`,
  GET_PRODUCT: (tenantId: string, partId: string, brand: string) => `/${tenantId}/product?partid=${partId}&brand=${brand}`,
  CREATE_PRODUCT: (tenantId: string) => `/${tenantId}/product/create`,
  GET_PRODUCT_RELATED_DATA: (tenantId: string, partId: string, customerType: number) => `/${tenantId}/product/related?partid=${partId}&customerType=${customerType}`,
  EQUIPMENT_PARAMS: (tenantId: string) => `/tenants/${tenantId}/equipment/parameters`
};

export const GATEWAY_API_ENDPOINTS = {
  GET_FULLY_SETUP_GATEWAYS_LIST: (tenantId: string) => `/tenants/${tenantId}/fullysetupgateways`,
  GET_GATEWAY_DETAILS_ID: (tenantId: string, gatewayId: string) => `/tenants/${tenantId}/gateway/${gatewayId}`,
  GET_GATEWAY_DETAILS: (tenantId: string, gatewaySerialNumber: string, assetType: string) => `/tenants/${tenantId}/gatewaybyserialnumber/${gatewaySerialNumber}`,
  GET_ASSET_GATEWAY_LIST: (tenantId: string, requiredFields: string) => `/tenants/${tenantId}/gateway/list?requiredFields=${requiredFields}`,
  GET_GATEWAY_LIST: (tenantId: string) => `/tenants/${tenantId}/gateway/listview`,
  GET_GATEWAY_LIST_BY_ROOT_ASSET_ID: (tenantId: string, rootAssetId: string) => `/tenants/${tenantId}/gateway/${rootAssetId}/list`,
  GET_READY_TO_ASSIGN_GATEWAYS_LIST: (tenantId: string) => `/tenants/${tenantId}/readytoassign`,
  GET_READY_TO_PROVISION_GATEWAYS_LIST: (tenantId: string) => `/tenants/${tenantId}/readytoprovision`,
  LOAD_ASSIGNED_SENSOR: (serialNumber: string, tenantId: string) => `/tenants/${tenantId}/gateway/${serialNumber}/sensors/${DeviceLifeCycle.ASSIGNED_SENSOR}`,
  LOAD_UNASSIGNED_SENSOR: (tenantId: string, gatewayId: string) => `/tenants/${tenantId}/gateways/${gatewayId}`,
  SAVE_GATEWAY_DETAILS: (tenantId: string, gatewayId: string) => `/tenants/${tenantId}/gateway/${gatewayId}/save`,
  GET_GATEWAY_INDUSTRIAL_PROTOCOL_CONFIGURATIONS: (tenantId: string, gatewayId: string) => `/tenants/${tenantId}/gateway/ipconfig/${gatewayId}`,
  SAVE_GATEWAY_INDUSTRIAL_PROTOCOL_CONFIGURATIONS: (tenantId: string, gatewayId: string) => `/tenants/${tenantId}/gateway/ipconfig/${gatewayId}`,
  GET_GATEWAY_STATUS: (tenantId: string, gatewaySerialNumber: string) => `/tenants/${tenantId}/gateway/${gatewaySerialNumber}/onlinestatus`,
  RESTART_GATEWAY: (tenantId: string, gatewaySerialNumber: string) => `/tenants/${tenantId}/gateway/${gatewaySerialNumber}/restart`
};

export const IMAGE_API_ENDPOINTS_DASHBOARD = {
  GET_IMAGE_SAS_URI: (tenantId: string) => `/tenants/${tenantId}/image`,
  GET_IMAGES_FOR_AN_ASSET: (tenantId: string, assetId: string, thumbnail: boolean) => `/tenants/${tenantId}/image/${assetId}?thumbnail=${String(thumbnail)}`,
  DELETE_IMAGE: (tenantId: string, imageId: string) => `/tenants/${tenantId}/image/${imageId}`,
};

export const LINK_API_ENDPOINTS = {
  POST_LINK: (tenantId: string) => `/tenants/${tenantId}/link`,
  POST_LINK_BY_SERIAL_NUMBER: (tenantId: string) => `/tenants/${tenantId}/linkBySerialNumber`,
  POST_UNLINK: (tenantId: string) => `/tenants/${tenantId}/unlink`,
  POST_UNLINK_BY_SERIAL_NUMBER: (tenantId: string) => `/tenants/${tenantId}/unlinkBySerialNumber`,
  POST_FORCE_UNLINK: (tenantId: string) => `/tenants/${tenantId}/forceunlink`,
};

export const NOTIFICATION_API_ENDPOINTS = {
  CREATE_TIMER_BASED_NOTIFICATION: (tenantId: string) => `/tenants/${tenantId}/notification`,
  GET_NOTIFICATION_BY_RULE_ID: (tenantId: string, notificationRuleId: string) => `/tenants/${tenantId}/notification/${notificationRuleId}`,
  UPDATE_NOTIFICATION_RULE_DETAIL: (tenantId: string, notificationRuleId: string) => `/tenants/${tenantId}/notification/${notificationRuleId}`,
  GET_NOTIFICATIONS_BY_USER: (tenantId: string) => `/tenants/${tenantId}/notification/user`,
  GET_NOTIFICATIONS_LIST: (tenantId: string) => `/tenants/${tenantId}/notification/list`,
  UPDATE_NOTIFICATIONS_STATUS: (tenantId: string) => `/tenants/${tenantId}/notification`,
};
export const ROUTE_TO_DEFAULT_PAGE = '';
export const SENSOR_API_ENDPOINTS = {
  GATEWAY_DISASSOCIATE: (tenantId: string, gatewayId: string, sensorSerialNumber: string) => `/tenants/${tenantId}/gateway/${gatewayId}/disassociate/${sensorSerialNumber}`,
  GET_FULLY_SETUP_SENSOR_LIST: (tenantId: string) => `/tenants/${tenantId}/sensors/fullysetupsensor`,
  GET_MANY_SENSOR_LIVE_TELEMETRY_DATA: (tenantId: string) => `/telemetry/${tenantId}`,
  GET_READY_TO_ASSIGN_SENSOR_LIST: (tenantId: string) => `/tenants/${tenantId}/sensors/readytoassign`,
  GET_READY_TO_COMMISSION_SENSOR_LIST: (tenantId: string) => `/tenants/${tenantId}/sensors/readytocommission`,
  GET_SENSOR_DETAILS_ID: (tenantId: string, id: string) => `/tenants/${tenantId}/sensors/${id}`,
  GET_SENSOR_DETAILS: (tenantId: string, sensorSerialNumber: string, assetType: string) => `/tenants/${tenantId}/sensorbyserialnumber/${sensorSerialNumber}`,
  GET_ASSET_SENSOR_LIST: (tenantId: string, requiredFields: string) => `/tenants/${tenantId}/sensors/list?requiredFields=${requiredFields}`,
  GET_SENSOR_LIST: (tenantId: string) => `/tenants/${tenantId}/sensors/listview`,
  GET_SENSOR_LIST_BY_ROOT_ASSET_ID: (tenantId: string, rootAssetId: string) => `/tenants/${tenantId}/sensors/${rootAssetId}/list`,
  GET_SENSOR_LIVE_TELEMETRY_DATA: (tenantId: string, macAddress: string) => `/telemetry/${macAddress}`,
  GET_SENSOR_PARAMETER: (tenantId: string, sensorId: string) => `/tenants/${tenantId}/sensors/${sensorId}/parameters`,
  SAVE_SENSOR_DETAILS: (tenantId: string, sensorId: string) => `/tenants/${tenantId}/sensors/${sensorId}`,
  SAVE_SENSOR_MEASUREMENT_SCHEDULER: (tenantId: string, sensorId: string) => `/tenants/${tenantId}/sensors/${sensorId}/schedule`,
  SAVE_SENSOR_PARAMETER: (tenantId: string, sensorId: string) => `/tenants/${tenantId}/sensors/${sensorId}/parameters`,
  GET_SENSOR_EVENTS: (tenantId: string) => `/tenants/${tenantId}/sensorevents`,
  GET_SENSOR_DEFAULT_PARAMETER: (tenantId: string) => `/tenants/${tenantId}/sensors/defaultparameters`,
  GET_SENSOR_DEFAULT_SCHEDULE: (tenantId: string) => `/tenants/${tenantId}/sensors/defaultschedule`,
};

export const SITE_API_ENDPOINTS = {
  GET_SITE_LIST: (tenantId: string, enterpriseId: string) => `/tenants/${tenantId}/site/${enterpriseId}/list`,
  SAVE_SITE_DETAILS: (tenantId: string) => `/tenants/${tenantId}/site`,
  GET_SITE_DETAILS: (tenantId: string, siteId: string) => `/tenants/${tenantId}/site/${siteId}`,
  PATCH_SITE_DETAILS: (tenantId: string, siteId: string) => `/tenants/${tenantId}/site/${siteId}`
};

export const SYSTEM_INFO_API_ENDPOINTS = {
  GET_SENSOR_LIST_BY_GATEWAY_ID: (tenantId: string, gatewayId: string) => `/tenants/${tenantId}/gateways/${gatewayId}`,
  GET_GATEWAY_DETAILS_BY_GATEWAY_ID: (tenantId: string, gatewayId: string) => GATEWAY_API_ENDPOINTS.GET_GATEWAY_DETAILS_ID(tenantId, gatewayId)
};

export const TENANT_API_ENDPOINTS = {
  GET_TENANT_LIST: (pageSize: number, pageNo: number, sortBy: string) => `/tenants?pageSize=${pageSize}&pageNo=${pageNo}&sortBy=${sortBy}`
};

export const TENANTS_API_ENDPOINTS_DASHBOARD = {
  GET_TENANTS: '/tenants',
};

export const THRESHOLD_API_ENDPOINTS = {
  THRESHOLD_CONFIG_BY_PROFILE: (tenantId: string, sensorType: string, profileKey: string) => `/tenants/${tenantId}/sensortypes/${sensorType}/thresoholdprofile/${profileKey}`,
  THRESHOLD_CONFIG: (tenantId: string, sensorType: string, sensorSerialNumber: string) => `/tenants/${tenantId}/sensortypes/${sensorType}/sensors/${sensorSerialNumber}/thresholdconfiguration`,
  THRESHOLD_PROFILE_DEFAULT_DATA: (tenantId: string, sensorType: string, sensorSerialNumber: string, profileKey: string, sensorId: string) => `/tenants/${tenantId}/sensortypes/${sensorType}/sensors/${sensorSerialNumber}/updatewithdefaultthresoholdprofile/${profileKey}/${sensorId}`,
};

export const UNIT_API_ENDPOINTS = {
  GET_UNIT_LIST: (tenantId: string, areaId: string) => `/tenants/${tenantId}/unit/${areaId}/list`,
  GET_UNIT_DETAILS: (tenantId: string, unitId: string) => `/tenants/${tenantId}/unit/${unitId}`,
  SAVE_UNIT_DETAILS: (tenantId: string) => `/tenants/${tenantId}/unit`,
  PATCH_UNIT_DETAILS: (tenantId: string, unitId: string) => `/tenants/${tenantId}/unit/${unitId}`
};

export const USER_API_ENDPOINTS = {
  ROLE: '/role',
  ROLES: '/Users/roles',
  PERMISSIONS: '/permissions',
  ROLEASSIGNMENTS: (tenantId: string, userId: string) => `/tenants/${tenantId}/users/${userId}/roleassignment`,
  USERROLEASSIGNMENTS: '/{tenantId}/roleassignment/user',
  DELETEUSER: '/{tenantId}/user/{id}',
  CREATEUSER: '/{tenantId}/user',
  UPDATE_USER_DETAILS: (tenantId: string) => `/tenants/${tenantId}/user`,
  GETUSERByID: '/{tenantId}/user/{id}',
  GETUSERBYTOKEN: '/userfromtoken',
  GETUSERS: '/{tenantId}/users',
  CREATEROLEASSIGNMENT: '/{tenantId}/roleassignment/user/{userId}',
  DELETEROLEASSIGNMENT: '/{tenantId}/roleassignment/user/{userId}',
  GET_USER_LIST: (tenantId: string, sortBy: string, pageNumber: string, pageSize: string) => `/tenants/${tenantId}/users?sortBy=${sortBy}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  GET_USER_LIST_TOBEDELETED: (tenantId: string, top:number, skip:number) => `/tenants/${tenantId}/users?$top=${top}&$skip=${skip}`,
  GET_USERS_BY_SCOPE: '/tenants/{tenantId}/users/{scope}',
  GET_USER_DETAILS_BY_OKTAID:  (tenantId: string, oktaId: string) => `/tenants/${tenantId}/users/details/${oktaId}`,
  CREATE_ROLE: '/roles',
  GET_USERS_BY_SEARCH_QUERY: (tenantId: string, searchQuery: string, top:number, skip:number) => `/${tenantId}/usersearch?top=${top}&skip=${skip}&searchText=${searchQuery}`,
  GET_USERS_BY_SITE_SCOPE: (tenantId: string, scopeIdOfHierarchy: string) => `/${tenantId}/users?$filter=scope=${scopeIdOfHierarchy}`,
  GET_USERS_ABSOLUTE_COUNT: (tenantId: string) => `/${tenantId}/users/count`
};

export const USER_API_ENDPOINTS_DASHBOARD = {
  GET_USER_LIST: (tenantId: string) => `/tenants/${tenantId}/users`,
  GET_USER_LIST_BY_SCOPE: (tenantId: string) => `/${tenantId}/users`,
  USER_ROLE_ASSIGNMENTS: (tenantId: string) => `/tenants/${tenantId}/user/roleassignment`,
  CREATE_USER: (tenantId: string) => `/tenants/${tenantId}/user`,
  CREATE_ROLE_ASSIGNMENT: (tenantId: string) => `/tenants/${tenantId}/users/roleassignment`,
  DELETE_ROLE_ASSIGNMENT: (tenantId: string) => `/tenants/${tenantId}/users/roleassignment`,
  GET_USER_DETAILS: (tenantId: string, oktaId: string) => `/tenants/${tenantId}/users/details/${oktaId}`,
  UPDATE_USER_DETAILS: (tenantId: string) => `/tenants/${tenantId}/user`,
  USER_ROLES: '/users/roles',
  GET_PERMISSIONS: '/users/permissions',
  GET_ROLE_BY_ROLEID: (roleId: string) => `/role/${roleId}`,
  CREATE_GLOBAL_USER: '/user',
  GET_GLOBAL_USER: (oktaId: string) => `/user/${oktaId}`,
  GET_ROLES_BY_ROLETYPE: (roleType: string) => `/users/roles?roletype=${roleType}`,
  GET_USERS_FOR_SCOPE: '/users/forscope'
};

export const ASSETS_API_ENDPOINTS = {
  GET_ASSETS: (tenantId: string, assetId: string, includeObjectType: string, objectType?: string) => `/tenants/${tenantId}/assets/${assetId}/list?includeObjectType=${includeObjectType ?? ''}${objectType ? '&objectType=' + objectType : ''}`,
  GET_ASSETS_BY_TYPE: (tenantId: string, assetId: string, objectType:string) => `/tenants/${tenantId}/assets/${assetId}/list?objectType=${objectType ?? ''}`,
  GET_ASSETS_BY_ID: (tenantId: string, assetId:string) => `/tenants/${tenantId}/asset/${assetId}`,
  GET_ASSETS_BY_IDS: (tenantId: string, objectType:string) => `/tenants/${tenantId}/assets/list?objectType=${objectType ?? ''}`,
  GET_ASSET_BREADCRUMB: (tenantId: string, assetId:string) => `/tenants/${tenantId}/assets/${assetId}/breadcrumb`
};

export const ASSETCLASS_METADATA_API_ENDPOINTS = {
  GET_ASSET_METADATA: (tenantId: string, ObjectType: string, ObjectClass: string) => `/tenants/${tenantId}/assetclassmetadata?ObjectType=${ObjectType}&ObjectClass=${ObjectClass}`,
};

export const COMMAND_API_ENDPOINTS = {
  INVOKE: (tenantId: string) => `/tenants/${tenantId}/command/invoke`
};

export const EVENTS_API_ENDPOINTS = {
  GET_EVENTS: (tenantId: string) => `/tenants/${tenantId}/events`,
  GET_EVENT_PARAMETERS: (tenantId: string, eventId: string) => `/tenants/${tenantId}/events/${eventId}/parameters`
};

export const ARTIFACT_MANAGEMENT_API_ENDPOINTS = {
  GET_ARTIFACTS: (tenantId: string, type: string) => `/tenants/${tenantId}/artifacts/${type}`,
  GET_ALL_ARTIFACTS: (tenantId: string, code:string, includeRevoked:boolean, type: string) => `/${tenantId}/ota/v1/artifacts?code=${code}&includeRevoked=${String(includeRevoked)}&type=${type}`,
  GET_ARTIFACT_DEATILS_BY_ID: (tenantId: string, artifactId: string, code:string) => `/${tenantId}/ota/v1/artifacts/${artifactId}?code=${code}`,
  UPLOAD_ARTIFACT: (tenantId: string, code:string) => `/${tenantId}/ota/v1/artifacts?code=${code}`,
  GET_CONFIG_VERSION: (tenantId: string, code:string) => `/${tenantId}/ota/v1/asset/meta?assetType=gateway&code=${code}`,
  GET_MODULES: (tenantId: string, code:string) => `/${tenantId}/ota/v1/acr/module?code=${code}`,
  GET_TAGS: (tenantId: string, code:string, selectedModule:string) => `/${tenantId}/ota/v1/acr/module/tags?code=${code}&moduleName=${selectedModule}`,
  DELETE_ARTIFACT: (tenantId: string, artifactId: string, code:string) => `/${tenantId}/ota/v1/artifacts/${artifactId}?code=${code}`,
  REVOKE_ARTIFACT: (tenantId: string, artifactType: string, artifactId: string) => `/tenants/${tenantId}/enterprises/${artifactType}/${artifactId}/revoke`,
  PATCH_ARTIFACT_DETAILS: (tenantId: string, artifactId: string, code: string) => `/${tenantId}/ota/v1/artifacts?code=${code}&artifactId=${artifactId}`,
};

export const CAMPAIGN_MANAGEMENT_API_ENDPOINTS = {
  GET_CAMPAIGNS: (tenantId: string, code:string) => `/${tenantId}/ota/v1/campaign?code=${code}`,
  GET_CAMPAIGN_BY_ID: (tenantId: string, campaignId: string, code:string) => `/${tenantId}/ota/v1/campaign/${campaignId}?code=${code}`,
  CREATE_CAMPAIGN: (tenantId: string, code:string) => `/${tenantId}/ota/v1/campaign?code=${code}`,
  GET_GROUPS: (tenantId: string, code:string, groupName?:string) => `/${tenantId}/ota/v1/groups?code=${code}`,
  CREATE_GROUP: (tenantId: string, code:string) => `/${tenantId}/ota/v1/groups?code=${code}`,
  DELETE_GROUP: (tenantId: string, groupId: string, code:string) => `/${tenantId}/ota/v1/groups/${groupId}?code=${code}`,
  GET_GROUP_BY_ID: (tenantId: string, code:string, groupId: string) => `/${tenantId}/ota/v1/groups/${groupId}?code=${code}`,
  GET_OPERATIONS: (tenantId: string, campaignId:string, code:string) => `/${tenantId}/ota/v1/campaign/${campaignId}/operations?code=${code}`,
  GET_CAMPAIGN_BY_GROUP_ID: (tenantId: string, groupId: string, code:string) => `/${tenantId}/ota/v1/campaign/group/${groupId}?code=${code}`,
  CAMPAIGN_ACTIONS: (tenantId: string, campaignId: string, code:string) => `/${tenantId}/v1/campaign/${campaignId}/actions?code=${code}`,
  UPDATE_GROUP: (tenantId: string,  groupId: string, code:string) => `/${tenantId}/ota/v1/groups/${groupId}?code=${code}`,
  ROLLBACK_CAMPAIGN: (tenantId: string, campaignId: string) => `/tenants/${tenantId}/campaigns/${campaignId}/rollback`,
  GET_ASSETS: (tenantId: string, code:string) => `/${tenantId}/ota/v1/assets?code=${code}`
};
