import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';

@Injectable()
export class XmlHttpRequestInterceptor {
  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  ) {
    this.interceptFetchCalls();
    const func = (XHR: any): any => {
      const that = this;
      let senderUrl: string;
      const open = XHR.prototype.open;
      XHR.prototype.open = function(method: string, url: string, async: boolean, user: string, pass: string): void {
        senderUrl = url;
        // eslint-disable-next-line prefer-rest-params
        open.apply(this, arguments);
        if (that.checkIfNeedAuthentication(senderUrl)) {
          const accessToken: string = that.oktaAuth.getAccessToken() ?? '';
          const request = <XMLHttpRequest> this;
          request.setRequestHeader(
            'Authorization',
            `Bearer ${accessToken}`
          );
        }
      };
    };
    func.apply(this, [XMLHttpRequest]);
  }

  interceptFetchCalls() {
    const that = this;
    const originalFetch = window?.fetch;
    window.fetch = new Proxy(originalFetch, {
      apply: function(target, thisArg, args) {
        // eslint-disable-next-line
        let [resource, options] = args;
        options = options || {};
        options.headers = options?.headers || {};

        if (that.checkIfNeedAuthentication(typeof resource === 'string' ? resource : resource.url as string)) {
          const accessToken = that.oktaAuth.getAccessToken() ?? '';
          if (options?.headers instanceof Headers) {
            options.headers.append('Authorization', `Bearer ${accessToken}`);
          }
          else if (Array.isArray(options.headers)) {
            options.headers.push(['Authorization', `Bearer ${accessToken}`]);
          }
          else {
            // eslint-disable-next-line
            options.headers['Authorization'] = `Bearer ${accessToken}`;
          }
        }
        // eslint-disable-next-line
        return target.apply(thisArg, args as any);
      }
    });
  }

  checkIfNeedAuthentication(senderUrl:string):boolean {
    if (senderUrl.startsWith(environment.dashboardApiBaseUrl)) {
      return true;
    }
    return false;
  }
}
