import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoCompleteComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EventArgs, SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { TooltipComponent } from '@syncfusion/ej2-angular-popups';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { AdminInfoRoutes, AreasInfoRoutes, EnterpriseInfoRoutes, EquipmentsInfoRoutes, GatewayInfoRoutes, SensorInfoRoutes, SiteInfoRoutes, SystemInfoRoutes, UnitsInfoRoutes } from 'src/app/admin/constants/route.constants';
import { DashboardInfoRoutes } from 'src/app/customer/dashboard-info/constants/route.constants';
import { AppRouteConstants } from 'src/app/shared/app-constants/app-route-constants';
import { AssetType, Scope } from 'src/app/shared/app-constants/asset-constants';
import { AppMessages } from 'src/app/shared/app-constants/events-constants';
import { ModalTemplateTypes } from 'src/app/shared/app-constants/shared.enum';
import { Asset } from 'src/app/shared/models/asset';
import { AssetSearch, GlobalSearchNav } from 'src/app/shared/models/global-search';
import { LoginState } from 'src/app/shared/models/login-state';
import { OpenModalDialogData } from 'src/app/shared/models/shared.models';
import { AssetListService } from 'src/app/shared/services/asset-list-services/asset-list.service';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { UtilitiesService } from 'src/app/shared/services/utlities-services/utilities.service';
import { UserDetails } from '../../admin/user-management/models/user-details';
import { PREFIX_URI_IN_SCANNED_VALUE } from 'src/app/shared/app-constants/shared.constants';
import { ToastService } from 'src/app/shared/services/toast-services/toast.service';
import { ToastComponent, ToastModel } from '@syncfusion/ej2-angular-notifications';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() enterpriseId!: string;
  @Input() authenticated = false;
  @ViewChild('toast') toast?: ToastComponent;
  @ViewChild('autocomplete') autocomplete?: AutoCompleteComponent;
  @ViewChild('sidebar') sidebar?: SidebarComponent; // Reference to side navbar
  @ViewChild('tooltip') toolTipControl?: TooltipComponent;
  itemTemplate: string = '<span><span class=\'name\'>${label} (${objectType})</span></span>';
  placeholder = 'Search by name or serial number...';
  searchData: Asset[] = [];
  searchField: Object = { text: 'label', value: 'id' };
  userDetails!: UserDetails;
  userScope!: string;
  globalSearchText: string = '';
  toastTarget = '.body';

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.setDynamicVariables();
    if (this.sidebar?.isOpen) {
      this.handleSideBarCustomImplementation();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    if (event?.target?.className !== 'flyoutContent' && event?.target?.className !== 'profile-sidebar' && event?.target?.id !== 'tooltipFocus' && event?.target?.parentElement?.id !== 'tooltipFocus'
      && event?.target?.parentElement?.id !== 'profile-pop-up-svg-g' && event?.target?.parentElement?.id !== 'profile-pop-up-svg') {
      this.closeToolTip();
    }
  }

  @HostListener('document:scroll', ['$event'])
  onScroll(event: Event): void {
    this.autocomplete?.hidePopup(); //This is done so as to forcefully close the opened search popup
  }

  position: string = 'Right'; //Navbar position
  showBackdrop = true; // Enables the backdrop when navbar is opened
  skipLinkPath!: string;
  type: string = 'Over'; // Navbar opening style
  isAdminLogin = false;
  isLoggedIn = false;
  showAdminButton: boolean = false; // True= show dashboard button, False= show admin button

  private subscriptions = new Subscription();
  private searchSubject = new Subject<any>();

  // gets the width for the sidebar
  get width() {
    const screenBreak = this.getScreenBreaks();
    if (screenBreak === ScreenBreak.Small) {
      return '100vw';
    }
    else if (screenBreak === ScreenBreak.Medium) {
      return '50vw';
    }
    else {
      return '30vw';
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private assetListService: AssetListService,
    private router: Router,
    private sessionService: SessionService,
    private utilityService: UtilitiesService,
    private modalDialogService: ModalDialogService,
    private toastService: ToastService,
  ) {
    this.searchSubject.pipe(debounceTime(500)).subscribe(response => {
      this.globalSearch(response);
    });
  }

  ngAfterViewInit(): void {
    this.setDynamicVariables();
  }

  ngOnInit() {
    this.closeNavBtnClick();
    if (!this.enterpriseId) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.enterpriseId = params['enterpriseId'];
        this.userScope = params['s'] ? decodeURIComponent(params['s'] as string) : '';
      });
    }
    this.subscriptions.add(this.sessionService.loginState$.subscribe((values: LoginState) => {
      this.isLoggedIn = values.loginStatus;
      this.isAdminLogin = values.isAdminRole;
      if (this.isLoggedIn && this.isAdminLogin) {
        this.showAdminButton = !values.isAdminPage;
      }
    }));
    this.subscriptions.add(this.toastService.toastEvent$.subscribe((data: ToastModel) => {
      if (this.sidebar?.isOpen) return;
      this.toast?.show(data);
    }));
  }

  // When side bar has to be opened
  openSideNav(e: MouseEvent | KeyboardEvent) {
    this.sidebar?.toggle();
    e.stopPropagation();
  }

  // Event raised when side nav bar close
  navClosed(e: EventArgs) {
    this.toggleBodyScroll(true);
  }

  // Event raised when side nav bar opens
  navOpened(e: EventArgs) {
    setTimeout(() => {
      this.handleSideBarCustomImplementation();
      this.toggleBodyScroll(false);
      this.toast?.hide('All');
    });
  }

  // Handles the  custom changes done to side bar
  handleSideBarCustomImplementation() {
    const navBarComp = this; //Reference to current comp
    const element: any = document.querySelector('.e-sidebar-overlay');
    element.style.top = this.getHeaderHeight() + 'px';
    document.documentElement.style.setProperty('--header-container-height', this.getHeaderHeight() + 'px');
    const screenBreak = this.getScreenBreaks();
    if (screenBreak === ScreenBreak.Small) {
      element.style.width = '0vw';
    }
    else if (screenBreak === ScreenBreak.Medium) {
      element.style.width = '50vw';
    }
    else {
      element.style.width = '70vw';
    }
    element.addEventListener('click', () => {
      navBarComp.closeNavBtnClick();
    });
  }

  getHeaderHeight() {
    const element: any = document.getElementById('header-container'); // Replace 'yourElementId' with the actual ID of the element
    return element ? element.offsetHeight : 0;
  }

  // Get dynamic height of full footer
  getCombinedFooterHeight() {
    const element: any = document.getElementById('combined-footer');
    return element ? element.offsetHeight : 0;
  }

  // Get dynamic height of full header along with breadcrumbs
  getCombinedHeaderHeight() {
    const element: any = document.getElementById('combined-header');
    return element ? element.offsetHeight : 0;
  }

  // Set dynamic variables used in css files
  setDynamicVariables() {
    document.documentElement.style.setProperty('--combined-header-container-height', this.getCombinedHeaderHeight() + 'px');
    document.documentElement.style.setProperty('--combined-footer-container-height', this.getCombinedFooterHeight() + 'px');
  }

  // When side nav bar needs to be closed
  closeNavBtnClick() {
    this.sidebar?.hide();
  }

  // Event when top nav bar is clicked
  handleNavClick(e: MouseEvent | KeyboardEvent) {
    this.closeNavBtnClick();
  }

  // Returns a screen breaks
  getScreenBreaks(): ScreenBreak {
    const mediaQuerySmall = '(max-width: 768px)';
    const mediaQueryMedium = '(min-width: 768px) and (max-width: 1024px)';
    const mediaQuerySmallList = window.matchMedia(mediaQuerySmall);
    const mediaQueryMediumList = window.matchMedia(mediaQueryMedium);
    if (mediaQuerySmallList.matches) {
      return ScreenBreak.Small;
    }
    else if (mediaQueryMediumList.matches) {
      return ScreenBreak.Medium;
    }
    else {
      return ScreenBreak.Large;
    }
  }

  changeDashboardType() {
    if (this.showAdminButton) {
      this.router.navigate([`/${AdminInfoRoutes.BASE_ADMIN_ROUTE}`]);
    }
    else
      if (!this.utilityService.isNullOrEmptyOrWhitespace(this.sessionService.userScope)) {
        const url = `/${AppRouteConstants.ROUTE_TO_CUSTOMER}/${DashboardInfoRoutes.BASE_DASHBOARD_ROUTE}/${DashboardInfoRoutes.DASHBOARD_HOME}`;
        this.router?.navigate([url], { queryParams: { s: this.sessionService.userScope } });
      }
      else {
        const data = new OpenModalDialogData();
        data.templateName = ModalTemplateTypes.ERROR_TEMPLATE;
        data.customData.message = AppMessages.SELECT_ENTERPRISE_MESSAGE;
        this.modalDialogService.openModal(data);
      }
  }

  closeToolTip() {
    this.toolTipControl?.close();
  }

  onFiltering(e: FilteringEventArgs) {
    e.preventDefaultAction = true;
    e.text = e?.text?.replace(new RegExp(`^${PREFIX_URI_IN_SCANNED_VALUE}`), '');
    this.globalSearchText = e.text;
    this.autocomplete?.hidePopup(); // initially hide popup if it's already open
    this.searchSubject.next(e);
  }

  globalSearch(e: any) {
    if (e.text.length > 3) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.assetListService.globalSearch(this.sessionService.tenantId, encodeURIComponent(e.text)).subscribe((response) => {
        if (response) {
          this.searchData = response;
          // a delay/timeout is added to introduce a gap between
          // progress ring overlay and auto complete control's flyout
          // added delay of 100ms
          setTimeout(() => {
            e.updateData(this.searchData);
            //in edge scenario if the flyout does not appear use this => this.autocomplete?.showPopup();
          }, 120);
        }
      });
    }
  }

  searchNavigation(e: any) {
    if (e.isInteracted && e.item != null) {
      const data: AssetSearch = e.itemData;
      let queryUrl: GlobalSearchNav;
      if (this.router.url.indexOf(AppRouteConstants.ROUTE_TO_ADMIN) !== -1) {
        queryUrl = this.adminGlobalSearch(data);
      }
      else {
        queryUrl = this.customerGlobalSearch(data);
      }
      this.router.navigate([queryUrl.url], { queryParams: queryUrl.queryParams });
      this.globalSearchTextClear();
    }
  }

  adminGlobalSearch(data: AssetSearch): GlobalSearchNav {
    let scopeData = data?.scope;
    if (data?.scope.charAt(0) === Scope.DEFAULT_SCOPE) {
      scopeData = data?.scope.slice(1);
    }
    const scope = scopeData?.split(Scope.DEFAULT_SCOPE);
    const result: GlobalSearchNav = {
      url: '',
      queryParams: {}
    };
    if (data && data.objectType === AssetType.ENTERPRISE) {
      const enterpriseId = data.id;
      result.url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${EnterpriseInfoRoutes.ENTERPRISE_BASE_ROUTE}/${EnterpriseInfoRoutes.ENTERPRISE_DETAILS}`;
      result.queryParams = { enterpriseId: enterpriseId };
    }
    else if (data && data.objectType === AssetType.SITE && scope.length > 1) {
      const enterpriseId = scope[0];
      result.url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${SiteInfoRoutes.SITE_BASE_ROUTE}/${SiteInfoRoutes.SITE_DETAILS_ROUTE}`;
      result.queryParams = { siteId: data.id, enterpriseId: enterpriseId };
    }
    else if (data && data.objectType === AssetType.AREA && scope.length > 2) {
      const enterpriseId = scope[0];
      const siteId = scope[1];
      result.url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${AreasInfoRoutes.AREAS_BASE_ROUTE}/${AreasInfoRoutes.AREA_DETAILS_ROUTE}`;
      result.queryParams = { areaId: data.id, siteId: siteId, enterpriseId: enterpriseId };
    }
    else if (data && data.objectType === AssetType.UNITS && scope.length > 3) {
      const enterpriseId = scope[0];
      const siteId = scope[1];
      const areaId = scope[2];
      result.url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${UnitsInfoRoutes.UNITS_BASE_ROUTE}/${UnitsInfoRoutes.UNIT_DETAILS_ROUTE}`;
      result.queryParams = { unitId: data.id, areaId: areaId, siteId: siteId, enterpriseId: enterpriseId };
    }
    else if (data && data.objectType === AssetType.EQUIPMENT && scope.length > 4) {
      const enterpriseId = scope[0];
      const siteId = scope[1];
      const areaId = scope[2];
      const unitId = scope[3];
      result.url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${EquipmentsInfoRoutes.EQUIPMENTS_BASE_ROUTE}/${EquipmentsInfoRoutes.EQUIPMENT_DETAILS_ROUTE}`;
      result.queryParams = { equipmentId: data.id, enterpriseId: enterpriseId, unitId: unitId, siteId: siteId, areaId: areaId };
    }
    else if (data && data.objectType === AssetType.GATEWAY) {
      result.url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${GatewayInfoRoutes.GATEWAY_INFO_BASE_ROUTE}/${GatewayInfoRoutes.ROUTE_TO_GATEWAY_DETAILS}`;
      result.queryParams = { id: data.id };
    }
    else if (data && data.objectType === AssetType.SENSOR) {
      result.url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${SensorInfoRoutes.SENSOR_INFO_BASE_ROUTE}/${SensorInfoRoutes.ROUTE_TO_SENSOR_DETAILS}`;
      result.queryParams = { id: data.id };
    }
    return result;
  }

  getEnterpriseIdFromScopes(scopes: string[]): string {
    // Check if the first element is an empty string (leading slash case)
    scopes = scopes.filter(x => x && x !== '');
    if (scopes.length > 0 && scopes[0] === '') {
      return scopes.length > 1 ? scopes[1] : '';
    }
    // Otherwise, return the first element
    return scopes.length > 0 ? scopes[0] : '';
  }

  customerGlobalSearch(data: AssetSearch): GlobalSearchNav {
    const scope = this.userScope;
    const result: GlobalSearchNav = {
      url: '',
      queryParams: { id: data.id, name: data.label }
    };
    if (data && data.objectType === AssetType.ENTERPRISE) {
      result.queryParams = { s: scope };
      result.url = `/${AppRouteConstants.ROUTE_TO_CUSTOMER}/${DashboardInfoRoutes.BASE_DASHBOARD_ROUTE}/${DashboardInfoRoutes.DASHBOARD_HOME}`;
    }
    else if (data && data.objectType === AssetType.SITE) {
      result.queryParams = { id: data.id, name: data.label, s: scope };
      result.url = `/${AppRouteConstants.ROUTE_TO_CUSTOMER}/${AppRouteConstants.ROUTE_TO_ASSETS_INFO}/${AppRouteConstants.APP_CUSTOMER_SITE_ROUTE}`;
    }
    else if (data && data.objectType === AssetType.AREA) {
      result.queryParams = { id: data.id, name: data.label, s: scope };
      result.url = `/${AppRouteConstants.ROUTE_TO_CUSTOMER}${AppRouteConstants.APP_CUSTOMER_AREA_ROUTE}`;
    }
    else if (data && data.objectType === AssetType.UNITS) {
      result.queryParams = { id: data.id, name: data.label, s: scope };
      result.url = `/${AppRouteConstants.ROUTE_TO_CUSTOMER}${AppRouteConstants.APP_CUSTOMER_UNIT_ROUTE}`;
    }
    else if (data && data.objectType === AssetType.EQUIPMENT) {
      result.queryParams = { id: data.id, name: data.label, s: scope };
      result.url = `/${AppRouteConstants.ROUTE_TO_CUSTOMER}${AppRouteConstants.APP_CUSTOMER_EQUIPMENT_ROUTE}`;
    }
    else if (data && data.objectType === AssetType.SENSOR) {
      result.url = `/${AppRouteConstants.ROUTE_TO_CUSTOMER}/${SystemInfoRoutes.SYSTEM_INFO_BASE_ROUTE}/${SystemInfoRoutes.ROUTE_TO_SENSOR}`;
      result.queryParams = { id: data.id, serialNumber: data.serialNumber, s: scope };
    }
    else if (data && data.objectType === AssetType.GATEWAY) {
      result.url = `/${AppRouteConstants.ROUTE_TO_CUSTOMER}${AppRouteConstants.APP_CUSTOMER_GATEWAY_ROUTE}`;
      result.queryParams = { id: data.id, s: scope };
    }
    return result;
  }

  globalSearchTextClear() {
    this.autocomplete?.clear();
  }

  globalSearchEnterKey(e: any) {
    this.autocomplete?.showPopup();
  }

  toggleBodyScroll(enableScroll: boolean) {
    if (enableScroll) {
      document.body.style.overflow = 'auto';
    }
    else {
      document.body.style.overflow = 'hidden';
    }
  }

  onBeforeOpen(e: any) {
    if (this.toastService.maxToastCount === this.toast?.element.childElementCount) {
      e.cancel = true;
    } else {
      e.cancel = false;
    }
  }

  closeNotificationPanel() {
    this.sidebar?.toggle();
  }
}

export enum ScreenBreak {
  Small,
  Medium,
  Large
}
