<div class="rootContent " [ngClass]="{'noneContent':noContent && showEmptyPill}">
    <div class="alertContent" *ngIf="alertContent">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M5.00195 4.99841L15.002 14.9984" stroke="white" stroke-width="2.3" stroke-linecap="round" />
            <path d="M4.99805 14.9978L14.998 4.9978" stroke="white" stroke-width="2.3" stroke-linecap="round" />
        </svg>
        <span *ngIf="itemsCount === 1 && alertContent.count && alertContent.count !== 0">
            {{alertContent.count}}
        </span>
    </div>
    <div class="warningContent" *ngIf="warningContent">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M9 2.70001L9 10.8" stroke="white" stroke-width="2.4" stroke-linecap="round"
                stroke-linejoin="round" />
            <ellipse cx="9.02789" cy="15" rx="1.44" ry="1.4398" fill="white" />
        </svg>
        <span *ngIf="itemsCount === 1 && warningContent.count && warningContent.count !== 0">
            {{warningContent.count}}
        </span>
    </div>
    <div class="successContent" *ngIf="healthyContent">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 21 20" fill="none">
            <path d="M3.5 11L7.5 15L16.5 6" stroke="white" stroke-width="2.8" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
        <span *ngIf="itemsCount === 1 && healthyContent.count && healthyContent.count !== 0">
            {{healthyContent.count}}
        </span>
    </div>
    <div class="systemContent" *ngIf="offlineContent">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="22" viewBox="0 0 28 22" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M19.5643 5.4204C19.4685 5.37521 19.372 5.33137 19.2748 5.2889C17.6869 4.5951 15.9355 4.27844 13.9103 4.29218C11.9167 4.30569 10.1985 4.63445 8.64172 5.32736C7.08531 6.0201 5.72369 7.0619 4.42354 8.4568C4.17878 8.71941 4.19324 9.13071 4.45584 9.37547C4.71844 9.62024 5.12974 9.60578 5.37451 9.34318C6.58389 8.04567 7.80756 7.12159 9.17034 6.51503C10.5327 5.90866 12.0676 5.6047 13.9191 5.59215C15.7208 5.57993 17.2329 5.84882 18.579 6.40563L19.5643 5.4204ZM16.6122 8.37251C15.7823 8.14095 14.8876 8.04089 13.9076 8.0512C11.007 8.08172 8.93467 9.20015 7.20025 11.2843C6.97061 11.5602 7.00815 11.97 7.28408 12.1997C7.56001 12.4293 7.96986 12.3918 8.19949 12.1158C9.71041 10.3003 11.4328 9.37731 13.9213 9.35113C14.4997 9.34504 15.0302 9.3817 15.5223 9.46235L16.6122 8.37251ZM17.6238 10.1893L18.5709 9.24222C19.3812 9.75605 20.1119 10.4376 20.8044 11.2903C21.0307 11.5689 20.9883 11.9783 20.7097 12.2046C20.431 12.4309 20.0216 12.3885 19.7953 12.1098C19.0965 11.2494 18.3892 10.6246 17.6238 10.1893ZM12.4528 12.5319C11.5029 12.8047 10.726 13.3326 10.0333 14.0478C9.78856 14.3005 9.78998 14.7008 10.0329 14.9518L12.4528 12.5319ZM14.1874 13.6257L15.3345 12.4786C16.3876 12.7385 17.2534 13.3057 17.9687 14.0494C18.2176 14.3082 18.2095 14.7197 17.9508 14.9685C17.6921 15.2174 17.2806 15.2093 17.0318 14.9506C16.2803 14.1693 15.372 13.6703 14.1874 13.6257ZM20.4103 7.40282L21.3436 6.46946C22.121 7.02534 22.8577 7.68775 23.5745 8.45681C23.8193 8.71942 23.8048 9.13072 23.5422 9.37548C23.2796 9.62024 22.8683 9.60578 22.6235 9.34317C21.9009 8.56782 21.1713 7.92467 20.4103 7.40282ZM15.6233 17.4162C15.6233 18.36 14.8582 19.125 13.9145 19.125C12.9708 19.125 12.2057 18.36 12.2057 17.4162C12.2057 16.4725 12.9708 15.7074 13.9145 15.7074C14.8582 15.7074 15.6233 16.4725 15.6233 17.4162Z"
                fill="white" />
            <path d="M7 17.3L21.7 2.59998" stroke="white" stroke-width="1.2" stroke-linecap="round" />
        </svg>
        <span *ngIf="itemsCount === 1 && offlineContent.count && offlineContent.count !== 0">
            {{offlineContent.count}}
        </span>
    </div>
</div>