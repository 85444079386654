import { ActivatedRoute, Router } from '@angular/router';
import { AdminInfoRoutes, NotificationManagementRoutes } from 'src/app/admin/constants/route.constants';
import { assignedNotificationGridColumnCustomerUserHeaders, assignedNotificationGridColumnHeaders, RoleGridColumnData } from 'src/app/shared/models/table-definition';
import { ButtonData, OpenModalDialogData } from 'src/app/shared/models/shared.models';
import { ButtonLabels, ButtonNames, ButtonTypes, CustomEventSource, CustomEventType, GridUIType, ModalTemplateTypes } from 'src/app/shared/app-constants/shared.enum';
import { ButtonStateService } from 'src/app/shared/services/button-state-service/button-state.service';
import { Component, OnInit } from '@angular/core';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { ExpandedEventArgs } from '@syncfusion/ej2-angular-navigations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { NotificationList } from 'src/app/admin/notification-rules-management/model/notification-list';
import { PageScrollService } from 'src/app/shared/services/page-scroll-service/page-scroll.service';
import { Permission } from 'src/app/shared/app-constants/permission';
import { RoleAssignment, UserDetails } from 'src/app/admin/user-management/models/user-details';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { TreeGridColumnData } from 'src/app/shared/models/tree-grid';
import { UserService } from 'src/app/admin/user-management/services/user.service';
import { UtilitiesService } from '../../services/utlities-services/utilities.service';

@Component({
  selector: 'app-notification-preferences',
  templateUrl: './notification-preferences.component.html',
  styleUrls: ['./notification-preferences.component.scss']
})
export class NotificationPreferencesComponent implements OnInit {
  assignedNotificationData: NotificationList[] = [];
  assignedNotificationGridColumnHeaders : Array<TreeGridColumnData> = [];
  cancelButtonData: ButtonData = { label: ButtonLabels.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.CANCEL };
  countryCodes: string[] = ['+1', '+91'];
  expansionText = 'Collapse';
  formGroup!: FormGroup;
  gridUIType: GridUIType = GridUIType.ALTERNATE_ROW_COLOR_FLAT_GRID;
  rolesGridColumnData: TreeGridColumnData[] = RoleGridColumnData;
  saveAndExitButtonData: ButtonData = { label: ButtonLabels.SAVE_AND_EXIT, type: ButtonTypes.PRIMARY_BORDER, name: ButtonNames.SAVE_AND_EXIT_FOR_NOTIFICATIONS, disable: true };
  saveButtonData: ButtonData = { label: ButtonLabels.SAVE, type: ButtonTypes.PRIMARY_BASIC, name: ButtonNames.SAVE_FOR_NOTIFICATIONS, disable: true };
  userAssignedRoles: RoleAssignment[] = [];
  userDetails!: UserDetails;
  requiredPermissions: string[] = [ Permission.Can_Update_Notification ];
  componentPermissionsMappedToUser: string[] = [];
  protected readonly Permissions = Permission;

  constructor(
    private activatedRoute: ActivatedRoute,
    private buttonStateService: ButtonStateService,
    private formBuilder: FormBuilder,
    private location: Location,
    private modalDialogService: ModalDialogService,
    private pageScrollService: PageScrollService,
    private router: Router,
    private sessionService: SessionService,
    private userService: UserService,
    private utilityService: UtilitiesService
  ) { }

  ngOnInit(): void {
    this.createFormGroup();
    this.getUserDetails();
    this.formGroup?.statusChanges.subscribe(() => {
      this.updateButtonsState();
    });
    this.getNotificationsByUser();
    this.componentPermissionsMappedToUser = this.sessionService.filterPermissions(this.requiredPermissions);
    this.assignedNotificationGridColumnHeaders = !this.componentPermissionsMappedToUser.includes(Permission.Can_Update_Notification as string) ? assignedNotificationGridColumnCustomerUserHeaders : assignedNotificationGridColumnHeaders;
  }

  buttonClick(e: CustomEvent) {
    if (e.eventSource === CustomEventSource.BUTTON && e.eventType === CustomEventType.CLICK && e.eventData === this.cancelButtonData.name) {
      this.routeToParent();
    }
    if (e.eventSource === CustomEventSource.BUTTON && e.eventType === CustomEventType.CLICK && e.eventData === this.saveAndExitButtonData.name) {
      this.saveNotification(this.saveAndExitButtonData.name);
    }
    if (e.eventSource === CustomEventSource.BUTTON && e.eventType === CustomEventType.CLICK && e.eventData === this.saveButtonData.name) {
      this.saveNotification(this.saveButtonData.name);
    }
  }

  createFormGroup() {
    this.formGroup = this.formBuilder.group({
      countryCode: ['+1'],
      phoneNumber: [null, [Validators.required, Validators.pattern('[0-9]{10,15}')]],
      emailNotifications: [false],
      smsNotifications: [false],
    });
  }

  getNotificationsByUser() {
    this.userService.getNotificationsByUser(this.sessionService.tenantId, [this.sessionService.userId]).subscribe((response) => {
      if (response) {
        this.assignedNotificationData = this.utilityService.buildFrequencyColumnData(response);
      }
    });
  }

  getUserDetails() {
    let scope = '/';
    if (this.sessionService.allRolesAssignedToUser) scope = this.sessionService.allRolesAssignedToUser[0].scope;
    this.userService.getUserDetailsByOktaId(this.sessionService.tenantId, this.sessionService.userId, scope).subscribe((response) => {
      if (response) {
        this.userDetails = response;
        if (this.userDetails?.tenants)
          this.userAssignedRoles = this.userDetails?.tenants[0].roleAssignments;
        this.formGroup?.patchValue({
          countryCode: this.userDetails.countryCode,
          phoneNumber: this.userDetails.phoneNo,
          emailNotifications: this.userDetails?.isEmailNotificationEnabled,
          smsNotifications: this.userDetails.isSmsNotificationEnabled,
        });
      }
    });
  }

  performNavigationOnSuccess(operationInitiateButtonName: string) {
    if (operationInitiateButtonName === this.saveAndExitButtonData.name) {
      this.routeToParent();
    }
    else {
      this.pageScrollService.scrollToTop();
      this.router.navigate([], {
        relativeTo: this.activatedRoute.parent,
        queryParamsHandling: 'merge'
      });
      this.formGroup.markAsPristine();
      this.updateButtonsState();
      this.getUserDetails();
    }
  }

  receiveTreeGridEvent(e: any) {
    if (e.eventSource === CustomEventSource.TREEGRID && e.eventType === CustomEventType.CELL_CLICK && e.eventData.data.notificationId  && this.componentPermissionsMappedToUser.includes(Permission.Can_Update_Notification as string)) {
      const notificationId = e.eventData.data.notificationId;
      const url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${NotificationManagementRoutes.NOTIFICATION_BASE_ROUTE}/${NotificationManagementRoutes.NOTIFICATION_DETAILS_ROUTE}/`;
      this.router.navigate([url], { queryParams: { notificationId: notificationId } });
    }
  }

  routeToParent() {
    this.location.back();
  }

  saveNotification(operationButtonName: string) {
    let userDetailUpdated = false;
    const isEmailNotificationEnabled = this.formGroup?.get('emailNotifications')?.value;
    if (this.userDetails.isEmailNotificationEnabled !== isEmailNotificationEnabled) {
      this.userDetails.isEmailNotificationEnabled = isEmailNotificationEnabled;
      userDetailUpdated = true;
    }

    const isSMSNotificationEnabled = this.formGroup?.get('smsNotifications')?.value;
    if (this.userDetails.isSmsNotificationEnabled !== isSMSNotificationEnabled) {
      this.userDetails.isSmsNotificationEnabled = isSMSNotificationEnabled;
      userDetailUpdated = true;
    }
    if (!this.userDetails.sapCode) {
      this.userDetails.sapCode = '01001';
    }
    const phoneNumber = this.formGroup?.get('phoneNumber')?.value;
    if (this.userDetails.phoneNumber !== phoneNumber) {
      this.userDetails.phoneNumber = phoneNumber;
      this.userDetails.phoneNo = phoneNumber;
      userDetailUpdated = true;
    }
    const countryCode = this.formGroup?.get('countryCode')?.value;
    if (this.userDetails.countryCode !== countryCode) {
      this.userDetails.countryCode = this.formGroup?.get('countryCode')?.value;
      userDetailUpdated = true;
    }
    if (!this.userDetails.createdBy) {
      this.userDetails.createdBy = this.userDetails.email;
    }
    this.userDetails.updatedBy = this.userDetails.email;

    if (userDetailUpdated) {
      this.userService.updateUser(this.sessionService.tenantId, this.userDetails).subscribe((response) => {
        if (response) {
          const data = new OpenModalDialogData();
          data.templateName = ModalTemplateTypes.SUCCESS_TEMPLATE;
          data.customData.message = 'User Updated Successfully';
          this.modalDialogService.openModal(data);
          this.performNavigationOnSuccess(operationButtonName);
        }
      });
    }
  }

  toggleText(e: ExpandedEventArgs) {
    if (e.isExpanded === true)
      this.expansionText = 'Collapse';
    else
      this.expansionText = 'Expand';
  }

  updateButtonsState() {
    const enableButtons = this.formGroup?.valid && this.formGroup?.dirty;
    this.buttonStateService.setButtonDisableState(this.saveButtonData.name, !enableButtons);
    this.buttonStateService.setButtonDisableState(this.saveAndExitButtonData.name, !enableButtons);
  }
}
