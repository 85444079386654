export class ModalDimensions {
  public static readonly SM = { height: '', width: '21.875rem' };
  public static readonly MD = { height: '', width: '34.375rem' };
  public static readonly LG = { height: '', width: '46.875rem' };
  public static readonly XL = { height: '', width: '72.5rem' };
}

export class ModalPositions {
  public static readonly TOP_CENTER = { X: 'center', Y: 'center' };
}
