import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, DistributedTracingModes, ICustomProperties, IDependencyTelemetry, IEventTelemetry, IExceptionTelemetry, IMetricTelemetry, IPageViewTelemetry, ITraceTelemetry } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      connectionString: environment.appInsights.connectionString,
      enableAutoRouteTracking: true,
      disableExceptionTracking: false,
      autoTrackPageVisitTime: true,
      distributedTracingMode: DistributedTracingModes.W3C,
      enableCorsCorrelation: true,
      disableCorrelationHeaders: false,
      extensions: [<any> this.angularPlugin],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new ErrorHandler()],
        },
      },
    },
  });

  constructor(
    private router: Router
  ) {
    this.appInsights.loadAppInsights();
    this.appInsights.setAuthenticatedUserContext('UserContext');
    this.appInsights.addTelemetryInitializer(envelope => {
      envelope.tags = envelope.tags || [];
      envelope.tags['ai.cloud.role'] = 'perceptivdashboard-ui';
    });
  }

  // expose tracking methods
  trackEvent(event: IEventTelemetry, customProperties?:ICustomProperties) {
    this.appInsights.trackEvent(event, customProperties);
  }

  startTrackEvent(name?: string) {
    this.appInsights.startTrackEvent(name);
  }

  stopTrackEvent(name: string, properties?: { [p: string]: string }, measurements?: { [p: string]: number }): any {
    this.appInsights.stopTrackEvent(name, properties, measurements);
  }

  trackPageView(pageView?:IPageViewTelemetry) {
    this.appInsights.trackPageView(pageView);
  }

  startTrackPage(name?: string) {
    this.appInsights.startTrackPage(name);
  }

  stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
    this.appInsights.stopTrackPage(name, url, properties, measurements);
  }

  trackMetric(metric:IMetricTelemetry, properties?: ICustomProperties) {
    this.appInsights.trackMetric(metric, properties);
  }

  trackException(exception: IExceptionTelemetry,  properties?: ICustomProperties) {
    this.appInsights.trackException(exception, properties);
  }

  trackTrace(message: ITraceTelemetry, properties?: ICustomProperties) {
    this.appInsights.trackTrace(message, properties);
  }

  trackDependencyData(dependency: IDependencyTelemetry) {
    this.appInsights.trackDependencyData(dependency);
  }

  flush() {
    this.appInsights.flush();
  }

  logException(exception?: Error | string, severityLevel?: number) {
    if (typeof exception === 'string') {
      exception = new Error(exception);
    }
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }
}
