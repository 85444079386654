import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerInfoRoutes } from 'src/app/admin/constants/route.constants';
import { DashboardInfoRoutes } from 'src/app/customer/dashboard-info/constants/route.constants';
import { AppRouteConstants } from 'src/app/shared/app-constants/app-route-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Output() viewInit: EventEmitter<any> = new EventEmitter<any>();
  scope!: string;
  public markTheFooterTop = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.scope = params['s'];
    });
  }

  navigateToDashboardInfo() {
    const path = this.scope ? `/${CustomerInfoRoutes.BASE_CUSTOMER_ROUTE}/${DashboardInfoRoutes.BASE_DASHBOARD_ROUTE}/${DashboardInfoRoutes.DASHBOARD_HOME}` : `/${AppRouteConstants.ROUTE_TO_HOME}`;
    const navigationExtras = this.scope ? { queryParams: { s: this.scope } } : {};
    this.router.navigate([path], navigationExtras);
  }
}
