import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ButtonTypes } from 'src/app/shared/app-constants/shared.enum';
import { CONFIG, HANDLERS, POWERBI_REPORT, POWERBI_REPORT_TEST_S7100, POWERBI_REPORTCONFIG } from 'src/app/shared/app-constants/powerbi-report-constants';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { SensorTelemetryGraphS7100Service } from '../../../services/sensor-telemetry-graph-services/sensor-telemetry-graph-s7100.service';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { PowerBIReportParams, TokenInfo } from '../../../models/token-info';

@Component({
  selector: 'app-senser-telemetry-graph-s7100',
  templateUrl: './sensor-telemetry-graph-s7100.component.html',
  styleUrls: ['./sensor-telemetry-graph-s7100.component.scss']
})
export class SensorTelemetryGraphS7100Component implements AfterViewInit, OnInit {
  @ViewChild('powerBIReportEmbedComponent') reportObj!: PowerBIReportEmbedComponent;
  @ViewChild('selectSensorSerialNumber') selectSensorSerialNumber!: ElementRef;
  @Input() sensorSerialNumber: string = '';
  @Input() macAddress: string = '';
  public reportConfigParams: PowerBIReportParams;
  cancelButtonData = { label: 'CLOSE', type: ButtonTypes.PRIMARY_BASIC, name: 'cancel' };
  eventHandlersMap = HANDLERS.EVENT_HANDLERS_MAP(this.reportObj);
  phasedEmbeddingFlag: boolean = false;
  report: any;
  reportClass: string = 'report-container-s7100';
  reportConfig = POWERBI_REPORTCONFIG;

  constructor(
    public httpService: SensorTelemetryGraphS7100Service,
    private sessionService: SessionService,
    private modalDialogService: ModalDialogService
  ) {
    this.reportConfigParams = new PowerBIReportParams();
  }

  ngOnInit(): void {
    this.onInitalLoad();
  }

  ngAfterViewInit(): void {
    this.report = this.reportObj.getReport();
  }

  async onInitalLoad(): Promise<void> {
    try {
      const response: TokenInfo = await this.httpService.getTokenForReportByEmailId(POWERBI_REPORT_TEST_S7100.WORKSPACE_ID_TEST_S7100, POWERBI_REPORT_TEST_S7100.REPORT_ID_TEST_S7100,  POWERBI_REPORT_TEST_S7100.REPORT_DATASET_ID_TEST_S7100, this.sessionService.emailId);
      this.reportConfigParams.reportId = POWERBI_REPORT_TEST_S7100.REPORT_ID_TEST_S7100;
      this.reportConfigParams.reportEmbedURL = POWERBI_REPORT.REPORT_EMBED_URL(POWERBI_REPORT_TEST_S7100.REPORT_ID_TEST_S7100, POWERBI_REPORT_TEST_S7100.WORKSPACE_ID_TEST_S7100);
      this.reportConfigParams.reportAccessToken = response.token as string;
      this.reportConfigParams.filterSchemaURL = POWERBI_REPORT_TEST_S7100.FILTER_SCHEMA_URL_TEST_S7100;
      this.reportConfigParams.filterDataSource = POWERBI_REPORT_TEST_S7100.REPORT_TABLE_TEST_S7100;
      this.reportConfigParams.filterDataColumn = POWERBI_REPORT_TEST_S7100.REPORT_COLUMN_SENSORID_TEST_S7100;
      this.reportConfigParams.filterDataValue = this.sensorSerialNumber;
      this.reportConfig = CONFIG.ADD_ADDITIONAL_CONFIG(this.reportConfig, this.reportConfigParams);
    } catch (error: any) {
      console.log(POWERBI_REPORT.FAILED_ERROR(String(error.status), String(error.statusText)));
      throw error;
    }
  }

  closeModal() {
    this.modalDialogService.closeModal();
  }
}

