import { ButtonData } from 'src/app/shared/models/shared.models';
import { ButtonLabels, ButtonTypes, ButtonNames } from 'src/app/shared/app-constants/shared.enum';
import { ButtonStateService } from 'src/app/shared/services/button-state-service/button-state.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { DeviceState } from 'src/app/shared/app-constants/asset-constants';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { Schedule, SensorSchedule } from '../../models/sensor-measurement-scheduler';
import { SensorSchedulerService } from 'src/app/shared/services/sensor-scheduler-services/sensor-scheduler.service';
import { debounceTime } from 'rxjs';
import { TimeSpan } from 'src/app/shared/extension-classes/time-span';
import { Gateway, TimeZone } from 'src/app/admin/gateways-info/models/gateway';
import { GatewayService } from 'src/app/admin/gateways-info/services/gateway-services/gateway.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sensor-scheduler',
  templateUrl: './sensor-scheduler.component.html',
  styleUrls: ['./sensor-scheduler.component.scss']
})
export class SensorSchedulerComponent implements OnInit, OnChanges {
  @Input() sensorId: string = '';
  @Input() sensorState: string = '';
  @Input() tenantId: string = '';
  @Input() associatedGatewayId: string = '';
  cancelButtonData:ButtonData = { label: ButtonLabels.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.CANCEL };
  isError = false;
  isErrorMeasureExceeded = false;
  loadMore = new EventEmitter<any>(); // Event passed to the consumer
  restoreDefaultsButtonData: ButtonData = { label: ButtonLabels.RESTORE_DEFAULTS, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.RESTORE_DEFAULTS };
  saveButtonData:ButtonData = { label: ButtonLabels.SAVE, type: ButtonTypes.PRIMARY_BASIC, name: ButtonNames.SAVE_MODAL, width: '10rem' };
  showTimeZone = false;
  siteButton: ButtonData = { label: '', type: ButtonTypes.PRIMARY_NO_BORDER_WITH_UNDERLINE, name: 'site', disable: true };
  timezoneDisplayName!: TimeZone | undefined;
  timezonesDisplayFields: any = { text: 'displayName', value: 'id' };
  timezonesList: TimeZone[] = [];
  formGroup!: FormGroup;
  gatewayDetail!: Gateway;
  gatewayOffSet: string = '0.00';
  beginMeasurement: Object[] = [
    { value: '00:00:00', text: '00:00' },
    { value: '01:00:00', text: '01:00' },
    { value: '02:00:00', text: '02:00' },
    { value: '03:00:00', text: '03:00' },
    { value: '04:00:00', text: '04:00' },
    { value: '05:00:00', text: '05:00' },
    { value: '06:00:00', text: '06:00' },
    { value: '07:00:00', text: '07:00' },
    { value: '08:00:00', text: '08:00' },
    { value: '09:00:00', text: '09:00' },
    { value: '10:00:00', text: '10:00' },
    { value: '11:00:00', text: '11:00' },
    { value: '12:00:00', text: '12:00' },
    { value: '13:00:00', text: '13:00' },
    { value: '14:00:00', text: '14:00' },
    { value: '15:00:00', text: '15:00' },
    { value: '16:00:00', text: '16:00' },
    { value: '17:00:00', text: '17:00' },
    { value: '18:00:00', text: '18:00' },
    { value: '19:00:00', text: '19:00' },
    { value: '20:00:00', text: '20:00' },
    { value: '21:00:00', text: '21:00' },
    { value: '22:00:00', text: '22:00' },
    { value: '23:00:00', text: '23:00' },
    { value: '23:59:00', text: '23:59' }
  ];

  intervalOptions: Object[] = [
    { value: '10', text: '10 min' },
    { value: '30', text: '30 min' },
    { value: '60', text: '1 hour' },
    { value: '120', text: '2 hours' },
    { value: '180', text: '3 hours' },
    { value: '240', text: '4 hours' },
    { value: '300', text: '5 hours' },
    { value: '360', text: '6 hours' },
    { value: '420', text: '7 hours' },
    { value: '480', text: '8 hours' },
    { value: '540', text: '9 hours' },
    { value: '600', text: '10 hours' },
    { value: '660', text: '11 hours' },
    { value: '720', text: '12 hours' },
    { value: '1440', text: '1 day' },
  ];

  fields: Object = { text: 'text', value: 'value' };
  sensorSchedulerForm!: FormGroup;
  sensorsSchedule: SensorSchedule = new SensorSchedule();

  readonly range0to59Regex: string = '^(?:[0-9]|[1-5][0-9])$';

  public get SensorLifeCycle(): typeof DeviceState {
    return DeviceState;
  }

  timezonesListFn = (): TimeZone[] => this.gatewayDetail ? this.gatewayDetail.timezoneSource : [];
  timezoneDisplayNameFn = (timezone: string):TimeZone | undefined => this.timezonesList.find((tz) => tz.id === timezone);

  constructor(
    private buttonStateService: ButtonStateService,
    private fb: FormBuilder,
    private modalDialogService: ModalDialogService,
    public gatewayService: GatewayService,
    private datePipe: DatePipe,
    public sensorSchedulerService: SensorSchedulerService,
  ) {
    this.loadMore.pipe(debounceTime(600)).subscribe(() => this.updateSchedule());
  }

  ngOnInit() {
    this.createSensorScheduler();
    this.getSensorMeasurementScheduler();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const sensorStateChange:SimpleChange = changes['sensorState'];
    if (sensorStateChange) {
      //Call button state service
      this.updateButtonState();
    }
  }

  getSensorMeasurementScheduler() {
    this.sensorSchedulerService.getSensorMeasurementScheduler(this.sensorId, this.tenantId).subscribe((response) => {
      this.sensorsSchedule = response;
      this.getGatewayTimeZone();
    });
  }

  createSensorScheduler(sensorScheduler?: SensorSchedule) {
    this.sensorSchedulerForm = new FormGroup({
      wakeUpTime: new FormControl({ value: sensorScheduler?.wakeUpTime, disabled: false }, [Validators.required]),
      sleepTime: new FormControl({ value: sensorScheduler?.sleepTime, disabled: false }, [Validators.required]),
      measurementIntervalInMinutes: new FormControl({ value: sensorScheduler?.measurementIntervalInMinutes.toString(), disabled: false }, [Validators.required]),
      offsetTime: new FormControl({ value: sensorScheduler?.offsetTime, disabled: false }, [ Validators.pattern(this.range0to59Regex)]),
      timezoneOffset: new FormControl({ value: this.timezoneDisplayName?.displayName, disabled: true }),
      schedule: this.fb.array([]),
      auto: new FormControl({ value: false, disabled: true }),
    });
    this.sensorSchedulerForm.statusChanges.subscribe(status => {
      this.updateButtonState();
    });
    this.updateButtonState();
  }

  private updateButtonState() {
    if (this.sensorSchedulerForm) {
      this.buttonStateService.setButtonDisableState(this.saveButtonData.name, !(this.sensorSchedulerForm.dirty && this.sensorSchedulerForm.valid));
    }
  }

  save(sensorSchedule: SensorSchedule) {
    sensorSchedule.sensorId = this.sensorsSchedule.sensorId;
    sensorSchedule.wakeUpTime = this.addupdateTimeZone(sensorSchedule?.wakeUpTime, this.gatewayOffSet, 'RESET', sensorSchedule.offsetTime);
    sensorSchedule.sleepTime = this.addupdateTimeZone(sensorSchedule.sleepTime, this.gatewayOffSet, 'RESET', 0);
    if (this.sensorsSchedule.schedule)
      for (let index = 0; index < sensorSchedule.schedule.length; index++) {
        const item = sensorSchedule.schedule[index];
        item.time = this.addupdateTimeZone(item.time, this.gatewayOffSet, 'RESET', 0);
        item.fullWaveform = sensorSchedule.schedule[index].fullWaveform;
        item.lowFrequency = sensorSchedule.schedule[index].lowFrequency;
      }
    //sensorSchedule.schedule = this.sensorsSchedule.schedule;
    this.sensorSchedulerService.saveSensorMeasurementScheduler(this.tenantId, this.sensorsSchedule.sensorId, sensorSchedule);
    this.modalDialogService.closeModal();
  }

  cancel() {
    this.modalDialogService.closeModal();
  }

  addSchedule(schedule?: any) {
    if (schedule && schedule.length > 0) {
      for (const scheduleIndex of schedule) {
        const timelistForm = this.fb.group({
          time: [scheduleIndex?.time, Validators.required],
          calculated: [{ value: true, disabled: true }, Validators.required],
          fullWaveform: [{ value: scheduleIndex?.fullWaveform, disabled: false }, Validators.required],
          lowFrequency: [{ value: scheduleIndex?.lowFrequency, disabled: false }, Validators.required]
        });
        this.schedule.push(timelistForm);
      }
    }
  }

  get schedule(): FormArray {
    return this.sensorSchedulerForm.get('schedule') as FormArray;
  }

  newTimelist(): FormGroup {
    return this.fb.group({
      time: '',
      calculated: true,
      fullWaveForm: false,
      lowFrequency: false,
    });
  }

  createSchedule() {
    const measurementIntervalInMinutes = this.sensorSchedulerForm.value.measurementIntervalInMinutes as string;
    const offsetTime = this.sensorSchedulerForm.value.offsetTime as string;
    if (!this.testRegex(measurementIntervalInMinutes) || !this.testRegexMax60(offsetTime)) return;
    this.sensorsSchedule.reportedMeasurementIntervalInMinutes = offsetTime;
    this.loadMore.next(this);
  }

  updateSchedule(): void {
    const wakeUpTime = this.sensorSchedulerForm.value.wakeUpTime as string;
    const sleepTime = this.sensorSchedulerForm.value.sleepTime as string;
    const measurementIntervalInMinutes = this.sensorSchedulerForm.value.measurementIntervalInMinutes as string;
    const offsetTime = this.sensorSchedulerForm.value.offsetTime as string;
    const scheduleList: Schedule[] = [];
    this.generateSchedule(wakeUpTime, sleepTime, measurementIntervalInMinutes, offsetTime, scheduleList);
    this.measurementIntervalValidation();
  }

  generateSchedule(wakeUpTime: string, sleepTime: string, interval: string, offsetTime: string, scheduleList: Schedule[]) {
    let offset = parseInt(offsetTime);
    if (isNaN(offset)) {
      offset = 0;
    }
    let minutes = parseInt(interval);
    if (isNaN(minutes)) {
      minutes = 0;
    }
    const wakeDate =  TimeSpan.fromString(wakeUpTime);
    const sleepDate = TimeSpan.fromString(sleepTime);
    if (wakeDate && sleepDate) {
      let nextDate = wakeDate;
      while (nextDate < sleepDate) {
        if (parseInt(this.createScheduleItem(nextDate.add(TimeSpan.fromMinutes(offset))).time) >= 24)
        {
          const item =  scheduleList.find(x => x.time === '23:59:00');
          if (!item) {
            const schedule: Schedule =  this.createScheduleItem(sleepDate);
            scheduleList.push(schedule);
          }
        }
        else
        {
          const schedule: Schedule = this.createScheduleItem(nextDate.add(TimeSpan.fromMinutes(offset)));
          scheduleList.push(schedule);
        }
        nextDate = nextDate.add(TimeSpan.fromMinutes(minutes));
      }
      this.schedule.clear();
      this.sensorSchedulerForm.setControl('schedule', this.fb.array([]));
      this.addSchedule(scheduleList);
    }
  }

  private createScheduleItem(time: TimeSpan) {
    const schedule: Schedule = new Schedule();
    schedule.time = time.toTimeString();
    schedule.calculated = false;
    schedule.fullWaveform = false;
    schedule.lowFrequency = false;
    return schedule;
  }

  getSchedule(wakeUpTime: string, sleepTime: string, interval: string, offsetTime: string, scheduleList: Schedule[], isFirstItem: boolean = false) {
    // this is an old implementation with some issues, keeping it here just for reference.
    wakeUpTime = this.getSchedulerTime(wakeUpTime, interval, isFirstItem);
    const wakeUpDate = this.getDate(wakeUpTime);
    wakeUpDate.setMinutes(wakeUpDate.getMinutes() + parseInt(offsetTime));
    const wakeUpTimeWithOffset = wakeUpDate.toLocaleTimeString('en-US', { hour12: false, });
    const wakeDate = this.getDate(wakeUpTime);
    const sleepDate = this.getDate(sleepTime);
    if (wakeDate <= sleepDate) {
      const schedule: Schedule = new Schedule();
      schedule.time = offsetTime ? wakeUpTimeWithOffset : wakeUpTime;
      schedule.calculated = false;
      schedule.fullWaveform = false;
      schedule.lowFrequency = false;
      scheduleList.push(schedule);
      this.getSchedule(wakeUpTime, sleepTime, interval, offsetTime, scheduleList);
    }
    else {
      this.schedule.clear();
      this.sensorSchedulerForm.setControl('schedule', this.fb.array([]));
      this.addSchedule(scheduleList);
    }
  }

  getDate(time: string) {
    const timeArray = time.split(':');
    const now = new Date();
    const newDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(timeArray[0]), parseInt(timeArray[1]), parseInt(timeArray[2]));
    return newDate;
  }

  getSchedulerTime(time: string, minutes: string, isFirstItem: boolean = false) {
    const timeArray = time.split(':');
    const now = new Date();
    const newDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), parseInt(timeArray[0]), parseInt(timeArray[1]), parseInt(timeArray[2]));
    if (!isFirstItem) {
      newDate.setMinutes(newDate.getMinutes() + parseInt(minutes));
    }
    return newDate.toLocaleTimeString('en-US', { hour12: false, });
  }

  getHoursbyDigit(hour: string) {
    const hours = hour.split(':');
    hours.pop();
    return hours.join(':');
  }

  enforcePattern(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!this.testRegex(input.value)) {
      input.value = input.value.slice(0, -1); // Remove the last character
    }
  }

  testRegex(value: string) {
    const regex = /^(0|[1-9][0-9]{0,2}|1[0-3][0-9]{0,2}|14[0-3][0-9]|1440)?$/; // Intger numbers between 0-1440 only
    return value ? regex.test(value) : true;
  }

  testRegexMax60(value: string) {
    const regex = /^(?:[0-5]?\d|60)$/; // Intger numbers between 0-60 only
    return value ? regex.test(value) : true;
  }

  onRestoreDefaultButtonClick() {
    this.sensorSchedulerService.getRestoreDefaultSensorSchedule(this.tenantId).subscribe(response => {
      this.sensorsSchedule = response;
      this.sensorsSchedule.sensorId = this.sensorId;
      this.createSensorScheduler(this.sensorsSchedule);
      this.addSchedule(this.sensorsSchedule.schedule);
      this.sensorSchedulerForm?.markAsDirty();
      this.updateButtonState();
    });
  }

  measurementIntervalValidation() {
    this.isError = false;
    this.isErrorMeasureExceeded = false;
    const wakeUpTime = Number(this.sensorSchedulerForm.get('wakeUpTime')?.value.split(':')[0]);
    const sleepTime = Number(this.sensorSchedulerForm.get('sleepTime')?.value.split(':')[0]);
    if (sleepTime <= wakeUpTime) {
      this.isErrorMeasureExceeded = true;
      this.buttonStateService.setButtonDisableState(this.saveButtonData.name, true);
    }
    const measurementIntervalInMinutes = Number(this.sensorSchedulerForm.get('measurementIntervalInMinutes')?.value);
    if (measurementIntervalInMinutes !== 1440) {
      if (sleepTime > wakeUpTime) {
        const interval = (sleepTime - wakeUpTime) * 60;
        const measurementIntervalInMinutes = Number(this.sensorSchedulerForm.get('measurementIntervalInMinutes')?.value);
        if (interval < measurementIntervalInMinutes) {
          this.isError = true;
          this.buttonStateService.setButtonDisableState(this.saveButtonData.name, true);
        }
      }
    }
  }

  getGatewayTimeZone() {
    this.gatewayService.getGatewayDetailsById(this.associatedGatewayId, this.tenantId).subscribe((response1) => {
      this.gatewayDetail = response1;
      this.timezonesList = this.timezonesListFn();
      this.timezoneDisplayName = this.timezoneDisplayNameFn(this.gatewayDetail.timeZone);
      const offset = this.timezoneDisplayNameFn(this.gatewayDetail.timeZone)?.baseUtcOffset;
      this.gatewayOffSet = offset !== undefined ? offset : '0.00';
      this.sensorsSchedule.offsetTime = this.sensorsSchedule.offsetTime ?? 0;
      this.sensorsSchedule.wakeUpTime = this.addupdateTimeZone(this.sensorsSchedule.wakeUpTime, this.gatewayOffSet, 'SET', this.sensorsSchedule.offsetTime);
      this.sensorsSchedule.sleepTime = this.addupdateTimeZone(this.sensorsSchedule.sleepTime, this.gatewayOffSet, 'SET', 0);
      if (this.sensorsSchedule.schedule)
        for (const item of this.sensorsSchedule.schedule) {
          item.time = this.addupdateTimeZone(item.time, this.gatewayOffSet, 'SET', 0);
        }
      this.createSensorScheduler(this.sensorsSchedule);
      this.addSchedule(this.sensorsSchedule.schedule);
    });
  }

  addupdateTimeZone(timeString: string, utcOffset: string, statusFlag: string, scheduleOffset:number): string {
    const dateTimeString = `1970-01-01T${timeString}`;
    const [hrs, min] = utcOffset.split(':').map(Number);
    const parsedDate = new Date(dateTimeString);

    if (statusFlag === 'SET') {
      parsedDate.setHours(parsedDate.getHours() + hrs);
      parsedDate.setMinutes(parsedDate.getMinutes() - scheduleOffset);
    } else if (statusFlag === 'RESET') {
      parsedDate.setHours(parsedDate.getHours() - hrs);
      parsedDate.setMinutes(parsedDate.getMinutes() + scheduleOffset);
    }

    if (!isNaN(parsedDate.getTime())) {
      return this.datePipe.transform(parsedDate, 'HH:mm:ss') || '';
    } else {
      throw new Error('Invalid date');
    }
  }
}
