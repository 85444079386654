import { Injectable } from '@angular/core';
import { SignalRHubService } from '../signalr-services/signalr-hub.service';
import { AlertSignalEventTypes, AssetHealthStatus, CommandNames, SignalREventTypes } from '../../app-constants/shared.enum';
import { SignalRAlertSignalEvent, AlarmOrWarningEventData, SignaREvent, SignalRCommandSignalEvent, DeviceOnlineOrOfflineEventData, AssetHealthStateChangeEventData } from '../../models/signalr-events';
import { ToastService } from '../toast-services/toast.service';
import { ToastModel } from '@syncfusion/ej2-angular-notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notifications: SignaREvent<any>[] = [];

  constructor(
    private signalRHubService: SignalRHubService,
    private toastService: ToastService,
  ) {
    this.signalRHubService.signalREvents$.subscribe((event) => {
      this.updateNotifications(event);
    });
  }

  updateNotifications(event: SignaREvent<any>) {
    const existingIndex = this.notifications.findIndex(item => item.id === event?.id);
    if (existingIndex !== -1) {
      this.notifications[existingIndex] = event;
    } else {
      this.notifications.unshift(event);
    }
    this.showToastForNotification(event);
  }

  showToastForNotification(event: SignaREvent<any>) {
    if (event.type === SignalREventTypes.AlertSignal) {
      const data = event.data as SignalRAlertSignalEvent<AlarmOrWarningEventData | AssetHealthStateChangeEventData | DeviceOnlineOrOfflineEventData>;
      const icon = this.getIconForAlertNotification(data);
      const toastModel: ToastModel = { title: this.getTitleForAlertSignalNotification(data), content: this.getMessageForAlertSignalNotification(data), position: { X: 'Right' }, showCloseButton: true, icon: icon, width: '25rem', timeOut: 5000 };
      this.toastService.showToast(toastModel);
    }
    if (event.type === SignalREventTypes.CommandSignal) {
      const data = event.data as SignalRCommandSignalEvent;
      const icon = this.getIconForCommandNotification(data?.responseCode);
      const toastModel: ToastModel = { title: this.getTitleForCommandSignalNotification(data), content: this.getMessageForCommandSignalNotification(data), position: { X: 'Right' }, showCloseButton: true, icon: icon, width: '25rem', timeOut: 5000 };
      this.toastService.showToast(toastModel);
    }
  }

  timeAgoValue(timeStamp: number | undefined) {
    if (!timeStamp) return;
    return new Date(timeStamp * 1000).timeAgo(new Date());
  }

  getTitleForCommandSignalNotification(commandSignalEvent?: SignalRCommandSignalEvent): string {
    if (!commandSignalEvent) return '';
    switch (commandSignalEvent.commandName) {
      case CommandNames.RebootGateway: {
        return `${this.getFormattedCommandName(commandSignalEvent.commandName)} - ${commandSignalEvent.gatewaySerialNumber}`;
      }
      case CommandNames.ReadFullSpectrum:
      case CommandNames.ReadLowFrequency: {
        const innerData = commandSignalEvent.data ? JSON.parse(commandSignalEvent.data) : '';
        return `${this.getFormattedCommandName(commandSignalEvent.commandName)} - ${innerData?.sensorSerialNumber as string}`;
      }
      default: {
        return commandSignalEvent.commandName;
      }
    }
  }

  getMessageForCommandSignalNotification(commandSignalEvent?: SignalRCommandSignalEvent): string {
    if (!commandSignalEvent) return '';
    switch (commandSignalEvent.commandName) {
      case CommandNames.RebootGateway: {
        return `${commandSignalEvent?.responseMessage}. Gateway Serial Number: ${commandSignalEvent.gatewaySerialNumber}. Command Id: ${commandSignalEvent.commandId}`;
      }
      case CommandNames.ReadFullSpectrum:
      case CommandNames.ReadLowFrequency: {
        const innerData = commandSignalEvent.data ? JSON.parse(commandSignalEvent.data) : '';
        return `${commandSignalEvent.responseMessage}. Sensor Serial Number: ${innerData?.sensorSerialNumber as string}. Command Id: ${commandSignalEvent.commandId}`;
      }
      default: {
        return `${commandSignalEvent.responseMessage}. Command Id: ${commandSignalEvent.commandId}`;
      }
    }
  }

  getTitleForAlertSignalNotification(alertEventData: SignalRAlertSignalEvent<AlarmOrWarningEventData | AssetHealthStateChangeEventData | DeviceOnlineOrOfflineEventData>): string {
    switch (alertEventData.eventType) {
      case AlertSignalEventTypes.AssetHealthStateChange:
      case AlertSignalEventTypes.Alarm:
      case AlertSignalEventTypes.Warning: {
        const data = alertEventData as SignalRAlertSignalEvent<AlarmOrWarningEventData>;
        return data.equipmentId ? `${data.eventType} - ${data.equipmentName}` : `${data.eventType} - Unassigned ${data.sourceId}`;
      }
      case AlertSignalEventTypes.DeviceOffline:
      case AlertSignalEventTypes.DeviceOnline: {
        const data = alertEventData as SignalRAlertSignalEvent<DeviceOnlineOrOfflineEventData>;
        return `${data.eventType} - ${data.objectType} ${data.sourceId}`;
      }
      default: {
        return '';
      }
    }
  }

  getMessageForAlertSignalNotification(alertEventData: SignalRAlertSignalEvent<AlarmOrWarningEventData | AssetHealthStateChangeEventData | DeviceOnlineOrOfflineEventData>): string {
    switch (alertEventData.eventType) {
      case AlertSignalEventTypes.Alarm:
      case AlertSignalEventTypes.Warning: {
        const data = alertEventData as SignalRAlertSignalEvent<AlarmOrWarningEventData>;
        return data.eventData.alertMessage;
      }
      case AlertSignalEventTypes.AssetHealthStateChange: {
        const data = alertEventData as SignalRAlertSignalEvent<AssetHealthStateChangeEventData>;
        return `Asset changed from ${data.eventData.previousHealthStatus} to ${data.eventData.currentHealthStatus}`;
      }
      case AlertSignalEventTypes.DeviceOnline: {
        const data = alertEventData as SignalRAlertSignalEvent<DeviceOnlineOrOfflineEventData>;
        return 'Device is now online';
      }
      case AlertSignalEventTypes.DeviceOffline: {
        const data = alertEventData as SignalRAlertSignalEvent<DeviceOnlineOrOfflineEventData>;
        return `Last online ${this.timeAgoValue(data.eventData.lastOnlineTimestamp) as string}`;
      }
      default: {
        return '';
      }
    }
  }

  getIconForAlertNotification(alertEventData: SignalRAlertSignalEvent<AlarmOrWarningEventData | AssetHealthStateChangeEventData | DeviceOnlineOrOfflineEventData>): string {
    switch (alertEventData.eventType) {
      case AlertSignalEventTypes.Alarm:
      case AlertSignalEventTypes.Warning: {
        const data = alertEventData as SignalRAlertSignalEvent<AlarmOrWarningEventData>;
        return this.getAssetHealthStatusIcon(data.eventType);
      }
      case AlertSignalEventTypes.AssetHealthStateChange: {
        const data = alertEventData as SignalRAlertSignalEvent<AssetHealthStateChangeEventData>;
        return this.getAssetHealthChangeStatusIcon(data.eventData.currentHealthStatus);
      }
      case AlertSignalEventTypes.DeviceOnline: {
        return 'device-online-icon';
      }
      case AlertSignalEventTypes.DeviceOffline: {
        return 'device-offline-icon';
      }
      default: {
        return '';
      }
    }
  }

  getAssetHealthStatusIcon(status: string): string {
    switch (status) {
      case AssetHealthStatus.Alarm:
        return 'alarm-icon';
      case AssetHealthStatus.Warning:
        return 'warning-icon';
      case AssetHealthStatus.Healthy:
        return 'healthy-icon';
      default:
        return '';
    }
  }

  getAssetHealthChangeStatusIcon(status: string): string {
    switch (status) {
      case AssetHealthStatus.Alarm:
        return 'asset-health-change-alarm-icon';
      case AssetHealthStatus.Warning:
        return 'asset-health-change-warning-icon';
      case AssetHealthStatus.Healthy:
        return 'asset-health-change-healthy-icon';
      default:
        return '';
    }
  }

  getIconForCommandNotification(responseCode: number): string {
    if (responseCode === 200) {
      return 'success-icon';
    } else if (responseCode > 200 && responseCode < 400) {
      return 'loading-icon';
    } else if (responseCode >= 400 && responseCode < 600) {
      return 'failure-icon';
    } else {
      return '';
    }
  }

  getFormattedCommandName(commandName: string): string {
    if (!commandName) return commandName;
    return commandName.charAt(0).toUpperCase() + commandName.slice(1);
  }
}
