export class EventsConstants {
  public static readonly DISASSOCIATE_GATEWAY_FROM_GATEWAY_DETAILS = 'disassociate-gateway-from-gateway-details';
  public static readonly DISASSOCIATE_GATEWAY_FROM_SENSOR_DETAILS = 'disassociate-gateway-from-sensor-details';
  public static readonly UNASSIGN_EQUIPMENT_FROM_SENSOR_DETAILS = 'unassign-equipment-from-sensor-details';
  public static readonly SENSOR_TELEMETRY_GRAPH_S7100 = 'sensor-telemetry-graph-s7100';
  public static readonly SAVE_AND_EXIT_BUTTON_CLICK = 'save-and-exit';
  public static readonly SAVE_BUTTON_CLICK = 'save';
  public static readonly CANCEL = 'cancel';
  public static readonly INVALID_GATEWAY = 'invalid-gateway-serial-number';
  public static readonly INVALID_SENSOR = 'invalid-sensor-serial-number';
  public static readonly ASSIGN_GATEWAY_TO_SITE = 'assign-gateway-to-site';
  public static readonly ASSIGN_GATEWAY_TO_AREA = 'assign-gateway-to-area';
  public static readonly UNASSIGN_GATEWAY_FROM_SITE = 'unassign-gateway-from-site';
  public static readonly UNASSIGN_GATEWAY_FROM_AREA = 'unassign-gateway-from-area';
  public static readonly UNASSIGN_SENSOR_FROM_EQUIPMENT = 'unassign-sensor-from-equipment';
  public static readonly ASSIGN_SENSOR_TO_EQUIPMENT = 'assign-sensor-to-equipment';
  public static readonly DISASSOCIATE_SENSOR_FROM_GATEWAY_DETAILS = 'disassociate-sensor-from-gateway-details';
  public static readonly USER_ROLE_ASSIGNMENT_MODAL_ID = 'user-role-assignment-modal-id';
  public static readonly USER_PREFERENCES_MODAL_ID = 'user-preferences-modal-id';
  public static readonly IMAGE_UPLOAD_MODAL_ID = 'image-upload-modal-id';
  public static readonly INITIATE_CAMPAIGN_MODAL_ID = 'initiate-campaign-modal-id';
  public static readonly CONFIRM_ASSOCIATE_GATEWAY_MODAL_ID = 'confirm-associate-gateway-modal-id';
  public static readonly CONFIGURE_INDUSTRIAL_PROTOCOL_COMMUNICATION_MODAL_ID = 'configure_industrial_protocol_communication_modal_id';
  public static readonly TECHNICAL_DETAILS_MODAL_ID = 'technical-details-modal-id';
  public static readonly FORCE_DISASSOCIATE_SENSOR_FROM_GATEWAY_DETAILS = 'force-disassociate-sensor-from-gateway-details';
  public static readonly UPLOAD_ARTIFACT_MODAL_ID = 'device-info-modal-id';
  public static readonly CREATE_GROUP_MODAL_ID = 'create-group-modal-id';
  public static readonly ADD_TAG_MODAL_ID = 'add-tag-modal-id';
}

export class GridSignatureConstants {
  public static readonly NOTIFICATION_ASSET_GRID = 'EquipmentTreeGridSignature';
  public static readonly NOTIFICATION_DEVICE_GRID = 'DeviceTreeGridSignature';
}
export class WindowPeriodConstants {
  public static readonly NUMBER_OF_HOURS_IN_A_DAY = 24;
  public static readonly NUMBER_OF_DAYS_IN_A_WEEK = 7;
  public static readonly NUMBER_OF_DAYS_IN_A_MONTH = 30;

  public static readonly DAILY_WINDOW_PERIOD = WindowPeriodConstants.NUMBER_OF_HOURS_IN_A_DAY;
  public static readonly WEEKLY_WINDOW_PERIOD = WindowPeriodConstants.NUMBER_OF_HOURS_IN_A_DAY * WindowPeriodConstants.NUMBER_OF_DAYS_IN_A_WEEK;
  public static readonly MONTHLY_WINDOW_PERIOD = WindowPeriodConstants.NUMBER_OF_HOURS_IN_A_DAY * WindowPeriodConstants.NUMBER_OF_DAYS_IN_A_MONTH;
}

export class NotificationRuleEventType {
  public static readonly TIMER_BASED_NOTIFICATION = 'Timer';
  public static readonly EVENT_BASED_NOTIFICATION = 'Event';
}

export class AppMessages {
  public static readonly SELECT_ENTERPRISE_MESSAGE = 'Please select an enterprise or site to proceed to the customer dashboard';
}

export class SignalREvents {
  public static SEND_NOTIFICATION = 'SendNotification';
}

export class SignalRMethods {
  public static SET_SUBSCRIBED_SCOPES = 'setSubscribedScopes';
}

