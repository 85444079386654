import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomEvent } from '../../models/custom-event';
import { CustomEventSource, CustomEventType } from '../../app-constants/shared.enum';
import { KebabMenuListItem } from '../../models/tree-grid';

@Component({
  selector: 'app-kebab-menu',
  templateUrl: './kebab-menu.component.html',
  styleUrls: ['./kebab-menu.component.scss']
})
export class KebabMenuComponent {
  @Input() label: string = '';
  @Input() menuItems?: KebabMenuListItem[];
  @Input() rowData: any;
  @Output() kebabMenuClickEvent = new EventEmitter<CustomEvent>();

  kebabMenuClick(e: MouseEvent | KeyboardEvent) {
    const event: CustomEvent = { eventType: CustomEventType.CLICK, eventSource: CustomEventSource.KEBAB_MENU, eventData: { clickEvent: e, items: this.menuItems, rowData: this.rowData } };
    this.kebabMenuClickEvent.emit(event);
  }
}
