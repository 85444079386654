import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OKTA_AUTH, OKTA_CONFIG, OktaAuthConfigService, OktaAuthGuard, OktaConfig } from '@okta/okta-angular';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { UserService } from 'src/app/customer/user-management/services/user-services/user.service';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { PermissionCheckerService } from './permission-checker.service';
import { SignalRHubService } from 'src/app/shared/services/signalr-services/signalr-hub.service';
import { RoleType } from 'src/app/shared/app-constants/role-constants';
import { UserDetails } from 'src/app/admin/user-management/models/user-details';
import { SignalREventTypes } from 'src/app/shared/app-constants/shared.enum';
import { IdleTimeoutService } from './idle-timeout.service';

@Injectable({
  providedIn: 'root'
})
export class OktaGuardService extends OktaAuthGuard {
  constructor(
    @Inject(OKTA_AUTH) public oktaAuthService: OktaAuth,
    @Inject(OKTA_CONFIG) public oktaConfig: OktaConfig,
    private permissionChecker: PermissionCheckerService,
    private router: Router,
    private sessionService: SessionService,
    public injectokta: Injector,
    public oktaAuthConfigService: OktaAuthConfigService,
    private authService: AuthService,
    private userService: UserService,
    private signalRHubService: SignalRHubService,
    private idleTimeoutService: IdleTimeoutService

  ) {
    super(new OktaAuth({
      ...environment.oktaAuthConfiguration as OktaAuthOptions
    }), injectokta, oktaAuthConfigService);

    this.signalRHubService.accessTokenFactory = async() => {
      if (!await this.isAuthenticated())
        return '';
      const accessToken = await this.oktaAuthService.tokenManager.get('accessToken');

      if (accessToken && this.oktaAuthService.tokenManager.hasExpired(accessToken)) {
        const token = await this.oktaAuthService.getOrRenewAccessToken();

        if (token && token !== null)
          return token;
      }
      return this.oktaAuthService.getAccessToken() ?? '';
    };
  }

  override async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isValidToken = await super.canActivate(route, state);
    const permissionsRequiredForRequestedRoute: string = route.data['permission'];
    const roleRequiredForRequestedRoute: string[] = route.data['role'];
    if (isValidToken) {
      this.idleTimeoutService.trackIdleSession();
      let isAuthorised = false;
      if (!this.sessionService.userId || !this.sessionService.emailId) await this.setSession();
      let isRole = false;
      if (roleRequiredForRequestedRoute) isRole = isAuthorised = this.permissionChecker.hasRequiredRole(this.sessionService.roleName, roleRequiredForRequestedRoute);
      if (isRole && permissionsRequiredForRequestedRoute) isAuthorised = this.permissionChecker.hasPermission(this.sessionService.permissions, permissionsRequiredForRequestedRoute);
      return isAuthorised;
    }
    else {
      this.onAuthRequire();
      this.idleTimeoutService.clearSessionTracking();
      return false;
    }
  }

  override canActivateChild = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => this.canActivate(route, state);

  onAuthRequire() {
    this.router.navigate(['/login']);
  }

  async isAuthenticated() {
    return await this.oktaAuthService.isAuthenticated();
  }

  async setSession() {
    const user = this.oktaAuthService.getUser();
    this.sessionService.userId = (await user).sub;
    this.sessionService.emailId = (await user).email;
    this.sessionService.roles = await this.authService.getRoles();
    const userFromToken: UserDetails = await this.userService.getUserByToken();
    if (userFromToken.globalRoles && userFromToken.globalRoles.length > 0) {
      this.sessionService.allRolesAssignedToUser = [ { roleId: userFromToken.globalRoles[0].roleId, scope: '/' } ];
    } else {
      this.sessionService.allRolesAssignedToUser = await this.authService.getAllRoleAssignmentsOfAUser(this.sessionService.tenantId, this.sessionService.userId);
    }

    const getPermissionsOfAUser = this.sessionService.allRolesAssignedToUser.find((role) => role.roleId !== '');
    const getRoleInfo = this.sessionService.roles.find(r => r.id === getPermissionsOfAUser?.roleId);
    if (getRoleInfo) {
      this.sessionService.permissions = getRoleInfo.permissions;
      this.sessionService.roleName = getRoleInfo.name;
    }
    if (!this.sessionService.userDetails) {
      let scope = '/';
      if (this.sessionService.allRolesAssignedToUser) scope = this.sessionService.allRolesAssignedToUser[0].scope;
      if (scope !== '/') {
        this.sessionService.userScope = scope;
        this.sessionService.defaultEnterpriseId = scope.split('/')[1];
      }
      this.sessionService.userDetails = userFromToken;
      this.sessionService.userDetails.fullName = `${this.sessionService.userDetails?.firstName} ${this.sessionService.userDetails?.lastName}`;
      if (this.sessionService.userDetails.globalRoles && this.sessionService.userDetails.globalRoles.length > 0) {
        this.sessionService.roleType = RoleType.GLOBAL_ROLE;
        this.sessionService.isAdminRole = true;
        this.sessionService.tenantId = environment.tenantId;
      } else if (this.sessionService.userDetails.tenants && this.sessionService.userDetails.tenants.length > 0 && this.sessionService.userDetails.tenants[0].roleAssignments && this.sessionService.userDetails.tenants[0].roleAssignments.length > 0) {
        this.sessionService.roleType =  RoleType.TENANT_ROLE;
        this.sessionService.isAdminRole = this.sessionService.userDetails.tenants[0].roleAssignments[0].isAdminRole;
        this.sessionService.tenantId =  this.sessionService.userDetails.tenants[0].tenantId;
      }
    }
    this.signalRHubService.setUpHub(this.sessionService.tenantId, this.sessionService.emailId as string);
    this.signalRHubService.startHub().subscribe(res => this.signalRHubService.setSubscribedScopes([this.sessionService.userScope ?? '/'], SignalREventTypes.AlertSignal).subscribe());
  }
}
