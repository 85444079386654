<span class="status-bar">
  <span *ngFor="let statusIndicator of statusIndicators" class="status-bar-item" [ngClass]="{'alert-bg':statusIndicator.type==='1','warning-bg':statusIndicator.type==='2'
,'good-bg':statusIndicator.type==='3','system-bg':statusIndicator.type==='4', 'system-offline-bg':statusIndicator.type==='5'}"
    (mouseover)="showValue(statusIndicator.type)" (focus)="showValue(statusIndicator.type)" (blur)="resetStatus()" (mouseleave)="resetStatus()">
    <span *ngIf="statusIndicator.type===selectedType">{{statusIndicator.value}}</span>
    <span *ngIf="statusIndicator.type!==selectedType">
      <img *ngIf="statusIndicator.type==='1'" src="assets/icons-and-logos/status/alert.svg" alt="alert">
      <img *ngIf="statusIndicator.type==='2'" src="assets/icons-and-logos/status/warning.svg" alt="warning">
      <img *ngIf="statusIndicator.type==='3'" src="assets/icons-and-logos/status/good.svg" alt="good">
      <img *ngIf="statusIndicator.type==='4'" src="assets/icons-and-logos/status/system.svg" alt="system">
      <img *ngIf="statusIndicator.type==='5'" src="assets/icons-and-logos/status/system-offline.svg" alt="system-offline">
    </span>
  </span>
</span>