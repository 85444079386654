import { AssetsTree } from 'src/app/shared/models/asset';
import { ButtonData } from 'src/app/shared/models/shared.models';
import { ButtonLabels, ButtonNames, ButtonTypes, CustomEventSource, CustomEventType, GridUIType } from 'src/app/shared/app-constants/shared.enum';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { RoleAssignmentModalResponse, UserRole } from '../../models/user-details';
import { Tenant } from 'src/app/shared/models/admin/tenant';
import { TreeGridColumnData } from 'src/app/shared/models/tree-grid';
import { TreeGridComponent } from 'src/app/shared/components/tree-grid/tree-grid.component';
import { TreeGridHelperService } from 'src/app/shared/services/tree-grid-helper-services/tree-grid-helper.service';
import { UserList } from 'src/app/customer/user-management/models/user-list';
import { UserService } from '../../services/user.service';
import { AssetApiIncludeConstants, Scope } from 'src/app/shared/app-constants/asset-constants';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { RoleName, RoleType } from 'src/app/shared/app-constants/role-constants';
import { UtilitiesService } from 'src/app/shared/services/utlities-services/utilities.service';

@Component({
  selector: 'app-role-and-scope-assignment',
  templateUrl: './role-and-scope-assignment.component.html',
  styleUrls: ['./role-and-scope-assignment.component.scss']
})
export class RoleAndScopeAssignmentComponent implements OnInit {
  @ViewChild('scopeGrid') scopeGrid!: TreeGridComponent;
  @Input() id!: string;
  @Input() selectedUsers: UserList[] = []; //Users list if navigated from users list else undefined
  @Input() roleIdToSelect!: string;
  @Input() scopeIdToSelect!: string;
  selectTenantDisabled: boolean = true;
  enterpriseDataDisabled: boolean = true;
  validationFailed: boolean = false;
  formGroup!: FormGroup;
  cancelButtonData: ButtonData = { label: ButtonLabels.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.CANCEL };
  saveButtonData: ButtonData = { label: ButtonLabels.SAVE, type: ButtonTypes.PRIMARY_BASIC, name: ButtonNames.SAVE_MODAL, width: '10rem' };
  selectRoleOptions: UserRole[] = [];
  selectRoleOptionsFieldMapping: Object = { text: 'name', value: 'id' };
  selectTenantOptions: Tenant[] = [];
  selectTenantOptionsFieldMapping: Object = { text: 'name', value: 'id' };
  treeChildMapping: string = '';
  treeColumns: Array<TreeGridColumnData> = []; // Holds the columns for the grid
  enterpriseData: AssetsTree[] = [];
  gridUIType: GridUIType = GridUIType.DEFAULT;
  gridHeight: string = '40vh';
  private readonly PERCEPTIV: string = 'Perceptiv';

  constructor(
    private treeGridHelperService: TreeGridHelperService,
    private modalDialogService: ModalDialogService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private utilitiesService: UtilitiesService
  ) { }

  ngOnInit(): void {
    this.treeColumns = this.treeGridHelperService.createTreeGridHeadersForRoleAssignment();
    this.treeChildMapping = this.treeGridHelperService.getChildMappingForAssetTreeData();
    this.createFormGroup();
    this.formGroup?.get('selectedRole')?.valueChanges.subscribe((value: string) => {
      if (this.selectRoleOptions) {
        const roleId = value;
        const role = this.selectRoleOptions.find(x => x.id === roleId);
        if (role) {
          switch (role.type) {
            case RoleType.GLOBAL_ROLE:
              {
                this.selectTenantDisabled = true;
                this.enterpriseDataDisabled = true;
              }
              break;
            case RoleType.TENANT_ROLE: {
              this.selectTenantDisabled = false;
              this.enterpriseDataDisabled = role.isAdminRole;
            }
              break;
            default:
            {
              this.selectTenantDisabled = false;
              this.enterpriseDataDisabled = false;
            }
          }
        }
      }
    });
    this.formGroup?.get('selectedTenant')?.valueChanges.subscribe((value: string) => {
      this.fetchAssets(value);
    });

    this.fetchRoles();
    this.fetchTenants();
  }

  preCheckScope(): void {
    if (!this.utilitiesService.isNullOrEmptyOrWhitespace(this.scopeIdToSelect) && this.scopeIdToSelect !== Scope.DEFAULT_SCOPE)
    {
      const allScopeIndexes: number[] = [];
      this.scopeGrid?.treeGrid?.expandAll();
      const scopeRowInfo = this.scopeGrid?.treeGrid?.getVisibleRecords();
      const scopeIds = this.scopeIdToSelect.split(Scope.DEFAULT_SCOPE).filter(guid => !this.utilitiesService.isNullOrEmptyOrWhitespace(guid));
      const scopeId = scopeIds[scopeIds.length - 1];

      const index = scopeRowInfo?.findIndex(
        (row: any) => row.id === scopeId
      );
      if (index !== -1)
        allScopeIndexes.push(index);
      setTimeout(() => {
        this.scopeGrid.treeGrid.selectCheckboxes(allScopeIndexes);
      }, 100);
    }
  }

  cancel() {
    this.modalDialogService.closeModal();
  }

  fetchRoles() {
    this.userService.getRoles().subscribe(res => {
      const result = this.filterRoles(res);
      this.selectRoleOptions = result;
      if (this.roleIdToSelect) {
        this.setDefaultRole(this.roleIdToSelect);
      }
      else {
        this.setDefaultRole(result?.[0]?.id);
      }
    });
  }

  filterRoles(roles: UserRole[]) : UserRole[] {
    if (this.sessionService.roleType === RoleType.GLOBAL_ROLE) return roles;
    if (this.sessionService.roleType === RoleType.TENANT_ROLE && this.sessionService.isAdminRole) return roles.filter(r => r.type === RoleType.TENANT_ROLE);
    return roles.filter(r => r.type === RoleType.TENANT_ROLE && !r.isAdminRole);
  }

  setDefaultRole(id: string) {
    this.formGroup?.get('selectedRole')?.patchValue(id);
  }

  fetchTenants() {
    if (this.sessionService.roleType === RoleType.GLOBAL_ROLE) {
      this.userService.getTenants().subscribe(res => {
        this.selectTenantOptions = res;
        this.setDefaultTenant(res?.[0]?.id);
      });
    }
    else {
      const perceptivTenant = {} as Tenant;
      perceptivTenant.id = this.sessionService.tenantId;
      perceptivTenant.name = this.PERCEPTIV;
      this.selectTenantOptions = [ perceptivTenant ];
      this.setDefaultTenant(this.sessionService.tenantId);
    }
  }

  setDefaultTenant(id: string) {
    this.formGroup?.get('selectedTenant')?.patchValue(id);
  }

  fetchAssets(tenantId: string) {
    this.treeGridHelperService.getEnterpriseTree(tenantId).subscribe(res => {
      this.enterpriseData = res;
      setTimeout(() => {
        this.preCheckScope();
      }, 200);
    });
  }

  createFormGroup() {
    this.formGroup = this.formBuilder.group({
      selectedRole: [null, [Validators.required]],
      selectedTenant: [null]
    });
  }

  save() {
    const selectedRole = this.selectRoleOptions?.find(x => x.id === this.formGroup?.get('selectedRole')?.value);
    const selectedTenant = this.selectTenantOptions?.find(x => x.id === this.formGroup?.get('selectedTenant')?.value);
    const selectedAssets = this.scopeGrid.treeGrid.getCheckedRecords();
    this.checkValidation(selectedAssets);
    if (this.validationFailed) {
      return;
    }
    const data: RoleAssignmentModalResponse = { selectedRole: selectedRole, selectedTenant: selectedTenant, selectedAssets: selectedAssets, selectedUsers: this.selectedUsers };
    const customData: CustomEvent = { eventSource: CustomEventSource.MODAL, eventType: CustomEventType.SAVE_USER_ROLE_ASSIGNMENT, eventData: { id: this.id, data: data } };
    this.modalDialogService.emitModalDialogEvent(customData);
  }

  checkValidation(selectedAssets: Object[]): void {
    if (this.enterpriseDataDisabled) {
      this.validationFailed = false;
    }
    else
    {
      this.validationFailed = selectedAssets.length === 0;
    }
  }

  onCheckBoxSelectionChange(evt: CustomEvent) {
    if (evt.eventSource === CustomEventSource.TREEGRID && (evt.eventType === CustomEventType.ROW_CHECKED || evt.eventType === CustomEventType.ROW_UNCHECKED)) {
      const selectedIndexs = this.scopeGrid.treeGrid.getCheckedRowIndexes();
      if (evt.eventData.rowData.childRecords) {
        const selectedChildRecords = evt.eventData.rowData.childRecords.filter((cr: { index: number; }) => selectedIndexs.indexOf(cr.index) !== -1);
        const unSelectedChildRecords = evt.eventData.rowData.childRecords.filter((cr: { index: number; }) => selectedIndexs.indexOf(cr.index) === -1);
        let selectedIndices: number[];
        if (evt.eventData.checked) {
          selectedIndices = unSelectedChildRecords.map((cr: { index: number; }) => cr.index);
        }
        else {
          selectedIndices = selectedChildRecords.map((cr: { index: number; }) => cr.index);
        }
        this.scopeGrid.treeGrid.selectCheckboxes(selectedIndices);
      }
      else if (!evt.eventData.checked && evt.eventData.rowData.parentItem) {
        if (selectedIndexs.indexOf(evt.eventData.rowData.parentItem.index as number) !== -1) {
          const arrParentItemIndex: number[] = [];
          arrParentItemIndex.push(evt.eventData.rowData.parentItem.index as number);
          this.scopeGrid.treeGrid.selectCheckboxes(arrParentItemIndex);
        }
      }
    }
  }
}
