import { ApiResponse } from '../../models/api-response';
import { AxialData, SensorParameterData } from 'src/app/admin/sensors-info/models/sensor-thresholds';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap } from 'rxjs';
import { THRESHOLD_API_ENDPOINTS } from '../../app-constants/api-constants';

@Injectable()
export class ThresholdConfigurationService {
  constructor(
    private errorHandlerService: ErrorHandlerService,
    private http: HttpClient,
  ) { }

  public getThresholdConfig(tenantId: string, sensorType: string, sensorSerialNumber: string): Observable<SensorParameterData> {
    const url = environment.dashboardApiBaseUrl + THRESHOLD_API_ENDPOINTS.THRESHOLD_CONFIG(tenantId, sensorType, sensorSerialNumber);
    return this.http.get<ApiResponse<SensorParameterData>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public saveThresholdConfig(tenantId: string, sensorType: string, sensorSerialNumber: string, sensorParameterData: SensorParameterData): Observable<AxialData[]> {
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = environment.dashboardApiBaseUrl + THRESHOLD_API_ENDPOINTS.THRESHOLD_CONFIG(tenantId, sensorType, sensorSerialNumber);
    return this.http.post<ApiResponse<AxialData[]>>(url, JSON.stringify(sensorParameterData), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  getThresholdConfigForSelectedProfile(tenantId: string, sensorType: string, profileKey: string): Observable<SensorParameterData> {
    const url = environment.dashboardApiBaseUrl + THRESHOLD_API_ENDPOINTS.THRESHOLD_CONFIG_BY_PROFILE(tenantId, sensorType, profileKey);
    return this.http.get<ApiResponse<SensorParameterData>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((httpError: HttpErrorResponse) => {
          this.errorHandlerService.showErrorPopUp(httpError);
          throw httpError;
        })
      );
  }
}
