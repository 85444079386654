<form [formGroup]="formGroup" class="notification-preferences-container">
    <div class="row-container">
      <p>Email </p>
      <div class="phone-input-group">
        <span>{{userDetails?.email}}</span>
      </div>
      <div class="vertical-container">
        <ejs-switch formControlName="emailNotifications"></ejs-switch>
        <span class="checkbox-title">Notify by Email</span>
      </div>
    </div>
    <div class="row-container">
      <p>Mobile Phone</p>
      <div class="phone-input-group">
        <ejs-dropdownlist id="countryCode" [dataSource]="countryCodes" placeholder="Country Code"
          formControlName="countryCode" class="extension-input"></ejs-dropdownlist>
        <input class="need-css-validation" type="text" formControlName="phoneNumber" placeholder="Phone Number">
      </div>
      <div class="vertical-container">
        <ejs-switch formControlName="smsNotifications"></ejs-switch>
        <span class="checkbox-title">Notify by SMS</span>
      </div>
    </div>

    <div class="row-grid-container">
      <h3>Your Roles</h3>
      <app-tree-grid [data]="userAssignedRoles" [columns]="rolesGridColumnData" [gridUIType]="gridUIType"
        [allowSorting]="false" [allowPaging]="true" [allowMultiselect]="true"></app-tree-grid>
    </div>
    <ejs-accordion (expanded)="toggleText($event)">
      <e-accordionitems>
        <e-accordionitem expanded='true'>
          <ng-template #header>
            <h3>Assigned Notifications</h3>
            <h6 class="accordion-text">{{ expansionText }}</h6>
          </ng-template>
          <ng-template #content>
            <app-tree-grid [data]="assignedNotificationData" [gridUIType]="gridUIType"
              [columns]="assignedNotificationGridColumnHeaders" (treeGridEvent)="receiveTreeGridEvent($event)" [allowPaging]="true"></app-tree-grid>
          </ng-template>
        </e-accordionitem>
      </e-accordionitems>
    </ejs-accordion>
    <div class="button-container">
      <app-custom-button (buttonClick)="buttonClick($event)" [buttonData]="cancelButtonData" />
      <app-custom-button (buttonClick)="buttonClick($event)" [buttonData]="saveAndExitButtonData" />
      <app-custom-button (buttonClick)="buttonClick($event)" [buttonData]="saveButtonData" />
    </div>
  </form>
