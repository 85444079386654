import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminInfoRoutes, ArtifactManagementRoutes } from 'src/app/admin/constants/route.constants';
import { ButtonLabels, ButtonNames, ButtonSizes, ButtonTypes } from 'src/app/shared/app-constants/shared.enum';
import { ButtonData } from 'src/app/shared/models/shared.models';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { ArtifactManagementServiceService } from '../../services/artifact-management-data-service.service';
import { ButtonStateService } from 'src/app/shared/services/button-state-service/button-state.service';

@Component({
  selector: 'app-upload-artifact',
  templateUrl: './upload-artifact.component.html',
  styleUrls: ['./upload-artifact.component.scss'],
})
export class UploadArtifactComponent implements OnInit {
  @Input() data:any;
  artifactFileData = {} as File;
  cancelButtonData: ButtonData = { label: ButtonLabels.CANCEL, name: ButtonNames.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER };
  formGroup!: FormGroup;
  uploadButtonData: ButtonData = { label: ButtonLabels.UPLOAD, name: ButtonNames.UPLOAD, type: ButtonTypes.PRIMARY_BASIC, disable: true };

  constructor(
    private router: Router,
    private modalDialogService: ModalDialogService,
    private formBuilder: FormBuilder,
    private artifactManagementService: ArtifactManagementServiceService,
    private buttonStateService: ButtonStateService
  ) {}

  ngOnInit():void {
    this.createFormGroup();
  }

  createFormGroup() {
    this.formGroup = this.formBuilder.group({
      fileName: [null],
    });
  }

  deleteFile(): void {
    this.artifactFileData = {} as File;
  }

  onFilePicked(event: any) {
    const file:File = event.target.files[0]; // Here we use only the first file (single file)
    this.buttonStateService.setButtonDisableState('upload', false);
    this.artifactFileData = file;
    this.artifactManagementService.saveFileData(file);
  }

  buttonclick(): void {
    const type = this.artifactManagementService.getArtifactType();
    if (type === 'OS') {
      this.modalDialogService.closeModal();
      const url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${ArtifactManagementRoutes.ARTIFACT_MANAGEMENT_BASE_ROUTE}/${ArtifactManagementRoutes.UPLOAD_ARTIFACT_OS}`;
      this.router.navigate([url]);
      this.artifactFileData = {} as File;
    } else if (type === 'firmware') {
      this.modalDialogService.closeModal();
      const url = `/${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${ArtifactManagementRoutes.ARTIFACT_MANAGEMENT_BASE_ROUTE}/${ArtifactManagementRoutes.UPLOAD_ARTIFACT_FIRMWARE}`;
      this.router.navigate([url]);
      this.artifactFileData = {} as File;
    }
  }

  onCancel(): void {
    this.artifactManagementService.deleteFileData();
    this.artifactFileData = {} as File;
    this.modalDialogService.closeModal();
  }
}
