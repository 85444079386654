import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-middle-ellipsis-template',
  templateUrl: './middle-ellipsis-template.component.html',
  styleUrls: ['./middle-ellipsis-template.component.scss']
})
export class MiddleEllipsisTemplateComponent implements OnInit, OnChanges {
  @Input() value!: string;
  @Input() additionalCss!: string | undefined;
  startSubString: string = '';
  endSubString: string = '';

  ngOnInit(): void {
    this.updateSubstrings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['value'];
    if (change && change.currentValue !== change.previousValue) {
      this.updateSubstrings();
    }
  }

  private updateSubstrings(): void {
    if (this.value && this.value.length > 5) {
      this.endSubString = this.value.slice(-5); // Last 5 characters
      this.startSubString = this.value.slice(0, -5); // All characters except the last 5
    } else {
      // If the input is less than or equal to 5 characters, all go to the endSubString
      this.startSubString = '';
      this.endSubString = this.value;
    }
  }
}

