import { Component, Input } from '@angular/core';
import { IconSize } from '../../app-constants/shared.enum';
import { SignalStrength } from '../../app-constants/strength-enum';

@Component({
  selector: 'app-signal-bar',
  templateUrl: './signal-bar.component.html',
  styleUrls: ['./signal-bar.component.scss']
})
export class SignalBarComponent {
  @Input() strength: SignalStrength = SignalStrength.REPLACE;
  @Input() size:IconSize = IconSize.SIZE_XXL;
}
