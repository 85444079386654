import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConnectionStatus } from '../../app-constants/asset-constants';

@Component({
  selector: 'app-connection-status-template',
  templateUrl: './connection-status-template.component.html',
  styleUrls: ['./connection-status-template.component.scss']
})
export class ConnectionStatusTemplateComponent implements OnChanges {
  @Input() connectionStatus!: string | number;
  connectionStatusLabel: string = '';
  connectionCssClass: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['connectionStatus']) {
      this.updateConnectionStatus();
    }
  }

  private updateConnectionStatus(): void {
    if (this.connectionStatus === 'true' || this.connectionStatus === 1) {
      this.connectionStatusLabel = ConnectionStatus.CONNECTED.toUpperCase();
      this.connectionCssClass = ConnectionStatus.CONNECTED;
    } else {
      this.connectionStatusLabel = ConnectionStatus.DISCONNECTED.toUpperCase();
      this.connectionCssClass = ConnectionStatus.DISCONNECTED;
    }
  }
}
