<div class="sensor-scheduler">
  <div class="grid-title">
    <h2 class="dialog-box-tittle">Sensor Measurement Scheduler</h2>
    <ejs-tooltip id="tooltipFocus" [content]="flyoutContent" opensOn='Auto'>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 51 51" fill="none">
        <g clip-path="url(#clip0_5000_9931)">
          <path
            d="M25.5 51C32.263 51 38.749 48.3134 43.5312 43.5312C48.3134 38.749 51 32.263 51 25.5C51 18.737 48.3134 12.251 43.5312 7.46878C38.749 2.6866 32.263 0 25.5 0C18.737 0 12.251 2.6866 7.46878 7.46878C2.6866 12.251 0 18.737 0 25.5C0 32.263 2.6866 38.749 7.46878 43.5312C12.251 48.3134 18.737 51 25.5 51ZM21.5156 33.4688H23.9062V27.0938H21.5156C20.1908 27.0938 19.125 26.0279 19.125 24.7031C19.125 23.3783 20.1908 22.3125 21.5156 22.3125H26.2969C27.6217 22.3125 28.6875 23.3783 28.6875 24.7031V33.4688H29.4844C30.8092 33.4688 31.875 34.5346 31.875 35.8594C31.875 37.1842 30.8092 38.25 29.4844 38.25H21.5156C20.1908 38.25 19.125 37.1842 19.125 35.8594C19.125 34.5346 20.1908 33.4688 21.5156 33.4688ZM25.5 12.75C26.3454 12.75 27.1561 13.0858 27.7539 13.6836C28.3517 14.2814 28.6875 15.0921 28.6875 15.9375C28.6875 16.7829 28.3517 17.5936 27.7539 18.1914C27.1561 18.7892 26.3454 19.125 25.5 19.125C24.6546 19.125 23.8439 18.7892 23.2461 18.1914C22.6483 17.5936 22.3125 16.7829 22.3125 15.9375C22.3125 15.0921 22.6483 14.2814 23.2461 13.6836C23.8439 13.0858 24.6546 12.75 25.5 12.75Z"
            fill="#2E3238" />
        </g>
        <defs>
          <clipPath id="clip0_5000_9931">
            <rect width="51" height="51" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ejs-tooltip>
  </div>
  <form [formGroup]="sensorSchedulerForm">
    <div class="grid-container">

      <div class="grid-item">
        <input type="text" class="timezoneClass" id="timezoneOffset" formControlName="timezoneOffset">
      </div>
      <div class="grid-item">
        <label for="timezone">Gateway Timezone</label>
      </div>
      <div class="grid-item">
        <ejs-dropdownlist class="dropdown-width" id="beginMeasurement" formControlName="wakeUpTime"
          [dataSource]='beginMeasurement' (change)="createSchedule()" (select)="createSchedule()"
          [fields]="fields"></ejs-dropdownlist>
      </div>
      <div class="grid-item">
        <label for="beginMeasurement">Begin Measurement window</label>
      </div>
      <div class="grid-item">
        <ejs-dropdownlist class="dropdown-width" id="endMeasurement" formControlName="sleepTime"
          [dataSource]='beginMeasurement' (change)="createSchedule()" (select)="createSchedule()"
          [fields]="fields"></ejs-dropdownlist>
      </div>
      <div class="grid-item">
        <label for="endMeasurement">End Measurement window</label>
      </div>
      <div class="grid-item">
        <ejs-dropdownlist class="dropdown-width" id="mintuesOffset" formControlName="measurementIntervalInMinutes"
          [dataSource]='intervalOptions' (change)="createSchedule()" (select)="createSchedule()"
          [fields]="fields"></ejs-dropdownlist>
        <div *ngIf="isError">
          <small class="error-message">Measurement Interval should not exceed Begin and End measurement schedule</small>
        </div>
        <div *ngIf="isErrorMeasureExceeded">
          <small class="error-message">Begin Measurement should not exceed End Measurement</small>
        </div>
      </div>
      <div class="grid-item">
        <label for="intervalMeasurement">Measurement Interval</label>
      </div>

      <div class="grid-item">
        <input type="number" label="offsetTime" class="Offset" id="mintuesOffset" formControlName="offsetTime" (keydown)="createSchedule()">
        <div *ngIf="sensorSchedulerForm && sensorSchedulerForm.get('offsetTime')?.hasError('pattern')">
          <small class="error-message">
            Acceptable range is 0 to 59.
          </small>
        </div>
      </div>

      <div class="grid-item">
        <label for="mintuesOffset">Minutes Offset </label>
      </div>
    </div>

    <div formArrayName="schedule" class="timelist">
      <div class="grid-container-form-array columns-container">
        <div id="time" class="grid-item">
          <label for="time">Time</label>
        </div>
        <div id="calculated" class="grid-item">
          <label for="calculated">Calculated</label>
        </div>
        <div id="fullWaveform" class="grid-item">
          <label for="fullWaveform">Full Waveform</label>
        </div>
        <div id="lowFrequency" class="grid-item">
          <label for="lowFrequency">Low Frequency</label>
        </div>
      </div>
      <div class="scheduler-body">
        <div *ngFor="let schedulerForm of schedule.controls; let i = index">
          <div class="lesson-form-row" [formGroupName]="i">
            <div class="grid-container-form-array scheduler-checkbox">
              <div class="grid-item">
                {{getHoursbyDigit(schedule.value[i].time)}}
              </div>
              <div class="grid-item"><ejs-checkbox formControlName="calculated"></ejs-checkbox></div>
              <div class="grid-item"><ejs-checkbox formControlName="fullWaveform"></ejs-checkbox></div>
              <div class="grid-item"><ejs-checkbox formControlName="lowFrequency"></ejs-checkbox></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <app-custom-button [buttonData]="restoreDefaultsButtonData" (buttonClick)="onRestoreDefaultButtonClick()"
      templatePosition="left" />
    <app-custom-button (buttonClick)="cancel()" [buttonData]="cancelButtonData" />
    <app-custom-button class="save-btn" (buttonClick)="save(sensorSchedulerForm.value)" [buttonData]="saveButtonData" />
  </div>
</div>


<ng-template #flyoutContent>
  <div class="flyoutContent">
    <div> Reported Begin Measurement window </div>
    <div> {{this.sensorsSchedule.reportedWakeUpTime}} </div>
    <div> Reported End Measurement window </div>
    <div> {{this.sensorsSchedule.reportedSleepTime}} </div>
    <div> Reported Interval between the start of measurements (min) </div>
    <div> {{this.sensorsSchedule.reportedMeasurementIntervalInMinutes}} min </div>
  </div>
</ng-template>
