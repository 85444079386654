import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventsInfoRoutes } from 'src/app/admin/constants/route.constants';
import { AppRouteConstants } from 'src/app/shared/app-constants/app-route-constants';
import { AppMessages } from 'src/app/shared/app-constants/events-constants';
import { ButtonLabels, ButtonNames, ButtonSizes, ButtonTypes, CommandNames, ModalTemplateTypes, SignalREventTypes } from 'src/app/shared/app-constants/shared.enum';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { ButtonData, OpenModalDialogData } from 'src/app/shared/models/shared.models';
import { SignalRAlertSignalEvent, AlarmOrWarningEventData, SignaREvent, SignalRCommandSignalEvent } from 'src/app/shared/models/signalr-events';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { NotificationService } from 'src/app/shared/services/notification-services/notification.service';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';

@Component({
  selector: 'app-alert-sidebar',
  templateUrl: './alert-sidebar.component.html',
  styleUrls: ['./alert-sidebar.component.scss']
})
export class AlertSidebarComponent implements OnInit, OnDestroy {
  @Output() closeNotificationPanel: EventEmitter<void> = new EventEmitter<void>();
  subscriptions = new Subscription();
  signalREventTypes = SignalREventTypes;
  dismissAllButtonData: ButtonData = { label: ButtonLabels.DISMISS_ALL, type: ButtonTypes.SECONDARY_BASIC, name: ButtonNames.DISMISS_ALL, size: ButtonSizes.SM };
  viewAllAlertSignalNotificationsButtonData: ButtonData = { label: ButtonLabels.VIEW_ALL_ALERT_SIGNAL_NOTIFICATIONS, type: ButtonTypes.SECONDARY_BASIC, name: ButtonNames.VIEW_ALL_ALERT_SIGNAL_NOTIFICATIONS, size: ButtonSizes.SM };
  viewAllCommandSignalNotificationsButtonData: ButtonData = { label: ButtonLabels.VIEW_ALL_COMMAND_SIGNAL_NOTIFICATIONS, type: ButtonTypes.SECONDARY_BASIC, name: ButtonNames.VIEW_ALL_COMMAND_SIGNAL_NOTIFICATIONS, size: ButtonSizes.SM };
  scope: string = '';
  constructor(
    public notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private modalDialogService: ModalDialogService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.scope = params['s'] ? params['s'] : '';
    });
  }

  getAlertSignalNotifications(): SignaREvent<SignalRAlertSignalEvent<AlarmOrWarningEventData>>[] {
    return this.notificationService.notifications?.filter(item => item.type === this.signalREventTypes?.AlertSignal).slice(0, 20);
  }

  getCommandSignalNotifications(): SignaREvent<SignalRCommandSignalEvent>[] {
    return this.notificationService.notifications?.filter(item => item.type === this.signalREventTypes?.CommandSignal).slice(0, 20);
  }

  dismissAllBtnClick(e: CustomEvent) {
    this.notificationService.notifications = [];
    this.closeNotificationPanel.emit();
  }

  viewAllNotificationsBtnClick(btnData: ButtonData) {
    if (!this.getScope()) {
      this.selectEnterprise();
      return;
    }
    if (btnData.name === ButtonNames.VIEW_ALL_ALERT_SIGNAL_NOTIFICATIONS) {
      const url = `/${AppRouteConstants.ROUTE_TO_CUSTOMER}/${EventsInfoRoutes.EVENTS_INFO_BASE_ROUTE}/${EventsInfoRoutes.GLOBAL_EVENTS}`;
      this.router.navigate([url], { queryParams: { s: this.getScope() } });
    }
    if (btnData.name === ButtonNames.VIEW_ALL_COMMAND_SIGNAL_NOTIFICATIONS) {
      console.log('view all command signal notifications');
    }
    this.closeNotificationPanel.emit();
  }

  getScope() {
    return this.scope || this.sessionService.userScope;
  }

  selectEnterprise() {
    const data = new OpenModalDialogData();
    data.templateName = ModalTemplateTypes.ERROR_TEMPLATE;
    data.customData.message = AppMessages.SELECT_ENTERPRISE_MESSAGE;
    this.modalDialogService.openModal(data);
  }

  notificationClicked() {
    this.closeNotificationPanel.emit();
  }
}
