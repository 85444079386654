<form class="form-container" [formGroup]="formGroup"  *ngIf="formGroup">
    <div >
        <p class="header-text">Upload an Artifact</p>
        <div class="upload-container" *ngIf="!artifactFileData.name">
            <input type="file" id="fileUpload" class="file-input" formControlName="fileName"  (change)="onFilePicked($event)"/>
            <label for="fileUpload" class="upload-area">
                <div class="upload-icon">
                    <img src="assets/icons-and-logos/Cloud-icon.svg" alt="file" />
                </div>
                <div class="upload-text">
                    Drag and drop here or browse to upload
                </div>
            </label>
        </div>
       <div *ngIf="artifactFileData.name" class="upload-container text-align:center" > <h3 class="file-name" *ngIf="artifactFileData">{{artifactFileData.name}}</h3><Button class="delete-icon" (click)="deleteFile()"  tabIndex = {{0}} role="button">
        <img src="assets/icons-and-logos/trashsolid.svg" alt="file" tabIndex={0} />
    </Button></div>
    </div>
  <div class="modal-footer">
    <app-custom-button [buttonData]="cancelButtonData" (buttonClick)="onCancel()"/>
    <app-custom-button [buttonData]="uploadButtonData" (buttonClick)="buttonclick()"/>
  </div>
</form>
