import { Component, OnInit } from '@angular/core';
import { ButtonLabels, ButtonNames, ButtonSizes, ButtonTypes, ModalTemplateTypes } from 'src/app/shared/app-constants/shared.enum';
import { ButtonData, OpenModalDialogData } from 'src/app/shared/models/shared.models';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { CampaignManagementService } from '../../services/campaign-management.service';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CampaignManagementDataServiceService } from '../../services/campaign-management-data-service.service';
import { ButtonStateService } from 'src/app/shared/services/button-state-service/button-state.service';

@Component({
  selector: 'app-initiate-campaign',
  templateUrl: './initiate-campaign.component.html',
  styleUrls: ['./initiate-campaign.component.scss']
})
export class InitiateCampaignComponent implements OnInit {
  artifactData: Object[] = [];
  artifactServiceUrl: string = '';
  campaignType = '';
  campaignTypes: any[] = [{ name: 'OS', value: 'os' }, { name: 'Firmware', value: 'firmware' }, { name: 'Module', value: 'module' }];
  cancelButtonData: ButtonData = { label: ButtonLabels.CANCEL, name: ButtonNames.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER };
  code:string = 'XW6ORRWMR28jq174vBpWwdBHFMOMib5gYFZnCc0RbioBAzFubz52iA==';
  createButtonData: ButtonData = { label: ButtonLabels.CREATE, name: ButtonNames.CREATE, type: ButtonTypes.PRIMARY_BASIC, disable: true };
  field = { text: 'name', value: 'value' };
  fields: any = { text: 'name', value: 'id' };
  firmwareTypes: object[] = [{ Id: '1', text: 'single-file' }, { Id: '2', text: 'carrier-board-firmware-update',  }, { Id: '3', text: 'sensor-firmware-update' }];
  formGroup!: FormGroup;
  later:boolean = false;
  now:boolean = false;
  selectedArtifacts:any[] = [];
  timeInterval: number = 60;
  timePickerFormat: string = 'HH:mm';

  constructor(
    private formBuilder: FormBuilder,
    public modalDialogService: ModalDialogService,
    private campaignManagementService: CampaignManagementService,
    private sessionSerive:SessionService,
    private campaignDataServices:CampaignManagementDataServiceService,
    private buttonStateService: ButtonStateService
  ) {}

  ngOnInit(): void {
    this.createFormGroup();
  }

  createFormGroup() {
    this.formGroup = this.formBuilder.group({
      campaignName: [null],
      campaignType: [''],
      firmwareType: [''],
      artifacts: [''],
      date: [''],
      time: [''],
      now: [false],
      later: [false],
    });

    this.formGroup?.valueChanges?.subscribe((arg: any) => {
      this.updateButtonsState();
    });
  }

  updateButtonsState() {
    const enableButtons = this.formGroup?.valid && this.formGroup?.dirty;
    this.buttonStateService.setButtonDisableState(this.createButtonData.name, !enableButtons);
  }

  onCreate(): void {
    const campaignName = this.formGroup?.get('campaignName')?.value;
    const campaignType = this.formGroup?.get('campaignType')?.value?.toString();
    const firmwareType = this.formGroup?.get('firmwareType')?.value?.toString();
    const artifacts = this.formGroup?.get('artifacts')?.value;
    const now = this.formGroup?.get('now')?.value;
    const later = this.formGroup?.get('later')?.value;
    const date = this.formGroup?.get('date')?.value?.toString();
    const time = this.formGroup?.get('time')?.value?.toString() ?? '';
    let formattedDateTime;
    if (later) {
      const dateString:Date = date;
      const timeString:Date = time;
      const dateObject = new Date(dateString);
      const timeObject = new Date(timeString);
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth(); // 0-indexed
      const day = dateObject.getDate();
      const hours = timeObject.getHours();
      const minutes = timeObject.getMinutes();
      const seconds = timeObject.getSeconds();
      const combinedDateTime = new Date(year, month, day, hours, minutes, seconds);
      formattedDateTime = combinedDateTime.toISOString().split('.')[0]; // Split to remove milliseconds
    }
    let savecampaignRequest:any;
    if (firmwareType) {
      savecampaignRequest = {
        campaignName: campaignName.trim(),
        campaignType: campaignType.trim(),
        firmwareType: firmwareType.trim(),
        artifacts: this.selectedArtifacts,
        isSchedule: now,
        schedule: now ? new Date() : formattedDateTime,
        targets: this.campaignDataServices.getGroupTargets(),
        createdBy: this.sessionSerive.userDetails.fullName
      };
    } else {
      savecampaignRequest = {
        campaignName: campaignName?.trim(),
        campaignType: campaignType?.trim(),
        artifacts: this.selectedArtifacts,
        isSchedule: now,
        schedule: now ? new Date() : formattedDateTime,
        targets: this.campaignDataServices.getGroupTargets(),
        createdBy: this.sessionSerive.userDetails.fullName,
        firmwareType: 'single-file'
      };
    }
    this.campaignManagementService.createCampaign(this.sessionSerive.tenantId, this.code, savecampaignRequest).subscribe((response) => {
      const data = new OpenModalDialogData();
      data.templateName = ModalTemplateTypes.SUCCESS_TEMPLATE;
      data.customData.message = 'Campaign created successfully';
      this.modalDialogService.openModal(data);
    });
    this.modalDialogService.closeModal();
  }

  onCancel(): void {
    this.modalDialogService.closeModal();
  }

  onChange(event: any) {
    this.campaignType = event.value;
    this.campaignManagementService.getArtifacts(this.sessionSerive.tenantId, this.code, this.campaignType).subscribe(response => {
      this.artifactData = response.map((item: any) => {
        return { id: item.id, name: item.name, version: item.version };
      });
    });
    this.artifactServiceUrl = this.campaignManagementService.getArtifactsUrl(this.sessionSerive.tenantId, this.code, this.campaignType);
  }

  onfirmwareTypeChange(event: any) {
    if (event.value) {
      const firmwareType = event.value;
      const url = this.artifactServiceUrl + '&subType=' + firmwareType;
      this.campaignManagementService.getAFirmwareArtifacts(url).subscribe(response => {
        this.artifactData = response.map((item: any) => {
          return { id: item.id, name: item.name, version: item.version };
        });
      });
    } else {
      this.campaignManagementService.getArtifacts(this.sessionSerive.tenantId, this.code, this.campaignType).subscribe(response => {
        this.artifactData = response.map((item: any) => {
          return { id: item.id, name: item.name, version: item.version };
        });
      });
    }
  }

  onCheckChange(event:any) {
    this.later =  event.checked;
  }

  onArtifactChange(event: any) {
    this.selectedArtifacts = this.artifactData.filter((x:any) => event.value.includes(x.id));
  }
}
