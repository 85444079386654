import { Component, Input } from '@angular/core';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { ButtonTypes, CustomEventSource, CustomEventType } from 'src/app/shared/app-constants/shared.enum';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';

@Component({
  selector: 'app-confirm-template',
  templateUrl: './confirm-template.component.html',
  styleUrls: ['./confirm-template.component.scss']
})
export class ConfirmTemplateComponent {
  @Input() id!: string; // A unique id to passed back to the consumer
  @Input() message!: string; // Message to be shown
  confirmButtonData = { label: 'CONFIRM', type: ButtonTypes.PRIMARY_BASIC, name: 'confirm' };
  cancelButtonData = { label: 'CANCEL', type: ButtonTypes.PRIMARY_NO_BORDER, name: 'cancel' };

  constructor(private modalDialogService: ModalDialogService) { }

  // Method call on click of Cancel button
  onCancelButtonClick() {
    this.closeModal();
  }

  // Method call on click of Confirm button
  onConfirmButtonClick() {
    const customData: CustomEvent = { eventSource: CustomEventSource.MODAL, eventType: CustomEventType.CLICK, eventData: { id: this.id } };
    this.modalDialogService.emitModalDialogEvent(customData);
  }

  // Method to close modal
  closeModal() {
    this.modalDialogService.closeModal();
  }
}
