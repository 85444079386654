<div *ngIf="isLoggedIn && breadcrumbs && breadcrumbs.length > 0">
  <ejs-breadcrumb cssClass="e-breadcrumb-chips" [enableNavigation]="true">
    <e-breadcrumb-items>
      <ng-container *ngFor="let breadcrumb of breadcrumbs">
        <e-breadcrumb-item [text]="breadcrumb.label" [url]="breadcrumb.url" [id]="breadcrumb.objectType">
          <ng-template #itemTemplate let-dataSource>
            <div>
              <a class="a-link" href="#" (click)="navigateTo($event, dataSource.url)">
                {{ dataSource.text }}
              </a>
              <div class="breadcrumb-subtext">
                {{ dataSource.id | capitalize }}
              </div>
            </div>
          </ng-template>
        </e-breadcrumb-item>
      </ng-container>
    </e-breadcrumb-items>
    <ng-template #separatorTemplate>
      <span class="e-icons e-chevron-right e-medium breadcrumb-separator"></span>
    </ng-template>
  </ejs-breadcrumb>
</div>