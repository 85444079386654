export const environment = {
  production: false,
  oktaAuthConfiguration: {
    issuer: 'https://authpreview.regalbeloit.com/oauth2/default',
    clientId: '0oa14qjq1o4GfZYKB0h8',
    redirectUri: window.location.origin + '/login/callback',
  },
  domain: 'Staging',
  // dashboardApiBaseUrl: 'https://localhost:44364/api',
  dashboardApiBaseUrl: 'https://appperceptivdashboardapit.azurewebsites.net/api',
  oktaBaseUrl: 'https://regalbeloit.okta.com',
  otaApiBaseUrl: 'https://otamodulevalidation.azurewebsites.net/api',
  signalRHubBaseUrl: 'https://apppecommandservicet.azurewebsites.net/notificationhub',
  oktaAPIKey: '00uf2njzFPEwvrAasWds77_bLCvOhtBGTlwko8mlje',
  appInsights: {
    instrumentationKey: '6298c5a5-2165-4068-b367-89d25b7a2cc1',
    connectionString: 'InstrumentationKey=6298c5a5-2165-4068-b367-89d25b7a2cc1;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/'
  },
  isMock: true,
  tenantId: 'fea7d713-34c8-451d-9fed-b0a10080c601',
  telemetryReport: {
    workspaceID: '30fcf1b3-725a-4fca-9c7b-2eab95ab1599',
    hotReportID: 'd01d3493-be5c-4a50-99ba-2e07c343795c',
    hotReportDatasetID: '78207097-850c-444d-ba25-b6e54ae642e6',
    coldReportID: 'c212526f-3472-457d-8e9f-e3e23be48654',
    coldReportDatasetID: 'b774f5ee-c675-4e0b-bb18-f3bff0b91272',
    filterSchemaURLBasic: 'https://powerbi.com/product/schema#basic',
    filterSchemaURLAdvanced: 'https://powerbi.com/product/schema#advanced',
    filterSchemaURLRelativeDate: 'https://powerbi.com/product/schema#relativeDate',
    filterDataSourceUnifiedMessages: 'txn_unified_messages_s7100_hot',
    filterDataSourceSensors: 'Sensors',
    filterDataColumnSensorId: 'sensorId',
    filterDataColumnSerialNumber: 'serialNumber',
    filterDataColumnTimestamp: '_ts',
    reportPageTelemetry: 'ReportSection',
  },
  customerDashboardReport: {
    workspaceID: '30fcf1b3-725a-4fca-9c7b-2eab95ab1599',
    reportID: '45a6fd89-1316-49bb-aed3-02431c442db6',
    reportDatasetID: 'ac49df6d-dd69-4af9-b19f-0d24c778369a',
    filterSchemaURL: 'https://powerbi.com/product/schema#basic',
    filterDataSource: 'mst_assets_enterprises_hot',
    filterSitesDataSource: 'mst_assets_sites_hot',
    filterAreasDataSource: 'mst_assets_areas_hot',
    filterDataColumn: 'id',
    reportPageNameAlert: 'ReportSection4e7e23d06c1076ee75a3',
    reportPageNameEquipmentHealth: 'ReportSectionbd976ed1e842929700e1',
    reportPageNameTopAlertedEquipment: 'ReportSection0a6c0dcb8a619a5f2dfd',
    reportPageNameCombinedReport: '7051eb240289837656f7',
    reportPageNameAssetPageReport: '352a6b699efff6566e2c',
  },
  assetImages: {
    maxImageSizeInMB: 5,
    maximumImagesInAsset: 4,
  }
};
