<div class="success-container">
  <svg class="modal-close-icon" (click)="this.closeModal()" (keypress)="closeModal()" xmlns="http://www.w3.org/2000/svg" width="20" height="21"
    viewBox="0 0 20 21" fill="none">
    <path
      d="M16.2727 6.11055C16.7933 5.58996 16.7933 4.74453 16.2727 4.22394C15.7521 3.70335 14.9067 3.70335 14.3861 4.22394L10.0007 8.61355L5.61104 4.2281C5.09045 3.70751 4.24502 3.70751 3.72443 4.2281C3.20384 4.74869 3.20384 5.59413 3.72443 6.11472L8.11403 10.5002L3.72859 14.8898C3.208 15.4104 3.208 16.2558 3.72859 16.7764C4.24918 17.297 5.09462 17.297 5.61521 16.7764L10.0007 12.3868L14.3903 16.7722C14.9108 17.2928 15.7563 17.2928 16.2769 16.7722C16.7975 16.2516 16.7975 15.4062 16.2769 14.8856L11.8873 10.5002L16.2727 6.11055Z"
      fill="#2E3238" />
  </svg>
  <svg class="modal-main-icon" xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46"
    fill="none">
    <g clip-path="url(#clip0_10592_24414)">
      <path
        d="M23 46C29.1 46 34.9501 43.5768 39.2635 39.2635C43.5768 34.9501 46 29.1 46 23C46 16.9 43.5768 11.0499 39.2635 6.73654C34.9501 2.42321 29.1 0 23 0C16.9 0 11.0499 2.42321 6.73654 6.73654C2.42321 11.0499 0 16.9 0 23C0 29.1 2.42321 34.9501 6.73654 39.2635C11.0499 43.5768 16.9 46 23 46ZM33.1523 18.7773L21.6523 30.2773C20.8078 31.1219 19.4422 31.1219 18.6066 30.2773L12.8566 24.5273C12.0121 23.6828 12.0121 22.3172 12.8566 21.4816C13.7012 20.6461 15.0668 20.6371 15.9023 21.4816L20.125 25.7043L30.0977 15.7227C30.9422 14.8781 32.3078 14.8781 33.1434 15.7227C33.9789 16.5672 33.9879 17.9328 33.1434 18.7684L33.1523 18.7773Z"
        fill="#72B840" />
    </g>
    <defs>
      <clipPath id="clip0_10592_24414">
        <rect width="46" height="46" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <div class="modal-message">{{message}}</div>
  <hr>
  <div class="modal-btns">
    <app-custom-button (buttonClick)="onOkayButtonClick()" [buttonData]="okayButtonData" />
  </div>
</div>