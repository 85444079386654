import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' }) // As off now i will keep this for quik fix later i will move this and move the dailogs and related services to shared
export class CampaignManagementDataServiceService {
  constructor() { }
  groupTargets: any[] = [];

  setGroupTargets(data: any, gatewaytype?: string, type?: string, id?:string) {
    if (type === 'entites') {
      const entityData = data.map((item: any) => {
        return { type: gatewaytype, serialNo: item.id };
      });
      const campaignData:any = { groupId: id, entities: entityData };
      this.groupTargets = [campaignData];
    } else {
      this.groupTargets = data.map((item:any) => {
        return { groupId: item.groupId, entities: [] };
      });
    }
  }

  getGroupTargets() {
    return this.groupTargets;
  }
}
