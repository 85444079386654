import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-campaign-status',
  templateUrl: './campaign-status.component.html',
  styleUrls: ['./campaign-status.component.scss']
})
export class CampaignStatusComponent {
  @Input() campaignStatus: any;
  @Input() type: any;
}
