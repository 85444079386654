import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ButtonStateService {
  private buttonDisableStates = new BehaviorSubject<{ [key: string]: boolean }>({}); //Holds disable states for all buttons

  constructor() { }

  // Returns observale of boolean for button disable state by passing button name
  getButtonDisableState(buttonName: string): Observable<boolean> {
    return this.buttonDisableStates.asObservable().pipe(map(disableStates => disableStates[buttonName] || false));
  }

  // Sets button state enable or disable by passing button name and disable state boolean value
  setButtonDisableState(buttonName: string, disableState: boolean): void {
    const currentState = this.buttonDisableStates.getValue();
    currentState[buttonName] = disableState;
    this.buttonDisableStates.next(currentState);
  }
}
