import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetsInfoHelperService } from 'src/app/customer/assets-info/services/assets-info-helper.service';
import { CommandNames } from 'src/app/shared/app-constants/shared.enum';
import { CommandSignalResponse, SignalRCommandSignalEvent } from 'src/app/shared/models/signalr-events';
import { NotificationService } from 'src/app/shared/services/notification-services/notification.service';

@Component({
  selector: 'app-command-signal',
  templateUrl: './command-signal.component.html',
  styleUrls: ['./command-signal.component.scss']
})
export class CommandSignalComponent implements OnInit {
  @Input() commandEventData?: SignalRCommandSignalEvent;
  @Output() notificationClicked: EventEmitter<void> = new EventEmitter<void>();
  commandNames = CommandNames;
  isCollapsed: boolean = true;
  title: string = '';
  message: string = '';

  constructor(
    private assetsInfoHelperService: AssetsInfoHelperService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    if (!this.commandEventData) return;
    this.title = this.getTitleForCommandSignalNotification(this.commandEventData);
    this.message = this.getMessageForCommandSignalNotification(this.commandEventData);
  }

  timeAgoValue(timeStamp: number | undefined) {
    return this.notificationService.timeAgoValue(timeStamp);
  }

  commandNotificationClicked(commandEventData: SignalRCommandSignalEvent) {

  }

  getStatusIconClass(): string {
    return this.commandEventData ? this.notificationService.getIconForCommandNotification(this.commandEventData.responseCode) : '';
  }

  toggleMessages() {
    this.isCollapsed = !this.isCollapsed;
  }

  getFormattedCommandName(commandName: string): string {
    return this.notificationService.getFormattedCommandName(commandName);
  }

  getTitleForCommandSignalNotification(commandSignalEvent: SignalRCommandSignalEvent): string {
    return this.notificationService.getTitleForCommandSignalNotification(commandSignalEvent);
  }

  getMessageForCommandSignalNotification(commandSignalEvent?: SignalRCommandSignalEvent): string {
    return this.notificationService.getMessageForCommandSignalNotification(commandSignalEvent);
  }

  getDetailedMessages(commandSignalEvent?: SignalRCommandSignalEvent): CommandSignalResponse[] {
    if (!commandSignalEvent?.response) return [];
    const uniqueMessages = new Set();
    return commandSignalEvent?.response?.filter(item => {
      if (uniqueMessages.has(item.message)) {
        return false;
      }
      uniqueMessages.add(item.message);
      item.message = item.message.replace('successfully reboot the gateway', 'successfully rebooted the gateway');
      return true;
    });
  }
}
