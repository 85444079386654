<form [formGroup]="formGroup" class="user-preference-container">
  <h2 class="heading-container pure-black-color">User Preferences</h2>
  <div class="row-container">
    <p>Time Zone</p>
    <ejs-dropdownlist formControlName="selectedTimeZone" [dataSource]='selectTimeZoneOptions' placeholder="Select TimeZone"
      [fields]="selectTimeZoneOptionsFieldMapping"></ejs-dropdownlist>
  </div>
  <div class="row-container bottom-row">
    <p>Unit Of Measure</p>
    <ejs-dropdownlist formControlName="selectedUnitPreferences" [dataSource]='selectUnitPreferencesOptions' placeholder="Select Unit Preferences"
      [fields]="selectUnitPreferencesOptionsFieldMapping"></ejs-dropdownlist>
  </div>
  <hr>
  <div class="modal-btns">
    <app-custom-button (buttonClick)="cancel()" [buttonData]="cancelButtonData" />
    <app-custom-button (buttonClick)="save()" [buttonData]="saveButtonData" />
  </div>
</form>
