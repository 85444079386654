import { TimeZone } from 'src/app/admin/gateways-info/models/gateway';

export const mockTimeZone: TimeZone[] = [
  {
    'id': 'Dateline Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-12:00) International Date Line West',
    'standardName': 'Dateline Standard Time',
    'daylightName': 'Dateline Daylight Time',
    'baseUtcOffset': '-12:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'UTC-11',
    'hasIanaId': false,
    'displayName': '(UTC-11:00) Coordinated Universal Time-11',
    'standardName': 'UTC-11',
    'daylightName': 'UTC-11',
    'baseUtcOffset': '-11:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Aleutian Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-10:00) Aleutian Islands',
    'standardName': 'Aleutian Standard Time',
    'daylightName': 'Aleutian Daylight Time',
    'baseUtcOffset': '-10:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Hawaiian Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-10:00) Hawaii',
    'standardName': 'Hawaiian Standard Time',
    'daylightName': 'Hawaiian Daylight Time',
    'baseUtcOffset': '-10:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Marquesas Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-09:30) Marquesas Islands',
    'standardName': 'Marquesas Standard Time',
    'daylightName': 'Marquesas Daylight Time',
    'baseUtcOffset': '-09:30:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Alaskan Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-09:00) Alaska',
    'standardName': 'Alaskan Standard Time',
    'daylightName': 'Alaskan Daylight Time',
    'baseUtcOffset': '-09:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'UTC-09',
    'hasIanaId': false,
    'displayName': '(UTC-09:00) Coordinated Universal Time-09',
    'standardName': 'UTC-09',
    'daylightName': 'UTC-09',
    'baseUtcOffset': '-09:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Pacific Standard Time (Mexico)',
    'hasIanaId': false,
    'displayName': '(UTC-08:00) Baja California',
    'standardName': 'Pacific Standard Time (Mexico)',
    'daylightName': 'Pacific Daylight Time (Mexico)',
    'baseUtcOffset': '-08:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'UTC-08',
    'hasIanaId': false,
    'displayName': '(UTC-08:00) Coordinated Universal Time-08',
    'standardName': 'UTC-08',
    'daylightName': 'UTC-08',
    'baseUtcOffset': '-08:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Pacific Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-08:00) Pacific Time (US & Canada)',
    'standardName': 'Pacific Standard Time',
    'daylightName': 'Pacific Daylight Time',
    'baseUtcOffset': '-08:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'US Mountain Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-07:00) Arizona',
    'standardName': 'US Mountain Standard Time',
    'daylightName': 'US Mountain Daylight Time',
    'baseUtcOffset': '-07:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Mountain Standard Time (Mexico)',
    'hasIanaId': false,
    'displayName': '(UTC-07:00) La Paz, Mazatlan',
    'standardName': 'Mountain Standard Time (Mexico)',
    'daylightName': 'Mountain Daylight Time (Mexico)',
    'baseUtcOffset': '-07:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Mountain Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-07:00) Mountain Time (US & Canada)',
    'standardName': 'Mountain Standard Time',
    'daylightName': 'Mountain Daylight Time',
    'baseUtcOffset': '-07:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Yukon Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-07:00) Yukon',
    'standardName': 'Yukon Standard Time',
    'daylightName': 'Yukon Daylight Time',
    'baseUtcOffset': '-07:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Central America Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-06:00) Central America',
    'standardName': 'Central America Standard Time',
    'daylightName': 'Central America Daylight Time',
    'baseUtcOffset': '-06:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Central Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-06:00) Central Time (US & Canada)',
    'standardName': 'Central Standard Time',
    'daylightName': 'Central Daylight Time',
    'baseUtcOffset': '-06:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Easter Island Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-06:00) Easter Island',
    'standardName': 'Easter Island Standard Time',
    'daylightName': 'Easter Island Daylight Time',
    'baseUtcOffset': '-06:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Central Standard Time (Mexico)',
    'hasIanaId': false,
    'displayName': '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    'standardName': 'Central Standard Time (Mexico)',
    'daylightName': 'Central Daylight Time (Mexico)',
    'baseUtcOffset': '-06:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Canada Central Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-06:00) Saskatchewan',
    'standardName': 'Canada Central Standard Time',
    'daylightName': 'Canada Central Daylight Time',
    'baseUtcOffset': '-06:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'SA Pacific Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    'standardName': 'SA Pacific Standard Time',
    'daylightName': 'SA Pacific Daylight Time',
    'baseUtcOffset': '-05:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Eastern Standard Time (Mexico)',
    'hasIanaId': false,
    'displayName': '(UTC-05:00) Chetumal',
    'standardName': 'Eastern Standard Time (Mexico)',
    'daylightName': 'Eastern Daylight Time (Mexico)',
    'baseUtcOffset': '-05:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Eastern Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-05:00) Eastern Time (US & Canada)',
    'standardName': 'Eastern Standard Time',
    'daylightName': 'Eastern Daylight Time',
    'baseUtcOffset': '-05:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Haiti Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-05:00) Haiti',
    'standardName': 'Haiti Standard Time',
    'daylightName': 'Haiti Daylight Time',
    'baseUtcOffset': '-05:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Cuba Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-05:00) Havana',
    'standardName': 'Cuba Standard Time',
    'daylightName': 'Cuba Daylight Time',
    'baseUtcOffset': '-05:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'US Eastern Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-05:00) Indiana (East)',
    'standardName': 'US Eastern Standard Time',
    'daylightName': 'US Eastern Daylight Time',
    'baseUtcOffset': '-05:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Turks And Caicos Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-05:00) Turks and Caicos',
    'standardName': 'Turks and Caicos Standard Time',
    'daylightName': 'Turks and Caicos Daylight Time',
    'baseUtcOffset': '-05:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Paraguay Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-04:00) Asuncion',
    'standardName': 'Paraguay Standard Time',
    'daylightName': 'Paraguay Daylight Time',
    'baseUtcOffset': '-04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Atlantic Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-04:00) Atlantic Time (Canada)',
    'standardName': 'Atlantic Standard Time',
    'daylightName': 'Atlantic Daylight Time',
    'baseUtcOffset': '-04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Venezuela Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-04:00) Caracas',
    'standardName': 'Venezuela Standard Time',
    'daylightName': 'Venezuela Daylight Time',
    'baseUtcOffset': '-04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Central Brazilian Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-04:00) Cuiaba',
    'standardName': 'Central Brazilian Standard Time',
    'daylightName': 'Central Brazilian Daylight Time',
    'baseUtcOffset': '-04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'SA Western Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    'standardName': 'SA Western Standard Time',
    'daylightName': 'SA Western Daylight Time',
    'baseUtcOffset': '-04:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Pacific SA Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-04:00) Santiago',
    'standardName': 'Pacific SA Standard Time',
    'daylightName': 'Pacific SA Daylight Time',
    'baseUtcOffset': '-04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Newfoundland Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-03:30) Newfoundland',
    'standardName': 'Newfoundland Standard Time',
    'daylightName': 'Newfoundland Daylight Time',
    'baseUtcOffset': '-03:30:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Tocantins Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-03:00) Araguaina',
    'standardName': 'Tocantins Standard Time',
    'daylightName': 'Tocantins Daylight Time',
    'baseUtcOffset': '-03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'E. South America Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-03:00) Brasilia',
    'standardName': 'E. South America Standard Time',
    'daylightName': 'E. South America Daylight Time',
    'baseUtcOffset': '-03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'SA Eastern Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-03:00) Cayenne, Fortaleza',
    'standardName': 'SA Eastern Standard Time',
    'daylightName': 'SA Eastern Daylight Time',
    'baseUtcOffset': '-03:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Argentina Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-03:00) City of Buenos Aires',
    'standardName': 'Argentina Standard Time',
    'daylightName': 'Argentina Daylight Time',
    'baseUtcOffset': '-03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Montevideo Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-03:00) Montevideo',
    'standardName': 'Montevideo Standard Time',
    'daylightName': 'Montevideo Daylight Time',
    'baseUtcOffset': '-03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Magallanes Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-03:00) Punta Arenas',
    'standardName': 'Magallanes Standard Time',
    'daylightName': 'Magallanes Daylight Time',
    'baseUtcOffset': '-03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Saint Pierre Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-03:00) Saint Pierre and Miquelon',
    'standardName': 'Saint Pierre Standard Time',
    'daylightName': 'Saint Pierre Daylight Time',
    'baseUtcOffset': '-03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Bahia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-03:00) Salvador',
    'standardName': 'Bahia Standard Time',
    'daylightName': 'Bahia Daylight Time',
    'baseUtcOffset': '-03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'UTC-02',
    'hasIanaId': false,
    'displayName': '(UTC-02:00) Coordinated Universal Time-02',
    'standardName': 'UTC-02',
    'daylightName': 'UTC-02',
    'baseUtcOffset': '-02:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Greenland Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-02:00) Greenland',
    'standardName': 'Greenland Standard Time',
    'daylightName': 'Greenland Daylight Time',
    'baseUtcOffset': '-02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Mid-Atlantic Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-02:00) Mid-Atlantic - Old',
    'standardName': 'Mid-Atlantic Standard Time',
    'daylightName': 'Mid-Atlantic Daylight Time',
    'baseUtcOffset': '-02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Azores Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-01:00) Azores',
    'standardName': 'Azores Standard Time',
    'daylightName': 'Azores Daylight Time',
    'baseUtcOffset': '-01:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Cape Verde Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC-01:00) Cabo Verde Is.',
    'standardName': 'Cabo Verde Standard Time',
    'daylightName': 'Cabo Verde Daylight Time',
    'baseUtcOffset': '-01:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'UTC',
    'hasIanaId': true,
    'displayName': '(UTC) Coordinated Universal Time',
    'standardName': 'Coordinated Universal Time',
    'daylightName': 'Coordinated Universal Time',
    'baseUtcOffset': '00:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'GMT Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    'standardName': 'GMT Standard Time',
    'daylightName': 'GMT Daylight Time',
    'baseUtcOffset': '00:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Greenwich Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+00:00) Monrovia, Reykjavik',
    'standardName': 'Greenwich Standard Time',
    'daylightName': 'Greenwich Daylight Time',
    'baseUtcOffset': '00:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Sao Tome Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+00:00) Sao Tome',
    'standardName': 'Sao Tome Standard Time',
    'daylightName': 'Sao Tome Daylight Time',
    'baseUtcOffset': '00:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Morocco Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+01:00) Casablanca',
    'standardName': 'Morocco Standard Time',
    'daylightName': 'Morocco Daylight Time',
    'baseUtcOffset': '00:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'W. Europe Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    'standardName': 'W. Europe Standard Time',
    'daylightName': 'W. Europe Daylight Time',
    'baseUtcOffset': '01:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Central Europe Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    'standardName': 'Central Europe Standard Time',
    'daylightName': 'Central Europe Daylight Time',
    'baseUtcOffset': '01:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Romance Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    'standardName': 'Romance Standard Time',
    'daylightName': 'Romance Daylight Time',
    'baseUtcOffset': '01:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Central European Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    'standardName': 'Central European Standard Time',
    'daylightName': 'Central European Daylight Time',
    'baseUtcOffset': '01:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'W. Central Africa Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+01:00) West Central Africa',
    'standardName': 'W. Central Africa Standard Time',
    'daylightName': 'W. Central Africa Daylight Time',
    'baseUtcOffset': '01:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'GTB Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Athens, Bucharest',
    'standardName': 'GTB Standard Time',
    'daylightName': 'GTB Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Middle East Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Beirut',
    'standardName': 'Middle East Standard Time',
    'daylightName': 'Middle East Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Egypt Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Cairo',
    'standardName': 'Egypt Standard Time',
    'daylightName': 'Egypt Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'E. Europe Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Chisinau',
    'standardName': 'E. Europe Standard Time',
    'daylightName': 'E. Europe Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'West Bank Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Gaza, Hebron',
    'standardName': 'West Bank Gaza Standard Time',
    'daylightName': 'West Bank Gaza Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'South Africa Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Harare, Pretoria',
    'standardName': 'South Africa Standard Time',
    'daylightName': 'South Africa Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'FLE Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    'standardName': 'FLE Standard Time',
    'daylightName': 'FLE Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Israel Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Jerusalem',
    'standardName': 'Jerusalem Standard Time',
    'daylightName': 'Jerusalem Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'South Sudan Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Juba',
    'standardName': 'South Sudan Standard Time',
    'daylightName': 'South Sudan Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Kaliningrad Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Kaliningrad',
    'standardName': 'Russia TZ 1 Standard Time',
    'daylightName': 'Russia TZ 1 Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Sudan Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Khartoum',
    'standardName': 'Sudan Standard Time',
    'daylightName': 'Sudan Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Libya Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Tripoli',
    'standardName': 'Libya Standard Time',
    'daylightName': 'Libya Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Namibia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+02:00) Windhoek',
    'standardName': 'Namibia Standard Time',
    'daylightName': 'Namibia Daylight Time',
    'baseUtcOffset': '02:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Jordan Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+03:00) Amman',
    'standardName': 'Jordan Standard Time',
    'daylightName': 'Jordan Daylight Time',
    'baseUtcOffset': '03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Arabic Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+03:00) Baghdad',
    'standardName': 'Arabic Standard Time',
    'daylightName': 'Arabic Daylight Time',
    'baseUtcOffset': '03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Syria Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+03:00) Damascus',
    'standardName': 'Syria Standard Time',
    'daylightName': 'Syria Daylight Time',
    'baseUtcOffset': '03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Turkey Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+03:00) Istanbul',
    'standardName': 'Turkey Standard Time',
    'daylightName': 'Turkey Daylight Time',
    'baseUtcOffset': '03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Arab Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+03:00) Kuwait, Riyadh',
    'standardName': 'Arab Standard Time',
    'daylightName': 'Arab Daylight Time',
    'baseUtcOffset': '03:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Belarus Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+03:00) Minsk',
    'standardName': 'Belarus Standard Time',
    'daylightName': 'Belarus Daylight Time',
    'baseUtcOffset': '03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Russian Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+03:00) Moscow, St. Petersburg',
    'standardName': 'Russia TZ 2 Standard Time',
    'daylightName': 'Russia TZ 2 Daylight Time',
    'baseUtcOffset': '03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'E. Africa Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+03:00) Nairobi',
    'standardName': 'E. Africa Standard Time',
    'daylightName': 'E. Africa Daylight Time',
    'baseUtcOffset': '03:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Volgograd Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+03:00) Volgograd',
    'standardName': 'Volgograd Standard Time',
    'daylightName': 'Volgograd Daylight Time',
    'baseUtcOffset': '03:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Iran Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+03:30) Tehran',
    'standardName': 'Iran Standard Time',
    'daylightName': 'Iran Daylight Time',
    'baseUtcOffset': '03:30:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Arabian Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+04:00) Abu Dhabi, Muscat',
    'standardName': 'Arabian Standard Time',
    'daylightName': 'Arabian Daylight Time',
    'baseUtcOffset': '04:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Astrakhan Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+04:00) Astrakhan, Ulyanovsk',
    'standardName': 'Astrakhan Standard Time',
    'daylightName': 'Astrakhan Daylight Time',
    'baseUtcOffset': '04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Azerbaijan Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+04:00) Baku',
    'standardName': 'Azerbaijan Standard Time',
    'daylightName': 'Azerbaijan Daylight Time',
    'baseUtcOffset': '04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Russia Time Zone 3',
    'hasIanaId': false,
    'displayName': '(UTC+04:00) Izhevsk, Samara',
    'standardName': 'Russia TZ 3 Standard Time',
    'daylightName': 'Russia TZ 3 Daylight Time',
    'baseUtcOffset': '04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Mauritius Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+04:00) Port Louis',
    'standardName': 'Mauritius Standard Time',
    'daylightName': 'Mauritius Daylight Time',
    'baseUtcOffset': '04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Saratov Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+04:00) Saratov',
    'standardName': 'Saratov Standard Time',
    'daylightName': 'Saratov Daylight Time',
    'baseUtcOffset': '04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Georgian Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+04:00) Tbilisi',
    'standardName': 'Georgian Standard Time',
    'daylightName': 'Georgian Daylight Time',
    'baseUtcOffset': '04:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Caucasus Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+04:00) Yerevan',
    'standardName': 'Caucasus Standard Time',
    'daylightName': 'Caucasus Daylight Time',
    'baseUtcOffset': '04:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Afghanistan Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+04:30) Kabul',
    'standardName': 'Afghanistan Standard Time',
    'daylightName': 'Afghanistan Daylight Time',
    'baseUtcOffset': '04:30:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'West Asia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+05:00) Ashgabat, Tashkent',
    'standardName': 'West Asia Standard Time',
    'daylightName': 'West Asia Daylight Time',
    'baseUtcOffset': '05:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Ekaterinburg Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+05:00) Ekaterinburg',
    'standardName': 'Russia TZ 4 Standard Time',
    'daylightName': 'Russia TZ 4 Daylight Time',
    'baseUtcOffset': '05:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Pakistan Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+05:00) Islamabad, Karachi',
    'standardName': 'Pakistan Standard Time',
    'daylightName': 'Pakistan Daylight Time',
    'baseUtcOffset': '05:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Qyzylorda Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+05:00) Qyzylorda',
    'standardName': 'Qyzylorda Standard Time',
    'daylightName': 'Qyzylorda Daylight Time',
    'baseUtcOffset': '05:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'India Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    'standardName': 'India Standard Time',
    'daylightName': 'India Daylight Time',
    'baseUtcOffset': '05:30:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Sri Lanka Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+05:30) Sri Jayawardenepura',
    'standardName': 'Sri Lanka Standard Time',
    'daylightName': 'Sri Lanka Daylight Time',
    'baseUtcOffset': '05:30:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Nepal Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+05:45) Kathmandu',
    'standardName': 'Nepal Standard Time',
    'daylightName': 'Nepal Daylight Time',
    'baseUtcOffset': '05:45:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Central Asia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+06:00) Astana',
    'standardName': 'Central Asia Standard Time',
    'daylightName': 'Central Asia Daylight Time',
    'baseUtcOffset': '06:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Bangladesh Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+06:00) Dhaka',
    'standardName': 'Bangladesh Standard Time',
    'daylightName': 'Bangladesh Daylight Time',
    'baseUtcOffset': '06:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Omsk Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+06:00) Omsk',
    'standardName': 'Omsk Standard Time',
    'daylightName': 'Omsk Daylight Time',
    'baseUtcOffset': '06:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Myanmar Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+06:30) Yangon (Rangoon)',
    'standardName': 'Myanmar Standard Time',
    'daylightName': 'Myanmar Daylight Time',
    'baseUtcOffset': '06:30:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'SE Asia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    'standardName': 'SE Asia Standard Time',
    'daylightName': 'SE Asia Daylight Time',
    'baseUtcOffset': '07:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Altai Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+07:00) Barnaul, Gorno-Altaysk',
    'standardName': 'Altai Standard Time',
    'daylightName': 'Altai Daylight Time',
    'baseUtcOffset': '07:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'W. Mongolia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+07:00) Hovd',
    'standardName': 'W. Mongolia Standard Time',
    'daylightName': 'W. Mongolia Daylight Time',
    'baseUtcOffset': '07:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'North Asia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+07:00) Krasnoyarsk',
    'standardName': 'Russia TZ 6 Standard Time',
    'daylightName': 'Russia TZ 6 Daylight Time',
    'baseUtcOffset': '07:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'N. Central Asia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+07:00) Novosibirsk',
    'standardName': 'Novosibirsk Standard Time',
    'daylightName': 'Novosibirsk Daylight Time',
    'baseUtcOffset': '07:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Tomsk Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+07:00) Tomsk',
    'standardName': 'Tomsk Standard Time',
    'daylightName': 'Tomsk Daylight Time',
    'baseUtcOffset': '07:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'China Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    'standardName': 'China Standard Time',
    'daylightName': 'China Daylight Time',
    'baseUtcOffset': '08:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'North Asia East Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+08:00) Irkutsk',
    'standardName': 'Russia TZ 7 Standard Time',
    'daylightName': 'Russia TZ 7 Daylight Time',
    'baseUtcOffset': '08:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Singapore Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+08:00) Kuala Lumpur, Singapore',
    'standardName': 'Malay Peninsula Standard Time',
    'daylightName': 'Malay Peninsula Daylight Time',
    'baseUtcOffset': '08:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'W. Australia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+08:00) Perth',
    'standardName': 'W. Australia Standard Time',
    'daylightName': 'W. Australia Daylight Time',
    'baseUtcOffset': '08:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Taipei Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+08:00) Taipei',
    'standardName': 'Taipei Standard Time',
    'daylightName': 'Taipei Daylight Time',
    'baseUtcOffset': '08:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Ulaanbaatar Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+08:00) Ulaanbaatar',
    'standardName': 'Ulaanbaatar Standard Time',
    'daylightName': 'Ulaanbaatar Daylight Time',
    'baseUtcOffset': '08:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Aus Central W. Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+08:45) Eucla',
    'standardName': 'Aus Central W. Standard Time',
    'daylightName': 'Aus Central W. Daylight Time',
    'baseUtcOffset': '08:45:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Transbaikal Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+09:00) Chita',
    'standardName': 'Transbaikal Standard Time',
    'daylightName': 'Transbaikal Daylight Time',
    'baseUtcOffset': '09:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Tokyo Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+09:00) Osaka, Sapporo, Tokyo',
    'standardName': 'Tokyo Standard Time',
    'daylightName': 'Tokyo Daylight Time',
    'baseUtcOffset': '09:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'North Korea Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+09:00) Pyongyang',
    'standardName': 'North Korea Standard Time',
    'daylightName': 'North Korea Daylight Time',
    'baseUtcOffset': '09:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Korea Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+09:00) Seoul',
    'standardName': 'Korea Standard Time',
    'daylightName': 'Korea Daylight Time',
    'baseUtcOffset': '09:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Yakutsk Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+09:00) Yakutsk',
    'standardName': 'Russia TZ 8 Standard Time',
    'daylightName': 'Russia TZ 8 Daylight Time',
    'baseUtcOffset': '09:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Cen. Australia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+09:30) Adelaide',
    'standardName': 'Cen. Australia Standard Time',
    'daylightName': 'Cen. Australia Daylight Time',
    'baseUtcOffset': '09:30:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'AUS Central Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+09:30) Darwin',
    'standardName': 'AUS Central Standard Time',
    'daylightName': 'AUS Central Daylight Time',
    'baseUtcOffset': '09:30:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'E. Australia Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+10:00) Brisbane',
    'standardName': 'E. Australia Standard Time',
    'daylightName': 'E. Australia Daylight Time',
    'baseUtcOffset': '10:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'AUS Eastern Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+10:00) Canberra, Melbourne, Sydney',
    'standardName': 'AUS Eastern Standard Time',
    'daylightName': 'AUS Eastern Daylight Time',
    'baseUtcOffset': '10:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'West Pacific Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+10:00) Guam, Port Moresby',
    'standardName': 'West Pacific Standard Time',
    'daylightName': 'West Pacific Daylight Time',
    'baseUtcOffset': '10:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Tasmania Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+10:00) Hobart',
    'standardName': 'Tasmania Standard Time',
    'daylightName': 'Tasmania Daylight Time',
    'baseUtcOffset': '10:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Vladivostok Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+10:00) Vladivostok',
    'standardName': 'Russia TZ 9 Standard Time',
    'daylightName': 'Russia TZ 9 Daylight Time',
    'baseUtcOffset': '10:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Lord Howe Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+10:30) Lord Howe Island',
    'standardName': 'Lord Howe Standard Time',
    'daylightName': 'Lord Howe Daylight Time',
    'baseUtcOffset': '10:30:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Bougainville Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+11:00) Bougainville Island',
    'standardName': 'Bougainville Standard Time',
    'daylightName': 'Bougainville Daylight Time',
    'baseUtcOffset': '11:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Russia Time Zone 10',
    'hasIanaId': false,
    'displayName': '(UTC+11:00) Chokurdakh',
    'standardName': 'Russia TZ 10 Standard Time',
    'daylightName': 'Russia TZ 10 Daylight Time',
    'baseUtcOffset': '11:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Magadan Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+11:00) Magadan',
    'standardName': 'Magadan Standard Time',
    'daylightName': 'Magadan Daylight Time',
    'baseUtcOffset': '11:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Norfolk Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+11:00) Norfolk Island',
    'standardName': 'Norfolk Standard Time',
    'daylightName': 'Norfolk Daylight Time',
    'baseUtcOffset': '11:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Sakhalin Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+11:00) Sakhalin',
    'standardName': 'Sakhalin Standard Time',
    'daylightName': 'Sakhalin Daylight Time',
    'baseUtcOffset': '11:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Central Pacific Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+11:00) Solomon Is., New Caledonia',
    'standardName': 'Central Pacific Standard Time',
    'daylightName': 'Central Pacific Daylight Time',
    'baseUtcOffset': '11:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Russia Time Zone 11',
    'hasIanaId': false,
    'displayName': '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
    'standardName': 'Russia TZ 11 Standard Time',
    'daylightName': 'Russia TZ 11 Daylight Time',
    'baseUtcOffset': '12:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'New Zealand Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+12:00) Auckland, Wellington',
    'standardName': 'New Zealand Standard Time',
    'daylightName': 'New Zealand Daylight Time',
    'baseUtcOffset': '12:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'UTC+12',
    'hasIanaId': false,
    'displayName': '(UTC+12:00) Coordinated Universal Time+12',
    'standardName': 'UTC+12',
    'daylightName': 'UTC+12',
    'baseUtcOffset': '12:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Fiji Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+12:00) Fiji',
    'standardName': 'Fiji Standard Time',
    'daylightName': 'Fiji Daylight Time',
    'baseUtcOffset': '12:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Kamchatka Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    'standardName': 'Kamchatka Standard Time',
    'daylightName': 'Kamchatka Daylight Time',
    'baseUtcOffset': '12:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Chatham Islands Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+12:45) Chatham Islands',
    'standardName': 'Chatham Islands Standard Time',
    'daylightName': 'Chatham Islands Daylight Time',
    'baseUtcOffset': '12:45:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'UTC+13',
    'hasIanaId': false,
    'displayName': '(UTC+13:00) Coordinated Universal Time+13',
    'standardName': 'UTC+13',
    'daylightName': 'UTC+13',
    'baseUtcOffset': '13:00:00',
    'supportsDaylightSavingTime': false
  },
  {
    'id': 'Tonga Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+13:00) Nuku\'alofa',
    'standardName': 'Tonga Standard Time',
    'daylightName': 'Tonga Daylight Time',
    'baseUtcOffset': '13:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Samoa Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+13:00) Samoa',
    'standardName': 'Samoa Standard Time',
    'daylightName': 'Samoa Daylight Time',
    'baseUtcOffset': '13:00:00',
    'supportsDaylightSavingTime': true
  },
  {
    'id': 'Line Islands Standard Time',
    'hasIanaId': false,
    'displayName': '(UTC+14:00) Kiritimati Island',
    'standardName': 'Line Islands Standard Time',
    'daylightName': 'Line Islands Daylight Time',
    'baseUtcOffset': '14:00:00',
    'supportsDaylightSavingTime': false
  }
];
