import { AdminInfoRoutes, AreasInfoRoutes, EnterpriseInfoRoutes, EquipmentsInfoRoutes, SiteInfoRoutes, UnitsInfoRoutes } from 'src/app/admin/constants/route.constants';
import { ApiResponse } from '../../models/api-response';
import { AppRouteConstants } from '../../app-constants/app-route-constants';
import { ASSETS_API_ENDPOINTS } from '../../app-constants/api-constants';
import { AssetType } from '../../app-constants/asset-constants';
import { BreadcrumbInfo } from '../../models/asset';
import { catchError, map, Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbsSubject: Subject<BreadcrumbInfo[]> = new Subject<BreadcrumbInfo[]>();
  public breadcrumbsDataChanged$: Observable<BreadcrumbInfo[]> = this.breadcrumbsSubject.asObservable();
  private breadcrumbs: BreadcrumbInfo[] = [];

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private http: HttpClient,
  ) { }

  setBreadcrumbInfo(breadcrumbs: BreadcrumbInfo[]): void {
    this.breadcrumbs = breadcrumbs;
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  getBreadcrumbValues(): BreadcrumbInfo[] {
    return this.breadcrumbs;
  }

  public getBreadcrumb(tenantId: string, assetId: string, preventSpinner?:boolean): Observable<BreadcrumbInfo[]>  {
    let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    if (preventSpinner)
      headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Prevent-Spinner': '' }) };
    const url = environment.dashboardApiBaseUrl + ASSETS_API_ENDPOINTS.GET_ASSET_BREADCRUMB(tenantId, assetId);
    return this.http.get<ApiResponse<BreadcrumbInfo[]>>(url, headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public loadBreadcrumb(assetId: string, tenantId: string)
  {
    this.getBreadcrumb(tenantId, assetId).subscribe((breadCrumbInfo) => {
      this.setBreadcrumbInfo(breadCrumbInfo);
    });
  }

  transformBreadcrumbs(breadcrumbs: BreadcrumbInfo[], adminPage: boolean, scope: string = ''): BreadcrumbInfo[] {
    if (!breadcrumbs || breadcrumbs.length === 0) return [];

    return breadcrumbs.map((item, index) => {
      let url = '';
      switch (item.objectType) {
        case  AssetType.ENTERPRISE: {
          // Construct URL for enterprise
          if (adminPage)
          {
            url = `${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${EnterpriseInfoRoutes.ENTERPRISE_BASE_ROUTE}/${EnterpriseInfoRoutes.ENTERPRISE_DETAILS}?enterpriseId=${item.id}`;
          }
          else {
            url = `${AppRouteConstants.ROUTE_TO_CUSTOMER}/${AppRouteConstants.ROUTE_TO_ASSETS_INFO}/${AppRouteConstants.ROUTE_TO_ASSETS_LIST}?s=${scope}`;
          }
          break;
        }
        case AssetType.SITE: {
          // Construct URL for site
          // Check if previous breadcrumb is enterprise
          const prevBreadcrumb = breadcrumbs[index - 1];
          if (adminPage)
          {
            url = `${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${SiteInfoRoutes.SITE_BASE_ROUTE}/${SiteInfoRoutes.SITE_DETAILS_ROUTE}?siteId=${item.id}&enterpriseId=${prevBreadcrumb.id}`;
          }
          else {
            url = `${AppRouteConstants.ROUTE_TO_CUSTOMER}/${AppRouteConstants.ROUTE_TO_ASSETS_INFO}/${AppRouteConstants.APP_CUSTOMER_SITE_ROUTE}?id=${item.id}&name=${item.name}&s=${scope}`;
          }

          break;
        }
        case  AssetType.AREA: {
          // Construct URL for area
          const prevEnterprise = breadcrumbs.find(b => b.objectType === AssetType.ENTERPRISE)?.id || '';
          if (adminPage)
          {
            // Check if previous breadcrumbs is site
            const prevSite = breadcrumbs.find(b => b.objectType === AssetType.SITE)?.id || '';
            url = `${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${AreasInfoRoutes.AREAS_BASE_ROUTE}/${AreasInfoRoutes.AREA_DETAILS_ROUTE}?areaId=${item.id}&siteId=${prevSite}&enterpriseId=${prevEnterprise}`;
          }
          else {
            url = `${AppRouteConstants.ROUTE_TO_CUSTOMER}/${AppRouteConstants.APP_CUSTOMER_AREA_ROUTE}/area?id=${item.id}&name=${item.name}&s=${scope}`;}
          break;
        }
        case AssetType.UNITS: {
          // Construct URL for unit
          const prevEnterprise = breadcrumbs.find(b => b.objectType === AssetType.ENTERPRISE)?.id || '';
          if (adminPage)
          {
          // Check if previous breadcrumbs are site, and area
            const prevSite = breadcrumbs.find(b => b.objectType === AssetType.SITE)?.id || '';
            const prevArea = breadcrumbs.find(b => b.objectType === AssetType.AREA)?.id || '';

            url = `${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${UnitsInfoRoutes.UNITS_BASE_ROUTE}/${UnitsInfoRoutes.UNIT_DETAILS_ROUTE}?unitId=${item.id}&areaId=${prevArea}&siteId=${prevSite}&enterpriseId=${prevEnterprise}`;
          }
          else {
            url = `${AppRouteConstants.ROUTE_TO_CUSTOMER}/${AppRouteConstants.APP_CUSTOMER_UNIT_ROUTE}/unit?id=${item.id}&name=${item.name}&s=${scope}`;
          }
          break;
        }
        case AssetType.EQUIPMENT: {
          // Construct URL for unit
          const prevEnterprise = breadcrumbs.find(b => b.objectType === AssetType.ENTERPRISE)?.id || '';
          if (adminPage)
          {
          // Check if previous breadcrumbs are site, and area
            const prevSite = breadcrumbs.find(b => b.objectType === AssetType.SITE)?.id || '';
            const prevArea = breadcrumbs.find(b => b.objectType === AssetType.AREA)?.id || '';
            const prevUnit = breadcrumbs.find(b => b.objectType === AssetType.UNITS)?.id || '';

            url = `${AdminInfoRoutes.BASE_ADMIN_ROUTE}/${EquipmentsInfoRoutes.EQUIPMENTS_BASE_ROUTE}/${EquipmentsInfoRoutes.EQUIPMENT_DETAILS_ROUTE}?equipmentId=${item.id}&enterpriseId=${prevEnterprise}&unitId=${prevUnit}&siteId=${prevSite}&areaId=${prevArea}`;
          }
          else {
            url = `${AppRouteConstants.ROUTE_TO_CUSTOMER}/${AppRouteConstants.APP_CUSTOMER_EQUIPMENT_ROUTE}/equipment?id=${item.id}&name=${item.name}&s=${scope}`;
          }
          break;
        }
        default:
          break;
      }
      return {
        id: item.id,
        objectType: item.objectType,
        name: item.name,
        label: item.name,
        url: url
      };
    });
  }
}

