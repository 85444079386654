import { UnitPreferences } from '../models/unit-preferences';

export const mockUnitPreferences: UnitPreferences[] = [
  {
    id: '1',
    name: 'Imperial'
  },
  {
    id: '2',
    name: 'Metric'
  }
];
