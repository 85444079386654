export class AssetApiIncludeConstants {
  public static readonly DEFAULT_OBJECT_TYPE_FILTERS = 'enterprise,site,area,unit,equipment,gateway,sensor';
  public static readonly ENTERPRISE_OBJECT_TYPE_FILTERS = 'site,area,unit,equipment';
  public static readonly SITE_OBJECT_TYPE_FILTERS = 'area,unit,equipment';
  public static readonly AREA_OBJECT_TYPE_FILTERS = 'unit,equipment';
  public static readonly UNIT_OBJECT_TYPE_FILTERS = 'equipment';
  public static readonly DEVICES_OBJECT_TYPE_FILTERS = 'gateway,sensor';
  public static readonly GATEWAY_LIST_REQUIRED_FIELDS = 'id,label,serialNumber,lifecycleStatus';
  public static readonly SENSOR_LIST_REQUIRED_FIELDS = 'id,label,serialNumber,objectSubType,reportedConnectionStatus,reportedFirmwareRevision,lifecycleStatus';
  public static readonly USER_CREATE_SCOPE_FILTERS = 'enterprise,site';
}
export class AssetRelations {
  public static readonly ASSETLINK = 'Associated';
}

export class AssetType {
  public static readonly UNITS = 'unit';
  public static readonly GEARBOX = 'gearbox';
  public static readonly MOTOR = 'motor';
  public static readonly ENTERPRISE = 'enterprise';
  public static readonly SITE = 'site';
  public static readonly AREA = 'area';
  public static readonly EQUIPMENT = 'equipment';
  public static readonly GATEWAY = 'gateway';
  public static readonly SENSOR = 'sensor';
  public static readonly LOCATION = 'location';
}

export class BatteryLevel {
  public static readonly MIN_BATTERY_LEVEL = '2500mV';
  public static readonly BATTERY_REPLACE = 'Replace';
}

export class DeviceLifeCycle {
  public static readonly ASSIGNED_SENSOR = 'assigned';
  public static readonly UNASSIGNED_SENSOR = 'unassigned';
}

export class DeviceState {
  public static readonly PROVISIONED  = 'Provisioned';
  public static readonly REGISTERED  = 'Registered';
  public static readonly COMMISSIONED  = 'Commissioned';
  public static readonly DECOMMISSIONED  = 'Decommissioned';
}

export class MeasurementUnits {
  public static readonly BATTERY_LEVEL_UNIT = 'mV';
  public static readonly SIGNAL_STRENGTH_UNIT = 'dBm';
}

export class Scope {
  public static readonly DEFAULT_SCOPE = '/';
}

export class ConnectionStatus
{
  public static readonly CONNECTED = 'connected';
  public static readonly DISCONNECTED = 'disconnected';
}

export class GatewayIndustrialProtocolConstants {
  public static readonly MODBUS = 'modbus';
  public static readonly ETHERNETIP = 'ethernetip';
  public static readonly PROFINET = 'profinet';
}

export class GatewayIndustrialProtocolLabelConstants {
  public static readonly MODBUS = 'Modbus TCP';
  public static readonly ETHERNETIP = 'Ethernet/IP';
  public static readonly PROFINET = 'Profinet';
}

export class UserPreferencesConstants {
  public static readonly DEFAULT_UNITOFMEASURE = '1';
  public static readonly DEFAUL_TTIMEZONE = 'UTC';
}

export class GatewayAssociationStatusConstants {
  public static readonly ASSOCIATED = 'Associated';
  public static readonly ASSOCIATIONNOTSTARTED = 'No Sensors';
  public static readonly ASSOCIATIONINPROGRESS = 'In Progress';
}
