<div class="sensor-parameter">
  <div class="grid-title">
    <h2 class="dialog-box-tittle">Sensor Parameters </h2>
    <ejs-tooltip id="tooltipFocus" [content]="flyoutContent" opensOn='Auto'>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 51 51" fill="none">
        <g clip-path="url(#clip0_5000_9931)">
          <path
            d="M25.5 51C32.263 51 38.749 48.3134 43.5312 43.5312C48.3134 38.749 51 32.263 51 25.5C51 18.737 48.3134 12.251 43.5312 7.46878C38.749 2.6866 32.263 0 25.5 0C18.737 0 12.251 2.6866 7.46878 7.46878C2.6866 12.251 0 18.737 0 25.5C0 32.263 2.6866 38.749 7.46878 43.5312C12.251 48.3134 18.737 51 25.5 51ZM21.5156 33.4688H23.9062V27.0938H21.5156C20.1908 27.0938 19.125 26.0279 19.125 24.7031C19.125 23.3783 20.1908 22.3125 21.5156 22.3125H26.2969C27.6217 22.3125 28.6875 23.3783 28.6875 24.7031V33.4688H29.4844C30.8092 33.4688 31.875 34.5346 31.875 35.8594C31.875 37.1842 30.8092 38.25 29.4844 38.25H21.5156C20.1908 38.25 19.125 37.1842 19.125 35.8594C19.125 34.5346 20.1908 33.4688 21.5156 33.4688ZM25.5 12.75C26.3454 12.75 27.1561 13.0858 27.7539 13.6836C28.3517 14.2814 28.6875 15.0921 28.6875 15.9375C28.6875 16.7829 28.3517 17.5936 27.7539 18.1914C27.1561 18.7892 26.3454 19.125 25.5 19.125C24.6546 19.125 23.8439 18.7892 23.2461 18.1914C22.6483 17.5936 22.3125 16.7829 22.3125 15.9375C22.3125 15.0921 22.6483 14.2814 23.2461 13.6836C23.8439 13.0858 24.6546 12.75 25.5 12.75Z"
            fill="#2E3238" />
        </g>
        <defs>
          <clipPath id="clip0_5000_9931">
            <rect width="51" height="51" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ejs-tooltip>
  </div>
  <form [formGroup]="sensorParameterForm">
    <div class="grid-container">
      <div id="advInterval" class="grid-item">
        <label htmlFor="advInterval">Advertisement interval</label>
        <p>Indicates when the sensor is available to communicate with the gateway (not including measurement intervals)
        </p>
      </div>
      <div class="grid-item">
        <ejs-dropdownlist id="advertisementInterval" formControlName="advertisementInterval"
          class="sensor-parameter-top-section" #advertisementInterval [fields]="fields"
          [dataSource]='advertisementIntervalSource'
          [cssClass]="getCssClass('advertisementInterval')"></ejs-dropdownlist>
      </div>
      <div class="grid-item">
        <label htmlFor="advertisementInterval">Advertisement Duration</label>
      </div>
      <div class="grid-item">
        <ejs-dropdownlist id="advertisementDuration" #advertisementDuration
          formControlName="advertisementDuration" class="sensor-parameter-top-section" [fields]="fields"
          [dataSource]='advertisementDurationSource'></ejs-dropdownlist>
      </div>
      <div class="grid-item">
        <label htmlFor="advertisementDuration">Acceleration Range</label>
      </div>
      <div class="grid-item">
        <ejs-dropdownlist id="accelerationRange" formControlName="accelerationRange"
          class="sensor-parameter-top-section" #samples [fields]="fields"
          [dataSource]='sensorsParameter.accelerationRangeSource'></ejs-dropdownlist>
      </div>
    </div>
    <div class="grid-container">
      <div class="grid-item sensor-parameter-sub-tittle">
        <label htmlFor="accelerationRange">Assign Axis</label>
      </div>
    </div>

    <div class="grid-container">
      <div id="value" class="grid-item sensor-parameter-sub-tittle">
        <label htmlFor="value">Value</label>
      </div>
      <div class="grid-item sensor-parameter-sub-tittle">
        <div id="label" class="sensor-parameter-bottom-section">
          <label htmlFor="label">Label</label>
        </div>
      </div>
      <div id="x-axis" class="grid-item">
        <label htmlFor="x-axis">X Axis</label>
      </div>
      <div class="grid-item">
        <ejs-dropdownlist formControlName="x_Axis" class="sensor-parameter-bottom-section" #samples [fields]="fields"
          [dataSource]='sensorsParameter.axisSources' [cssClass]="getCssClass('x_Axis')"></ejs-dropdownlist>
      </div>
      <div id="y-axis" class="grid-item">
        <label htmlFor="y-axis">Y Axis</label>
      </div>
      <div class="grid-item">
        <ejs-dropdownlist formControlName="y_Axis" class="sensor-parameter-bottom-section" #samples [fields]="fields"
          [dataSource]='sensorsParameter.axisSources' [cssClass]="getCssClass('y_Axis')"></ejs-dropdownlist>
      </div>
      <div id="z-axis" class="grid-item">
        <label htmlFor="z-axis">Z Axis</label>
      </div>
      <div class="grid-item">
        <ejs-dropdownlist formControlName="z_Axis" class="sensor-parameter-bottom-section" #samples [fields]="fields"
          [dataSource]='sensorsParameter.axisSources' [cssClass]="getCssClass('z_Axis')"></ejs-dropdownlist>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <app-custom-button [buttonData]="restoreDefaultsButtonData" (buttonClick)="onRestoreDefaultButtonClick()"  templatePosition="left"/>
    <app-custom-button (buttonClick)="cancel()" [buttonData]="cancelButtonData" />
    <app-custom-button (buttonClick)="save(sensorParameterForm.value)" [buttonData]="saveButtonData" />
  </div>
</div>

<ng-template #flyoutContent>
  <div class="flyoutContent">
    <div> Reported Advertisement interval </div>
    <div> {{this.reportedAdvertisementInterval}} min </div>
    <div> Reported Advertisement Duration </div>
    <div> {{this.sensorsParameter.reportedAdvertisementDuration}} sec </div>
    <div> Reported Acceleration Range </div>
    <div> {{this.sensorsParameter.reportedAccelerationRange}} g </div>
    <div> Reported X Axis </div>
    <div>
      <ng-container *ngTemplateOutlet="axisTemplate; context: { $implicit: this.sensorsParameter.reported_X_Axis }" />
    </div>
    <div> Reported Y Axis </div>
    <div>
      <ng-container *ngTemplateOutlet="axisTemplate; context: { $implicit: this.sensorsParameter.reported_Y_Axis }" />
    </div>
    <div> Reported Z Axis </div>
    <div>
      <ng-container *ngTemplateOutlet="axisTemplate; context: { $implicit: this.sensorsParameter.reported_Z_Axis }" />
    </div>
  </div>
</ng-template>

<ng-template #axisTemplate let-data>
  <span *ngIf="data === 'A'">Axial</span>
  <span *ngIf="data === 'V'">Vertical</span>
  <span *ngIf="data === 'H'">Horizontial</span>
</ng-template>
