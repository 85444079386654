import { Component } from '@angular/core';
import { RouterLinkConstants } from '../../shared/app-constants/app-route-constants';

@Component({
  selector: 'app-admin-side-nav',
  templateUrl: './admin-side-nav.component.html',
  styleUrls: ['./admin-side-nav.component.scss']
})
export class AdminSideNavComponent {
  protected readonly RouterLinkConstants = RouterLinkConstants;
}
