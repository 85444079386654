import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap } from 'rxjs';
import { ARTIFACT_MANAGEMENT_API_ENDPOINTS } from 'src/app/shared/app-constants/api-constants';
import { ApiResponse } from 'src/app/shared/models/api-response';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler/error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' }) // As off now i will keep this for quik fix later i will move this and move the dailogs and related services to shared
export class ArtifactService {
  constructor(
    private errorHandlerService: ErrorHandlerService,
    private http: HttpClient
  ) { }

  public getArtifactOsUrl(tenantId: string, code:string, type:string): string {
    return environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.GET_ALL_ARTIFACTS(tenantId, code, false, type);
  }

  public uploadArtifact(tenantId: string, artifactData: any, code:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.UPLOAD_ARTIFACT(tenantId, code);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = artifactData;
    return this.http.post<ApiResponse<any>>(url, JSON.stringify(body), headers).pipe(
      tap(response => this.errorHandlerService.handleCustomError(response.error)),
      map(response => response),
      catchError(error => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }
  // sas_url

  public getArtifactDetails(tenantId: string,  artifactId: string, code:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.GET_ARTIFACT_DEATILS_BY_ID(tenantId, artifactId, code);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAllArtifacts(tenantId: string, code:string, type:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.GET_ALL_ARTIFACTS(tenantId, code, false, type);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public updatefiletoartifact(sasUrl:any, file:File): Observable<string> {
    const url:string = sasUrl;
    const headers = { headers: new HttpHeaders({  'x-ms-blob-type': 'BlockBlob',
      'Content-Type': 'application/octet-stream' }) };
    return this.http.put<ApiResponse<string>>(url, file, headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        // this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public updateArtifactDetails(tenantId: string,  saveArtifactRequest: any, artifactId: string, code:string): Observable<string> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.PATCH_ARTIFACT_DETAILS(tenantId, artifactId, code);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = saveArtifactRequest;
    return this.http.put<ApiResponse<string>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public uploadArtifactFirmware(tenantId: string, type:string, artifactData: any): Observable<any> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.UPLOAD_ARTIFACT(tenantId, type);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = artifactData;
    return this.http.post<ApiResponse<any>>(url, JSON.stringify(body), headers).pipe(
      tap(response => this.errorHandlerService.handleCustomError(response.error)),
      map(response => response.data),
      catchError(error => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public uploadArtifactSoftwareModule(tenantId: string, type:string, artifactData: any): Observable<any> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.UPLOAD_ARTIFACT(tenantId, type);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = artifactData;
    return this.http.post<ApiResponse<any>>(url, JSON.stringify(body), headers).pipe(
      tap(response => this.errorHandlerService.handleCustomError(response.error)),
      map(response => response.data),
      catchError(error => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public revokeArtifact(tenantId: string, artifactType: string, artifactId: string): Observable<string> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.REVOKE_ARTIFACT(tenantId, artifactType, artifactId);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<ApiResponse<any>>(url, headers)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public deleteArtifact(tenantId: string, artifactId: string, code:string): Observable<string> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.DELETE_ARTIFACT(tenantId, artifactId, code);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<ApiResponse<any>>(url, headers)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public getConfigVersion(tenantId: string, code:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.GET_CONFIG_VERSION(tenantId, code);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        // this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public geModules(tenantId: string, code:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.GET_MODULES(tenantId, code);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        // this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getTags(tenantId: string, code:string, selectedModule:string): Observable<any> {
    const url = environment.dashboardApiBaseUrl + ARTIFACT_MANAGEMENT_API_ENDPOINTS.GET_TAGS(tenantId, code, selectedModule);
    return this.http.get<ApiResponse<any>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.result)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        // this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }
}
