import { Component, Input } from '@angular/core';
import { IconSize } from '../../app-constants/shared.enum';

@Component({
  selector: 'app-online-signal-status',
  templateUrl: './online-signal-status.component.html',
})
export class OnlineSignalStatusComponent {
  @Input() status:string = '';
  @Input() size:IconSize = IconSize.SIZE_XL;
}
