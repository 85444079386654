import { ApiResponse } from 'src/app/shared/models/api-response';
import { AREA_API_ENDPOINTS, ASSETS_API_ENDPOINTS, ASSETS_TREE_API_ENDPOINTS, ENTERPRISE_API_ENDPOINTS, EQUIPMENT_API_ENDPOINTS, GATEWAY_API_ENDPOINTS, SENSOR_API_ENDPOINTS, SITE_API_ENDPOINTS, UNIT_API_ENDPOINTS } from 'src/app/shared/app-constants/api-constants';
import { AreaList } from '../../models/asset-list-model/area-list';
import { Asset, AssetDto, AssetsList } from '../../models/asset';
import { EnterpriseList } from '../../models/asset-list-model/enterprise-list';
import { environment } from 'src/environments/environment';
import { EquipmentList } from '../../models/asset-list-model/equipment-list';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler/error-handler.service';
import { GatewayList } from '../../models/asset-list-model/gateway-list';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap } from 'rxjs';
import { SensorEvent, SensorList } from '../../models/asset-list-model/sensor-list';
import { SiteList } from '../../models/asset-list-model/site-list';
import { UnitList } from '../../models/asset-list-model/unit-list';
import { AssetType } from '../../app-constants/asset-constants';

@Injectable({
  providedIn: 'root'
})
export class AssetListService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getEnterpriseServiceUrl(tenantId: string): string {
    return environment.dashboardApiBaseUrl + ENTERPRISE_API_ENDPOINTS.GET_ENTERPRISE_LIST(tenantId);
  }

  public getSiteServiceUrl(tenantId: string, enterpriseId: string): string {
    return environment.dashboardApiBaseUrl + SITE_API_ENDPOINTS.GET_SITE_LIST(tenantId, enterpriseId);
  }

  public getAreaServiceUrl(tenantId: string, siteId: string): string {
    return environment.dashboardApiBaseUrl + AREA_API_ENDPOINTS.GET_AREA_LIST(tenantId, siteId);
  }

  public getUnitServiceUrl(tenantId: string, areaId: string): string {
    return environment.dashboardApiBaseUrl + UNIT_API_ENDPOINTS.GET_UNIT_LIST(tenantId, areaId);
  }

  public getGatewayListInAreaServiceUrl(tenantId: string, areaId: string): string {
    return environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_LIST_BY_ROOT_ASSET_ID(tenantId, areaId);
  }

  public getEquipmentListServiceUrl(tenanId: string, unitId: string): string {
    return environment.dashboardApiBaseUrl + EQUIPMENT_API_ENDPOINTS.GET_EQUIPMENT_LIST(tenanId, unitId);
  }

  public getSensorListForUnitServiceUrl(tenantId: string, unitId: string): string {
    return environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_LIST_BY_ROOT_ASSET_ID(tenantId, unitId);
  }

  public getSensorListForEquipmentServiceUrl(tenantId: string, equipmentId: string): string {
    return environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_LIST_BY_ROOT_ASSET_ID(tenantId, equipmentId);
  }

  public getGatewayListInSiteServiceUrl(tenantId: string, siteId: string): string {
    return environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_LIST_BY_ROOT_ASSET_ID(tenantId, siteId);
  }

  public getEnterpriseList(tenantId: string): Observable<EnterpriseList[]> {
    const url = environment.dashboardApiBaseUrl + ENTERPRISE_API_ENDPOINTS.GET_ENTERPRISE_LIST(tenantId);
    return this.http.get<ApiResponse<EnterpriseList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getEnterpriseList_paging(tenantId: string, top: number, skip: number): Observable<EnterpriseList[]> {
    const url = environment.dashboardApiBaseUrl + ENTERPRISE_API_ENDPOINTS.GET_ENTERPRISE_LIST_TOBEDELETED(tenantId, top, skip);
    return this.http.get<ApiResponse<EnterpriseList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getSiteList(tenantId: string, enterpriseId: string): Observable<SiteList[]> {
    const url = environment.dashboardApiBaseUrl + SITE_API_ENDPOINTS.GET_SITE_LIST(tenantId, enterpriseId);
    return this.http.get<ApiResponse<SiteList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAreaList(tenantId: string, siteId: string): Observable<AreaList[]> {
    const url = environment.dashboardApiBaseUrl + AREA_API_ENDPOINTS.GET_AREA_LIST(tenantId, siteId);
    return this.http.get<ApiResponse<AreaList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getUnitList(tenantId: string, areaId: string): Observable<UnitList[]> {
    const url = environment.dashboardApiBaseUrl + UNIT_API_ENDPOINTS.GET_UNIT_LIST(tenantId, areaId);
    return this.http.get<ApiResponse<UnitList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getEquipmentList(tenanId: string, unitId: string): Observable<EquipmentList[]> {
    const url = environment.dashboardApiBaseUrl + EQUIPMENT_API_ENDPOINTS.GET_EQUIPMENT_LIST(tenanId, unitId);
    return this.http.get<ApiResponse<EquipmentList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getGatewayListInSite(tenantId: string, siteId: string): Observable<GatewayList[]> {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_LIST_BY_ROOT_ASSET_ID(tenantId, siteId);
    return this.http.get<ApiResponse<GatewayList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getGatewayListInArea(tenantId: string, areaId: string): Observable<GatewayList[]> {
    const url = environment.dashboardApiBaseUrl + GATEWAY_API_ENDPOINTS.GET_GATEWAY_LIST_BY_ROOT_ASSET_ID(tenantId, areaId);
    return this.http.get<ApiResponse<GatewayList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getSensorListForUnit(tenantId: string, unitId: string): Observable<SensorList[]> {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_LIST_BY_ROOT_ASSET_ID(tenantId, unitId);
    return this.http.get<ApiResponse<SensorList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getSensorListForEquipment(tenantId: string, equipmentId: string): Observable<SensorList[]> {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_LIST_BY_ROOT_ASSET_ID(tenantId, equipmentId);
    return this.http.get<ApiResponse<SensorList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public globalSearch(tenantId: string, search: string): Observable<Asset[]> {
    const url = environment.dashboardApiBaseUrl + ASSETS_TREE_API_ENDPOINTS.GET_SEARCH_ASSETS(tenantId, search);
    return this.http.get<ApiResponse<Asset[]>>(url).pipe(map((response) => response.data));
  }

  public getAssetsListByType(tenantId: string, assetId: string, objectType: string): Observable<AssetsList[]> {
    const url = environment.dashboardApiBaseUrl + ASSETS_API_ENDPOINTS.GET_ASSETS_BY_TYPE(tenantId, assetId, objectType);
    return this.http.get<ApiResponse<AssetsList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAssetsById(tenantId: string, assetId: string): Observable<AssetDto> {
    const url = environment.dashboardApiBaseUrl + ASSETS_API_ENDPOINTS.GET_ASSETS_BY_ID(tenantId, assetId);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<ApiResponse<AssetDto>>(url, headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAssetsListByIds(tenantId: string, assetIds: string[], objectType: string): Observable<AssetsList[]> {
    const url = environment.dashboardApiBaseUrl + ASSETS_API_ENDPOINTS.GET_ASSETS_BY_IDS(tenantId, objectType);
    const body = { ids: assetIds };
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<ApiResponse<AssetsList[]>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getSensorEvents(tenantId: string, sensorSerialNumbers: string[]): Observable<SensorEvent[]> {
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_EVENTS(tenantId);
    return this.http.post<ApiResponse<SensorEvent[]>>(url, JSON.stringify(sensorSerialNumbers), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  getProjectedChildAssetById(tenantId: string, rootAssetId: string, childAssetId: string, childObjectType: string): Observable<any[]>  {
    const includeObjectType = `${AssetType.ENTERPRISE},${AssetType.SITE},${AssetType.AREA},${AssetType.UNITS},${AssetType.EQUIPMENT}`;
    const url = environment.dashboardApiBaseUrl + ASSETS_API_ENDPOINTS.GET_ASSETS(tenantId, rootAssetId, includeObjectType, childObjectType);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<ApiResponse<any[]>>(url, JSON.stringify([
      {
        assetProperty: 'id',
        operator: '==',
        value: childAssetId
      },
      {
        assetProperty: 'objectType',
        operator: '==',
        value: childObjectType
      },
    ]), headers)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  getProjectedChildAssetByIds(tenantId: string, rootAssetId: string, childAssetIds: string[], childObjectType: string): Observable<any[]>  {
    const includeObjectType = `${AssetType.ENTERPRISE},${AssetType.SITE},${AssetType.AREA},${AssetType.UNITS},${AssetType.EQUIPMENT}`;
    const url = environment.dashboardApiBaseUrl + ASSETS_API_ENDPOINTS.GET_ASSETS(tenantId, rootAssetId, includeObjectType, childObjectType);
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Prevent-Spinner': ''
      })
    };
    return this.http.post<ApiResponse<any[]>>(url, JSON.stringify([
      {
        assetProperty: 'id',
        operator: 'within',
        value: childAssetIds.join('\',\'')
      },
      {
        assetProperty: 'objectType',
        operator: '==',
        value: childObjectType
      },
    ]), headers)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }
}
