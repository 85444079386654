<div *ngIf="!this.isAuthenticatedUser" >
  <div class="row bannerbody">
    <div class="component promo promo--legacy-link-bar"><div class="component-content">
      <div class="field-promolink"><a class="ancherbody" href="https://legacy.perceptiv.com/" rel="noopener noreferrer" data-variantitemid="{EDECF61C-6F7F-4183-B6B2-9F6B7D9350CB}" data-variantfieldname="PromoLink" target="_blank">If you are an existing Perceptiv™ dashboard user, please click here to log in
        <svg version="1.1" id="Layer_2_00000080889819568844800750000005699479695224222597_"	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          width="20" height="18" viewBox="0 0 169.8 150.8" style="enable-background:new 0 0 169.8 150.8;padding-left:0.25rem;" xml:space="preserve">
          <style type="text/css">	.st0{fill:#FFFFFF;}</style>
          <g id="Layer_1-2">	<path class="st0" d="M110.1,135.8h27.6c9.4,0,17.1-7.6,17.1-17.1V32c0-9.4-7.6-17-17.1-17h-27.6c0,0,1.4-15,0-15h27.6 c17.7,0,32.1,14.4,32.1,32v86.7c0,17.7-14.4,32.1-32.1,32.1h-27.6C110.1,150.8,110.1,135.8,110.1,135.8z M115.8,76.9l-59.6,53.9 c-2.1,1.9-5.6,0.4-5.6-2.5v-28H9.2C4.1,100.3,0,96.1,0,91V66.7c0-5.1,4.1-9.2,9.2-9.2h41.5V29.4c0-2.8,3.2-4.3,5.3-2.7l59.6,45 C117.3,73,117.4,75.5,115.8,76.9L115.8,76.9z"/></g></svg>
        </a>
      </div>
    </div>
  </div>
</div>
</div>
<app-header class="header" [authenticated]="this.isAuthenticatedUser"></app-header>
<div class="body" [ngClass]="{'body': !isLoginPage,'body-at-login': isLoginPage}">
  <nav class="sidenav" *ngIf="isAuthenticatedUser" aria-label="user_nav">
    <app-side-nav *ngIf="!isAdminPage" [scope] = "scope" ></app-side-nav>
    <app-admin-side-nav *ngIf="isAdminLogin && isAdminPage"></app-admin-side-nav>
  </nav>
  <main [ngClass]="{'main': isLoginPage === false,'main-at-login': isLoginPage === true}">
    <div class="breadcrumb" *ngIf="this.isAuthenticatedUser" [ngClass]="{'breadcrumb-admin-page': isAdminPage}">
      <ejs-dropdowntree *ngIf="isAdminPage" #enterpriseDropdown cssClass="breadcrumb-dropdown dropdown-size"
        [fields] = 'remoteFieldForEnterpriseDropdown'
        [allowFiltering]='true'
        (select) = 'onChangeOfAsset($event)'
        filterType='Contains'
        [readonly]="isDropDownTreeDisabled"
        placeholder="Type Enterprise/Site Name"></ejs-dropdowntree>
      <app-breadcrumbs class="breadcrumbs" [adminPage] = 'isAdminPage'></app-breadcrumbs>
  </div>
    <hr class="dividor" *ngIf="!isLoginPage">
    <div class="main-content-placeholder"><router-outlet></router-outlet></div>
  </main>
</div>
<footer class="footer" id="combined-footer" *ngIf="isAuthenticatedUser">
  <app-footer></app-footer>
</footer>
<div id="container"></div>
<app-modal-dialog></app-modal-dialog>
