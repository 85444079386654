<div class="sidenav-container">
  <div class="sidenav-item" routerLink="customer/dashboard-info/dashboard" routerLinkActive="active" [queryParams]="{ s: scope }">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
      <path
        d="M32 16.8333C32 25.7083 24.8125 32.8333 16 32.8333C7.125 32.8333 0 25.7083 0 16.8333C0 8.02081 7.125 0.833313 16 0.833313C24.8125 0.833313 32 8.02081 32 16.8333ZM16 4.83331C14.875 4.83331 14 5.77081 14 6.83331C14 7.95831 14.875 8.83331 16 8.83331C17.0625 8.83331 18 7.95831 18 6.83331C18 5.77081 17.0625 4.83331 16 4.83331ZM16 26.8333C18.1875 26.8333 20 25.0833 20 22.8333C20 21.7708 19.5625 20.7708 18.8125 20.0833L22.8125 10.9583C23.1875 10.2083 22.8125 9.33331 22.0625 8.95831C21.3125 8.64581 20.4375 9.02081 20.125 9.77081L16.0625 18.8333H16C13.75 18.8333 12 20.6458 12 22.8333C12 25.0833 13.75 26.8333 16 26.8333ZM9 7.83331C7.875 7.83331 7 8.77081 7 9.83331C7 10.9583 7.875 11.8333 9 11.8333C10.0625 11.8333 11 10.9583 11 9.83331C11 8.77081 10.0625 7.83331 9 7.83331ZM6 18.8333C7.0625 18.8333 8 17.9583 8 16.8333C8 15.7708 7.0625 14.8333 6 14.8333C4.875 14.8333 4 15.7708 4 16.8333C4 17.9583 4.875 18.8333 6 18.8333ZM26 14.8333C24.875 14.8333 24 15.7708 24 16.8333C24 17.9583 24.875 18.8333 26 18.8333C27.0625 18.8333 28 17.9583 28 16.8333C28 15.7708 27.0625 14.8333 26 14.8333Z" />
    </svg>
    <p>Dashboard</p>
  </div>
  <div class="sidenav-item" routerLink="customer/assets-info" routerLinkActive="active" [queryParams]="{ s: scope }">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="33" viewBox="0 0 28 33" fill="none">
      <path
        d="M18.9566 26.1L18.9566 30.9C18.9566 31.7835 19.6965 32.5 20.6088 32.5L25.5653 32.5C26.4776 32.5 27.2175 31.7835 27.2175 30.9L27.2175 26.1C27.2175 25.2165 26.4776 24.5 25.5653 24.5L20.6088 24.5C19.6965 24.5 18.9566 25.2165 18.9566 26.1ZM14.8262 27.3L14.8262 17.7L17.3045 17.7L17.3045 15.3L14.8262 15.3L14.8262 5.7L17.3045 5.7L17.3045 3.3L14.3311 3.3C13.238 3.3 12.3479 4.1615 12.3479 5.2205L12.3479 15.3L9.04358 15.3L9.04358 13.3C9.04358 12.4165 8.30372 11.7 7.39141 11.7L2.43489 11.7C1.52258 11.7 0.782714 12.4165 0.782714 13.3L0.782714 19.7C0.782714 20.5835 1.52258 21.3 2.43489 21.3L7.39141 21.3C8.30372 21.3 9.04358 20.5835 9.04358 19.7L9.04358 17.7L12.3479 17.7L12.3479 27.7795C12.3479 28.8385 13.2375 29.7 14.3311 29.7L17.3045 29.7L17.3045 27.3L14.8262 27.3ZM18.9566 14.1L18.9566 18.9C18.9566 19.7835 19.6965 20.5 20.6088 20.5L25.5653 20.5C26.4776 20.5 27.2175 19.7835 27.2175 18.9L27.2175 14.1C27.2175 13.2165 26.4776 12.5 25.5653 12.5L20.6088 12.5C19.6965 12.5 18.9566 13.2165 18.9566 14.1ZM18.9566 2.1L18.9566 6.9C18.9566 7.7835 19.6965 8.5 20.6088 8.5L25.5653 8.5C26.4776 8.5 27.2175 7.7835 27.2175 6.9L27.2175 2.1C27.2175 1.2165 26.4776 0.499999 25.5653 0.499999L20.6088 0.499999C19.6965 0.499999 18.9566 1.2165 18.9566 2.1Z" />
    </svg>
    <p>Assets</p>
  </div>
  <div class="sidenav-item" routerLink="customer/system-info" routerLinkActive="active" [queryParams]="{ s: scope }">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
      <path
        d="M22 16.1666C22 17.5541 21.925 18.8916 21.7938 20.1666H10.2063C10.0688 18.8916 10 17.5541 10 16.1666C10 14.7791 10.075 13.4416 10.2063 12.1666H21.7938C21.9313 13.4416 22 14.7791 22 16.1666ZM23.8 12.1666H31.4937C31.825 13.4479 32 14.7854 32 16.1666C32 17.5479 31.825 18.8854 31.4937 20.1666H23.8C23.9312 18.8791 24 17.5416 24 16.1666C24 14.7916 23.9312 13.4541 23.8 12.1666ZM30.8375 10.1666H23.5438C22.9188 6.17288 21.6812 2.82913 20.0875 0.691626C24.9813 1.98538 28.9625 5.53538 30.8312 10.1666H30.8375ZM21.5187 10.1666H10.4812C10.8625 7.89163 11.45 5.87913 12.1687 4.24788C12.825 2.77288 13.5562 1.70413 14.2625 1.02913C14.9625 0.366626 15.5437 0.166626 16 0.166626C16.4562 0.166626 17.0375 0.366626 17.7375 1.02913C18.4437 1.70413 19.175 2.77288 19.8312 4.24788C20.5562 5.87288 21.1375 7.88538 21.5187 10.1666ZM8.45625 10.1666H1.1625C3.0375 5.53538 7.0125 1.98538 11.9125 0.691626C10.3188 2.82913 9.08125 6.17288 8.45625 10.1666ZM0.50625 12.1666H8.2C8.06875 13.4541 8 14.7916 8 16.1666C8 17.5416 8.06875 18.8791 8.2 20.1666H0.50625C0.175 18.8854 0 17.5479 0 16.1666C0 14.7854 0.175 13.4479 0.50625 12.1666ZM12.1687 28.0791C11.4437 26.4541 10.8625 24.4416 10.4812 22.1666H21.5187C21.1375 24.4416 20.55 26.4541 19.8312 28.0791C19.175 29.5541 18.4437 30.6229 17.7375 31.2979C17.0375 31.9666 16.4562 32.1666 16 32.1666C15.5437 32.1666 14.9625 31.9666 14.2625 31.3041C13.5562 30.6291 12.825 29.5604 12.1687 28.0854V28.0791ZM8.45625 22.1666C9.08125 26.1604 10.3188 29.5041 11.9125 31.6416C7.0125 30.3479 3.0375 26.7979 1.1625 22.1666H8.45625ZM30.8375 22.1666C28.9625 26.7979 24.9875 30.3479 20.0938 31.6416C21.6875 29.5041 22.9187 26.1604 23.55 22.1666H30.8375Z" />
    </svg>
    <p>System Info</p>
  </div>
</div>
