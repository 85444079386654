import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggingService } from '../login-services/logging.service';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../models/api-response';
import { SensorParameterRequest } from '../../models/sensor-parameter';
import { SENSOR_API_ENDPOINTS } from '../../app-constants/api-constants';
import { catchError, throwError, lastValueFrom, Observable, map, tap } from 'rxjs';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { SensorParameter } from '../../../admin/sensors-info/models/sensor-parameter';

@Injectable()
export class SensorParameterService {
  constructor(
    private http: HttpClient,
    private appInsightService: LoggingService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  public getSensorParameters(sensorId: string, tenantId: string): Observable<SensorParameter> {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_PARAMETER(tenantId, sensorId);
    return this.http.get<ApiResponse<SensorParameter>>(url)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public saveSensorParameter(tenantId: string, sensorId: string, sensorParameterRequest: SensorParameterRequest): Promise<ApiResponse<SensorParameter>> {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.SAVE_SENSOR_PARAMETER(tenantId, sensorId);
    const saveSensorParameterRequest: SensorParameterRequest = {
      sensorId: sensorParameterRequest.sensorId,
      advertisementInterval: sensorParameterRequest.advertisementInterval,
      advertisementDuration: sensorParameterRequest.advertisementDuration,
      accelerationRange: sensorParameterRequest.accelerationRange,
      accelerationRangeSource: sensorParameterRequest.accelerationRangeSource,
      x_Axis: sensorParameterRequest.x_Axis,
      y_Axis: sensorParameterRequest.y_Axis,
      z_Axis: sensorParameterRequest.z_Axis,
      axisSources: sensorParameterRequest.axisSources
    };
    const sensorMeasurementSchedule = this.http.put<ApiResponse<SensorParameter>>(url, saveSensorParameterRequest)
      .pipe(
        catchError((error: ApiResponse<SensorParameter>) => {
          const errorMessage = error.error?.map(pair => `${pair.key}: ${pair.value}`).join(', ');
          this.appInsightService.logException(errorMessage);
          throw error;
        })
      );
    return lastValueFrom(sensorMeasurementSchedule);
  }

  public getRestoreDefaultSensorParameters(tenantId: string): Observable<SensorParameter> {
    const url = environment.dashboardApiBaseUrl + SENSOR_API_ENDPOINTS.GET_SENSOR_DEFAULT_PARAMETER(tenantId);
    return this.http.get<ApiResponse<SensorParameter>>(url)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }
}
