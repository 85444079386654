import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SignalStrength } from '../../app-constants/strength-enum';

@Component({
  selector: 'app-cellular-signal-strength',
  templateUrl: './cellular-signal-strength.component.html',
  styleUrls: ['./cellular-signal-strength.component.scss']
})
export class CellularSignalStrengthComponent implements OnChanges {
  @Input()data!: number;
  @Input()unit: string =  'dBm';
  label!:string;
  strength:SignalStrength = SignalStrength.REPLACE;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.label = this.data ? `${this.data}` : ' ';
      this.strength = this.signalStrengthSignalLevel(this.data);
    }
  }

  /*
  *  0 dBm or null: display no bars
  * -100 dBm or less: display no bars
  * -100 dBm to -80 dBm: display one bar
  * -80 dBm to -60 dBm: display two bars
  * -60 dBm to -40 dBm: display three bars
  * -40 dBm or better: display four bars
  */
  public signalStrengthSignalLevel(level?:number): SignalStrength {
    if (level === undefined)
      return SignalStrength.REPLACE;
    switch (true) {
      case (level === 0 || level === null):  //0 dBm or null: display no bars
        return SignalStrength.REPLACE;
      case (level >= -40):  //-40 dBm or better: display four bars
        return SignalStrength.HIGH;
      case (level >= -60) && (level < -40):  //-60 dBm to -40 dBm: display three bars
        return SignalStrength.GOOD;
      case (level >= -80) && (level < -60):  //80 dBm to -60 dBm: display two bars
        return SignalStrength.LOW;
      case (level >= -100) && (level < -80):  //-100 dBm to -80 dBm: display one bar
        return SignalStrength.VERYLOW;
      case (level <= -100):  //-100 dBm or less: display no bars
        return SignalStrength.REPLACE;
      default:
        return SignalStrength.REPLACE;
    }
  }
}
