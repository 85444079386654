<form [formGroup]="formGroup" class="role-assign-container" *ngIf="formGroup">
  <h2 class="role-assign-heading pure-black-color">Assign Role & Scope</h2>
  <div class="select-role-container">
    <p>Select Role</p>
    <ejs-dropdownlist formControlName="selectedRole" [dataSource]='selectRoleOptions'
      [fields]="selectRoleOptionsFieldMapping"></ejs-dropdownlist>
  </div>
  <div class="select-tenant-container" [ngClass]="{ 'disabled': selectTenantDisabled }">
    <p>Select Tenant</p>
    <ejs-dropdownlist formControlName="selectedTenant" [dataSource]='selectTenantOptions'
      [fields]="selectTenantOptionsFieldMapping"></ejs-dropdownlist>
  </div>
  <div class="enterprise-list-container" [ngClass]="{ 'disabled': enterpriseDataDisabled }">
    <app-tree-grid #scopeGrid [gridUIType]="gridUIType" [data]="enterpriseData" [columns]="treeColumns"
      [gridHeight]="gridHeight" [childMapping]="treeChildMapping" [treeColumnIndex]="0"
      [autoCheckHierarchy]="true"></app-tree-grid>
    <div *ngIf=validationFailed>
      <small class="error-message">Please ensure that you have selected at least one asset before proceeding further.</small>
    </div>
  </div>
  <hr>
  <div class="modal-btns">
    <app-custom-button (buttonClick)="cancel()" [buttonData]="cancelButtonData" />
    <app-custom-button (buttonClick)="save()" [buttonData]="saveButtonData" />
  </div>
</form>
