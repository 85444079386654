import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserDetails } from 'src/app/admin/user-management/models/user-details';
import { AllRolesAssignedToUser } from 'src/app/customer/user-management/models/all-roles-assigned-to-user';
import { Permission } from 'src/app/customer/user-management/models/permission';
import { Role } from 'src/app/customer/user-management/models/role';
import { UserRoleAssignment } from 'src/app/customer/user-management/models/user-role-assignment';
import { environment } from 'src/environments/environment';
import { RoleName } from '../../app-constants/role-constants';
import { LoginState } from '../../models/login-state';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public allRolesAssignedToUser!: AllRolesAssignedToUser[];
  public emailId: string | undefined;
  public loginState: LoginState = {
    loginStatus: false,
    roleName: '',
    isAdminPage: false,
    isAdminRole: false
  };

  public loginState$: BehaviorSubject<LoginState> = new BehaviorSubject<LoginState>(this.loginState);
  public permissions!: Permission[];
  public requestedUrl: string = '/';
  public roleName: string = '';
  public roles!: Role[];
  public tenantId: string = environment.tenantId;
  public userId!: string;
  public userRoleAssignment!: UserRoleAssignment[];
  public userDetails!: UserDetails;
  public defaultEnterpriseId!: string;
  public userScope!: string;
  public roleType: string = '';
  public isAdminRole: boolean = false;

  public filterPermissions(componentPermissions: string[]): string[] {
    if (this.roleName === RoleName.PERCEPTIV_PLATFORM_ADMIN) return componentPermissions;
    if (this.allRolesAssignedToUser && this.allRolesAssignedToUser.length > 0) {
      const permissionNames: string[] = this.permissions.map(p => p.name);
      return componentPermissions.filter(element => permissionNames.includes(element));
    }
    return [];
  }
}
