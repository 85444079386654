import { Component, Input, ViewChild } from '@angular/core';
import { ButtonLabels, ButtonNames, ButtonTypes, CustomEventSource, CustomEventType, GridUIType } from 'src/app/shared/app-constants/shared.enum';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { TreeGridColumnData } from 'src/app/shared/models/tree-grid';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { TreeGridComponent } from '../../tree-grid/tree-grid.component';

@Component({
  selector: 'app-grid-template',
  templateUrl: './grid-template.component.html',
  styleUrls: ['./grid-template.component.scss']
})
export class GridTemplateComponent {
  @ViewChild('modalGrid') modalGrid!: TreeGridComponent;
  @Input() id!: string; // A unique id to passed back to the consumer
  @Input() heading!: string;
  @Input() columnData!: TreeGridColumnData[];
  @Input() rowData!: Object[];
  @Input() allowMultiselect!: boolean;

  allowSorting: boolean = true;
  toolbarOption: string[] = ['Search'];
  gridUIType: GridUIType = GridUIType.CHECK_BOX_FLAT_GRID;
  gridHeight: string = '50vh';
  confirmButtonData = { label: ButtonLabels.CONFIRM, type: ButtonTypes.PRIMARY_BASIC, name: ButtonNames.CONFIRM, width: '10rem' };
  cancelButtonData = { label: ButtonLabels.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.CANCEL };

  constructor(private modalDialogService: ModalDialogService) { }

  // Method call on click of Cancel button
  onCancelButtonClick() {
    this.closeModal();
  }

  // Method call on click of Confirm button
  onConfirmButtonClick() {
    const customData: CustomEvent = { eventSource: CustomEventSource.MODAL, eventType: CustomEventType.GRID_TEMPLATE_ITEMS_SELECTED, eventData: { id: this.id, selectedItems: this.modalGrid.treeGrid.getSelectedRecords() } };
    this.modalDialogService.emitModalDialogEvent(customData);
    this.resetRowData();
  }

  // Method to close modal
  closeModal() {
    this.modalDialogService.closeModal();
    this.resetRowData();
  }

  resetRowData() {
    this.rowData = [];
  }
}
