<div class="alert-signal-template-container" *ngIf="alertEventData">
  <span class="event-icon" [ngClass]="getAlertIcon()"></span>
  <div class="alert-signal-template-texts">
    <div class="text-sm alert-heading" [ngClass]="{'pointer': isAlertNotificationClickable(alertEventData)}" (click)="alertNotificationClicked(alertEventData)"
      (keypress)="alertNotificationClicked(alertEventData)" tabindex="0">
      <ng-container *ngIf="alertEventData.equipmentId;">
        <app-asset-hierarchy-tool-tip [label]="title" [assetHierarchyData] = "assetHierarchyData"
        [preloadedData] = "preloadedData"></app-asset-hierarchy-tool-tip>
      </ng-container>
      <ng-container *ngIf="!alertEventData.equipmentId">
        {{ title }}
      </ng-container>
    </div>
    <small class="text-xsm">{{message}}</small>
    <small class="text-xsm timestamp">{{timeAgoValue(alertEventData.timestamp)}}</small>
  </div>
</div>