import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-signal-status',
  templateUrl: './signal-status.component.html'
})
export class SignalStatusComponent {
  @Input() label!:string;
  @Input() onlineStatus = '';
}
