import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonLabels, ButtonNames, ButtonSizes, ButtonTypes, ModalTemplateTypes } from 'src/app/shared/app-constants/shared.enum';
import { ButtonData, OpenModalDialogData } from 'src/app/shared/models/shared.models';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { ArtifactService } from '../../services/artifact-management.service';
import { ArtifactManagementServiceService } from '../../services/artifact-management-data-service.service';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.scss']
})
export class AddTagComponent implements OnInit {
  addButtonData: ButtonData = { label: 'Add',  name: 'Add',  type: ButtonTypes.PRIMARY_BASIC, size: ButtonSizes.SM };
  artifactdetails:any;
  artifactId:string = '';
  availabletags: object[] = [{ Id: '1', text: 'Default' }, { Id: '1', text: 'Release Candidate 1' }, { Id: '1', text: 'Latest' }, { Id: '1', text: 'General Available' }];
  cancelButtonData: ButtonData = { label: ButtonLabels.CANCEL, name: ButtonNames.CANCEL, type: ButtonTypes.PRIMARY_BORDER, size: ButtonSizes.SM };
  code:string = 'XW6ORRWMR28jq174vBpWwdBHFMOMib5gYFZnCc0RbioBAzFubz52iA==';
  formGroup!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public modalDialogService: ModalDialogService,
    private artifactServices:ArtifactService,
    private artifactManagementService: ArtifactManagementServiceService,
    private sessionService: SessionService
  ) {}

  ngOnInit():void {
    this.formGroup = this.formBuilder.group({
      artifactTag: [null],
    });
    this.artifactId = this.artifactManagementService.getAddTagArtifactId();
    this.artifactServices.getArtifactDetails(this.sessionService.tenantId, this.artifactId, this.code).subscribe({
      next: (artifact) => {
        if (!artifact) {
          // this.routeToParent();
          return;
        }
        this.artifactdetails = artifact;
        this.updateFormGroup(artifact);
      },

    });
  }

  updateFormGroup(artifact:any) {
    this.formGroup.patchValue({
      artifactTag: artifact.tags,
    });
  }

  onCancel(): void {
    this.modalDialogService.closeModal();
  }

  onAddTag(): void {
    const artifactTag = this.formGroup?.get('artifactTag')?.value;
    const updateArtifactFirmwareRequest =
    {
      ...this.artifactdetails, tags: artifactTag
    };
    this.artifactServices.updateArtifactDetails(this.sessionService.tenantId, updateArtifactFirmwareRequest, this.artifactId, this.code).subscribe((response) => {
      const data = new OpenModalDialogData();
      data.templateName = ModalTemplateTypes.SUCCESS_TEMPLATE;
      data.customData.message = 'Tag added successfully';
      this.modalDialogService.openModal(data);
      this.modalDialogService.closeModal();
      this.onCancel();
    });
  }
}
