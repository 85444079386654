<div class="error-container">
  <svg class="modal-close-icon" (click)="this.closeModal()" (keypress)="closeModal()" xmlns="http://www.w3.org/2000/svg" width="20" height="21"
    viewBox="0 0 20 21" fill="none">
    <path
      d="M16.2727 6.11055C16.7933 5.58996 16.7933 4.74453 16.2727 4.22394C15.7521 3.70335 14.9067 3.70335 14.3861 4.22394L10.0007 8.61355L5.61104 4.2281C5.09045 3.70751 4.24502 3.70751 3.72443 4.2281C3.20384 4.74869 3.20384 5.59413 3.72443 6.11472L8.11403 10.5002L3.72859 14.8898C3.208 15.4104 3.208 16.2558 3.72859 16.7764C4.24918 17.297 5.09462 17.297 5.61521 16.7764L10.0007 12.3868L14.3903 16.7722C14.9108 17.2928 15.7563 17.2928 16.2769 16.7722C16.7975 16.2516 16.7975 15.4062 16.2769 14.8856L11.8873 10.5002L16.2727 6.11055Z"
      fill="#2E3238" />
  </svg>
  <svg class="modal-main-icon" xmlns="http://www.w3.org/2000/svg" width="59" height="57" viewBox="0 0 59 57"
    fill="none">
    <path
      d="M57.2582 28.5605C57.2582 43.5446 44.7674 55.6855 29.3615 55.6855C13.9557 55.6855 1.46484 43.5446 1.46484 28.5605C1.46484 13.5852 13.9557 1.43555 29.3615 1.43555C44.7674 1.43555 57.2582 13.5852 57.2582 28.5605ZM30.1101 10.4043C23.9799 10.4043 20.0701 12.9152 16.9999 17.3778C16.6022 17.956 16.7352 18.7357 17.3053 19.156L21.2085 22.0337C21.794 22.4654 22.6282 22.3627 23.0831 21.8016C25.0926 19.3234 26.4704 17.8863 29.5289 17.8863C31.8269 17.8863 34.6693 19.3244 34.6693 21.4911C34.6693 23.1291 33.2787 23.9703 31.0097 25.2072C28.3636 26.6495 24.8621 28.4447 24.8621 32.9355V33.373C24.8621 34.0979 25.4665 34.6855 26.2119 34.6855H32.5112C33.2566 34.6855 33.861 34.0979 33.861 33.373V33.2272C33.861 30.1142 43.2183 29.9846 43.2183 21.5605C43.2183 15.2166 36.4506 10.4043 30.1101 10.4043ZM29.3615 37.5293C26.5083 37.5293 24.1871 39.7862 24.1871 42.5605C24.1871 45.3347 26.5083 47.5918 29.3615 47.5918C32.2148 47.5918 34.5359 45.3347 34.5359 42.5605C34.5359 39.7862 32.2148 37.5293 29.3615 37.5293Z"
      fill="#CC3300" />
  </svg>
  <div class="modal-message">{{message}}</div>
  <hr>
  <div class="modal-btns">
    <app-custom-button (buttonClick)="onOkayButtonClick()" [buttonData]="okayButtonData" />
  </div>
</div>