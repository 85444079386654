import { AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertInfo } from '../../components/asset-status-indicator/asset-status-indicator.component';
import { Injectable } from '@angular/core';
import { DayOfWeek } from '../../app-constants/shared.enum';
import { NotificationList } from '../../../admin/notification-rules-management/model/notification-list';
import { NotificationFrequency } from '../../../admin/notification-rules-management/model/notification-view-model';
import { AssetType } from '../../app-constants/asset-constants';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor() { }

  buildFrequencyColumnData(notificationList: NotificationList[]): NotificationList[] {
    notificationList.forEach((notificationList) => {
      if (notificationList) {
        switch (notificationList.frequency) {
          case NotificationFrequency.Daily:
            if (notificationList.dailyFrequencyTime)
            {
              const dailyFrequencyTime = new Date(notificationList.dailyFrequencyTime);
              notificationList.frequency = this.formatDailyNotification(notificationList.frequency, dailyFrequencyTime);
            }
            break;
          case NotificationFrequency.Weekly:
            if (notificationList.weeklyFrequencyTime && notificationList.dayOfWeek) {
              const weeklyFrequencyTime = new Date(notificationList.weeklyFrequencyTime);
              notificationList.frequency = this.formatWeeklyNotification(weeklyFrequencyTime, notificationList.dayOfWeek);
            }
            break;
          case NotificationFrequency.Monthly:
            notificationList.frequency = this.formatMonthlyNotification(notificationList.dayOfMonth);
            break;
        }
      }
    });
    return notificationList;
  }

  isNullOrEmptyOrWhitespace(str: string | null | undefined): boolean {
    return !str || !str.trim();
  }

  getNumberStringFromText(value: string): string {
    return value?.match(/-?\s?\d+/g)?.join('') ?? '';
  }

  getScopeIdFromHierarchy(input: string): string {
    const parts = input.split('/');
    for (let i = parts.length - 1; i >= 0; i--) {
      if (parts[i]) {
        return parts[i];
      }
    }

    // If no non-empty parts are found, return an empty string
    return '';
  }

  formatMonthlyNotification(dayOfMonth: number): string {
    let suffix = 'th';
    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
      suffix = 'st';
    } else if (dayOfMonth === 2 || dayOfMonth === 22) {
      suffix = 'nd';
    } else if (dayOfMonth === 3 || dayOfMonth === 23) {
      suffix = 'rd';
    }
    return `Every Month ${dayOfMonth}${suffix} Day`;
  }

  formatWeeklyNotification(weeklyTime: Date, dayOfWeek: DayOfWeek): string {
    const weeklyFrequencyTime = weeklyTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    return `Every ${dayOfWeek} at ${weeklyFrequencyTime}`;
  }

  formatDailyNotification(frequency: string, dailyTime: Date): string {
    const dailyFrequencyTime = dailyTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    return `${frequency} at ${dailyFrequencyTime}`;
  }

  extractScopeIdFromScopeHierarchy(scopeIdOfHierarchy: string): string[] {
    return scopeIdOfHierarchy.trim().split('/')
      .filter(scopeId => scopeId !== '');
  }

  getAssetTypeByScope(scope: string): string | null {
    const parts = scope.split('/').filter(part => part);
    switch (parts.length) {
      case 1:
        return AssetType.ENTERPRISE;
      case 2:
        return AssetType.SITE;
      case 3:
        return AssetType.AREA;
      case 4:
        return AssetType.UNITS;
      case 5:
        return AssetType.EQUIPMENT;
      default:
        return null;
    }
  }

  processDataAndFindMaxLastCommunication(data: any[]): { statusIndicators: AlertInfo[], maxLastCommunication: string } {
    const statusIndicators: AlertInfo[] = [];
    let maxLastCommunication: string = '';

    data.forEach(item => {
      // Extract status indicators
      item.status.forEach((status: any) => {
        // Check if the alert type is already present in statusIndicators
        const index = statusIndicators.findIndex(indicator => indicator.alertType === status.alertType);
        if (index === -1) {
          // If not present, add it to statusIndicators
          statusIndicators.push({ alertType: status.alertType });
        }
      });

      if (item.lastCommunication && (!maxLastCommunication || item.lastCommunication < maxLastCommunication)) {
        maxLastCommunication = item.lastCommunication;
      }
    });

    return { statusIndicators, maxLastCommunication };
  }
}

export function customWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;
    if (value == null) return null; // Handle null or undefined values
    const utilitiesService = new UtilitiesService();
    const isWhitespace = utilitiesService.isNullOrEmptyOrWhitespace(value);
    return isWhitespace ? { 'whitespace': true } : null;
  };
}
